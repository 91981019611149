import posthog from 'posthog-js';

// Only initialize if not already initialized
if (!posthog.__loaded) {
  posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY ?? '', {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
    loaded: posthog => {
      if (import.meta.env.DEV) posthog.debug();
    }
  });
}

// Export the initialized instance
export default posthog;
