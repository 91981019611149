import SearchIcon from '@material-design-icons/svg/round/search.svg?react';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';

export const Caption = styled.div`
  margin-top: var(--space-1);
  ${TEXT_STYLES.bodyCopySmall}
`;

export const Container = styled.div`
  position: relative;
`;

export const Description = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-dark-dark);
`;

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--app-status-error);
`;

export const Icon = styled(SearchIcon)`
  position: absolute;
  top: 0;
  right: calc(1rem - 1px);
  bottom: 0;
  width: 1.5rem;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--gray-800);
`;

export const Input = styled.input`
  width: 100%;
  height: 3rem;
  padding: calc(0.75rem - 1px) calc(0.5rem + 1.5rem + (1rem - 1px)) calc(0.75rem - 1px)
    calc(1rem - 1px);
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  background-color: var(--white-light);
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  outline: none;

  &::placeholder {
    color: var(--gray-500);
  }

  &::-webkit-search-cancel-button {
    display: none;
  }

  &:focus {
    padding: calc(0.75rem - 2px) calc(0.5rem + 1.5rem + (1rem - 2px)) calc(0.75rem - 2px)
      calc(1rem - 2px);
    background-position: right calc(1rem - 2px) center;
    border-width: 2px;
    border-color: var(--brand-bright);

    &::placeholder {
      color: var(--gray-800);
    }
  }
`;

export const Label = styled.label`
  display: block;
  padding-bottom: 0.5rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
`;

export const SearchField = styled.div`
  &.is-disabled {
    ${Description} {
      color: var(--gray-dark-light);
    }

    ${Input} {
      border-color: var(--gray-light-dark);

      &::placeholder {
        color: var(--gray-light-dark);
      }
    }
  }
`;
