import * as Sentry from '@sentry/react';
import type { FormEventHandler, Key } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { AriaCheckboxGroupProps, AriaComboBoxProps } from 'react-aria';
import { useFilter } from 'react-aria';
import { useLocation, useNavigate } from 'react-router-dom';
import { Item } from 'react-stately';
import ComboBox from '@/components/FormFields/ComboBox';
import OnboardingHero from '@/components/heroes/OnboardingHero';
import { STATES } from '@/pages/constants';
import { LANGUAGE_SEARCH_TEXT } from '@/pages/strings';

import BackNext from '../../../components/Buttons/BackNext';
import GroupCheckbox from '../../../components/FormFields/CheckboxGroup/GroupCheckbox';
import type { Props as LanguageSearchProps } from '../../../components/FormFields/LanguageSearch';
import TextField from '../../../components/FormFields/TextField';
import useBreakpointRange from '../../../hooks/useBreakpointRange';
import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../hooks/useUser';
import LanguageProficienciesData from '../../../utils/data/LanguageProficiencies';
import OnboardingLayout from '../components/OnboardingLayout';

import BenchmarksModalDialog from './BenchmarksModalDialog';
import PhoneNumberField from './PhoneNumberField';
import * as S from './styles';

const Information = () => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  const location = useLocation();
  const navigate = useNavigate();

  const { state: locationState } = location as { state: LocationState };

  const openErrorModalDialog = useOpenErrorModalDialog();

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken, setUser, user } = useUser();

  const functionToCallAfterUserUpdateRef = useRef<() => void>();

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [pronounSets, setPronounSets] = useState<string[]>(user.user_info.pronouns);
  const [selectedLanguages, setSelectedLanguages] = useState<
    { id?: string; language: Key | null; proficiency: Key }[]
  >([]);
  const [smsConsent, setSmsConsent] = useState<boolean | undefined>(user.user_info.sms_consent);
  const [smsCheckboxIsDisabled, setSmsCheckboxIsDisabled] = useState<boolean>();
  const [state, setState] = useState<Key | null | undefined>(user.state ?? undefined);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState<boolean>(
    user.user_info.privacy_policy ?? false
  );
  const [privacyPolicyTouched, setPrivacyPolicyTouched] = useState<boolean>(false);

  const { contains } = useFilter({ sensitivity: 'base' });

  const formFieldRefs = {
    city: useRef<HTMLInputElement>(null),
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    npi: useRef<HTMLInputElement>(null),
    phoneNumber: useRef<HTMLInputElement>(null)
  };

  const languageProficienciesDataRef = useRef(new LanguageProficienciesData(bearerToken));
  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }
  }, [user]);

  useEffect(() => {
    languageProficienciesDataRef.current.findAll().then(({ json, response }) => {
      if (!response.ok) {
        openErrorModalDialog();
      } else {
        setSelectedLanguages(json.data);
      }
    });
  }, [openErrorModalDialog]);

  const handleFormSubmit: FormEventHandler = async event => {
    event.preventDefault();
    updateFormValidity();
    if (pronounSets.length === 0) {
      return;
    }
    if (
      formFieldRefs.firstName.current?.validity.valid === false ||
      formFieldRefs.lastName.current?.validity.valid === false ||
      !privacyPolicyChecked
    ) {
      return;
    }

    setFormIsSubmitting(true);

    const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/onboard`;

    const firstName = formFieldRefs.firstName.current!.value;
    const lastName = formFieldRefs.lastName.current!.value;
    const phoneNumber = formFieldRefs.phoneNumber.current!.value;
    const npi = formFieldRefs.npi.current?.value ?? null;
    const city = formFieldRefs.city.current?.value ?? null;

    const options: RequestInit = {
      body: JSON.stringify({
        user: {
          city,
          first_name: firstName,
          id: user.id,
          last_name: lastName,
          state,
          user_info: {
            id: user.user_info.id,
            npi: npi !== null && npi.length > 0 ? npi : null,
            phone_number: phoneNumber.replace(/[^0-9]/g, ''),
            privacy_policy: privacyPolicyChecked,
            pronouns: pronounSets,
            sms_consent: smsConsent,
            user_id: user.id
          }
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else if (response.status === 422) {
          const error = (await response.json()) as { ['user_info.npi']: string[] };
          if (error['user_info.npi'].includes('has already been taken')) {
            throw new Error('npi_error_taken');
          } else if (
            error['user_info.npi'].includes('is the wrong length (should be 10 characters)')
          ) {
            throw new Error('npi_error_length');
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APIUsersOnboard;

      const languageUpdateSuccess =
        await languageProficienciesDataRef.current.updateAll(selectedLanguages);

      if (!languageUpdateSuccess) {
        throw new Error('There was an issue saving languages.');
      }

      setUser(data);

      functionToCallAfterUserUpdateRef.current = () => {
        navigate('/onboarding/identity');
      };
    } catch (error) {
      if (error instanceof Error && error.message.includes('npi_error_')) {
        if (error.message.includes('npi_error_taken')) {
          formFieldRefs.npi.current!.setCustomValidity(
            'This NPI is already taken. Check it is typed correctly, or contact support.'
          );
          formFieldRefs.npi.current!.reportValidity();
        } else if (error.message.includes('npi_error_length')) {
          formFieldRefs.npi.current!.setCustomValidity('NPI should be 10 characters long.');
          formFieldRefs.npi.current!.reportValidity();
        }
      } else {
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    }

    setFormIsSubmitting(false);
  };

  const handleLanguageSearchAddition: LanguageSearchProps['addLanguage'] = value => {
    const newSelection = selectedLanguages.concat(value);
    setSelectedLanguages(newSelection);
  };

  const handleLanguageSearchDeletion: LanguageSearchProps['deleteLanguage'] = value => {
    const newSelection = selectedLanguages.filter(item => item !== value);
    setSelectedLanguages(newSelection);
  };

  const handlePronounSetsChange: AriaCheckboxGroupProps['onChange'] = pronounSets => {
    setPronounSets(pronounSets);
  };

  const updateSmsCheckboxDisabledState = useCallback(() => {
    const currentPhoneNum = formFieldRefs.phoneNumber.current!;
    const phoneNumberValidity = currentPhoneNum.validity;

    if (!phoneNumberValidity.valid || currentPhoneNum.value === '') {
      setSmsConsent(false);
      setSmsCheckboxIsDisabled(true);
    } else {
      setSmsCheckboxIsDisabled(false);
    }
  }, [formFieldRefs.phoneNumber]);

  useEffect(() => {
    updateSmsCheckboxDisabledState();
  }, [updateSmsCheckboxDisabledState]);

  const clearNpiErrors = () => {
    formFieldRefs.npi.current!.setCustomValidity('');
  };

  const updateStateSelection: AriaComboBoxProps<object>['onSelectionChange'] = value =>
    setState(value);

  const updateFormValidity = () => {
    /* TODO: refactor this page to
        use isInvalid and errorMessage props on required fields
    */
    setPrivacyPolicyTouched(true);
    formFieldRefs.firstName.current!.reportValidity();
    formFieldRefs.lastName.current!.reportValidity();
  };

  return (
    <>
      <OnboardingLayout progressBarValue={0}>
        <OnboardingHero
          copy="In order to get started on Violet, we'll first need to collect some information about you."
          header="Personal information."
        />
        <form
          noValidate
          onSubmit={handleFormSubmit}
        >
          <S.YourInformationTextFields.Root>
            <S.YourInformationTextFields.Header>
              Your information
            </S.YourInformationTextFields.Header>
            <S.YourInformationTextFields.TextFields>
              <TextField
                ref={formFieldRefs.firstName}
                isRequired
                autoComplete="given-name"
                data-cy="first-name-field"
                defaultValue={user.first_name!}
                label="First name"
                validationBehavior="native"
              />
              <TextField
                ref={formFieldRefs.lastName}
                isRequired
                autoComplete="family-name"
                data-cy="last-name-field"
                defaultValue={user.last_name!}
                label="Last name"
                validationBehavior="native"
              />
              <PhoneNumberField
                ref={formFieldRefs.phoneNumber}
                defaultValue={user.user_info.phone_number ?? ''}
                onChange={updateSmsCheckboxDisabledState}
              />
            </S.YourInformationTextFields.TextFields>
            {user.is_clinical && (
              <S.YourInformationTextFields.TextFields className="addtl-row">
                <TextField
                  ref={formFieldRefs.npi}
                  data-cy="npi-field"
                  defaultValue={user.user_info.npi ?? ''}
                  errorMessage={formFieldRefs.npi.current?.validationMessage}
                  isInvalid={formFieldRefs.npi.current?.validity.valid === false}
                  label="NPI (National Provider ID)"
                  onChange={clearNpiErrors}
                />
                <TextField
                  ref={formFieldRefs.city}
                  autoComplete="address-level2"
                  data-cy="city-field"
                  defaultValue={user.city!}
                  label="City"
                />
                <ComboBox
                  data-cy="state-field"
                  filter={contains}
                  label="State"
                  // Remove when this is resolved: https://github.com/adobe/react-spectrum/issues/5492
                  //@ts-expect-error - This is a valid prop
                  selectedKey={state}
                  onSelectionChange={updateStateSelection}
                >
                  {STATES.map(({ abbreviation, name }) => (
                    <Item
                      key={abbreviation}
                      textValue={`${abbreviation} (${name})`}
                    >
                      {abbreviation} ({name})
                    </Item>
                  ))}
                </ComboBox>
              </S.YourInformationTextFields.TextFields>
            )}
          </S.YourInformationTextFields.Root>
          <S.Label>Pronouns*</S.Label>
          <S.PronounsCheckboxGroup
            allowOther
            isRequired
            aria-label="Pronouns*"
            data-cy="pronoun-sets-field"
            direction={isInMobileBreakpointRange ? 'vertical' : 'horizontal'}
            errorMessage={pronounSets.length === 0 ? 'Please select at least one option.' : ''}
            isInvalid={pronounSets.length === 0}
            otherLabel="Or enter your pronouns"
            size="regular"
            validationBehavior="native"
            value={pronounSets}
            onChange={handlePronounSetsChange}
          >
            <GroupCheckbox value="She/Her/Hers">She/Her/Hers</GroupCheckbox>
            <GroupCheckbox value="He/Him/His">He/Him/His</GroupCheckbox>
            <GroupCheckbox value="They/Them/Theirs">They/Them/Theirs</GroupCheckbox>
            <GroupCheckbox value="Prefer not to say">Prefer not to say</GroupCheckbox>
          </S.PronounsCheckboxGroup>
          <S.LanguageSearch
            addLanguage={handleLanguageSearchAddition}
            copy={
              user.is_clinical
                ? LANGUAGE_SEARCH_TEXT.copyClinical
                : LANGUAGE_SEARCH_TEXT.copyNonClinical
            }
            deleteLanguage={handleLanguageSearchDeletion}
            header={
              user.is_clinical
                ? LANGUAGE_SEARCH_TEXT.titleClinical
                : LANGUAGE_SEARCH_TEXT.titleNonClinical
            }
            selectedLanguages={selectedLanguages}
          />
          <S.SmsConsentCheckbox
            data-cy="sms-consent-field"
            defaultSelected={user.user_info.sms_consent}
            isDisabled={smsCheckboxIsDisabled}
            isSelected={smsConsent}
            onChange={setSmsConsent}
          >
            {smsCheckboxIsDisabled === true ? (
              <S.DisabledText>
                A phone number is required to enable receiving SMS messages.
              </S.DisabledText>
            ) : (
              <>I consent to receiving messages through SMS.</>
            )}
          </S.SmsConsentCheckbox>
          <S.PrivacyPolicyTocAgreementCheckbox
            isRequired
            data-cy="privacy-policy-agreement-field"
            hasError={privacyPolicyTouched && !privacyPolicyChecked}
            isInvalid={privacyPolicyTouched && !privacyPolicyChecked}
            isSelected={privacyPolicyChecked}
            onChange={isChecked => {
              setPrivacyPolicyChecked(isChecked);
              setPrivacyPolicyTouched(true);
            }}
          >
            I agree to the{' '}
            <S.Anchor
              href="https://www.joinviolet.com/privacy-policy"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </S.Anchor>{' '}
            and{' '}
            <S.Anchor
              href="https://www.joinviolet.com/terms-of-service"
              rel="noreferrer"
              target="_blank"
            >
              Terms and Conditions
            </S.Anchor>
            .
          </S.PrivacyPolicyTocAgreementCheckbox>
          <BackNext
            backTo="/onboarding/welcome"
            nextIsLoading={formIsSubmitting}
            nextLabel="Next: Identities"
          />
        </form>
      </OnboardingLayout>
      {(locationState === null || locationState.referrer !== '/onboarding/identity') &&
        user.is_clinical && <BenchmarksModalDialog />}
    </>
  );
};

export default Information;
