import { Temporal } from '@js-temporal/polyfill';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  description?: string;
  title: string;
}

const NetworkReportHeader = ({ description = '', title }: Props) => {
  const todaysDate = Temporal.Now.plainDateISO();
  return (
    <div>
      <S.ReportUpperHeader>
        <span>
          {todaysDate.month}/{todaysDate.day}/{todaysDate.year}
        </span>
        <S.Logo
          hideReserved
          color="green"
          size="small"
        />
      </S.ReportUpperHeader>
      <S.ReportTitle $hasDesciption={isNonEmptyString(description)}>{title}</S.ReportTitle>
      {isNonEmptyString(description) && <S.ReportDescription>{description}</S.ReportDescription>}
    </div>
  );
};

export default NetworkReportHeader;
