import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

export interface Notification {
  createdAt: string;
  data: {
    // new_benchmarks object -> scores
    scores?: {
      image: string;
      level: string;
      type: string;
    }[];
  };
  id: string;
  read: boolean;
  templateName: 'new_benchmarks';
  updatedAt: string;
}

interface ContextValue {
  fetchUnreadNotifications: () => Promise<void>;
  markNotificationRead: (id: string) => Promise<void>;
  notifications: Notification[];
  setNotifications: Dispatch<SetStateAction<Notification[]>>;
  unreadNotifications: Notification[];
}

export const NotificationContext = createContext<ContextValue>({
  fetchUnreadNotifications: () => Promise.resolve(),
  markNotificationRead: (_id: string) => Promise.resolve(),
  notifications: [],
  setNotifications: () => {},
  unreadNotifications: []
});

const useNotifications = () => useContext(NotificationContext);

export default useNotifications;
