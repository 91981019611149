import type { NetworkDiversity } from '@/pages/Dashboard/utils';

import Meter from '../Meter';

import * as S from './styles';

interface Props {
  actionButton?: React.ReactNode;
  data:
    | NetworkDiversity['genderIdentity']
    | NetworkDiversity['language']
    | NetworkDiversity['raceEthnicity']
    | NetworkDiversity['sexualOrientation'];
  type: 'genderIdentity' | 'language' | 'raceEthnicity' | 'sexualOrientation';
}

const labelMaxWidths = {
  genderIdentity: 128,
  language: 72,
  raceEthnicity: 214,
  sexualOrientation: 101
};

const DemographicGraph = ({ actionButton, data, type }: Props) => (
  <S.MeterWrapper
    $labelWidth={labelMaxWidths[type]}
    className={`demographic-${type}-graph`}
  >
    {data.map(({ label, percentage }) => (
      <Meter
        key={[type, label].join('-')}
        data-cy="meter"
        label={label}
        value={percentage}
      />
    ))}
    {actionButton !== undefined && <S.ButtonWrapper>{actionButton}</S.ButtonWrapper>}
  </S.MeterWrapper>
);

export default DemographicGraph;
