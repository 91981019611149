import { useFeatureFlagEnabled } from 'posthog-js/react';

const useGetFaqLinks = () => {
  const isKnowledgeBaseEnabled = useFeatureFlagEnabled('knowledge_base');

  return isKnowledgeBaseEnabled === true
    ? {
        benchmarks: 'https://help.joinviolet.com/providers/benchmarks/overview',
        ceCredit: 'https://help.joinviolet.com/providers/ce-cme',
        sharingBenchmarks:
          'https://help.joinviolet.com/providers/benchmarks/sharing-benchmarks#html'
      }
    : {
        benchmarks: 'https://www.joinviolet.com/faq/benchmarks',
        ceCredit: 'https://www.joinviolet.com/faq/ce-credit',
        sharingBenchmarks:
          'https://wordpress.com/support/wordpress-editor/blocks/custom-html-block/'
      };
};

export default useGetFaqLinks;
