import AddCircleIcon from '@material-design-icons/svg/filled/add_circle.svg?react';
import type { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import { produce } from 'immer';
import type { FormEventHandler } from 'react';
import { createRef, useEffect, useRef, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { useOutletContext } from 'react-router-dom';
import Button from '@/components/Buttons/Button';
import ButtonGroup from '@/components/Buttons/ButtonGroup';
import ExpandableEditableTable from '@/components/ExpandableEditableTable';
import PageTitle from '@/components/PageTitle';
import type { VioletToast } from '@/components/ToastProvider';
import useOpenErrorModalDialog from '@/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import useUser from '@/hooks/useUser';
import WhatWorkExperienceToAddPopover from '@/pages/components/WhatWorkExperienceToAddPopover';
import WorkExperienceRow from '@/pages/Onboarding/components/WorkExperienceRow';
import type { WorkExperienceFormType } from '@/pages/Onboarding/WorkExperience';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

const WorkExperience = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken, setUser, user } = useUser();
  const toastState = useOutletContext();

  const checkValidityOfWorkExperienceRowsRef = useRef<(() => boolean)[]>([]);
  const functionToCallAfterUserUpdateRef = useRef<() => void>();
  const formRef = useRef<HTMLFormElement>(null);

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(0);
  const [invalidRowIndices, setInvalidRowIndices] = useState<number[]>([]);
  const [workExperiences, setWorkExperiences] = useState<WorkExperienceFormType[]>([]);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const mapExperiencesToFormRefs = (workExperiences: User['user_info']['experiences']) => {
    /***** Create form field refs from existing experiences */
    const experiencesToFormRefs = workExperiences.map((experience, index) => {
      const remapExperience = {
        averagePanelSize: createRef<HTMLInputElement | null>(),
        bipocPercentage: createRef<HTMLInputElement | null>(),
        department: createRef<HTMLInputElement | null>(),
        endDate: createRef<string | null>(),
        id: experience.id,
        index,
        isCurrentRole: createRef<HTMLInputElement | null>(),
        jobTitle: createRef<HTMLInputElement | null>(),
        lgbqPercentage: createRef<HTMLInputElement | null>(),
        organization: createRef<HTMLInputElement | null>(),
        startDate: createRef<string | null>(),
        tgncPercentage: createRef<HTMLInputElement | null>()
      };
      return remapExperience;
    });
    setWorkExperiences(experiencesToFormRefs);
  };

  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }

    if (hasRendered) return;
    /***** Create form field refs from existing experiences */
    const workExperiences = [...user.user_info.experiences];
    mapExperiencesToFormRefs(workExperiences);

    setHasRendered(true);
  }, [hasRendered, user]);

  const removeWorkExperience = async (index: number) => {
    const item = workExperiences.find(experience => experience.index === index);

    if (item && isNonEmptyString(item.id)) {
      const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard/experiences/${item.id}`;
      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        method: 'DELETE'
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }
      } catch (error) {
        (toastState as ToastState<VioletToast>).add(
          {
            description: 'Something went wrong. Please check for errors.',
            type: 'error'
          },
          { timeout: 8000 }
        );
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    }

    const newWorkExperiences = workExperiences.filter(experience => experience.index !== index);
    setWorkExperiences(newWorkExperiences);
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    setHasAttemptedSubmit(true);
    refreshInvalidRowIndices();

    if (
      checkValidityOfWorkExperienceRowsRef.current.some(
        checkValidityOfWorkExperienceRow => !checkValidityOfWorkExperienceRow()
      )
    ) {
      formRef.current!.reportValidity();
      return;
    }

    setFormIsSubmitting(true);
    const newExperienceValues = workExperiences.map(workExperience => ({
      average_panel_size: workExperience.averagePanelSize.current!.value,
      bipoc_percentage: workExperience.bipocPercentage.current!.value,
      department: workExperience.department.current!.value,
      end_date: workExperience.isCurrentRole.current!.checked
        ? null
        : workExperience.endDate.current!,
      id: workExperience.id,
      job_title: workExperience.jobTitle.current!.value,
      lgbq_percentage: workExperience.lgbqPercentage.current!.value,
      organization: workExperience.organization.current!.value,
      start_date: workExperience.startDate.current!,
      tgnc_percentage: workExperience.tgncPercentage.current!.value
    }));
    try {
      const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard/experiences/batch`;

      const options: RequestInit = {
        body: JSON.stringify({
          experiences: newExperienceValues
        }),
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        method: 'PATCH'
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data: updatedExperiences } =
        (await response.json()) as APIUsersDashboardExperiencesBatch;

      setUser(
        produce(user, draft => {
          draft.user_info.experiences = updatedExperiences;
        })
      );

      functionToCallAfterUserUpdateRef.current = () => {
        /***** Reset form field refs from saved experiences */
        mapExperiencesToFormRefs(updatedExperiences);
        window.scrollTo(0, 0);
        (toastState as ToastState<VioletToast>).add(
          {
            description: 'Your data has been saved.',
            type: 'success'
          },
          { timeout: 8000 }
        );
        setHasAttemptedSubmit(false);
      };
    } catch (error) {
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setFormIsSubmitting(false);
  };

  const addWorkExperience = () => {
    const lastIndex = workExperiences.length - 1;
    const lastSavedWorkExp = lastIndex >= 0 ? workExperiences[lastIndex] : null;
    const newIndex = lastSavedWorkExp ? lastSavedWorkExp.index + 1 : 0;
    const newWorkExperience: WorkExperienceFormType = {
      averagePanelSize: createRef<HTMLInputElement | null>(),
      bipocPercentage: createRef<HTMLInputElement | null>(),
      department: createRef<HTMLInputElement | null>(),
      endDate: createRef<string | null>(),
      id: undefined,
      index: newIndex,
      isCurrentRole: createRef<HTMLInputElement | null>(),
      jobTitle: createRef<HTMLInputElement | null>(),
      lgbqPercentage: createRef<HTMLInputElement | null>(),
      organization: createRef<HTMLInputElement | null>(),
      startDate: createRef<string | null>(),
      tgncPercentage: createRef<HTMLInputElement | null>()
    };
    setWorkExperiences([...workExperiences, newWorkExperience]);
    setExpandedRowIndex(newIndex);
  };

  const refreshInvalidRowIndices = () => {
    const invalidRows = checkValidityOfWorkExperienceRowsRef.current
      .map((checkValidityOfWorkExperienceRow, index) =>
        checkValidityOfWorkExperienceRow() ? null : index
      )
      .filter((index): index is number => index !== null);
    setInvalidRowIndices(invalidRows);
  };

  useEffect(() => {
    if (workExperiences.length === 0) return;
    refreshInvalidRowIndices();
  }, [workExperiences]);

  const autoPopulatedRowIndices = user.user_info.experiences
    .filter(exp => exp.source === 'claims_data')
    .map((_, index) => index);
  const centeredColumnIndices = [0, 4, 6];

  return (
    <>
      <PageTitle
        description="Add any clinical work experience in which you provided care to BIPOC, LGBQ, and TGNC communities, including your current position."
        title="Work experience"
        titleVariant="h1"
      />
      <form
        ref={formRef}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <ExpandableEditableTable
          aria-label="Work experience table"
          autoPopulatedRowIndices={autoPopulatedRowIndices}
          centeredColumnIndices={centeredColumnIndices}
          emptyMessage="You have not added any work experience."
          expandedRowIndex={expandedRowIndex}
          hasAttemptedSubmit={hasAttemptedSubmit}
          invalidRowIndices={invalidRowIndices}
        >
          <thead>
            <tr>
              <th>
                <VisuallyHidden>EXPAND / COLLAPSE</VisuallyHidden>
              </th>
              <th>JOB TITLE</th>
              <th>ORGANIZATION</th>
              <th>DEPARTMENT</th>
              <th>CURRENT ROLE</th>
              <th>START / END DATE</th>
              <th>ACTIONS</th>
              <th>
                <VisuallyHidden>EXPANDED CONTENT</VisuallyHidden>
              </th>
            </tr>
          </thead>
          <tbody>
            {workExperiences.map((experience, index) => {
              const initialState = user.user_info.experiences.find(ex => ex.id === experience.id);
              return (
                <WorkExperienceRow
                  key={experience.id ?? experience.index}
                  checkValidityRef={checkValidityOfWorkExperienceRowsRef}
                  data-cy={`work-experience-row-${experience.index ? experience.index : index}`}
                  expandedRowIndex={expandedRowIndex}
                  handleExpandCollapseClick={index =>
                    setExpandedRowIndex(expandedRowIndex === index ? -1 : index)
                  }
                  handleValidityChange={refreshInvalidRowIndices}
                  hasAttemptedSubmit={hasAttemptedSubmit}
                  index={index}
                  initialState={initialState}
                  isInvalid={hasAttemptedSubmit && invalidRowIndices.includes(index)}
                  workExperience={experience}
                  onRemove={removeWorkExperience}
                />
              );
            })}
          </tbody>
        </ExpandableEditableTable>
        <S.AddExperienceButton
          data-cy="add-experience-button"
          trailingIcon={AddCircleIcon}
          variant="plain"
          onPress={addWorkExperience}
        >
          Add experience
        </S.AddExperienceButton>
        <WhatWorkExperienceToAddPopover />
        <ButtonGroup
          align="right"
          size="large"
        >
          <Button
            data-cy="save-button"
            isLoading={formIsSubmitting}
            size="large"
            type="submit"
          >
            Save
          </Button>
        </ButtonGroup>
      </form>
    </>
  );
};

export default WorkExperience;
