import UnstyledButton from '@/components/UnstyledButton';
import { FOCUS_STYLES } from '@/constants';
import styled from 'styled-components';

export const CollapsibleButton = styled(UnstyledButton)`
  margin-bottom: auto;
  height: 1.5rem;
  min-width: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-focused {
    ${FOCUS_STYLES}
    outline-offset: 1px;
    border-radius: 50%;
  }

  svg {
    fill: var(--gray-400);
    height: 1.5rem;
    min-width: 1.5rem;
  }

  &.is-hovered,
  &.is-active,
  &:active {
    svg {
      fill: var(--gray-500);
    }
  }
`;
