import { createCalendar } from '@internationalized/date';
import { useRef } from 'react';
import type { AriaDateFieldProps, DateValue } from 'react-aria';
import { useDateField, useLocale } from 'react-aria';
import { useDateFieldState } from 'react-stately';

import * as S from '../styles';

import DateSegment from './DateSegment';

interface DateFieldProps extends AriaDateFieldProps<DateValue> {
  displayErrorMessage: () => void;
}

const DateField = ({ displayErrorMessage, label, ...ariaDateFieldProps }: DateFieldProps) => {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...ariaDateFieldProps,
    createCalendar,
    locale
  });

  const ref = useRef<HTMLInputElement>(null);
  const { fieldProps, labelProps } = useDateField(ariaDateFieldProps, state, ref);

  return (
    <S.DateWrapper>
      <span {...labelProps}>{label}</span>
      <S.DateField
        {...fieldProps}
        ref={ref}
        className={state.isInvalid ? 'is-invalid' : 'is-valid'}
      >
        {state.segments.map((segment, i) => (
          <DateSegment
            key={i}
            segment={segment}
            state={state}
          />
        ))}
        {state.isInvalid && (
          <S.DateErrorContainer
            aria-hidden="true"
            onPress={displayErrorMessage}
          >
            <S.DateErrorIcon />
          </S.DateErrorContainer>
        )}
      </S.DateField>
    </S.DateWrapper>
  );
};

export default DateField;
