import Popover from '@/components/Popover';
import styled from 'styled-components';

import Button from '../Button';

interface MenuPopoverProps {
  $width?: number;
}

export const MenuButton = styled(Button)`
  &.no-gap {
    padding: 0.5rem 0.5rem 0.5rem 1rem;

    > div {
      column-gap: 0.2rem;
    }
  }
`;

export const MenuPopover = styled(Popover)<MenuPopoverProps>`
  ${({ $width }) => {
    if ($width !== undefined) {
      return `width: ${$width}px;`;
    }
  }}
`;
