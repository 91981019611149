import CheckCircleIcon from '@material-design-icons/svg/round/check_circle.svg?react';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';

export const CheckIcon = styled(CheckCircleIcon)`
  fill: var(--action-complete-default);
  height: 1.5rem;
  width: auto;
`;

export const EducationComplete = styled.div`
  display: flex;
  column-gap: 0.375rem;
  align-items: center;
  ${TEXT_STYLES.flagSmall}
`;
