import type { FC, SVGProps } from 'react';

import * as S from './styles';

interface Props {
  children: string;
  'data-cy'?: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
}

const Label = ({ children, icon, ...args }: Props) => (
  <S.Label {...args}>
    {icon && <S.LeadingIcon as={icon} />}
    {children}
  </S.Label>
);

export default Label;
