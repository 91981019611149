import type { LocationFilter } from '@/pages/Dashboard/hooks/useGetNetworkLocations';
import type { ServiceAreaLocationFilter } from '@/pages/Dashboard/hooks/useGetNetworkServiceAreaComparisons';

export const prepareLocationsParam = (
  locations: AtLeastOne<LocationFilter>[] | ServiceAreaLocationFilter[] | string | undefined
): string | undefined => {
  if (locations === undefined) return undefined;
  const locationsIsArray = Array.isArray(locations);
  const moreThanOneLocation = locationsIsArray && locations.length > 0;
  return Array.isArray(locations)
    ? moreThanOneLocation
      ? JSON.stringify(locations)
      : undefined
    : locations;
};
