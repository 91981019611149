import styled from 'styled-components';
import UnstyledButton from '../UnstyledButton';
import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;

  &.active {
    background: var(--light-purple-200);
    color: var(--purple-400);
  }
`;

// eslint-disable-next-line sort-exports/sort-exports
export const NavButton = styled(UnstyledButton)`
  display: flex;
  height: 3rem;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  color: var(--purple-400);
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  font-size: 1.0625rem;

  &.active {
    border-bottom: 3px solid var(--purple-400);
  }

  &.is-hovered ${Tab} {
    border-radius: 0.75rem;
    background: var(--light-purple-200);

    &.active {
      background: var(--light-purple-400);
      color: var(--purple-400);
    }
  }

  &.is-focused {
    outline: 0;

    ${Tab} {
      border-radius: 0.75rem;
      background: var(--light-purple-200);
      outline: 2px solid var(--bright-purple-400);
      outline-offset: 2px;

      &.active {
        background: var(--light-purple-400);
        color: var(--purple-400);
      }
    }
  }
`;

export const Navigation = styled.nav<{ $alignment: 'left' | 'center' }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-wrap: wrap;
  }

  ${({ $alignment }) => {
    if ($alignment === 'center') {
      return `
        align-self: center;
      `;
    } else {
      return `
        align-self: flex-start;
      `;
    }
  }}
`;
