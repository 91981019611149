import CloseIconSvg from '@material-symbols/svg-400/rounded/close.svg?react';
import styled, { keyframes } from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import UnstyledButton from '../../UnstyledButton';

import FireworksBgUrl from './fireworks-bg.png';

const bangEffect = keyframes`
  to {
    box-shadow: ${generateFireworksShadow()};
  }
`;

const gravityEffect = keyframes`
  to {
    transform: translateY(200px);
    opacity: 0;
  }
  from {
    opacity: 1;
  }
`;

const positionEffect = keyframes`
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 10%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 90%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 5%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 50%;
  }
`;

const spinIn = keyframes`
  from {
    transform: rotate(0deg) scale(0.8);
    opacity: 0;
  }
  to {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
`;

const textExpandIn = keyframes`
  from {
    transform: scaleX(0.9);
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    opacity: 1;
  }
`;

export const BadgeImage = styled.img`
  width: 5.25rem;
  height: 5.25rem;
  position: relative;
  z-index: 1;
`;

export const BadgeImageContainer = styled(UnstyledButton)`
  display: flex;
  width: 5.875rem;
  height: 5.875rem;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #1a6760;
  box-shadow:
    0 0 10px rgba(16, 24, 40, 0.1),
    0 6.6667px 10px -3.3333px rgba(16, 24, 40, 0.03);
  position: relative;
  z-index: 1;
  animation: ${spinIn} 1s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  transform-origin: center;

  &:focus {
    outline: 2px solid var(--bright-purple-400);
    outline-offset: 2px;
  }

  &:hover {
    box-shadow:
      0 0 10px rgba(16, 24, 40, 0.2),
      0 6.6667px 10px -3.3333px rgba(16, 24, 40, 0.13);
  }
`;

export const ButtonContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  position: relative;
`;

export const CloseButton = styled(UnstyledButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  right: 1rem;
  border-radius: 6.25rem;
  background: var(--gray-100);
  color: var(--gray-500);
  padding: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  z-index: 5;

  &.is-hovered {
    background: var(--gray-200);
  }

  &.is-pressed {
    background: var(--gray-300);
  }

  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
    outline-offset: 2px;
  }
`;

export const CloseIcon = styled(CloseIconSvg)`
  fill: currentcolor;
  width: 1rem;
  height: 1rem;
`;

export const ConfettiAnimation = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18rem;
  height: 18rem;
`;

export const CongratulationsText = styled.div`
  font-family: 'Recoleta Alt', serif;
  color: white;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  letter-spacing: 0.05rem;
  font-size: 2rem;
  text-shadow: 0 0 3px rgba(0, 0, 0, 1);
  position: absolute;
  z-index: 5;
  bottom: 2.5rem;
  width: 100%;
  animation: ${textExpandIn} 1s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  animation-delay: 0.2s;
  transform-origin: center;
`;

export const Dialog = styled.div`
  position: relative;
  max-height: 100%;
  border-radius: 1.5rem;
  overflow: hidden auto;
  outline: none;
  border: 1px solid var(--gray-200);
  max-width: 34rem;
  background-color: var(--white-light);
`;

export const EarnedBadgesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
`;

export const FireworkAnimationAfter = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation:
    ${bangEffect} 1s ease-out infinite backwards,
    ${gravityEffect} 1s ease-in infinite backwards,
    ${positionEffect} 5s linear infinite backwards;
  animation-delay: 2.5s, 2.5s, 2.5s;
  animation-duration: 2s, 2s, 10s;
`;

export const FireworkAnimationBefore = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation:
    ${bangEffect} 1s ease-out infinite backwards,
    ${gravityEffect} 1s ease-in infinite backwards,
    ${positionEffect} 5s linear infinite backwards;
  animation-delay: 0s, 0s, 0s;
  animation-duration: 2s, 2s, 10s;
`;

export const GrowthJourneyBlock = styled.div`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  margin-top: 0.5rem;
`;

export const GrowthJourneyText = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-600);
`;

export const GrowthJourneyTitle = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  font-weight: 500;
  color: var(--gray-800);
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ModalImageWrapper = styled.div`
  height: 14.5rem;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #134a45;
  background-image: url(${FireworksBgUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
`;

export const NewBenchmarksModalDialog = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  padding: var(--space-6);
  background: rgba(29, 41, 57, 0.5);
`;

export const Text = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-600);
`;

export const TextBlock = styled.div`
  padding: 1rem 1.5rem 1.5rem;
  display: grid;
  row-gap: 1rem;
  color: var(--gray-800);
`;

export const Title = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Bold}
  margin: 0 auto;
  color: var(--gray-800);
  text-align: center;
  max-width: 20.875rem;
`;

export const TooltipContent = styled.div`
  max-width: 21rem;
  font-size: 0.8125rem;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: var(--gray-700);
`;

export const TooltipText = styled.p`
  margin-bottom: 0;
`;

function generateFireworksShadow() {
  const particles = 75;
  const width = 700;
  const height = 400;
  const shadows = [];

  for (let i = 0; i < particles; i++) {
    const x = Math.random() * width - width / 2;
    const y = Math.random() * height - height / 1.2;
    const colors = [
      '#f5bfff',
      '#ef9fff',
      '#ea7fff',
      '#fddcab',
      '#d1e9ff',
      '#b2ddff',
      '#84caff',
      '#fedf89',
      '#fec84b',
      '#a6f4c5',
      '#6ce9a6',
      '#32d583'
    ];
    const color = colors[Math.floor(Math.random() * colors.length)];
    shadows.push(`${x}px ${y}px ${color}`);
  }

  return shadows.join(',');
}

export const TooltipTitle = styled.div`
  font-weight: 700;
`;
