export const STRINGS = {
  UnderstandingThisReportTitle: 'Understanding this report',
  UnderstandingThisReportDescription:
    "Backed by clinical studies, Violet's methodology demonstrates that inclusive care delivery significantly improves patient engagement and adherence to preventive care, leading to better outcomes for patients and reduced costs for employers and payers.",
  UnderstandingThisReportDefinitions: {
    Inclusive:
      'Violet views inclusivity as a clinical skill that can be measured and improved. Inclusive providers are evaluated and benchmarked based on their level of cultural competence, ranging from being aware, proficient, to excellent in one or more communities.',
    BIPOC: 'Black, Indigenous, People of Color',
    LGBQ: 'Lesbian, Gay, Bisexual, Queer',
    TGNC: 'Transgender, Non-conforming',
    Demographics:
      "Demographic data is compiled based on providers' self-reported identity information collected by Violet.",
    Engagement:
      "Providers voluntarily opt-in to Violet's measurement process, and their participation is indicated through engagement metrics.",
    'Quality improvement':
      "Providers are enhancing their culturally responsive skill set through Violet's evidence-based clinical training specifically designed to prioritize patient identities."
  }
};
