import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import type { Notification } from '@/hooks/useNotifications';
import useNotifications from '@/hooks/useNotifications';
import { setLocalStorageWithExpiration } from '@/utils/localStorageWithExpiration';

import NewBenchmarksNotification from '../Modals/NewBenchmarksNotification';

const NotificationView = () => {
  const navigate = useNavigate();
  const { markNotificationRead, unreadNotifications } = useNotifications();
  const [visibleNotification, setVisibleNotification] = useState<Notification | null>(null);
  const [isMarkingNotificationAsRead, setIsMarkingNotificationAsRead] = useState(false);
  const {
    close: closeNotificationDialog,
    isOpen: notificationDialogIsOpen,
    open: openNotificationDialog
  } = useOverlayTriggerState({});

  useEffect(() => {
    if (isMarkingNotificationAsRead) {
      return;
    }
    /*
      If there are multiple notifications, we want to show the first one.
      If there are no notifications, make sure the dialog is closed.
      After a notification is marked read, we want to fetch the next unread notification.
    */
    if (unreadNotifications.length > 0) {
      setVisibleNotification(unreadNotifications[0]);
      openNotificationDialog();
    } else {
      setVisibleNotification(null);
      closeNotificationDialog();
    }
  }, [
    unreadNotifications,
    closeNotificationDialog,
    openNotificationDialog,
    isMarkingNotificationAsRead
  ]);

  const handleCloseNotificationDialog = async () => {
    setIsMarkingNotificationAsRead(true);

    if (visibleNotification !== null) {
      try {
        setLocalStorageWithExpiration(
          `latestEarnedBadges`,
          visibleNotification.data.scores?.map(score => score.type.toUpperCase()).join(',') ?? '',
          1000 * 60 * 60 * 24 * 3
        );
        await markNotificationRead(visibleNotification.id).then(() => {
          setVisibleNotification(null);
          closeNotificationDialog();
          setIsMarkingNotificationAsRead(false);
        });
      } finally {
        setIsMarkingNotificationAsRead(false);
      }
    } else {
      closeNotificationDialog();
      setIsMarkingNotificationAsRead(false);
    }
  };

  const handleGoToPageClick = async (path: string) => {
    await handleCloseNotificationDialog();
    navigate(path);
  };

  return (
    visibleNotification !== null &&
    notificationDialogIsOpen && (
      <NewBenchmarksNotification
        close={handleCloseNotificationDialog}
        handleGoToPageClick={handleGoToPageClick}
        notification={visibleNotification}
      />
    )
  );
};

export default NotificationView;
