import type { SortDescriptor } from 'react-stately';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';
import Table from '@/components/Table';
import EmptyTableState from '@/components/Table/EmptyState';
import type { AssignmentUser } from '@/pages/Dashboard/utils';

import * as S from './styles';

interface Props {
  handleTableSortChange: (descriptor: SortDescriptor) => void;
  sortDescriptor: SortDescriptor;
  users: AssignmentUser[];
}

const AssignmentUserTable = ({ handleTableSortChange, sortDescriptor, users }: Props) => (
  <Table
    aria-label="Table listing all users for this assignment."
    data-cy="assignment-users-table"
    renderEmptyState={() => (
      <EmptyTableState
        colSpan={6}
        message="No users have been found."
      />
    )}
    sortDescriptor={sortDescriptor}
    onSortChange={handleTableSortChange}
  >
    <TableHeader>
      <Column
        key="full_name"
        allowsSorting
      >
        Name
      </Column>
      <Column
        key="email"
        allowsSorting
      >
        Email
      </Column>
      <Column
        key="is_clinical"
        allowsSorting
      >
        User type
      </Column>
      <Column
        key="status"
        allowsSorting
      >
        User status
      </Column>
      <Column
        key="assignment_status"
        allowsSorting
      >
        Assignment status
      </Column>
      <Column
        key="completed_at"
        allowsSorting
      >
        Completion date
      </Column>
    </TableHeader>
    <TableBody>
      {users.map(user => (
        <Row
          key={user.id}
          data-cy="assignment-user-row"
        >
          <Cell>{user.fullName}</Cell>
          <Cell>{user.email}</Cell>
          <Cell>{user.isClinical ? 'Clinical' : 'Non-clinical'}</Cell>
          <Cell>
            <S.StatusLabel>
              <S.StatusCircle className={user.status === 'activated' ? 'active' : 'inactive'} />
              {user.status === 'activated' ? 'Active' : 'Inactive'}
            </S.StatusLabel>
          </Cell>
          <Cell>
            <S.StatusPill className={user.assignmentStatus.toLowerCase().replaceAll('_', '-')}>
              {user.assignmentStatus === 'complete'
                ? 'Completed'
                : user.assignmentStatus === 'in_progress'
                  ? 'Started'
                  : 'Not started'}
            </S.StatusPill>
          </Cell>
          <Cell>
            {user.completedAt !== undefined ? user.completedAt.toLocaleString('en-US') : '--'}
          </Cell>
        </Row>
      ))}
    </TableBody>
  </Table>
);

export default AssignmentUserTable;
