import Button from '@/components/Buttons/Button';
import PageTitle from '@/components/PageTitle';
import styled from 'styled-components';

import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../../../constants';
import Main from '../../components/Main';

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const BackToCollectionsButton = styled(Button)`
  position: relative;
  display: inline-grid;
  margin-bottom: 1.5rem;
  background-color: #fff;

  &:hover {
    background-color: var(--purple-400);
  }
`;

export const CollectionCompletedDate = styled.span`
  font-weight: 400;
`;

export const CollectionPageTitle = styled(PageTitle)`
  grid-gap: 1.5rem;
`;

export const CollectionProgressBar = styled.div`
  display: inline-flex;
  height: 2.25rem;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.5rem;
  border: 1px solid #eaecf0;
  background: #fff;
  color: var(--gray-500);
  ${TEXT_STYLES.tagLarge}
  line-height: 140%;
  letter-spacing: 0.0088rem;
`;

export const CollectionProgressLabel = styled.div`
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
`;

export const MainWrapper = styled(Main)`
  @media all and (width <= 1200px) {
    grid-template-columns: 1fr;
  }

  @media all and (width > 1200px) {
    grid-template-columns: minmax(200px, 1000px) 1fr;
  }
`;

export const ResourceList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > li {
    border-radius: 1rem;
    border-bottom-width: 1px;
  }

  .course {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
`;
