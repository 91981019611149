import Button from '@/components/Buttons/Button';
import TextField from '@/components/FormFields/TextField';
import UnstyledButton from '@/components/UnstyledButton';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const BodyText = styled.div`
  ${TEXT_STYLES.bodyCopyLarge}
  color: var(--gray-500);
`;

export const BodyTextWithTooltip = styled(BodyText)`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
`;

export const InputField = styled(TextField)`
  input {
    margin-top: 1rem;
  }
`;

export const LoginLink = styled(UnstyledButton)`
  color: var(--purple-400);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SmallText = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--gray-800);
  margin-top: 0.5rem;
`;

export const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

export const Title = styled.h1`
  ${TEXT_STYLES.headlineStandardLevel2BoldRegular}
  margin: 0 0 0.5rem;
`;
