import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import type { OrganizationAssignment } from '../utils';
import { generateOrganizationAssignment } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchOrganizationAssignments = (filters: OrganizationAssignmentsFilters) => Promise<void>;

interface OrganizationAssignmentsFilters {
  active?: 'false' | 'true';
  assignment_completed?: 'false' | 'true';
  completed_onboarding?: 'false' | 'true';
  order_by?: {
    column: string | undefined;
    dir: 'asc' | 'desc' | undefined;
  };
  page?: number;
  per_page?: number;
  resource_name?: string;
}

interface UseGetOrganizationAssignments {
  (
    organizationId: string,
    filters: OrganizationAssignmentsFilters
  ): {
    assignments: OrganizationAssignment[] | undefined;
    isFetchingOrgAssignments: boolean;
    totalAssignmentPages: number;
    updateAssignmentsFilters: (newFilters: OrganizationAssignmentsFilters) => void;
  };
}

const useGetOrganizationAssignments: UseGetOrganizationAssignments = (organizationId, filters) => {
  const isFirstRender = useIsFirstRender();
  const { getRequest, reportError } = useApiRequest();

  const [assignments, setAssignments] = useState<OrganizationAssignment[]>();
  const [isFetchingOrgAssignments, setIsFetchingOrgAssignments] = useState(false);
  const [query, setQuery] = useState<OrganizationAssignmentsFilters>(filters);
  const [totalAssignmentPages, setTotalAssignmentPages] = useState(1);

  const fetchOrganizationAssignments: FetchOrganizationAssignments = useCallback(
    async filters => {
      if (!organizationId) return;
      setIsFetchingOrgAssignments(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        import.meta.env.VITE_API_V2_BASE_PATH
      }/organizations/${organizationId}/assignments${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APIOrganizationsOrganizationIdAssignments['data'];
          meta?: APIOrganizationsOrganizationIdAssignments['meta'];
        };

        setAssignments(
          data !== undefined ? data.map(fields => generateOrganizationAssignment(fields)) : []
        );
        if (meta !== undefined) {
          setTotalAssignmentPages(Number(meta.total_pages));
        }
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetchingOrgAssignments(false);
      }
    },
    [organizationId, getRequest, reportError]
  );

  const debouncedFetchOrganizationAssignments = useDebounce(fetchOrganizationAssignments, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetchingOrgAssignments(true);
    debouncedFetchOrganizationAssignments(filters);

    return () => {
      setAssignments([]);
      setIsFetchingOrgAssignments(false);
      setQuery({});
    };
  }, [debouncedFetchOrganizationAssignments, filters, isFirstRender]);

  const updateAssignmentsFilters = (newFilters: Partial<OrganizationAssignmentsFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof OrganizationAssignmentsFilters] === undefined
        ? delete newFilters[key as keyof OrganizationAssignmentsFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchOrganizationAssignments(newFilters);
  };

  return {
    assignments,
    isFetchingOrgAssignments,
    totalAssignmentPages,
    updateAssignmentsFilters
  };
};

export default useGetOrganizationAssignments;
