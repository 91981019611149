import CloseIcon from '@material-design-icons/svg/round/close.svg?react';
import styled from 'styled-components';

import Button from '../../../../../../components/Buttons/Button';
import TextField from '../../../../../../components/FormFields/TextField';
import UnstyledButton from '../../../../../../components/UnstyledButton';
import {
  DESKTOP_BREAKPOINT_RANGE,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../../../../constants';
import { ProgressBar as BaseProgressBar } from '../../../../../components/ProgressBar';
import { MOBILE_HEADER_HEIGHT } from '../../../../constants';

export const BackButton = styled(Button)``;

export const CloseButton = {
  Icon: styled(CloseIcon)`
    display: block;
    fill: var(--gray-dark-dark);
    height: 1.8rem;
    width: auto;
    margin: -0.25rem -0.5rem;
  `,
  Root: styled(UnstyledButton)`
    position: absolute;
    top: var(--space-8);
    right: var(--space-8);
  `
};

export const Container = styled.div`
  margin-bottom: var(--space-6);
  padding: var(--space-8);
  background-color: var(--gray-50);
  border-radius: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 47.75rem;
  margin-right: auto;
  margin-left: auto;
  padding: var(--space-8) var(--space-9) var(--space-16);
`;

export const FeedbackTextField = styled(TextField)`
  margin-bottom: 1.25rem;
  height: 13.875rem;
  display: flex;
  flex-direction: column;

  .multi {
    margin-top: -0.5rem;
  }
`;

export const FeedbackTextFieldLabel = styled.div`
  margin-bottom: 1rem;
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-dark-dark);
`;

export const Main = styled.main`
  position: relative;
`;

export const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-1);
`;

export const NextButton = styled(Button)``;

export const PracticeChangesTextField = styled(TextField)`
  margin-bottom: 1.25rem;
  height: 13.875rem;
  display: flex;
  flex-direction: column;

  .multi {
    margin-top: -0.5rem;
  }
`;

export const PracticeChangesTextFieldLabel = styled.div`
  margin-bottom: 1rem;
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-dark-dark);
`;

export const ProgressBar = styled(BaseProgressBar)`
  margin-top: var(--space-9);
  margin-bottom: var(--space-4);
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;

  ${MOBILE_BREAKPOINT_RANGE} {
    height: calc(100dvh - ${MOBILE_HEADER_HEIGHT}px);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    height: 100dvh;
  }
`;

export const Statement = styled.p`
  margin-bottom: var(--space-6);
  color: var(--gray-dark-dark);
  ${TEXT_STYLES.bodyCopyRegular}
`;

export const Title = styled.h1`
  margin-bottom: var(--space-6);
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
`;
