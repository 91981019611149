import { useNavigate } from 'react-router-dom';
import Button from '@/components/Buttons/Button';

import * as S from './styles';

const NotFound = () => {
  const navigate = useNavigate();

  const handleBackButtonPress = () => {
    navigate(-1);
  };

  return (
    <S.NotFound>
      <S.Icon />
      <S.Hero
        copy="The page you are looking for cannot be found."
        header="Not found."
        level={2}
        subcopy={
          <>
            URL: {window.location.host}
            {window.location.pathname}
            <br />
            If you continue to encounter this error, please contact{' '}
            <a href="mailto:support@joinviolet.com">support@joinviolet.com</a>
          </>
        }
      />
      <S.Buttons>
        <Button
          background="dark"
          variant="outline"
          onPress={handleBackButtonPress}
        >
          Back
        </Button>
        <Button
          background="dark"
          to="/dashboard"
        >
          Return to dashboard
        </Button>
      </S.Buttons>
    </S.NotFound>
  );
};

export default NotFound;
