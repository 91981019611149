// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import { forwardRef, useEffect, useRef } from 'react';
import type { AriaSelectProps } from 'react-aria';
import { HiddenSelect, useButton, useSelect } from 'react-aria';
import { useSelectState } from 'react-stately';
import useSize from '@/hooks/useSize';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaSelectProps<object> {
  className?: string;
  hideRequiredIndicator?: boolean;
  maxListBoxHeight?: string;
}
const Select = forwardRef<HTMLButtonElement & HTMLInputElement, Props>(
  (
    { className, hideRequiredIndicator = false, maxListBoxHeight, ...selectProps },
    forwardedRef
  ) => {
    const { isDisabled = false } = selectProps as { isDisabled?: boolean };
    const state = useSelectState(selectProps);

    const ref = useObjectRef(forwardedRef);
    const selectRef = useRef<HTMLDivElement>(null);

    const { isInvalid, labelProps, menuProps, triggerProps, valueProps } = useSelect(
      selectProps,
      state,
      ref
    );

    const { buttonProps } = useButton(triggerProps, ref);

    const {
      isRequired = false,
      label,
      name,
      placeholder,
      selectedKey
    } = selectProps as {
      isRequired?: boolean;
      label?: string;
      name?: string;
      placeholder?: string;
      selectedKey?: string;
    };

    useEffect(() => {
      if (!isRequired) return;

      const input = ref.current?.previousElementSibling?.querySelector('input');

      if (selectedKey === 'NO_VALUE' && input) {
        input.setCustomValidity('Please select a value.');
      } else if (input) {
        input.setCustomValidity('');
      }
    }, [isRequired, selectedKey, ref]);

    const hasValidSelection = state.selectedItem && state.selectedItem.rendered !== ' ';

    const size = useSize(selectRef);

    const selectIsDisabled = isDisabled || !state.collection.size;

    return (
      <S.Select
        ref={selectRef}
        className={className}
      >
        {isNonEmptyString(label) && (
          <S.Label {...labelProps}>
            {label}
            {isRequired && !hideRequiredIndicator && '*'}
          </S.Label>
        )}
        {!selectIsDisabled && (
          <HiddenSelect
            label={label}
            name={name}
            state={state}
            triggerRef={ref}
          />
        )}
        <S.Trigger
          {...buttonProps}
          ref={ref}
          className={isInvalid ? 'is-invalid' : ''}
          disabled={selectIsDisabled}
          tabIndex={selectIsDisabled ? -1 : 0}
        >
          <S.Value {...valueProps}>
            {state.selectedItem?.textValue !== undefined && Boolean(hasValidSelection) ? (
              state.selectedItem.textValue
            ) : hasValidSelection ? (
              state.selectedItem?.rendered
            ) : (
              <S.Placeholder>{placeholder ?? ''}</S.Placeholder>
            )}
          </S.Value>
          <S.Icon aria-hidden="true" />
        </S.Trigger>
        {state.isOpen && (
          <S.Popover
            $width={size.width}
            placement="bottom start"
            state={state}
            triggerRef={ref}
          >
            <S.ListBox
              {...menuProps}
              $maxListBoxHeight={maxListBoxHeight}
              state={state}
            />
          </S.Popover>
        )}
      </S.Select>
    );
  }
);

Select.displayName = 'Select';

export default Select;
