import useBreakpointRange from '@/hooks/useBreakpointRange';
import useUser from '@/hooks/useUser';

import * as S from './styles';

interface Props {
  progressBarValue: number;
}

const OnboardingHeader = ({ progressBarValue }: Props) => {
  const { user } = useUser();
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const onboardingLabels = user.is_clinical
    ? ['Personal info', 'Identities', 'Communities', 'Education', 'Work', 'Self assessment']
    : ['Personal info', 'Identities', 'Submit'];
  return (
    <S.Header>
      <S.LogotypeLink to="/onboarding/welcome">
        <S.Logo
          color="dark"
          size="regular"
        />
      </S.LogotypeLink>
      <S.BarContainer>
        <S.OnboardingProgressBar
          currentStep={progressBarValue}
          labels={onboardingLabels}
        />
      </S.BarContainer>
      {isInMobileBreakpointRange && (
        <S.StepText>
          Step {progressBarValue + 1} of {onboardingLabels.length}
        </S.StepText>
      )}
    </S.Header>
  );
};

export default OnboardingHeader;
