import OrganizationIcon from '@material-design-icons/svg/round/apartment.svg?react';
import ExportIcon from '@material-design-icons/svg/round/download.svg?react';
import CheckCircleFill from '@material-symbols/svg-400/rounded/check_circle-fill.svg?react';
import WarningIcon from '@material-symbols/svg-400/rounded/warning-fill.svg?react';
import * as Sentry from '@sentry/react';
import { produce } from 'immer';
import { useEffect, useRef, useState } from 'react';
import type { AriaSearchFieldProps, AriaSelectProps } from 'react-aria';
import { useNumberFormatter } from 'react-aria';
import { useNavigate } from 'react-router-dom';
import type { SortDescriptor } from 'react-stately';
import {
  Cell,
  Column,
  Item,
  Row,
  TableBody,
  TableHeader,
  useOverlayTriggerState
} from 'react-stately';
import Badge from '@/components/Badge';
import ButtonWithMenu from '@/components/Buttons/ButtonWithMenu';
import ViewDetailsButton from '@/components/Buttons/ViewDetailsButton';
import SearchField from '@/components/FormFields/SearchField';
import HelpPopoverTrigger from '@/components/HelpPopoverTrigger';
import Label from '@/components/Label';
import PageTitle from '@/components/PageTitle';
import Pagination from '@/components/Pagination';
import CommunityGraphs from '@/components/Reporting/CommunityGraphs';
import DataCard from '@/components/Reporting/DataCard';
import DataCardHeader from '@/components/Reporting/DataCardHeader';
import DataContainer from '@/components/Reporting/DataContainer';
import DataEmptyState from '@/components/Reporting/DataEmptyState';
import DataHighlight from '@/components/Reporting/DataHighlight';
import DataReportRow from '@/components/Reporting/DataReportRow';
import MeterWithGoal from '@/components/Reporting/MeterWithGoal';
import Table from '@/components/Table';
import EmptyTableState from '@/components/Table/EmptyState';
import useAnalytics from '@/hooks/useAnalytics';
import InclusivityScorecardReport from '@/pages/Reports/InclusivityScorecard/InclusivityScorecardReport';
import { downloadPdf } from '@/utils/downloadPdf';
import { downloadPng } from '@/utils/downloadPng';
import isNonEmptyString from '@/utils/isNonEmptyString';
import useDeepCompareEffect from 'use-deep-compare-effect';

import Button from '../../../components/Buttons/Button';
import Spinner from '../../../components/Spinner';
import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../hooks/useUser';
import { downloadCsv } from '../../../utils/downloadCsv';
import { filtersToParams } from '../hooks/useApiRequest';
import type { OrganizationEducationOverviewFilters } from '../hooks/useGetOrganizationEducationOverview';
import useGetOrganizationEducationOverview from '../hooks/useGetOrganizationEducationOverview';
import useGetOrganizationEducationTopResources from '../hooks/useGetOrganizationEducationTopResources';
import useGetOrganizationOverview from '../hooks/useGetOrganizationOverview';
import type { OrganizationFilters } from '../hooks/useGetOrganizationUsers';
import useGetOrganizationUsers from '../hooks/useGetOrganizationUsers';
import type { OrganizationUser } from '../utils';
import { OnboardingStatus } from '../utils';

import VioModal from './components/VioModal';
import * as S from './styles';
import { useFeatureFlagEnabled } from 'posthog-js/react';

enum SendEmailReminderButtonState {
  Hidden,
  Sending,
  Sent,
  Unsent
}

const MyOrganization = () => {
  const { analytics } = useAnalytics();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const formatter = useNumberFormatter();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const isSelfServiceUserManagementEnabled = useFeatureFlagEnabled('self_service_user_management');
  const { bearerToken, user } = useUser();

  const {
    close: closeVioModal,
    isOpen: vioModalIsOpen,
    open: openVioModal
  } = useOverlayTriggerState({});

  const [skipNavigateTrigger, setSkipNavigateTrigger] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(
    params.get('page') !== null ? Number(params.get('page')) : 1
  );
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'full_name',
    direction: 'ascending'
  });
  /*
    nameText    manages a controlled state for the name search field
                this allows us to set the value of the search field
                on back/forward navigation
    nameValue   clones the value of nameText, but debounced
                to prevent duplicate requests and URL filter updates
  */
  const [searchByNameText, setSearchByNameText] = useState(
    params.get('user_name') !== null ? params.get('user_name') : ''
  );
  const [searchByNameValue, setSearchByNameValue] = useState(
    params.get('user_name') !== null ? params.get('user_name') : ''
  );
  const [selectedUserType, setSelectedUserType] = useState<string | null>(
    params.get('clinical') !== null ? params.get('clinical') : 'all'
  );
  const [selectedUserRole, setSelectedUserRole] = useState<string | null>(
    params.get('member_role') !== null ? params.get('member_role') : 'all'
  );
  const [selectedUserStatus, setSelectedUserStatus] = useState<string | null>(
    params.get('status') !== null ? params.get('status') : 'all'
  );
  const [filters, setFilters] = useState<OrganizationFilters>({});

  const [selectedDateRange, setSelectedDateRange] = useState<
    OrganizationEducationOverviewFilters['date_range'] | 'all_time'
  >(
    params.get('date_range') !== null
      ? (params.get('date_range') as OrganizationEducationOverviewFilters['date_range'])
      : 'all_time'
  );
  const [educationFilters, setEducationFilters] = useState<OrganizationEducationOverviewFilters>(
    {}
  );

  const organizationId = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization_id;
  const organizationName = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization.name;
  const isNetworkManagerOfThisOrg = user.organization_memberships.some(
    membership =>
      membership.member_role === 'network_manager' && membership.organization_id === organizationId
  );
  const { isFetching: isFetchingOrgOverview, organizationOverview } = useGetOrganizationOverview(
    {},
    organizationId ?? ''
  );
  const { educationOverview, isFetchingOrgEducationOverview, updateEducationOverviewFilters } =
    useGetOrganizationEducationOverview(organizationId ?? '', educationFilters);
  const { isFetchingOrgEducationTopResources, topResources, updateEducationTopResourcesFilters } =
    useGetOrganizationEducationTopResources(organizationId ?? '', {
      resource_type: 'course_collection',
      ...educationFilters
    });
  const {
    isFetchingOrgUsers,
    totalUserPages,
    updateOrganizationFilters,
    users: organizationUsers
  } = useGetOrganizationUsers(filters, organizationId);
  const [users, setUsers] = useState<OrganizationUser[]>([]);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const inclusivityScorecardRef = useRef<HTMLDivElement>(null);

  const [isExporting, setIsExporting] = useState(false);
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  useEffect(() => {
    setUsers(organizationUsers);
  }, [organizationUsers]);

  useEffect(() => {
    const newFilters: OrganizationFilters = {};
    const newEducationFilters: OrganizationEducationOverviewFilters = {};
    let params = '';

    newFilters.order_by = {
      column: sortDescriptor.column as string,
      dir: sortDescriptor.direction === 'ascending' ? 'asc' : 'desc'
    };
    if (currentPage !== 1) {
      params += `?page=${currentPage}`;
      newFilters.page = currentPage;
    }
    if (isNonEmptyString(searchByNameValue)) {
      params += `${params.length > 0 ? '&' : '?'}user_name=${searchByNameValue}`;
      newFilters.full_name = searchByNameValue ?? undefined;
    }
    if (selectedUserType !== 'all') {
      params += `${params.length > 0 ? '&' : '?'}clinical=${selectedUserType}`;
      newFilters.clinical = selectedUserType === 'true';
    }
    if (selectedUserRole !== null && ['member', 'superuser'].includes(selectedUserRole)) {
      params += `${params.length > 0 ? '&' : '?'}member_role=${selectedUserRole}`;
      newFilters.member_role = selectedUserRole as 'member' | 'superuser';
    }
    if (selectedUserStatus !== 'all') {
      switch (selectedUserStatus) {
        case 'invited':
          newFilters.status = 'created';
          newFilters.completed_onboarding = false;
          break;
        case 'onboarding':
          newFilters.status = 'activated';
          newFilters.completed_onboarding = false;
          break;
        case 'active':
          newFilters.status = 'activated';
          newFilters.completed_onboarding = true;
          break;
      }
      params += `${params.length > 0 ? '&' : '?'}status=${selectedUserStatus}`;
    }
    if (selectedDateRange !== 'all_time') {
      params += `${params.length > 0 ? '&' : '?'}date_range=${selectedDateRange}`;
      newEducationFilters.date_range = selectedDateRange;
    }
    setFilters(newFilters);
    setEducationFilters(newEducationFilters);
    if (skipNavigateTrigger) {
      setSkipNavigateTrigger(false);
    } else {
      navigate(`/dashboard/my-organization${params}`);
    }
    /*
    Do not let skipNavigateTrigger retrigger this effect
    used to prevent need for double back press to clear filters
  */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    currentPage,
    sortDescriptor,
    searchByNameValue,
    selectedDateRange,
    selectedUserType,
    selectedUserRole,
    selectedUserStatus
  ]);

  useEffect(() => {
    /* Add listener for popstate
     *  to reset search and filters
     *  when user navigates back in browser
     */
    const eventListener = () => {
      const params = new URLSearchParams(window.location.search);

      setSkipNavigateTrigger(true);
      setSearchByNameText(params.get('full_name') !== null ? params.get('full_name') : '');
      setSearchByNameValue(params.get('full_name') !== null ? params.get('full_name') : '');
      setSelectedUserRole(params.get('member_role') !== null ? params.get('member_role') : 'all');
      setSelectedUserStatus(params.get('status') !== null ? params.get('status') : 'all');
      setSelectedUserType(params.get('clinical') !== null ? params.get('clinical') : 'all');
      setSelectedDateRange(
        params.get('date_range') !== null
          ? (params.get('date_range') as OrganizationEducationOverviewFilters['date_range'])
          : 'all_time'
      );
      setCurrentPage(params.get('page') !== null ? Number(params.get('page')) : 1);
    };
    window.addEventListener('popstate', eventListener);

    return () => {
      // Clear listener when modal is unmounted
      window.removeEventListener('popstate', eventListener);
    };
    // run once -- rerunning this effect will break the listener
  }, []);

  useDeepCompareEffect(() => {
    updateOrganizationFilters(filters);
  }, [filters]);

  useDeepCompareEffect(() => {
    updateEducationOverviewFilters(educationFilters);
    updateEducationTopResourcesFilters({ resource_type: 'course_collection', ...educationFilters });
  }, [educationFilters]);

  const handleProvidersExportButtonPress = async () => {
    if (organizationId === undefined) {
      return;
    }

    setIsExporting(true);

    const exportFilters = { ...filters };
    delete exportFilters.order_by;
    const params = filtersToParams(exportFilters);

    const usersExportUrl: RequestInfo = `${
      import.meta.env.VITE_API_V2_BASE_PATH
    }/organizations/${organizationId}/users/export${isNonEmptyString(params) ? `?${params}` : ''}`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };

    try {
      const response = await fetch(usersExportUrl, options);

      let isSignedOut = false;

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          isSignedOut = true;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      if (isSignedOut) return;

      const date = new Date();
      const formattedDate = date
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        })
        .replace(/\//g, '-');
      await downloadCsv(
        response,
        `Violet_Users_${organizationName?.replaceAll(' ', '')}_${formattedDate}.csv`
      );

      setIsExporting(false);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
      setIsExporting(false);
    }
  };

  const handleSendEmailReminderButtonPress = (membershipId?: string) => async () => {
    if (membershipId === undefined) {
      return;
    }

    setUsers(
      produce(organizationUsers, draft => {
        draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
          SendEmailReminderButtonState.Sending;
      })
    );

    const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard/organizations/send_invite/${membershipId}`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      },
      method: 'POST'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      setUsers(
        produce(organizationUsers, draft => {
          draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
            SendEmailReminderButtonState.Sent;
        })
      );
    } catch (error) {
      setUsers(
        produce(organizationUsers, draft => {
          draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
            SendEmailReminderButtonState.Unsent;
        })
      );

      Sentry.captureException(error);
      openErrorModalDialog();
    }
  };

  const handleSearchByNameFieldChange: AriaSearchFieldProps['onChange'] = value => {
    setSearchByNameText(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchByNameValue(value);
    }, 500);
  };

  const handleUserTypeSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    setSelectedUserType(key as string);
  };

  const handleUserRoleSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    setSelectedUserRole(key as string);
  };

  const handleUserStatusSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    setSelectedUserStatus(key as string);
  };

  const handleDateRangeSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    setSelectedDateRange(key as OrganizationEducationOverviewFilters['date_range'] | 'all_time');
  };

  const generateReportTitle = () => {
    if (organizationOverview === undefined || educationOverview === undefined) {
      return '';
    }
    const date = new Date();
    const formattedDate = date
      .toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
      .replace(/\//g, '-');
    return `Violet_InclusivityScorecard_${organizationOverview.organizationName.replaceAll(
      ' ',
      ''
    )}_${formattedDate}`;
  };

  const handleInclusivityScorecardExportButtonPress = async (
    downloadType: number | string
  ): Promise<void> => {
    if (!organizationOverview || !educationOverview) {
      return;
    }

    if (downloadType === 'png') {
      await downloadPng(inclusivityScorecardRef.current as HTMLElement, generateReportTitle());
    } else if (downloadType === 'pdf') {
      await downloadPdf(inclusivityScorecardRef.current as HTMLElement, generateReportTitle());
    }

    analytics?.track('Report Downloaded', {
      organization_id: organizationId,
      organization_name: organizationName,
      report_format: downloadType,
      report_name: 'Inclusivity scorecard'
    });
  };

  return (
    <>
      {!isNetworkManagerOfThisOrg && (
        <S.HiddenOffscreenReportRender ref={inclusivityScorecardRef}>
          {organizationOverview && educationOverview && (
            <InclusivityScorecardReport
              educationOverview={educationOverview}
              organizationOverview={organizationOverview}
              topResources={topResources ?? []}
            />
          )}
        </S.HiddenOffscreenReportRender>
      )}
      <PageTitle
        actionButton={
          !isNetworkManagerOfThisOrg ? (
            <ButtonWithMenu
              matchButtonWidth
              data-cy="export-inclusivity-scorecard-button"
              isDisabled={!organizationOverview || !educationOverview}
              label={
                !organizationOverview || !educationOverview ? 'Preparing...' : 'Generate scorecard'
              }
              onAction={handleInclusivityScorecardExportButtonPress}
            >
              <Item key="pdf">PDF</Item>
              <Item key="png">PNG</Item>
            </ButtonWithMenu>
          ) : undefined
        }
        data-cy="organization-overview-title-container"
        labelComponent={
          <Label
            data-cy="organization-label"
            icon={OrganizationIcon}
          >
            {organizationName ?? ''}
          </Label>
        }
        title="Overview"
        titleVariant="h1"
      />
      {isFetchingOrgOverview || organizationOverview === undefined ? (
        <Spinner withWrapper />
      ) : (
        <DataContainer data-cy="organization-overview">
          <S.VioRow layout="thirds-last-wide">
            <S.VioCard>
              <S.VioCardTitle>
                Total users
                <HelpPopoverTrigger inline>
                  Total number of clinical and non-clinical users linked to your organization, based
                  on your provided roster or inferred from third-party data. Clinical users include
                  all licensed and pre-licensed providers, as well as interns actively delivering
                  care. Non-clinical users are health care staff not directly involved in patient
                  care.
                </HelpPopoverTrigger>
              </S.VioCardTitle>
              <S.VioTotal data-cy="users-total">
                {commaNumFormatter.format(organizationOverview.userMetrics.total)}
              </S.VioTotal>
              <S.VioCardBottomGrouping>
                <MeterWithGoal
                  currentTotal={organizationOverview.userMetrics.clinical.total}
                  data-cy="org-users-clinical"
                  label="Clinical"
                  outOfTotal={organizationOverview.userMetrics.total}
                  value={organizationOverview.userMetrics.clinical.percentage}
                />
                <MeterWithGoal
                  currentTotal={organizationOverview.userMetrics.nonClinical.total}
                  data-cy="org-users-non-clinical"
                  label="Non-clinical"
                  outOfTotal={organizationOverview.userMetrics.total}
                  value={organizationOverview.userMetrics.nonClinical.percentage}
                />
              </S.VioCardBottomGrouping>
            </S.VioCard>
            <S.VioCard>
              <S.VioCardTitle>
                Onboarded users
                <HelpPopoverTrigger inline>
                  Total number of clinical and non-clinical users who have accepted their invite to
                  join Violet's platform and completed their profiles. Clinical users' profiles are
                  used to generate their Benchmarks.
                </HelpPopoverTrigger>
              </S.VioCardTitle>
              <S.VioTotal data-cy="onboarded-total">
                {commaNumFormatter.format(organizationOverview.userMetrics.onboarded.total)}
              </S.VioTotal>
              <S.VioCardBottomGrouping>
                <MeterWithGoal
                  currentTotal={organizationOverview.userMetrics.onboarded.clinical}
                  data-cy="org-users-onboarded-clinical"
                  label="Clinical"
                  outOfTotal={organizationOverview.userMetrics.clinical.total}
                  value={organizationOverview.userMetrics.onboarded.clinicalPercentage}
                />
                <MeterWithGoal
                  currentTotal={organizationOverview.userMetrics.onboarded.nonClinical}
                  data-cy="org-users-onboarded-non-clinical"
                  label="Non-clinical"
                  outOfTotal={organizationOverview.userMetrics.nonClinical.total}
                  value={organizationOverview.userMetrics.onboarded.nonClinicalPercentage}
                />
              </S.VioCardBottomGrouping>
            </S.VioCard>
            <S.VioCard className="wide">
              <S.VioCardTitle className="justify-between">
                Verified Inclusive Organization
                <Badge
                  color={organizationOverview.verifiedInclusive ? 'green' : 'yellow'}
                  data-cy="vio-status"
                  leadingIcon={
                    organizationOverview.verifiedInclusive ? CheckCircleFill : WarningIcon
                  }
                  size="large"
                >
                  {organizationOverview.verifiedInclusive ? 'Verified' : 'Not verified'}
                </Badge>
              </S.VioCardTitle>
              <S.Requirements organizationOverview={organizationOverview} />
              <Button
                fullWidth
                data-cy="vio-learn-more-button"
                variant="outline"
                onPress={openVioModal}
              >
                Learn more
              </Button>
            </S.VioCard>
          </S.VioRow>
          {!isNetworkManagerOfThisOrg && (
            <DataReportRow layout="fifty-fifty">
              <DataCard>
                <DataCardHeader
                  description="This chart displays the percentage of your team's providers that have achieved awareness Benchmarks or above in each community."
                  title="Cultural competencies"
                  titleVariant="h3"
                />
                <CommunityGraphs
                  data={organizationOverview.providerInclusivityPercentages}
                  dataType="benchmarks"
                  linkToPage={`/dashboard/my-organization/inclusivity${isSelfServiceUserManagementEnabled === true ? '/benchmarks' : ''}`}
                />
              </DataCard>
              <DataCard>
                <DataCardHeader
                  description="Demographic insights are compiled based on providers' self-reported identity data collected during the onboarding process."
                  title="Demographics"
                  titleVariant="h3"
                />
                <CommunityGraphs
                  data={organizationOverview.providerDiversityPercentages}
                  dataType="demographics"
                  linkToPage={`/dashboard/my-organization/${isSelfServiceUserManagementEnabled === true ? 'inclusivity/' : ''}demographics`}
                />
              </DataCard>
            </DataReportRow>
          )}
        </DataContainer>
      )}

      <PageTitle
        title="Education engagement"
        titleVariant="h2"
      />
      {isFetchingOrgEducationOverview || isFetchingOrgEducationTopResources ? (
        <Spinner />
      ) : (
        <DataContainer data-cy="organization-engagement">
          <DataReportRow layout="quarters">
            <S.DateSelect
              aria-label="Filter by date range"
              data-cy="date-range-filter"
              placeholder="Filter by date range"
              selectedKey={selectedDateRange}
              onSelectionChange={handleDateRangeSelectionChange}
            >
              <Item key="last_30_days">Last 30 days</Item>
              <Item key="last_90_days">Last 90 days</Item>
              <Item key="year_to_date">Year to date</Item>
              <Item key="last_12_months">Last 12 months</Item>
              <Item key="all_time">All time</Item>
            </S.DateSelect>
          </DataReportRow>
          <DataReportRow layout="quarters">
            <DataHighlight
              data-cy="organization-courses-complete"
              detailText="Courses completed"
              numberHighlight={formatter.format(educationOverview?.educationCompletedCount ?? 0)}
              variant="small"
            />
            <DataHighlight
              data-cy="organization-hours-learning"
              detailText="Hours learning"
              numberHighlight={formatter.format(educationOverview?.educationHours ?? 0)}
              variant="small"
            />
            <DataHighlight
              data-cy="organization-avg-rating"
              detailText="Average course rating"
              isEmptyState={
                educationOverview === undefined || educationOverview.averageCourseScore === 0
              }
              numberHighlight={
                educationOverview !== undefined && educationOverview.averageCourseScore > 0
                  ? `${educationOverview.averageCourseScore}/5`
                  : 'N/A'
              }
              variant="small"
            />
            <DataHighlight
              data-cy="organization-credits-earned"
              detailText="CE/CME credits earned"
              numberHighlight={formatter.format(Number(educationOverview?.ceCreditsEarned ?? 0))}
              variant="small"
            />
          </DataReportRow>
          <DataReportRow layout="fifty-fifty">
            <DataCard>
              <S.EngagementTitle>Top learners</S.EngagementTitle>
              {educationOverview?.topEngagedProviders === undefined ||
              educationOverview.topEngagedProviders.length === 0 ? (
                <DataEmptyState />
              ) : (
                <S.EngagementList data-cy="top-learners-list">
                  {educationOverview.topEngagedProviders.map(provider => (
                    <li key={`provider-${provider.id}`}>
                      <S.TopLearnerListItem>{provider.name}</S.TopLearnerListItem>
                    </li>
                  ))}
                </S.EngagementList>
              )}
            </DataCard>
            <DataCard>
              <S.EngagementTitle>Top collections</S.EngagementTitle>
              {topResources === undefined || topResources.length === 0 ? (
                <DataEmptyState />
              ) : (
                <S.EngagementList data-cy="top-collections-list">
                  {topResources.map(collection => (
                    <li key={`collection-${collection.id}`}>
                      <S.CourseLink
                        data-cy="collection-link"
                        to={encodeURI(
                          `/dashboard/my-organization/${isSelfServiceUserManagementEnabled === true ? 'education/engagement' : 'education-engagement'}?resource_name=${collection.title}&resource_type=course_collection&order_by[column]=user_name&order_by[dir]=asc`
                        )}
                      >
                        {collection.title}
                      </S.CourseLink>
                    </li>
                  ))}
                </S.EngagementList>
              )}
            </DataCard>
          </DataReportRow>
          <ViewDetailsButton
            linkToPage={encodeURI(
              `/dashboard/my-organization/${isSelfServiceUserManagementEnabled === true ? 'education/engagement' : 'education-engagement'}?order_by[column]=user_name&order_by[dir]=asc&resource_type=course_collection`
            )}
          />
        </DataContainer>
      )}

      {isSelfServiceUserManagementEnabled !== true && (
        <>
          <PageTitle
            id="users-section"
            title="Users"
            titleVariant="h2"
          />
          <S.UserFilters
            isSingleRow
            actionButton={
              <Button
                data-cy="export-users-csv-button"
                isLoading={isExporting}
                size="regular"
                trailingIcon={ExportIcon}
                onPress={handleProvidersExportButtonPress}
              >
                Export CSV
              </Button>
            }
          >
            <S.FilterWrapper>
              <SearchField
                aria-label="Search by user name"
                data-cy="name-search-field"
                placeholder="User name"
                value={searchByNameText ?? ''}
                onChange={handleSearchByNameFieldChange}
              />
              <S.TeamMemberSelect
                aria-label="Filter by user role"
                data-cy="user-role-filter"
                placeholder="Filter by user role"
                selectedKey={selectedUserRole}
                onSelectionChange={handleUserRoleSelectionChange}
              >
                <Item key="all">All roles</Item>
                <Item key="member">Member</Item>
                <Item key="superuser">Admin</Item>
              </S.TeamMemberSelect>
              <S.TeamMemberSelect
                aria-label="Filter by user type"
                data-cy="user-type-filter"
                placeholder="Filter by user type"
                selectedKey={selectedUserType}
                onSelectionChange={handleUserTypeSelectionChange}
              >
                <Item key="all">All types</Item>
                <Item key="true">Clinical</Item>
                <Item key="false">Non-clinical</Item>
              </S.TeamMemberSelect>
              <S.TeamMemberSelect
                aria-label="Filter by user status"
                data-cy="user-status-filter"
                placeholder="Filter by user status"
                selectedKey={selectedUserStatus}
                onSelectionChange={handleUserStatusSelectionChange}
              >
                <Item key="all">All statuses</Item>
                <Item key="invited">Invited</Item>
                <Item key="onboarding">Onboarding in progress</Item>
                <Item key="active">Active</Item>
              </S.TeamMemberSelect>
            </S.FilterWrapper>
          </S.UserFilters>
          {isFetchingOrgUsers ? (
            <Spinner withWrapper />
          ) : (
            <>
              <Table
                aria-label="Users"
                data-cy="organization-members"
                renderEmptyState={() => (
                  <EmptyTableState
                    colSpan={6}
                    message="No team members have been found."
                  />
                )}
                sortDescriptor={sortDescriptor}
                onSortChange={({ column, direction }) => {
                  setSortDescriptor({ column, direction });
                }}
              >
                <TableHeader>
                  <Column
                    key="full_name"
                    allowsSorting
                  >
                    Name
                  </Column>
                  <Column key="email">Email</Column>
                  <Column key="role">Role</Column>
                  <Column key="user_type">User type</Column>
                  <Column key="onboarded">Status</Column>
                  <Column key="actions">Actions</Column>
                </TableHeader>
                <TableBody>
                  {users.map((member: OrganizationUser) => (
                    <Row
                      key={member.id}
                      data-cy="organization-member-row"
                    >
                      <Cell>{member.name}</Cell>
                      <Cell>{member.email}</Cell>
                      <Cell>{member.memberRole === 'superuser' ? 'Admin' : 'Member'}</Cell>
                      <Cell>{member.isClinical ? 'Clinical' : 'Non-clinical'}</Cell>
                      <Cell>
                        {member.onboardingStatus === OnboardingStatus.Complete ? (
                          <Badge
                            color="green"
                            size="small"
                          >
                            Active
                          </Badge>
                        ) : member.inviteStatus === 'can send' ||
                          member.inviteStatus === 'already sent' ? (
                          <Badge
                            color="gray"
                            size="small"
                          >
                            Invited
                          </Badge>
                        ) : (
                          <Badge
                            color="yellow"
                            size="small"
                          >
                            Onboarding in progress
                          </Badge>
                        )}
                      </Cell>
                      <Cell>
                        {member.sendEmailReminderButtonState ===
                        SendEmailReminderButtonState.Sent ? (
                          <S.EmailReminderSentText>
                            <S.EmailReminderCheck />
                            Email reminder sent
                          </S.EmailReminderSentText>
                        ) : member.sendEmailReminderButtonState ===
                            SendEmailReminderButtonState.Sending ||
                          member.sendEmailReminderButtonState ===
                            SendEmailReminderButtonState.Unsent ? (
                          <Button
                            data-cy="send-email-reminder-button"
                            isDisabled={
                              member.sendEmailReminderButtonState ===
                              SendEmailReminderButtonState.Sending
                            }
                            variant="outline"
                            onPress={handleSendEmailReminderButtonPress(member.membershipId)}
                          >
                            {member.sendEmailReminderButtonState ===
                            SendEmailReminderButtonState.Sending ? (
                              <Spinner />
                            ) : (
                              'Send email reminder'
                            )}
                          </Button>
                        ) : null}
                      </Cell>
                    </Row>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                currentPage={currentPage}
                setPage={setCurrentPage}
                totalPages={totalUserPages}
              />
            </>
          )}
        </>
      )}
      {vioModalIsOpen && (
        <VioModal
          close={closeVioModal}
          organizationOverview={organizationOverview}
        />
      )}
    </>
  );
};

export default MyOrganization;
