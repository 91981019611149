import { useNumberFormatter } from 'react-aria';
import type { SortDescriptor } from 'react-stately';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';
import BooleanIcon from '@/components/BooleanIcon';
import HelpPopoverTrigger from '@/components/HelpPopoverTrigger';
import TableNumberCell from '@/components/Table/TableNumberCell';
import type { NetworkOrganizationMember } from '@/pages/Dashboard/utils';

import * as Shared from '../styles';

import * as S from './styles';

interface Props {
  currentPageOrganizations: number;
  handleOrganizationsTableSortChange?: (descriptor: SortDescriptor) => void;
  handleSetPageOrganizations: (page: number) => void;
  networkOrgMembers: NetworkOrganizationMember[];
  networkOrganizationsTotalPages: number;
  sortDescriptorOrganizations?: SortDescriptor;
}

export const NetworkMembersTable = ({
  currentPageOrganizations,
  handleOrganizationsTableSortChange,
  handleSetPageOrganizations,
  networkOrgMembers,
  networkOrganizationsTotalPages,
  sortDescriptorOrganizations
}: Props) => {
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });
  return (
    <>
      <S.NetworkOrganizationsTable
        aria-label="Table of provider organizations"
        data-cy="network-organization-members-table"
        sortDescriptor={sortDescriptorOrganizations}
        onSortChange={handleOrganizationsTableSortChange}
      >
        <TableHeader>
          <Column
            key="name"
            allowsSorting
          >
            Provider Organization
          </Column>
          <Column
            key="total_providers"
            allowsSorting
          >
            <TableNumberCell alignRight>Provider count</TableNumberCell>
          </Column>
          <Column
            key="on_violet"
            allowsSorting
          >
            <Shared.CenterColumn>On Violet</Shared.CenterColumn>
          </Column>
          <Column
            key="verified_inclusive"
            allowsSorting
          >
            <Shared.CenterColumn>
              Verified Inclusive
              <HelpPopoverTrigger
                inline
                size="small"
                type="info"
              >
                <p>Violet’s criteria for Verified Inclusive Organizations:</p>
                <ul>
                  <li>
                    At least 30% of a provider’s clinical staff (including licensed professionals,
                    pre-licensed individuals, and interns) must onboard with Violet, submitting
                    their profiles to receive Benchmarks.
                  </li>
                  <li>
                    Of those, 40% must achieve an Awareness Benchmark in at least one of the three
                    communities—BIPOC, LGBQ, or TGNC.
                  </li>
                </ul>
              </HelpPopoverTrigger>
            </Shared.CenterColumn>
          </Column>
          <Column
            key="bipoc_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>BIPOC inclusivity</TableNumberCell>
          </Column>
          <Column
            key="lgbq_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>LGBQ inclusivity</TableNumberCell>
          </Column>
          <Column
            key="tgnc_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>TGNC inclusivity</TableNumberCell>
          </Column>
        </TableHeader>
        <TableBody>
          {networkOrgMembers.map(member => (
            <Row
              key={member.id}
              data-cy="network-organization-member-row"
            >
              <Cell>{member.name}</Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-total-providers">
                  {commaNumFormatter.format(member.totalProviders)}
                </TableNumberCell>
              </Cell>
              <Cell>
                <Shared.CenterColumn data-cy="organization-member-on-violet">
                  <BooleanIcon
                    hideFalseIcon
                    aria-label={member.onViolet ? 'On Violet' : 'Not yet on Violet'}
                    data-cy="org-on-violet"
                    value={member.onViolet}
                  />
                </Shared.CenterColumn>
              </Cell>
              <Cell>
                <Shared.CenterColumn>
                  <BooleanIcon
                    hideFalseIcon
                    aria-label={
                      member.verifiedInclusive
                        ? 'Verified Inclusive Organization'
                        : 'Not yet verified'
                    }
                    data-cy="verified-inclusive"
                    value={member.verifiedInclusive}
                  />
                </Shared.CenterColumn>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-bipoc-inclusivity-percentage">
                  {`${member.inclusivityPercentages.bipoc}%`}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-lgbq-inclusivity-percentage">
                  {`${member.inclusivityPercentages.lgbq}%`}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-tgnc-inclusivity-percentage">
                  {`${member.inclusivityPercentages.tgnc}%`}
                </TableNumberCell>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </S.NetworkOrganizationsTable>
      <Shared.TablePagination
        currentPage={currentPageOrganizations}
        setPage={handleSetPageOrganizations}
        totalPages={networkOrganizationsTotalPages}
      />
    </>
  );
};
