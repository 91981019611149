import * as Sentry from '@sentry/react';
import type { FormEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { AriaCheckboxGroupProps } from 'react-aria';
import { useNavigate } from 'react-router-dom';
import BackNext from '@/components/Buttons/BackNext';
import CheckboxGroup from '@/components/FormFields/CheckboxGroup';
import GroupCheckbox from '@/components/FormFields/CheckboxGroup/GroupCheckbox';
import InputGroup from '@/components/FormFields/InputGroup';
import SliderAndNumberField from '@/components/FormFields/SliderAndNumberField';
import OnboardingHero from '@/components/heroes/OnboardingHero';
import type { VioletCommunity } from '@/utils';
import { useUpdateEffect } from 'usehooks-ts';

import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../hooks/useUser';
import OnboardingLayout from '../components/OnboardingLayout';

import * as S from './styles';

const communities = ['BIPOC', 'LGBQ', 'TGNC'] as const;

interface CulturalCompetency {
  community_of_interest: boolean;
  competence_type: VioletCommunity | null;
  confidence_level: number;
  formal_education_training: boolean;
  id: string | null;
  lived_experience: string[];
  professional_experience: boolean;
}

export type ExperienceObject = Record<string, boolean>;

export const communityDescriptions = {
  BIPOC: 'Black, Indigenous, & People of Color',
  LGBQ: 'Lesbian, Gay, Bisexual, & Queer',
  TGNC: 'Transgender & Non-Conforming'
};

export const experienceTypeOptions = [
  {
    label:
      'I’ve completed formal education and/or training that has prepared me to work with these communities.',
    value: 'formal_education_training'
  },
  {
    label: 'I have direct work experience providing care to members of these communities.',
    value: 'professional_experience'
  },
  {
    label: 'I am interested in working more frequently with members of these communities.',
    value: 'community_of_interest'
  },
  {
    label:
      'A member of my immediate family or I belong to one or more of these communities (i.e. lived experience).',
    value: 'lived_experience'
  }
];

export const initialCompetencyObject: CulturalCompetency = {
  community_of_interest: false,
  competence_type: null,
  confidence_level: 0,
  formal_education_training: false,
  id: null,
  lived_experience: [],
  professional_experience: false
};

export const livedExperienceOptions = (community: string) => [
  {
    label: `I belong to ${community === 'BIPOC' ? 'one or more' : 'the'} ${community} communities.`,
    value: 'self'
  },
  {
    label: `My immediate family member(s) belong to ${
      community === 'BIPOC' ? 'one or more' : 'the'
    } ${community.toUpperCase()} communities.`,
    value: 'family'
  },
  { label: 'I have other lived experience with one or more of these communities.', value: 'other' }
];

const CulturalCompetencies = () => {
  const hash = window.location.hash.toUpperCase().replace('#', '');
  const community = communities.find(value => value === hash) ?? 'BIPOC';

  const functionToCallAfterUserUpdateRef = useRef<() => void>();
  const { bearerToken, setUser, user } = useUser();
  const navigate = useNavigate();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);

  const [cultureCompetencyId, setCultureCompetencyId] = useState<string | null>();
  const [experienceTypes, setExperienceTypes] = useState<string[]>([]);
  const [livedExperience, setLivedExperience] = useState<string[]>([]);
  const [communityConfidence, setCommunityConfidence] = useState(0);

  /***** Find and set saved community data hook */
  useEffect(() => {
    window.scrollTo(0, 0);
    /***** Find or create initial cultural competence object */
    const culturalCompetences = user.user_info.cultural_competences;
    const userCommunityData = culturalCompetences.find(
      competency => competency.competence_type === community.toLowerCase()
    ) ?? { ...initialCompetencyObject, competence_type: community.toLowerCase() };

    /***** Set initial set data */
    const savedExperienceTypesObject: ExperienceObject = {
      community_of_interest: userCommunityData.community_of_interest,
      formal_education_training: userCommunityData.formal_education_training,
      lived_experience: (userCommunityData.lived_experience ?? []).length > 0,
      professional_experience: userCommunityData.professional_experience
    };
    const savedExperienceTypes = [];
    for (const key in savedExperienceTypesObject) {
      if (savedExperienceTypesObject[key]) {
        savedExperienceTypes.push(key);
      }
    }
    setCultureCompetencyId(userCommunityData.id);
    setExperienceTypes(savedExperienceTypes);
    setLivedExperience(userCommunityData.lived_experience ?? []);
    setCommunityConfidence(userCommunityData.confidence_level);
  }, [community, user.user_info]);

  /***** Next/Prev Handling */
  const communityList = ['BIPOC', 'LGBQ', 'TGNC'];
  const index = communityList.findIndex(value => value === community);

  const nextCommunity = communityList[index + 1];
  const prevCommunity = communityList[index - 1];
  const prevUrl = prevCommunity
    ? `/onboarding/cultural-competencies#${prevCommunity}`
    : '/onboarding/identity';
  const nextUrl = nextCommunity
    ? `/onboarding/cultural-competencies#${nextCommunity}`
    : '/onboarding/education-experience';
  const nextLabel = `Next: ${
    nextCommunity ? `${nextCommunity} competencies` : 'Education history'
  }`;

  /***** Customized options */
  const livedExperienceOptions = [
    {
      label: `I belong to ${
        community === 'BIPOC' ? 'one or more' : 'the'
      } ${community} communities.`,
      value: 'self'
    },
    {
      label: `My immediate family member(s) belong to ${
        community === 'BIPOC' ? 'one or more' : 'the'
      } ${community} communities.`,
      value: 'family'
    },
    {
      label: 'I have other lived experience with one or more of these communities.',
      value: 'other'
    }
  ];

  /***** User effect */
  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }
  }, [user]);

  useUpdateEffect(() => {
    if (communityConfidence === 0) {
      setExperienceTypes([]);
      setLivedExperience([]);
    }
  }, [communityConfidence]);

  /***** Action Handlers */
  const handleExperienceTypeChange: AriaCheckboxGroupProps['onChange'] = experienceTypeSet => {
    setExperienceTypes(experienceTypeSet);
  };

  const handleLivedExperienceChange: AriaCheckboxGroupProps['onChange'] = livedExperienceSet => {
    setLivedExperience(livedExperienceSet);
  };

  const handleFormSubmit: FormEventHandler = async event => {
    event.preventDefault();

    setFormIsSubmitting(true);

    const method = cultureCompetencyId !== null ? 'PATCH' : 'POST';

    const url: RequestInfo = `${
      import.meta.env.VITE_API_BASE_PATH
    }/users/dashboard/cultural_competences${
      cultureCompetencyId !== null ? `/${cultureCompetencyId}` : ''
    }`;

    const options: RequestInit = {
      body: JSON.stringify({
        cultural_competence: {
          community_of_interest: experienceTypes.includes('community_of_interest'),
          competence_type: community.toLowerCase(),
          confidence_level: communityConfidence,
          formal_education_training: experienceTypes.includes('formal_education_training'),
          id: cultureCompetencyId,
          lived_experience: experienceTypes.includes('lived_experience') ? livedExperience : [],
          professional_experience: experienceTypes.includes('professional_experience')
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } =
        (await response.json()) as APIUsersDashboardCulturalCompetencesCulturalCompetenceId;
      const updatedUser = { ...user };
      if (method === 'POST') {
        updatedUser.user_info.cultural_competences.push(data);
      } else {
        updatedUser.user_info.cultural_competences = user.user_info.cultural_competences.map(
          competence => {
            if (competence.id === cultureCompetencyId) {
              return data;
            } else {
              return competence;
            }
          }
        );
      }
      setUser(updatedUser);

      functionToCallAfterUserUpdateRef.current = () => {
        window.scrollTo(0, 0);
        navigate(nextUrl);
      };
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setFormIsSubmitting(false);
  };

  return (
    <OnboardingLayout progressBarValue={2}>
      <S.Form onSubmit={handleFormSubmit}>
        <OnboardingHero
          copy={`This section helps us understand your level of experience with ${community} (${communityDescriptions[community]}) communities, whether that is through formal training and/or lived experience.`}
          graphicType="communities"
          header={`${community} competencies.`}
        />
        <S.Grid>
          <InputGroup
            copy={`For example, if you have a lot of experience and/or identify as ${community}, you might select a higher number.`}
            header={`What’s your confidence level working with ${community} patients?`}
          >
            <SliderAndNumberField
              isRequired
              aria-label={`What’s your confidence level working with ${community} patients?`}
              data-cy="community-confidence-field"
              maxValue={10}
              minValue={0}
              setValue={setCommunityConfidence}
              value={communityConfidence}
            />
          </InputGroup>
          {communityConfidence > 0 && (
            <InputGroup
              copy="Select all that are applicable."
              header={`Let us know more about your experience with ${community} communities.`}
            >
              <CheckboxGroup
                aria-label={`Let us know more about your experience with ${community} communities.`}
                data-cy="experience-types-field"
                direction="vertical"
                validationBehavior="native"
                value={experienceTypes}
                onChange={handleExperienceTypeChange}
              >
                {experienceTypeOptions.map(option => (
                  <GroupCheckbox
                    key={option.label}
                    name={`${community}ExperienceTypes`}
                    value={option.value}
                  >
                    {option.label}
                  </GroupCheckbox>
                ))}
              </CheckboxGroup>
            </InputGroup>
          )}
          {experienceTypes.includes('lived_experience') && communityConfidence > 0 && (
            <InputGroup header="Please describe your lived experience.">
              <CheckboxGroup
                isRequired
                aria-label="Please describe your lived experience."
                data-cy="lived-experience-description-field"
                direction="vertical"
                validationBehavior="native"
                value={livedExperience}
                onChange={handleLivedExperienceChange}
              >
                {livedExperienceOptions.map(option => (
                  <GroupCheckbox
                    key={option.label}
                    name={`${community}LivedExperience`}
                    value={option.value}
                  >
                    {option.label}
                  </GroupCheckbox>
                ))}
              </CheckboxGroup>
            </InputGroup>
          )}
        </S.Grid>
        <BackNext
          backTo={prevUrl}
          nextIsLoading={formIsSubmitting}
          nextLabel={nextLabel}
        />
      </S.Form>
    </OnboardingLayout>
  );
};

export default CulturalCompetencies;
