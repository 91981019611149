import ViewDetailsButton from '@/components/Buttons/ViewDetailsButton';
import type { NetworkOverview, OrganizationOverview } from '@/pages/Dashboard/utils';
import isNonEmptyString from '@/utils/isNonEmptyString';

import Meter from '../Meter';

import * as S from './styles';

interface Props {
  data:
    | NetworkOverview['networkDiversityPercentages']
    | NetworkOverview['networkInclusivityPercentages']
    | OrganizationOverview['providerDiversityPercentages']
    | OrganizationOverview['providerInclusivityPercentages']
    | undefined;
  dataType: 'benchmarks' | 'demographics';
  isReport?: boolean;
  isVertical?: boolean;
  linkToPage?: string;
  size?: 'small' | 'large';
}

const CommunityGraphs = ({
  data,
  dataType,
  isReport = false,
  isVertical = false,
  linkToPage,
  size = 'small'
}: Props) => {
  /*  [dataType]     ==>    [subTitle]
      benchmarks     ==>    inclusive
      demographics   ==>    identifying
  */
  const subTitle = dataType === 'benchmarks' ? 'Inclusive' : 'Identifying';

  return (
    <S.MeterWrapper
      $isVertical={isVertical}
      className="report-community-graph-wrapper"
    >
      <S.MeterGroup
        $isVertical={isVertical}
        $size={size}
      >
        <Meter
          data-cy={`meter-${dataType}-bipoc`}
          isReport={isReport}
          isVertical={isVertical}
          label="BIPOC"
          labelPopoverText="Black, Indigenous, and People of Color"
          size={size}
          subTitle={subTitle}
          value={data?.bipoc}
        />
        <Meter
          data-cy={`meter-${dataType}-lgbq`}
          isReport={isReport}
          isVertical={isVertical}
          label="LGBQ"
          labelPopoverText="Lesbian, Gay, Bisexual, and Queer"
          size={size}
          subTitle={subTitle}
          value={data?.lgbq}
        />
        <Meter
          data-cy={`meter-${dataType}-tgnc`}
          isReport={isReport}
          isVertical={isVertical}
          label="TGNC"
          labelPopoverText="Transgender and Gender Non-Conforming"
          size={size}
          subTitle={subTitle}
          value={data?.tgnc}
        />
      </S.MeterGroup>
      {linkToPage !== undefined && isNonEmptyString(linkToPage) && (
        <ViewDetailsButton
          data-cy="community-graphs-view-details-button"
          linkToPage={linkToPage}
        />
      )}
    </S.MeterWrapper>
  );
};

export default CommunityGraphs;
