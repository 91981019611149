import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import type { OrganizationEducationOverview } from '../utils';
import { generateOrganizationEducationOverview } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchOrganizationEducationOverview = (
  filters: OrganizationEducationOverviewFilters
) => Promise<void>;

export interface OrganizationEducationOverviewFilters {
  'communities[]'?: string[] | null;
  date_range?: 'last_12_months' | 'last_30_days' | 'last_90_days' | 'year_to_date';
  is_clinical?: boolean;
  resource_name?: string;
  resource_type?: 'course_collection' | 'course';
  user_name?: string;
}

interface UseGetOrganizationEducationOverview {
  (
    organizationId: string,
    filters: OrganizationEducationOverviewFilters
  ): {
    educationOverview: OrganizationEducationOverview | undefined;
    isFetchingOrgEducationOverview: boolean;
    updateEducationOverviewFilters: (newFilters: OrganizationEducationOverviewFilters) => void;
  };
}

const useGetOrganizationEducationOverview: UseGetOrganizationEducationOverview = (
  organizationId,
  filters
) => {
  const isFirstRender = useIsFirstRender();
  const { getRequest, reportError } = useApiRequest();

  const [educationOverview, setEducationOverview] = useState<OrganizationEducationOverview>();
  const [isFetchingOrgEducationOverview, setIsFetchingOrgEducationOverview] = useState(false);
  const [query, setQuery] = useState<OrganizationEducationOverviewFilters>(filters);

  const fetchOrganizationEducationOverview: FetchOrganizationEducationOverview = useCallback(
    async filters => {
      if (!organizationId) return;
      setIsFetchingOrgEducationOverview(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        import.meta.env.VITE_API_V2_BASE_PATH
      }/organizations/${organizationId}/educations/overview${params ? `?${params}` : ''}`;

      try {
        const { data } = (await getRequest(url)) as {
          data: APIOrganizationsOrganizationIdEducationsOverview['data'] | undefined;
        };

        setEducationOverview(
          data !== undefined ? generateOrganizationEducationOverview(data) : undefined
        );
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetchingOrgEducationOverview(false);
      }
    },
    [organizationId, getRequest, reportError]
  );

  const debouncedFetchOrganizationEducationOverview = useDebounce(
    fetchOrganizationEducationOverview,
    200
  );

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetchingOrgEducationOverview(true);
    debouncedFetchOrganizationEducationOverview(filters);

    return () => {
      setEducationOverview(undefined);
      setIsFetchingOrgEducationOverview(false);
      setQuery({});
    };
  }, [debouncedFetchOrganizationEducationOverview, filters, isFirstRender]);

  const updateEducationOverviewFilters = (
    newFilters: Partial<OrganizationEducationOverviewFilters>
  ) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof OrganizationEducationOverviewFilters] === undefined
        ? delete newFilters[key as keyof OrganizationEducationOverviewFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchOrganizationEducationOverview(newFilters);
  };

  return {
    educationOverview,
    isFetchingOrgEducationOverview,
    updateEducationOverviewFilters
  };
};

export default useGetOrganizationEducationOverview;
