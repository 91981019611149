import Spinner from '@/components/Spinner';
import UnstyledButton from '@/components/UnstyledButton';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const AboveAvg = styled.span`
  color: var(--success-600);
  display: flex;
  align-items: center;
  gap: 0.25rem;

  svg {
    fill: var(--success-600);
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const Avg = styled.span`
  color: var(--blue-700);
  display: flex;
  align-items: center;
  gap: 0.25rem;

  svg {
    fill: var(--blue-700);
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const BelowAvg = styled.span`
  color: var(--warning-700);
  display: flex;
  align-items: center;
  gap: 0.25rem;

  svg {
    fill: var(--warning-700);
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const ButtonCell = styled(UnstyledButton)`
  ${TEXT_STYLES.formsLabelRegular}
  cursor: default !important;
  width: calc(100% + 1rem);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  margin: -0.5rem;
  padding: 0.5rem;

  &.is-hovered,
  &.is-focused {
    border: 2px solid var(--blue-600);
    background: var(--blue-50);
  }
`;

export const NoData = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-700);
`;

export const SmallSpinner = styled(Spinner)`
  width: 100%;
  height: 1.5rem !important;
  margin-bottom: 0 !important;
`;
