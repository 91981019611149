import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import type { Placement } from 'react-aria';

import * as S from '../HelpPopoverTrigger/styles';
import TooltipTrigger from '../TooltipTrigger';

interface Props extends PropsWithChildren {
  className?: string;
  delay?: number;
  inline?: boolean;
  label?: string;
  placement?: Placement;
  size?: 'large' | 'small';
  type?: 'help' | 'info';
}

const HelpTooltipTrigger = ({
  children,
  className,
  delay,
  inline = false,
  label,
  placement,
  size = 'large',
  type = 'help'
}: Props) => (
  <TooltipTrigger
    content={<S.PopoverContent>{children}</S.PopoverContent>}
    delay={delay}
    placement={placement}
  >
    <S.PopoverButton className={clsx({ inline }, size, className)}>
      {label}
      {type === 'help' && <S.HelpIcon />}
      {type === 'info' && <S.InfoIcon />}
    </S.PopoverButton>
  </TooltipTrigger>
);

export default HelpTooltipTrigger;
