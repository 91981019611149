import CheckboxGroup from '@/components/FormFields/CheckboxGroup';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const Filters = {
  CheckboxGroup: styled(CheckboxGroup)`
    .group-label {
      ${TEXT_STYLES.bodyCopyRegular}
      color: var(--gray-800);
      opacity: 0.8;
      margin-bottom: 0.5rem;
    }

    .checkbox-container {
      row-gap: 0.5rem;
    }
  `
};

export const LoadingMore = styled.div`
  display: grid;
  place-content: center;
  height: 14rem;
`;

export const Pathways = styled.ul`
  display: grid;
  margin-bottom: 0;
  padding-left: 0;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--space-4);
`;
