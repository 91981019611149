import clsx from 'clsx';
import type { Context } from 'react';
import { useContext, useRef } from 'react';
import type { AriaRadioProps } from 'react-aria';
import { useFocusRing, useHover, useRadio, VisuallyHidden } from 'react-aria';

import type { GroupContext } from '../RadioGroup';
import { RadioContext } from '../RadioGroup';

interface RadioProps extends AriaRadioProps {
  className?: string;
}

const Radio = ({ className, ...radioProps }: RadioProps) => {
  const { state } = useContext<GroupContext>(RadioContext as Context<GroupContext>);
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, isSelected } = useRadio(radioProps, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  const { hoverProps, isHovered } = useHover({});

  const { children } = radioProps;

  return (
    <label
      {...hoverProps}
      className={clsx(
        className,
        { 'is-focused': isFocusVisible },
        { 'is-hovered': isHovered },
        { 'is-selected': isSelected }
      )}
    >
      <VisuallyHidden>
        <input
          {...inputProps}
          {...focusProps}
          ref={ref}
          required={state.isRequired}
        />
      </VisuallyHidden>
      {children}
    </label>
  );
};

export { Radio };
