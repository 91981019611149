import { FOCUS_STYLES } from '@/constants';
import styled from 'styled-components';

interface ToastRegionProps {
  $placement: 'bottom' | 'top';
}

export const ToastRegion = styled.div<ToastRegionProps>`
  position: fixed;
  z-index: 101; /* one more than that applied to full screen modals */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &:focus-visible {
    ${FOCUS_STYLES}
  }

  ${({ $placement }) => {
    if ($placement === 'top') {
      // 104px is the height of the secondary navigation
      return `top: calc(6.5rem + 1rem);`;
    } else {
      return `bottom: 1rem;`;
    }
  }}
`;
