import GroupIcon from '@material-symbols/svg-400/rounded/group-fill.svg?react';
import TimeIcon from '@material-symbols/svg-400/rounded/schedule-fill.svg?react';
import EducationIcon from '@material-symbols/svg-400/rounded/school-fill.svg?react';
import capitalize from 'lodash/capitalize';
import PopoverTrigger from '@/components/PopoverTrigger';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import type { Collection } from '@/pages/Dashboard/utils';
import { getHoursAndMinutes } from '@/utils/getHoursAndMinutes';
import getLevelIcon from '@/utils/getLevelIcon';

import * as S from './styles';
import useGetFaqLinks from '@/utils/getFaqLinks';

interface Props {
  collection: Collection;
}

const CollectionMetaData = ({ collection }: Props) => {
  const { isInDesktopBreakpointRange } = useBreakpointRange();
  const { hours, minutes } = getHoursAndMinutes(collection.hours);
  const LevelIcon = getLevelIcon(collection.level ?? '');
  const FaqLinks = useGetFaqLinks();

  return (
    <S.MetaData data-cy="pathway-metadata">
      <S.MetaDataItem>
        <LevelIcon
          aria-hidden
          role="img"
        />
        <S.MetaDataItemHeader>Level</S.MetaDataItemHeader>
        <S.MetaDataItemValue data-cy="collection-metadata-level">
          {capitalize(collection.level)}
        </S.MetaDataItemValue>
      </S.MetaDataItem>
      <S.MetaDataItem>
        <TimeIcon
          aria-hidden
          role="img"
        />
        <S.MetaDataItemHeader>Time</S.MetaDataItemHeader>
        <S.MetaDataItemValue data-cy="collection-metadata-time">
          {isInDesktopBreakpointRange ? (
            <>
              {hours > 0 && `${hours} hour${hours > 1 ? 's' : ''}`}{' '}
              {minutes > 0 && `${minutes} min${minutes > 1 ? 's' : ''}`}
            </>
          ) : (
            <>
              {hours > 0 && `${hours} hr`} {minutes > 0 && `${minutes} m`}
            </>
          )}
        </S.MetaDataItemValue>
      </S.MetaDataItem>
      <S.MetaDataItem>
        <GroupIcon
          aria-hidden
          role="img"
        />
        <S.MetaDataItemHeader>Communities</S.MetaDataItemHeader>
        <S.MetaDataItemValue data-cy="collection-metadata-communities">
          {collection.communities.map(community => community.toUpperCase()).join(', ')}
        </S.MetaDataItemValue>
      </S.MetaDataItem>
      {collection.isAccredited ? (
        <PopoverTrigger
          content={
            <S.PopoverContent>
              <S.PopoverTitle>Approved accrediting organizations</S.PopoverTitle>
              <S.PopoverAccreditingOrganizationList>
                {collection.externalAccreditationReferences.map(({ accrediting_organizations }) =>
                  accrediting_organizations?.map(accrediting_organization => (
                    <S.PopoverAccreditingOrganizationItem
                      key={accrediting_organization.professional_group_acronym}
                    >
                      {accrediting_organization.professional_group}{' '}
                      <strong>({accrediting_organization.professional_group_acronym})</strong>
                    </S.PopoverAccreditingOrganizationItem>
                  ))
                )}
              </S.PopoverAccreditingOrganizationList>
              For information about how to obtain CE/CME credits through Violet, please{' '}
              <S.FaqLink
                href={FaqLinks.ceCredit}
                target="_blank"
              >
                visit our FAQ
              </S.FaqLink>
              .
            </S.PopoverContent>
          }
          placement="bottom"
          theme="dark"
        >
          <S.ButtonMetaDataItem>
            <EducationIcon
              aria-hidden
              role="img"
            />
            <S.MetaDataItemHeader>
              CE/CME{isInDesktopBreakpointRange && ' eligible'}
            </S.MetaDataItemHeader>
            <S.MetaDataItemValue data-cy="collection-metadata-accreditation">
              Yes
            </S.MetaDataItemValue>
          </S.ButtonMetaDataItem>
        </PopoverTrigger>
      ) : (
        <S.MetaDataItem>
          <EducationIcon
            aria-hidden
            role="img"
          />
          <S.MetaDataItemHeader>
            CE/CME{isInDesktopBreakpointRange && ' eligible'}
          </S.MetaDataItemHeader>
          <S.MetaDataItemValue data-cy="collection-metadata-accreditation">No</S.MetaDataItemValue>
        </S.MetaDataItem>
      )}
    </S.MetaData>
  );
};

export default CollectionMetaData;
