import { default as BaseCheckbox } from '@/components/FormFields/Checkbox';
import CheckboxGroup from '@/components/FormFields/CheckboxGroup';
import styled from 'styled-components';

import { RESOURCE_CARD_MIN_WIDTH } from '../../../../components/Education/resource_card_styles';
import { default as BaseSeparator } from '../../../../components/Separator';
import { default as BaseSpinner } from '../../../../components/Spinner';
import { TEXT_STYLES } from '../../../../constants';

export const EducationalResources = styled.ul`
  display: grid;
  margin-bottom: 0;
  padding-left: 0;
  grid-template-columns: repeat(auto-fill, minmax(${RESOURCE_CARD_MIN_WIDTH}px, 1fr));
  gap: var(--space-4);
`;

export const EducationalResourcesLoadingMoreState = {
  Root: styled.div`
    display: grid;
    place-content: center;
    height: 14rem;
  `,
  Spinner: styled(BaseSpinner)``
};

export const Filters = {
  CheckboxGroup: styled(CheckboxGroup)`
    .group-label {
      ${TEXT_STYLES.bodyCopyRegular}
      color: var(--gray-800);
      opacity: 0.8;
      margin-bottom: 0.5rem;
    }

    .checkbox-container {
      row-gap: 0.5rem;
    }
  `,
  SectionLabel: styled.div`
    ${TEXT_STYLES.bodyCopyRegular}
    color: var(--gray-800);
    opacity: 0.8;
    margin-bottom: 0.5rem;
  `,
  Separator: styled(BaseSeparator)`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid var(--gray-200);
  `,
  SingleCheckbox: styled(BaseCheckbox)`
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  `
};
