import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const List = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  gap: 0.31rem 0.5rem;
  margin-top: 0.5rem;
`;

export const SectionHeading = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-500);
  padding: 0.81rem 0.5rem 0.31rem;
  position: sticky;
  top: -0.81rem;
  background: #fff;
  z-index: 5;
  border-bottom: 1px solid var(--gray-200);
`;

export const SectionListItem = styled.li``;
