import useBreakpointRange from '../../../hooks/useBreakpointRange';
import CommunityIndicators from '../../CommunityIndicators';

import BenchmarkingCommunities from './benchmarking/benchmarking-communities.svg?url';
import BenchmarkingEducation from './benchmarking/benchmarking-education.svg?url';
import BenchmarkingIdentities from './benchmarking/benchmarking-identities.svg?url';
import BenchmarkingSelfEfficacy from './benchmarking/benchmarking-self-efficacy.svg?url';
import BenchmarkingWorkExperience from './benchmarking/benchmarking-work-experience.svg?url';
import * as S from './styles';

import isNonEmptyString from '@/utils/isNonEmptyString';

interface Props {
  className?: string;
  community?: 'BIPOC' | 'LGBQ' | 'TGNC' | null;
  copy: JSX.Element | string;
  graphicType?: 'communities' | 'education' | 'identities' | 'self-efficacy' | 'work experience';
  header: string;
  isClinical?: boolean;
}

const OnboardingHero = ({
  className,
  community,
  copy,
  graphicType,
  header,
  isClinical = true
}: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  return (
    <S.OnboardingHero
      $hasGraphic={isNonEmptyString(graphicType)}
      className={className}
    >
      <S.TextBlock $hasGraphic={isNonEmptyString(graphicType)}>
        {community && (
          <CommunityIndicators
            community={community}
            direction="row"
            size={isInMobileBreakpointRange ? 'small' : 'regular'}
          />
        )}
        <S.Header data-cy="onboarding-hero-header">{header}</S.Header>
        <S.Copy>{copy}</S.Copy>
      </S.TextBlock>
      {graphicType && (
        <S.Graphic>
          {isClinical && graphicType === 'communities' && (
            <img
              alt="Cultural competencies"
              src={BenchmarkingCommunities}
            />
          )}
          {isClinical && graphicType === 'education' && (
            <img
              alt="Education"
              src={BenchmarkingEducation}
            />
          )}
          {isClinical && graphicType === 'self-efficacy' && (
            <img
              alt="Self-efficacy"
              src={BenchmarkingSelfEfficacy}
            />
          )}
          {isClinical && graphicType === 'identities' && (
            <img
              alt="Identity"
              src={BenchmarkingIdentities}
            />
          )}
          {isClinical && graphicType === 'work experience' && (
            <img
              alt="Work experience"
              src={BenchmarkingWorkExperience}
            />
          )}
        </S.Graphic>
      )}
    </S.OnboardingHero>
  );
};
export default OnboardingHero;
