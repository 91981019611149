import DataContainer from '@/components/Reporting/DataContainer';
import Table from '@/components/Table';
import TableLinkedCell from '@/components/Table/TableLinkedCell';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const CenterColumn = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  button {
    position: relative;
    top: -2px;
  }
`;

export const CenterDataCell = styled.div`
  text-align: center;
  width: 100%;
`;

export const EmptyState = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
`;

export const LinkToOrg = styled(TableLinkedCell)`
  max-width: 10.68rem;
`;

export const ProviderOrganizationsTable = styled(Table)`
  tr th:nth-child(2) {
    > div {
      justify-content: end;
    }
  }

  tr td {
    height: 3.125rem;
  }
`;

export const SingleTable = styled(DataContainer)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-bottom: 0;
`;
