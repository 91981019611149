import { Temporal } from '@js-temporal/polyfill';
import capitalize from 'lodash/capitalize';
import * as S from './styles';
import type { Benchmark } from '../..';
import Checkbox from '@/components/FormFields/Checkbox';
import { VIOLET_COMMUNITY_DEFINITIONS } from '@/utils';

interface Props {
  activeTab: 'linkedin' | 'email' | 'html' | 'success';
  benchmark: Benchmark;
  checkBenchmarkChange: (benchmark: Benchmark) => void;
  handleBenchmarkClick: (benchmark: Benchmark) => void;
  selectedBenchmarks: Benchmark[];
}

const BenchmarkCard = ({
  activeTab,
  benchmark,
  checkBenchmarkChange,
  handleBenchmarkClick,
  selectedBenchmarks
}: Props) => {
  const imageUrl = benchmark.image.url.includes('http')
    ? benchmark.image.url
    : `${import.meta.env.VITE_BASE_PATH}${benchmark.image.url}`;

  const earnedAtDate = Temporal.PlainDate.from(
    benchmark.earned_at[benchmark.earned_at.length - 1] === 'Z'
      ? benchmark.earned_at.slice(0, -1)
      : benchmark.earned_at
  ).toLocaleString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric'
  });

  const isSelected = selectedBenchmarks.some(
    b => b.score_type === benchmark.score_type && b.score_level === benchmark.score_level
  );

  return (
    <S.BenchmarkItem
      key={`${benchmark.score_type} ${benchmark.score_level}`}
      className={isSelected ? 'is-selected' : ''}
      data-cy="benchmark-item"
      onPress={handleBenchmarkClick.bind(null, benchmark)}
    >
      {activeTab !== 'linkedin' && (
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Checkbox
            aria-label={`Share ${benchmark.score_type.toUpperCase()} ${benchmark.score_level}`}
            data-cy="benchmark-checkbox"
            isSelected={isSelected}
            onChange={() => checkBenchmarkChange(benchmark)}
          />
        </div>
      )}
      <S.BenchmarkWrapper className={benchmark.score_level === 'awareness' ? 'not-round' : ''}>
        <S.BenchmarkImage
          alt={`${benchmark.score_type.toUpperCase()} ${benchmark.score_level}`}
          src={imageUrl}
        />
      </S.BenchmarkWrapper>
      <S.TextWrapper>
        <S.RowWrapper>
          <S.BenchmarkLabel>Cultural {capitalize(benchmark.score_level)}</S.BenchmarkLabel>
          <S.DateLabel>Earned {earnedAtDate}</S.DateLabel>
        </S.RowWrapper>
        <S.RowWrapper>
          <S.BenchmarkSubLabel>
            {benchmark.score_type.toUpperCase()} communities
          </S.BenchmarkSubLabel>
        </S.RowWrapper>
        <S.RowWrapper>
          <S.BenchmarkDescription>
            {VIOLET_COMMUNITY_DEFINITIONS[
              benchmark.score_type as keyof typeof VIOLET_COMMUNITY_DEFINITIONS
            ] ?? ''}
          </S.BenchmarkDescription>
        </S.RowWrapper>
      </S.TextWrapper>
    </S.BenchmarkItem>
  );
};

export default BenchmarkCard;
