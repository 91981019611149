import type { Cacheable, OAuthError, RedirectLoginOptions } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { AnimatePresence, motion, MotionGlobalConfig } from 'framer-motion';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  matchPath,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';

import ErrorModalDialog from './components/Modals/ErrorModalDialog';
import ErrorState from './components/ErrorState';
import ForbiddenModalDialog from './components/Modals/ForbiddenModalDialog';
import DashboardWrapper from './components/Navigation';
import NotificationView from './components/NotificationView';
import SignedOutModalDialog from './components/Modals/SignedOutModalDialog';
import { FADE_IN_TRANSITION_DURATION_MS, FADE_OUT_TRANSITION_DURATION_MS } from './constants';
import useAnalytics from './hooks/useAnalytics';
import type { Notification } from './hooks/useNotifications';
import { NotificationContext } from './hooks/useNotifications';
import { ErrorModalDialogContext } from './hooks/useOpenErrorModalDialog';
import { OpenForbiddenModalDialogContext } from './hooks/useOpenForbiddenModalDialog';
import { SignedOutModalDialogContext } from './hooks/useOpenSignedOutModalDialog';
import { UserContext } from './hooks/useUser';
import Dashboard from './pages/Dashboard';
import DashboardEducationAllEducation from './pages/Dashboard/Education/AllEducation';
import DashboardEducationCollections from './pages/Dashboard/Education/Collections';
import DashboardEducationCollection from './pages/Dashboard/Education/Collections/Collection';
import DashboardEducationCollectionsCollectionAccreditationCertificate from './pages/Dashboard/Education/Collections/Collection/AccreditationCertificate';
import DashboardEducationCollectionsCollectionAccreditationSurvey from './pages/Dashboard/Education/Collections/Collection/AccreditationSurvey';
import DashboardEducationCompletionsAndCertificates from './pages/Dashboard/Education/CompletionsAndCertificates';
import DashboardEducationWrapper from './pages/Dashboard/Education/components/Wrapper';
import DashboardEducationEducationalResource from './pages/Dashboard/Education/EducationalResource';
import DashboardEducationInProgressEducation from './pages/Dashboard/Education/InProgressEducation';
import DashboardEducationPathways from './pages/Dashboard/Education/Pathways';
import DashboardEducationPathway from './pages/Dashboard/Education/Pathways/Pathway';
import DashboardMyNetwork from './pages/Dashboard/MyNetwork';
import DashboardMyNetworkEducationEngagement from './pages/Dashboard/MyNetwork/EducationEngagement';
import DashboardMyNetworkInclusivity from './pages/Dashboard/MyNetwork/Inclusivity';
import DashboardMyNetworkDiversity from './pages/Dashboard/MyNetwork/Inclusivity/Diversity';
import DashboardMyNetworkInclusivityOverview from './pages/Dashboard/MyNetwork/Inclusivity/Overview';
import DashboardMyNetworkInclusivityServiceAreas from './pages/Dashboard/MyNetwork/Inclusivity/ServiceAreas';
import DashboardMyNetworkWrapper from './pages/Dashboard/MyNetwork/Wrapper';
import DashboardMyOrganization from './pages/Dashboard/MyOrganization';
import DashboardMyOrganizationAssignments from './pages/Dashboard/MyOrganization/Education/Assignments';
import DashboardMyOrganizationWrapper from './pages/Dashboard/MyOrganization/components/Wrapper';
import DashboardMyOrganizationDemographics from './pages/Dashboard/MyOrganization/Inclusivity/Demographics';
import DashboardMyOrganizationEducationEngagement from './pages/Dashboard/MyOrganization/Education/Engagement';
import DashboardMyOrganizationInclusivity from './pages/Dashboard/MyOrganization/Inclusivity/Benchmarks';
import DashboardMyOrganizationTeam from './pages/Dashboard/MyOrganization/Team';
import DashboardProfileAccountInformation from './pages/Dashboard/Profile/AccountInformation';
import DashboardProfileAdditionalExperience from './pages/Dashboard/Profile/AdditionalExperience';
import DashboardProfileWrapper from './pages/Dashboard/Profile/components/Wrapper';
import DashboardProfileCulturalCompetencies from './pages/Dashboard/Profile/CulturalCompetencies';
import DashboardProfileEducation from './pages/Dashboard/Profile/Education';
import DashboardProfileIdentityAndLanguages from './pages/Dashboard/Profile/IdentityAndLanguages';
import DashboardProfileWorkExperience from './pages/Dashboard/Profile/WorkExperience';
import NotFound from './pages/NotFound';
import OnboardingCulturalCompetencies from './pages/Onboarding/CulturalCompetencies';
import OnboardingEducationExperience from './pages/Onboarding/EducationExperience';
import OnboardingFurtherExperience from './pages/Onboarding/FurtherExperience';
import OnboardingIdentity from './pages/Onboarding/Identity';
import OnboardingInformation from './pages/Onboarding/Information';
import OnboardingSubmitted from './pages/Onboarding/Submitted';
import OnboardingWelcome from './pages/Onboarding/Welcome';
import OnboardingWorkExperience from './pages/Onboarding/WorkExperience';
import { camelizeObjectKeys } from './utils/camelCaseObject';
import getPageTitleAndCategory from './utils/getPageTitleAndCategory';
import posthog from './utils/posthog';
import { ShareBenchmarksContext } from './hooks/useShareBenchmarks';
import ShareBenchmarksModalDialog from './components/Modals/ShareBenchmarksModalDialog';
import ToastProvider from './components/ToastProvider';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

if (window.Cypress) {
  // Disable animations in Cypress tests for better reliable tests
  MotionGlobalConfig.skipAnimations = true;
}

// Keep track of paths outside of React's lifecycle
let previousPath = '';

const MotionDiv = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  // Calculate skip animation synchronously during render
  const currentPathParts = location.pathname.split('/');
  const prevPathParts = previousPath.split('/');
  const currentMainRoute = currentPathParts[2] || '';
  const prevMainRoute = prevPathParts[2] || '';
  const skipAnimation = currentMainRoute === prevMainRoute;

  // Update previous path after logging
  useLayoutEffect(() => {
    previousPath = location.pathname;
  }, [location.pathname]);

  const variants = {
    center: {
      opacity: 1,
      transition: {
        delay: skipAnimation ? 0 : FADE_OUT_TRANSITION_DURATION_MS / 1000,
        duration: skipAnimation ? 0 : FADE_IN_TRANSITION_DURATION_MS / 1000
      }
    },
    enter: {
      opacity: skipAnimation ? 1 : 0
    },
    exit: {
      opacity: skipAnimation ? 1 : 0,
      transition: {
        duration: skipAnimation ? 0 : FADE_OUT_TRANSITION_DURATION_MS / 1000
      }
    }
  };

  return (
    <motion.div
      animate="center"
      exit="exit"
      initial="enter"
      style={{ height: '100%', position: 'relative', width: '100%' }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

const AssignmentRedirect = () => {
  const { assignmentId } = useParams();
  const [searchParams] = useSearchParams();
  return (
    <Navigate
      to={`/dashboard/my-organization/education/assignments${assignmentId ? `/${assignmentId}` : ''}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`}
    />
  );
};

const App = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const accessTokenRef = useRef<string>();
  const isFirstRunRef = useRef(true);
  const isSelfServiceUserManagementEnabled = useFeatureFlagEnabled('self_service_user_management');

  const { analytics } = useAnalytics();
  const [error, setError] = useState<Error>();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [source, setSource] = useState<'announcement' | 'dashboard'>('dashboard');
  const [wasIdentified, setWasIdentified] = useState(false);

  const {
    error: auth0Error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout
  } = useAuth0();

  const {
    close: closeErrorModalDialog,
    isOpen: errorModalDialogIsOpen,
    open: openErrorModalDialog
  } = useOverlayTriggerState({});

  const shareBenchmarksModalDialog = useOverlayTriggerState({});

  const [networkParams, setNetworkParams] = useState('');
  const [isNetworkManager, setIsNetworkManager] = useState(false);
  const [isSuperuserOfNetworkOrg, setIsSuperuserOfNetworkOrg] = useState(false);

  const { isOpen: signedOutModalDialogIsOpen, open: openSignedOutModalDialog } =
    useOverlayTriggerState({});
  const {
    close: closeForbiddenModalDialog,
    isOpen: forbiddenModalDialogIsOpen,
    open: openForbiddenModalDialog
  } = useOverlayTriggerState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOpenErrorModalDialog = useCallback(openErrorModalDialog, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOpenSignedOutModalDialog = useCallback(openSignedOutModalDialog, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOpenForbiddenModalDialog = useCallback(openForbiddenModalDialog, []);

  const handleHeadwayLmsRedirect = async () =>
    new Promise(resolve => {
      if (searchParams.get('source') === 'headway_lms') {
        localStorage.setItem('is_headway_lms', 'true');
        localStorage.setItem('redirect_after_onboarding_url', `"${location.pathname}"`);
      }
      resolve(true);
    });

  const refetchUser = useCallback(async () => {
    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/profile`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${accessTokenRef.current}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data: user } = (await response.json()) as APIProfile;

      setUser(user);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }
  }, [openErrorModalDialog, openSignedOutModalDialog]);

  const fetchUnreadNotifications = useCallback(async () => {
    if (accessTokenRef.current === undefined) {
      return;
    }

    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/notifications/unread`;
    const options: RequestInit = {
      headers: { Authorization: `Bearer ${accessTokenRef.current}` }
    };
    try {
      const response = await fetch(url, options);
      const { data } = (await response.json()) as APIUnreadNotifications;
      setNotifications(
        data.map(notification => camelizeObjectKeys(notification)) as Notification[]
      );
    } catch (error) {
      Sentry.captureException(error);
      setNotifications([]);
    }
  }, []);

  const markNotificationAsRead = useCallback(
    async (id: string) => {
      if (accessTokenRef.current === undefined) {
        return;
      }

      const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/notifications/${id}/read`;
      const options: RequestInit = {
        headers: { Authorization: `Bearer ${accessTokenRef.current}` },
        method: 'POST'
      };
      try {
        await fetch(url, options);
      } catch (error) {
        // Do not show error modal; fail silently and still hide notification
        Sentry.captureException(error);
      }
      setNotifications(
        notifications.map(notification =>
          notification.id === id ? { ...notification, read: true } : notification
        )
      );
    },
    [notifications]
  );

  useEffect(() => {
    if (isLoading) return;

    if (auth0Error) {
      setError(auth0Error);
      return;
    }

    const auth0Cypress = localStorage.getItem('auth0Cypress');
    const cypressAuth0Login =
      auth0Cypress === null ? null : (JSON.parse(auth0Cypress) as Cacheable);
    const redirectLoginOptions: RedirectLoginOptions = {
      appState: { returnTo: `${window.location.pathname}${window.location.search}` }
    };
    if (!isAuthenticated && !cypressAuth0Login) {
      handleHeadwayLmsRedirect().then(() => {
        loginWithRedirect(redirectLoginOptions);
      });
      return;
    }

    (async () => {
      try {
        if (cypressAuth0Login) {
          // @ts-expect-error - Its OK to stub the access token for cypress tests
          accessTokenRef.current = cypressAuth0Login;
        } else {
          accessTokenRef.current = await getAccessTokenSilently({
            authorizationParams: { audience: import.meta.env.VITE_AUTH0_API_AUDIENCE }
          });
        }
      } catch (error_) {
        const error = error_ as OAuthError;

        // This handles the case where the user opens the web app and their session has expired.
        // Since @auth0/auth0-react v2, `isAuthenticated` remains `true` even after the user's session has expired.
        if (error.error === 'login_required') {
          handleHeadwayLmsRedirect().then(() => {
            loginWithRedirect(redirectLoginOptions);
          });
          return;
        }

        setError(error);
        return;
      }

      const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/profile`;

      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${accessTokenRef.current}`
        }
      };

      let userData: User | undefined = undefined;

      try {
        const response = await fetch(url, options);
        const json = (await response.json()) as APIProfile | { errors?: string[] };

        if (!response.ok) {
          const { errors } = json as { errors?: string[] };
          if (response.status === 401 && !(errors?.includes('SSO Error') ?? false)) {
            openSignedOutModalDialog();
            setError(new Error('401: Unauthenticated'));
          } else {
            throw new Error(`${response.status} (${errors![0] || response.statusText})`);
          }
        } else {
          const { data: user } = json as APIProfile;

          Sentry.setUser({
            email: user.email,
            id: user.id,
            username: `${user.first_name} ${user.last_name}`
          });

          setUser(user);
          userData = { ...user };

          const { status: userStatus } = user;

          if (userStatus === 'created') {
            const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/profile/activate`;

            const options: RequestInit = {
              headers: {
                Authorization: `Bearer ${accessTokenRef.current}`,
                'Content-Type': 'application/json'
              },
              method: 'POST'
            };

            const response = await fetch(url, options);

            if (!response.ok) {
              if (response.status === 401) {
                openSignedOutModalDialog();
              } else {
                throw new Error(`${response.status} (${response.statusText})`);
              }
            }

            const updatedUser = { ...user, status: 'activated' as User['status'] };

            setUser(updatedUser);
            userData = { ...updatedUser };
          }
        }
      } catch (error) {
        setError(error as Error);
        return;
      } finally {
        setIsNetworkManagerAndSuperuserOfNetworkOrg(userData);
      }

      fetchUnreadNotifications();

      setPageIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Error, getAccessTokenSilently, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (error) {
      setPageIsLoading(false);
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (!analytics || !user || wasIdentified) return;

    analytics.identify(user.id, {
      account_status: user.status,
      completed_onboarding_at: user.completed_onboarding_at,
      created_at: user.created_at,
      email: user.email,
      first_name: user.first_name,
      hubspot_id: user.hubspot_id,
      is_clinical: user.is_clinical,
      last_name: user.last_name,
      npi: user.user_info.npi,
      primary_role: user.member_roles.includes('network_manager')
        ? 'network_manager'
        : user.member_roles.includes('superuser')
          ? 'superuser'
          : 'member',
      sign_up_source: user.sign_up_source
    });

    analytics.group(user.organization_memberships[0].organization.id, {
      name: user.organization_memberships[0].organization.name
    });

    setWasIdentified(true);
  }, [analytics, user, wasIdentified]);

  useEffect(() => {
    if (!user) {
      return;
    }
    posthog.identify(user.id);
  }, [user]);

  useEffect(() => {
    if (!pageIsLoading) {
      const pageSpinner = document.querySelector<HTMLDivElement>('#page-spinner');
      const pageSpinnerStyles = document.querySelector<HTMLStyleElement>('#page-spinner-styles');

      // If the App component is mounted a second time, which happens during development because of React Fast Refresh, the page spinner and its styles will already have been removed.
      if (pageSpinner && pageSpinnerStyles) {
        pageSpinner.remove();
        pageSpinnerStyles.remove();
      }
    }
  }, [pageIsLoading]);

  useEffect(() => {
    if (isFirstRunRef.current) {
      isFirstRunRef.current = false;
      return;
    }

    const isOnboarding = location.pathname.split('/')[1] === 'onboarding';

    if (isOnboarding) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, FADE_OUT_TRANSITION_DURATION_MS);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    const category = getPageTitleAndCategory(location.pathname);
    analytics?.page(category, document.title);
  }, [location.pathname, analytics]);

  const setIsNetworkManagerAndSuperuserOfNetworkOrg = (userData: User | undefined) => {
    if (!userData) return;

    const networkManagerMembership = userData.organization_memberships.find(
      membership => membership.member_role === 'network_manager'
    );
    setIsNetworkManager(networkManagerMembership !== undefined);
    setIsSuperuserOfNetworkOrg(
      userData.organization_memberships.some(
        membership =>
          membership.member_role === 'superuser' &&
          membership.organization_id === networkManagerMembership?.organization_id
      )
    );
    if (networkManagerMembership !== undefined && !userData.completed_onboarding) {
      const networkParamsString = `internal_referral=onboarding&is_clinical=${userData.is_clinical}&org_role=network_manager`;
      setNetworkParams(networkParamsString);
    }
  };

  const memoizedUserContextValue = useMemo(
    () => ({
      bearerToken: accessTokenRef.current!,
      refetchUser,
      setUser: setUser as Dispatch<SetStateAction<User>>,
      user: user!
    }),
    [refetchUser, user]
  );

  const memoizedNotificationContextValue = useMemo(
    () => ({
      fetchUnreadNotifications,
      markNotificationRead: markNotificationAsRead,
      notifications,
      setNotifications,
      unreadNotifications: notifications.filter(notification => !notification.read)
    }),
    [fetchUnreadNotifications, markNotificationAsRead, notifications, setNotifications]
  );

  if (pageIsLoading) return null;

  if (error)
    return (
      <SignedOutModalDialogContext.Provider value={memoizedOpenSignedOutModalDialog}>
        <OpenForbiddenModalDialogContext.Provider value={memoizedOpenForbiddenModalDialog}>
          <ErrorState error={error} />
          {signedOutModalDialogIsOpen && <SignedOutModalDialog />}
          {forbiddenModalDialogIsOpen && <ForbiddenModalDialog close={closeForbiddenModalDialog} />}
        </OpenForbiddenModalDialogContext.Provider>
      </SignedOutModalDialogContext.Provider>
    );

  if (matchPath('/sign-out', window.location.pathname) !== null) {
    logout();
    return null;
  }

  const userHasCompletedOnboarding = user!.completed_onboarding;

  const isEmbeddedEducationPage =
    matchPath('/dashboard/education/:educationalResourceId/view', window.location.pathname) !==
    null;

  return (
    <AnimatePresence
      initial={false}
      mode="wait"
    >
      <SentryRoutes
        key={location.pathname}
        location={location}
      >
        <Route
          element={
            <UserContext.Provider value={memoizedUserContextValue}>
              <ErrorModalDialogContext.Provider value={memoizedOpenErrorModalDialog}>
                <SignedOutModalDialogContext.Provider value={memoizedOpenSignedOutModalDialog}>
                  <OpenForbiddenModalDialogContext.Provider
                    value={memoizedOpenForbiddenModalDialog}
                  >
                    <ShareBenchmarksContext.Provider
                      value={{ source, setSource, shareBenchmarksModalDialog }}
                    >
                      <NotificationContext.Provider value={memoizedNotificationContextValue}>
                        <Routes>
                          <Route
                            element={
                              <Navigate
                                to={
                                  isNetworkManager
                                    ? isSuperuserOfNetworkOrg
                                      ? '/dashboard/my-organization'
                                      : '/dashboard/my-network'
                                    : !userHasCompletedOnboarding
                                      ? '/onboarding/welcome'
                                      : '/dashboard'
                                }
                              />
                            }
                            path="/"
                          />
                          <Route
                            element={
                              userHasCompletedOnboarding || isNetworkManager ? (
                                <ToastProvider placement="top">
                                  {toastState => (
                                    <DashboardWrapper isHidden={isEmbeddedEducationPage}>
                                      <MotionDiv>
                                        <Outlet context={toastState} />
                                        {shareBenchmarksModalDialog.isOpen && (
                                          <ShareBenchmarksModalDialog
                                            close={shareBenchmarksModalDialog.close}
                                            source={source}
                                            toastState={toastState}
                                          />
                                        )}
                                      </MotionDiv>
                                    </DashboardWrapper>
                                  )}
                                </ToastProvider>
                              ) : (
                                <Navigate to="/onboarding/welcome" />
                              )
                            }
                            path="dashboard/*"
                          >
                            <Route
                              element={
                                isNetworkManager && !location.pathname.includes('my-') ? (
                                  <Navigate
                                    replace
                                    to={{
                                      pathname: isSuperuserOfNetworkOrg
                                        ? '/dashboard/my-organization'
                                        : '/dashboard/my-network',
                                      search: networkParams.length > 0 ? `?${networkParams}` : ''
                                    }}
                                  />
                                ) : (
                                  <Dashboard />
                                )
                              }
                              path=""
                            />
                            <Route path="education/collections/:collectionId/*">
                              <Route
                                element={
                                  <DashboardEducationCollectionsCollectionAccreditationCertificate />
                                }
                                path="accreditation-certificate"
                              />
                              <Route
                                element={
                                  <DashboardEducationCollectionsCollectionAccreditationSurvey />
                                }
                                path="accreditation-survey"
                              />
                            </Route>
                            <Route
                              element={
                                <DashboardEducationWrapper isHidden={isEmbeddedEducationPage} />
                              }
                              path="education/*"
                            >
                              <Route
                                element={<Navigate to="collections" />}
                                path=""
                              />
                              <Route
                                element={<DashboardEducationAllEducation />}
                                path="all-education/*"
                              >
                                <Route
                                  element={<DashboardEducationAllEducation />}
                                  path=":courseId"
                                />
                              </Route>
                              <Route path="collections/*">
                                <Route
                                  element={<DashboardEducationCollections />}
                                  path=""
                                />
                                <Route
                                  element={<DashboardEducationCollection />}
                                  path=":collectionId/*"
                                />
                                <Route
                                  element={<DashboardEducationCollection />}
                                  path=":collectionId/:courseId/*"
                                />
                              </Route>
                              <Route path="pathways/*">
                                <Route
                                  element={<DashboardEducationPathways />}
                                  path=""
                                />
                                <Route
                                  element={<DashboardEducationPathway />}
                                  path=":pathwayId/*"
                                />
                                <Route
                                  element={<DashboardEducationPathway />}
                                  path=":pathwayId/:courseId/*"
                                />
                              </Route>
                              <Route
                                element={<DashboardEducationEducationalResource />}
                                path=":educationalResourceId/view"
                              />
                            </Route>
                            <Route
                              element={
                                <DashboardEducationWrapper isHidden={isEmbeddedEducationPage} />
                              }
                              path="my-education/*"
                            >
                              <Route
                                element={<Navigate to="in-progress" />}
                                path=""
                              />
                              <Route
                                element={<DashboardEducationCompletionsAndCertificates />}
                                path="completions-and-certificates"
                              />
                              <Route
                                element={<DashboardEducationInProgressEducation />}
                                path="in-progress"
                              />
                            </Route>
                            <Route
                              element={<DashboardMyNetworkWrapper />}
                              path="my-network/*"
                            >
                              <Route
                                element={<DashboardMyNetwork />}
                                path=""
                              />
                              <Route
                                element={<Navigate to="inclusivity/diversity" />}
                                path="demographics"
                              />
                              <Route
                                element={<DashboardMyNetworkEducationEngagement />}
                                path="education-engagement"
                              />
                              <Route
                                element={<DashboardMyNetworkInclusivity />}
                                path="inclusivity/*"
                              >
                                <Route
                                  element={<DashboardMyNetworkInclusivityOverview />}
                                  path=""
                                />
                                <Route
                                  element={<DashboardMyNetworkInclusivityServiceAreas />}
                                  path="service-areas"
                                />
                                <Route
                                  element={<DashboardMyNetworkDiversity />}
                                  path="diversity"
                                />
                              </Route>
                            </Route>
                            <Route
                              element={<DashboardMyOrganizationWrapper />}
                              path="my-organization/*"
                            >
                              <Route
                                element={<DashboardMyOrganization />}
                                path=""
                              />
                              <Route
                                element={<DashboardMyOrganizationTeam />}
                                path="team"
                              />
                              <Route
                                element={
                                  isSelfServiceUserManagementEnabled === true ? (
                                    <Navigate to="/dashboard/my-organization/inclusivity/benchmarks" />
                                  ) : (
                                    <Navigate to="/dashboard/my-organization/inclusivity" />
                                  )
                                }
                                path="cultural-competencies"
                              />
                              <Route
                                element={
                                  isSelfServiceUserManagementEnabled === true ? (
                                    <Navigate to="/dashboard/my-organization/inclusivity/benchmarks" />
                                  ) : (
                                    <DashboardMyOrganizationInclusivity />
                                  )
                                }
                                path="inclusivity"
                              />
                              {isSelfServiceUserManagementEnabled === true && (
                                <Route
                                  element={<Outlet />}
                                  path="inclusivity"
                                >
                                  <Route
                                    element={<DashboardMyOrganizationInclusivity />}
                                    path="benchmarks"
                                  />
                                  <Route
                                    element={<DashboardMyOrganizationDemographics />}
                                    path="demographics"
                                  />
                                </Route>
                              )}
                              <Route
                                element={
                                  isSelfServiceUserManagementEnabled === true ? (
                                    <Navigate to="/dashboard/my-organization/inclusivity/demographics" />
                                  ) : (
                                    <DashboardMyOrganizationDemographics />
                                  )
                                }
                                path="demographics"
                              />
                              <Route
                                element={
                                  isSelfServiceUserManagementEnabled === true ? (
                                    <Navigate to="/dashboard/my-organization/education/engagement" />
                                  ) : (
                                    <DashboardMyOrganizationEducationEngagement />
                                  )
                                }
                                path="education-engagement"
                              />
                              {isSelfServiceUserManagementEnabled === true && (
                                <Route
                                  element={
                                    <ToastProvider>
                                      {toastState => <Outlet context={toastState} />}
                                    </ToastProvider>
                                  }
                                  path="education"
                                >
                                  <Route
                                    element={<DashboardMyOrganizationEducationEngagement />}
                                    path="engagement"
                                  />
                                  <Route
                                    element={<DashboardMyOrganizationAssignments />}
                                    path="assignments"
                                  >
                                    <Route
                                      element={<DashboardMyOrganizationAssignments />}
                                      path=":assignmentId/*"
                                    />
                                  </Route>
                                </Route>
                              )}
                              <Route
                                element={
                                  isSelfServiceUserManagementEnabled === true ? (
                                    <AssignmentRedirect />
                                  ) : (
                                    <DashboardMyOrganizationAssignments />
                                  )
                                }
                                path="assignments"
                              >
                                <Route
                                  element={
                                    isSelfServiceUserManagementEnabled === true ? (
                                      <AssignmentRedirect />
                                    ) : (
                                      <DashboardMyOrganizationAssignments />
                                    )
                                  }
                                  path=":assignmentId/*"
                                />
                              </Route>
                            </Route>
                            <Route
                              element={<DashboardProfileWrapper />}
                              path="profile/*"
                            >
                              <Route
                                element={<Navigate to="account-information" />}
                                path=""
                              />
                              <Route
                                element={
                                  <Navigate to="/dashboard/my-education/completions-and-certificates" />
                                }
                                path="certifications"
                              />
                              <Route
                                element={<DashboardProfileEducation />}
                                path="education-history"
                              />
                              <Route
                                element={<DashboardProfileCulturalCompetencies />}
                                path="cultural-competencies"
                              />
                              <Route
                                element={<Navigate to="/dashboard/profile/account-information" />}
                                path="information"
                              />
                              <Route
                                element={<DashboardProfileAccountInformation />}
                                path="account-information"
                              />
                              <Route
                                element={<DashboardProfileIdentityAndLanguages />}
                                path="identity-and-languages"
                              />
                              <Route
                                element={<DashboardProfileWorkExperience />}
                                path="work-experience"
                              />
                              <Route
                                element={<DashboardProfileAdditionalExperience />}
                                path="additional-experience"
                              />
                            </Route>
                          </Route>
                          <Route
                            element={
                              <Navigate
                                to={
                                  userHasCompletedOnboarding ? '/dashboard' : '/onboarding/welcome'
                                }
                              />
                            }
                            path="login"
                          />
                          <Route
                            element={
                              userHasCompletedOnboarding &&
                              window.location.pathname !== '/onboarding/submitted' ? (
                                <Navigate to="/dashboard" />
                              ) : (
                                <MotionDiv>
                                  <Outlet />
                                </MotionDiv>
                              )
                            }
                            path="onboarding/*"
                          >
                            <Route
                              element={<Navigate to="welcome" />}
                              path=""
                            />
                            <Route
                              element={<OnboardingCulturalCompetencies />}
                              path="cultural-competencies"
                            />
                            <Route
                              element={<OnboardingEducationExperience />}
                              path="education-experience"
                            />
                            <Route
                              element={<OnboardingFurtherExperience />}
                              path="further-experience"
                            />
                            <Route
                              element={<OnboardingIdentity />}
                              path="identity"
                            />
                            <Route
                              element={<OnboardingInformation />}
                              path="information"
                            />
                            <Route
                              element={<OnboardingSubmitted />}
                              path="submitted"
                            />
                            <Route
                              element={<OnboardingWelcome />}
                              path="welcome"
                            />
                            <Route
                              element={<OnboardingWorkExperience />}
                              path="work-experience"
                            />
                          </Route>
                          <Route
                            element={<NotFound />}
                            path="*"
                          />
                        </Routes>
                        {errorModalDialogIsOpen && (
                          <ErrorModalDialog close={closeErrorModalDialog} />
                        )}
                        {signedOutModalDialogIsOpen && <SignedOutModalDialog />}
                        {forbiddenModalDialogIsOpen && (
                          <ForbiddenModalDialog close={closeForbiddenModalDialog} />
                        )}
                        <NotificationView />
                      </NotificationContext.Provider>
                    </ShareBenchmarksContext.Provider>
                  </OpenForbiddenModalDialogContext.Provider>
                </SignedOutModalDialogContext.Provider>
              </ErrorModalDialogContext.Provider>
            </UserContext.Provider>
          }
          path="*"
        />
      </SentryRoutes>
    </AnimatePresence>
  );
};

export default App;
