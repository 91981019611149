import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import type { SortDescriptor } from 'react-stately';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';
import TableComparisonCell from '@/components/Table/TableComparisonCell';
import TableNumberCell from '@/components/Table/TableNumberCell';
import { STATES } from '@/pages/constants';
import type { ServiceAreaComparisonResponse } from '@/pages/Dashboard/hooks/useGetNetworkServiceAreaComparisons';
import useGetNetworkServiceAreaComparisons from '@/pages/Dashboard/hooks/useGetNetworkServiceAreaComparisons';
import type { NetworkProvidersLocation } from '@/pages/Dashboard/utils';

import useNetworks from '../../../Wrapper/useNetworks';
import * as Shared from '../styles';

import * as S from './styles';

interface Props {
  currentPageLocations: number;
  handleLocationsTableSortChange?: (descriptor: SortDescriptor) => void;
  handleSetPageLocations: (page: number) => void;
  hideNameColumn?: boolean;
  networkLocations: NetworkProvidersLocation[];
  networkLocationsTotalPages: number;
  sortDescriptorLocations?: SortDescriptor;
}

export const ServiceAreasTable = ({
  currentPageLocations,
  handleLocationsTableSortChange,
  handleSetPageLocations,
  hideNameColumn = false,
  networkLocations,
  networkLocationsTotalPages,
  sortDescriptorLocations
}: Props) => {
  const { defaultComparisonStates } = useNetworks();
  const [searchParams] = useSearchParams();
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });
  const isComparativeDataEnabled = useFeatureFlagEnabled('comparative_network_data');

  const { isFetching, singleFetchServiceAreaComparisons } = useGetNetworkServiceAreaComparisons({
    locations: defaultComparisonStates
  });

  const [locationComparisons, setLocationComparisons] = useState<
    Record<string, ServiceAreaComparisonResponse>
  >({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchLocationComparisons = async () => {
      if (isFetching) return;

      const locationTypeValue =
        searchParams.get('view') !== null ? searchParams.get('view') : 'county';
      const locationType = (locationTypeValue ?? 'county') as 'city' | 'county' | 'state' | 'zip';

      const comparisons: Record<string, ServiceAreaComparisonResponse> = {};
      for (const location of networkLocations) {
        try {
          const result = await singleFetchServiceAreaComparisons(
            {
              locations: [
                {
                  location_name: location.name ?? '',
                  location_type: locationType,
                  state_code: location.state
                }
              ],
              scope: [locationType]
            },
            signal
          );
          comparisons[`${location.state}-${location.name}`] = {
            data: result.data,
            meta: result.meta
          };
        } catch (error) {
          if (error instanceof Error && error.name === 'AbortError') {
            return;
          }
          comparisons[`${location.state}-${location.name}`] = {
            data: [],
            meta: undefined
          };
        }
      }
      setLocationComparisons(comparisons);
    };

    fetchLocationComparisons();

    return () => {
      controller.abort();
    };
  }, [networkLocations, isFetching, singleFetchServiceAreaComparisons, searchParams]);

  return (
    <>
      <S.LocationTable
        $showAllColumns={!hideNameColumn}
        aria-label="Table of service area locations"
        data-cy="network-service-area-table"
        sortDescriptor={sortDescriptorLocations}
        onSortChange={handleLocationsTableSortChange}
      >
        <TableHeader>
          <Column
            key="name"
            allowsSorting
            /* @ts-expect-error - This is a valid custom prop */
            exclude={hideNameColumn}
          >
            Service area{` - ${searchParams.get('view') ?? 'State'}`}
          </Column>
          <Column
            key="state"
            allowsSorting
          >
            {hideNameColumn ? 'Service area' : 'State'}
          </Column>
          <Column
            key="provider_count"
            allowsSorting
          >
            <TableNumberCell alignRight>Provider count</TableNumberCell>
          </Column>
          <Column
            key="bipoc_inclusivity"
            allowsSorting
          >
            {isComparativeDataEnabled === true ? (
              'BIPOC inclusivity'
            ) : (
              <TableNumberCell alignRight>BIPOC inclusivity</TableNumberCell>
            )}
          </Column>
          <Column
            key="lgbq_inclusivity"
            allowsSorting
          >
            {isComparativeDataEnabled === true ? (
              'LGBQ inclusivity'
            ) : (
              <TableNumberCell alignRight>LGBQ inclusivity</TableNumberCell>
            )}
          </Column>
          <Column
            key="tgnc_inclusivity"
            allowsSorting
          >
            {isComparativeDataEnabled === true ? (
              'TGNC inclusivity'
            ) : (
              <TableNumberCell alignRight>TGNC inclusivity</TableNumberCell>
            )}
          </Column>
        </TableHeader>
        <TableBody>
          {networkLocations.map(location => {
            const comparison = locationComparisons[`${location.state}-${location.name}`] as
              | ServiceAreaComparisonResponse
              | undefined;
            const rowServiceAreaComparisons = comparison === undefined ? [] : comparison.data;
            const rowServiceAreaComparisonsMeta = comparison?.meta;

            return (
              <Row
                key={`${location.name}-${location.state}`}
                data-cy="network-service-area-row"
              >
                <Cell
                  /* @ts-expect-error - This is a valid custom prop */
                  exclude={hideNameColumn}
                >
                  {location.name ?? 'Unknown'}
                </Cell>
                <Cell>
                  {hideNameColumn
                    ? STATES.find(state => state.abbreviation === location.state)?.name
                    : location.state}
                </Cell>
                <Cell>
                  <TableNumberCell data-cy="service-area-total-providers">
                    {commaNumFormatter.format(location.totalProviders)}
                  </TableNumberCell>
                </Cell>
                <Cell>
                  {isComparativeDataEnabled === true ? (
                    <TableComparisonCell
                      community="bipoc"
                      data-cy="bipoc-inclusivity-comparison"
                      isFetching={isFetching || rowServiceAreaComparisonsMeta === undefined}
                      serviceAreaComparisons={rowServiceAreaComparisons}
                      serviceAreaComparisonsMeta={rowServiceAreaComparisonsMeta}
                      totalNetworkInclusivePercentage={location.inclusivity.bipoc.percentage}
                      totalNetworkInclusiveProviders={location.inclusivity.bipoc.total}
                    />
                  ) : (
                    <TableNumberCell data-cy="bipoc-inclusivity-percentage">
                      {`${location.inclusivity.bipoc.percentage}%`}
                    </TableNumberCell>
                  )}
                </Cell>
                <Cell>
                  {isComparativeDataEnabled === true ? (
                    <TableComparisonCell
                      community="lgbq"
                      data-cy="lgbq-inclusivity-comparison"
                      isFetching={isFetching || rowServiceAreaComparisonsMeta === undefined}
                      serviceAreaComparisons={rowServiceAreaComparisons}
                      serviceAreaComparisonsMeta={rowServiceAreaComparisonsMeta}
                      totalNetworkInclusivePercentage={location.inclusivity.lgbq.percentage}
                      totalNetworkInclusiveProviders={location.inclusivity.lgbq.total}
                    />
                  ) : (
                    <TableNumberCell data-cy="lgbq-inclusivity-percentage">
                      {`${location.inclusivity.lgbq.percentage}%`}
                    </TableNumberCell>
                  )}
                </Cell>
                <Cell>
                  {isComparativeDataEnabled === true ? (
                    <TableComparisonCell
                      community="tgnc"
                      data-cy="tgnc-inclusivity-comparison"
                      isFetching={isFetching || rowServiceAreaComparisonsMeta === undefined}
                      serviceAreaComparisons={rowServiceAreaComparisons}
                      serviceAreaComparisonsMeta={rowServiceAreaComparisonsMeta}
                      totalNetworkInclusivePercentage={location.inclusivity.tgnc.percentage}
                      totalNetworkInclusiveProviders={location.inclusivity.tgnc.total}
                    />
                  ) : (
                    <TableNumberCell data-cy="tgnc-inclusivity-percentage">
                      {`${location.inclusivity.tgnc.percentage}%`}
                    </TableNumberCell>
                  )}
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </S.LocationTable>
      <Shared.TablePagination
        currentPage={currentPageLocations}
        setPage={handleSetPageLocations}
        totalPages={networkLocationsTotalPages}
      />
    </>
  );
};
