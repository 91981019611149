import { Link } from 'react-router-dom';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const ChildLink = styled(Link)`
  ${TEXT_STYLES.bodyCopyRegular}
  text-decoration: none;

  padding: 0.5rem 1.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline-color: var(--bright-purple-400);
  }
`;

export const ChildLinkWrapper = styled.li`
  border-radius: 0.75rem;
  text-align: center;
  flex-grow: 1;

  ${ChildLink} {
    color: var(--gray-400);
  }

  &:hover {
    ${ChildLink} {
      color: var(--gray-600);
    }

    background-color: var(--gray-100);
  }

  &.is-selected {
    font-weight: 500;
    background: var(--light-purple-50);

    ${ChildLink} {
      color: var(--purple-400);
      font-weight: 500;
    }

    &:hover {
      background-color: var(--light-purple-100);
    }
  }
`;

export const ChildNavigation = styled.ul`
  position: absolute;
  list-style: none;
  top: 103px;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  background: #fff;
  border-top: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  opacity: 0;
  height: 0;
  visibility: hidden;
  z-index: 5;
  transition:
    height 0.5s ease-in-out,
    opacity 0.5s ease-in-out,
    visibility 0s linear 0.5s;
  overflow: hidden;

  &.active-child-nav {
    opacity: 1;
    height: 3.5rem;
    visibility: visible;
  }
`;

export const ChildNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  max-width: 1420px;
  padding: 0 var(--space-9);
`;

export const DesktopSecondaryNavigationWrapper = styled.div`
  margin: 0;
  margin-left: auto;
  flex-grow: 1;
  height: 100%;
  padding: 0 3rem;
  max-width: calc(100% - 3rem - 200px);
`;

export const ParentLink = styled(Link)`
  ${TEXT_STYLES.bodyCopyRegular}
  text-decoration: none;
  transition: color 0.3s;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline-color: var(--bright-purple-400);
  }
`;

export const ParentLinkWrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition: border-bottom 0.3s ease-in-out;

  ${ParentLink} {
    color: var(--gray-400);
  }

  &:hover,
  &:focus-within {
    border-bottom: 5px solid var(--gray-200);

    ${ParentLink} {
      color: var(--gray-600);
    }

    ${ChildNavigation} {
      visibility: visible;
      transition:
        height 0.5s ease-in-out,
        opacity 0.5s ease-in-out,
        visibility 0s;
      transition-delay: 0.35s;
      opacity: 1;
      height: 3.5rem;
      z-index: 6;
    }
  }

  &.is-selected {
    border-bottom: 5px solid var(--purple-400);

    ${ParentLink} {
      font-weight: 500;
      color: var(--purple-400);
    }
  }
`;

export const SecondaryNavigationBar = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`;
