import * as Sentry from '@sentry/react';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';
import { useCallback } from 'react';
import useOpenErrorModalDialog from '@/hooks/useOpenErrorModalDialog';
import useOpenForbiddenModalDialog from '@/hooks/useOpenForbiddenModalDialog';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import useUser from '@/hooks/useUser';

const apiOptions = (bearerToken: string) =>
  ({
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json'
    }
  }) as RequestInit;

export const filtersToParams = <T extends { [K in keyof T]: unknown }>(filters: T) => {
  const params = new URLSearchParams();

  // Filter out null/undefined/empty values
  const cleanedFilters = Object.entries(filters).reduce(
    (acc, [key, value]) => {
      // Skip undefined, null, and empty string values
      if (value === undefined || value === null || value === '') {
        return acc;
      }

      // Skip empty arrays
      if (Array.isArray(value) && value.length === 0) {
        return acc;
      }

      // Skip empty objects
      if (
        value &&
        typeof value === 'object' &&
        !Array.isArray(value) &&
        Object.keys(value).length === 0
      ) {
        return acc;
      }

      acc[key] = value;
      return acc;
    },
    {} as Record<string, unknown>
  );

  if (Object.keys(cleanedFilters).length === 0) {
    return '';
  }

  Object.keys(cleanedFilters).forEach(key => {
    const filterValue = cleanedFilters[key];

    if (typeof filterValue === 'string') {
      // column: 'value' => column=value
      params.append(key, filterValue);
    } else if (typeof filterValue === 'boolean' || typeof filterValue === 'number') {
      // column: true => column=true
      // column: 1 => column=1
      params.append(key, String(filterValue));
    } else if (Array.isArray(filterValue)) {
      // 'column[]': [1,2] => column[]=1&column[]=2
      // column: [1,2] => column=1&column=2
      filterValue
        .filter(item => item !== undefined && item !== null)
        .forEach(item => {
          params.append(key, String(item));
        });
    } else if (filterValue && typeof filterValue === 'object') {
      // column: { subcolumn: value } => column[subcolumn]=value
      const objValue = filterValue as Record<string, unknown>;
      Object.entries(objValue).forEach(([subKey, subValue]) => {
        if (subValue !== undefined && subValue !== null) {
          if (Array.isArray(subValue)) {
            subValue
              .filter(item => item !== undefined && item !== null)
              .forEach(item => {
                params.append(`${key}[${subKey}]`, String(item));
              });
          } else if (subValue && typeof subValue === 'object') {
            const deepObj = subValue as Record<string, unknown>;
            Object.entries(deepObj)
              .filter(([, deepValue]) => deepValue !== undefined && deepValue !== null)
              .forEach(([deepKey, deepValue]) => {
                params.append(`${key}[${subKey}][${deepKey}]`, String(deepValue));
              });
          } else {
            params.append(`${key}[${subKey}]`, String(subValue));
          }
        }
      });
    }
  });

  return params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']');
};

export const snakeCaseKeys = (obj: object) =>
  transform(obj, (acc: Record<string, unknown>, value, key: string, target) => {
    const snakeKey = isArray(target) ? key : snakeCase(key);

    acc[snakeKey] = isObject(value) ? snakeCase(value) : value;
  });

const useApiRequest = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const openForbiddenModalDialog = useOpenForbiddenModalDialog();
  const { bearerToken } = useUser();

  const reportError = useCallback(
    (error: unknown) => {
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error(error);
      openErrorModalDialog();
    },
    [openErrorModalDialog]
  );

  const validateResponse = useCallback(
    (response: Response) => {
      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else if (response.status === 403) {
          openForbiddenModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }
    },
    [openSignedOutModalDialog, openForbiddenModalDialog]
  );

  const getRequest = useCallback(
    async (url: string, signal?: AbortSignal) => {
      try {
        const response = await fetch(url, {
          ...apiOptions(bearerToken),
          signal
        });

        validateResponse(response);

        return await response.json();
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          return { data: undefined, meta: undefined };
        }
        reportError(error);
        return { data: undefined, meta: undefined };
      }
    },
    [bearerToken, reportError, validateResponse]
  );

  const postRequest = useCallback(
    async (url: string, requestBody: FormData | object) => {
      try {
        const options = apiOptions(bearerToken);
        const isFormData = requestBody instanceof FormData;

        if (isFormData && options.headers !== undefined) {
          delete (options.headers as Record<string, string>)['Content-Type'];
        }

        const response = await fetch(url, {
          body: isFormData ? requestBody : JSON.stringify(requestBody),
          method: 'POST',
          ...options
        });

        validateResponse(response);

        return response.status !== 204 ? await response.json() : response.ok;
      } catch (error) {
        reportError(error);
      }
    },
    [bearerToken, reportError, validateResponse]
  );

  const patchRequest = useCallback(
    async (url: string, requestBody: FormData | object) => {
      try {
        const options = apiOptions(bearerToken);
        const isFormData = requestBody instanceof FormData;

        if (isFormData && options.headers !== undefined) {
          delete (options.headers as Record<string, string>)['Content-Type'];
        }

        const response = await fetch(url, {
          body: isFormData ? requestBody : JSON.stringify(requestBody),
          method: 'PATCH',
          ...options
        });

        validateResponse(response);

        return response.status !== 204 ? await response.json() : response.ok;
      } catch (error) {
        reportError(error);
      }
    },
    [bearerToken, reportError, validateResponse]
  );

  const deleteRequest = useCallback(
    async (url: string) => {
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          ...apiOptions(bearerToken)
        });

        validateResponse(response);

        return response.status !== 204 ? await response.json() : response.ok;
      } catch (error) {
        reportError(error);
      }
    },
    [bearerToken, reportError, validateResponse]
  );

  return { deleteRequest, getRequest, patchRequest, postRequest, reportError };
};

export default useApiRequest;
