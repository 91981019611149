import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import type { AriaDialogProps } from 'react-aria';
import { useDialog } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import * as S from './styles';

interface Props extends AriaDialogProps {
  state: OverlayTriggerState;
}

const Dialog = ({ children, state, ...ariaDialogProps }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);

  const { dialogProps } = useDialog(ariaDialogProps, ref);

  const handleCloseButtonPress = () => {
    state.close();
  };

  return (
    <S.Dialog
      {...dialogProps}
      ref={ref}
    >
      <S.CloseButton.Root onPress={handleCloseButtonPress}>
        <S.CloseButton.Icon />
      </S.CloseButton.Root>
      {children}
    </S.Dialog>
  );
};

export default Dialog;
