import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';
import type { NonClinicalExperience } from '@/pages/Dashboard/utils';

interface ContextValue {
  draftNonClinicalExperiences: NonClinicalExperience[];
  invalidRowIndices: number[];
  originalNonClinicalExperiences: NonClinicalExperience[];
  setDraftNonClinicalExperiences: Dispatch<SetStateAction<NonClinicalExperience[]>>;
  setInvalidRowIndices: Dispatch<SetStateAction<number[]>>;
  setOriginalNonClinicalExperiences: Dispatch<SetStateAction<NonClinicalExperience[]>>;
}

export const NonClinicalExperiencesContext = createContext<ContextValue>({
  draftNonClinicalExperiences: [],
  invalidRowIndices: [],
  originalNonClinicalExperiences: [],
  setDraftNonClinicalExperiences: () => {},
  setInvalidRowIndices: () => {},
  setOriginalNonClinicalExperiences: () => {}
});

const useNonClinicalExperiences = () => useContext(NonClinicalExperiencesContext);

export const revalidateNonClinicalRows = (draftExperiences: NonClinicalExperience[]) => {
  const invalidIndices = draftExperiences.reduce<number[]>((invalidArray, experience, index) => {
    if (
      experience.category === undefined ||
      experience.organization === '' ||
      experience.role === '' ||
      experience.startYear === undefined ||
      experience.startYear < new Date().getFullYear() - 70 ||
      experience.startYear > new Date().getFullYear() ||
      (!experience.current &&
        (experience.endYear === undefined ||
          experience.endYear < new Date().getFullYear() - 70 ||
          experience.endYear > new Date().getFullYear() ||
          experience.endYear < experience.startYear))
    ) {
      invalidArray.push(index);
    }
    return invalidArray;
  }, []);
  return invalidIndices;
};

export default useNonClinicalExperiences;
