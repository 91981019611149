import ExpandLessIcon from '@material-design-icons/svg/round/expand_less.svg?react';
import ExpandMoreIcon from '@material-design-icons/svg/round/expand_more.svg?react';
import { useEffect, useState } from 'react';
import type {
  AriaCheckboxGroupProps,
  AriaCheckboxProps,
  AriaNumberFieldProps,
  AriaSelectProps,
  AriaTextFieldProps
} from 'react-aria';
import { Item } from 'react-stately';
import GroupCheckbox from '@/components/FormFields/CheckboxGroup/GroupCheckbox';
import PopoverTrigger from '@/components/PopoverTrigger';
import TableSelect from '@/components/Table/TableSelect';
import TextField from '@/components/Table/TableTextField';
import { BREAKPOINT } from '@/constants';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import type { NonClinicalExperience } from '@/pages/Dashboard/utils';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  expandedRowIndex: number;
  handleExpandCollapseClick: (index: number) => void;
  index: number;
  isInvalid: boolean;
  nonClinicalExperience: NonClinicalExperience;
  onRemove: (index: number) => void;
  updateNonClinicalExperience: (index: number, updatedExperience: NonClinicalExperience) => void;
}

const communitiesPopoverText =
  'For each entry, please specify which communities were the primary focus. If the experience you added did not focus on BIPOC, LGBQ, or TGNC communities, you do not need to include it.';

const NonClinicalExperienceRow = ({
  expandedRowIndex,
  handleExpandCollapseClick,
  index,
  isInvalid,
  nonClinicalExperience,
  onRemove,
  updateNonClinicalExperience
}: Props) => {
  const [communityCheckboxSelections, setCommunityCheckboxSelections] = useState<string[]>();
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const BodyElementType = window.innerWidth < BREAKPOINT ? S.BodyFieldElement : S.BodyCell;

  const handleCategoryChange: AriaSelectProps<object>['onSelectionChange'] = value => {
    nonClinicalExperience.category = value as
      | 'advocacy'
      | 'leadership'
      | 'research'
      | 'teaching'
      | 'volunteer';
    updateNonClinicalExperience(index, nonClinicalExperience);
  };

  const handleRoleChange: AriaTextFieldProps['onChange'] = value => {
    nonClinicalExperience.role = value;
    updateNonClinicalExperience(index, nonClinicalExperience);
  };

  const handleOrganizationChange: AriaTextFieldProps['onChange'] = value => {
    nonClinicalExperience.organization = value;
    updateNonClinicalExperience(index, nonClinicalExperience);
  };

  const handleStartYearChange: AriaNumberFieldProps['onChange'] = value => {
    nonClinicalExperience.startYear = value;
    updateNonClinicalExperience(index, nonClinicalExperience);
  };

  const handleCurrentChange: AriaCheckboxProps['onChange'] = isCurrent => {
    nonClinicalExperience.current = isCurrent;
    if (isCurrent) {
      nonClinicalExperience.endYear = undefined;
    }
    updateNonClinicalExperience(index, nonClinicalExperience);
  };

  const handleEndYearChange: AriaNumberFieldProps['onChange'] = value => {
    nonClinicalExperience.endYear = value;
    updateNonClinicalExperience(index, nonClinicalExperience);
  };

  const handleCommunityCheckboxesChange: AriaCheckboxGroupProps['onChange'] = values => {
    nonClinicalExperience.bipoc = values.includes('bipoc');
    nonClinicalExperience.lgbq = values.includes('lgbq');
    nonClinicalExperience.tgnc = values.includes('tgnc');
    updateNonClinicalExperience(index, nonClinicalExperience);
  };

  const handleDescriptionChange: AriaTextFieldProps['onChange'] = value => {
    nonClinicalExperience.description = value;
    updateNonClinicalExperience(index, nonClinicalExperience);
  };

  useEffect(() => {
    const values = [];
    if (nonClinicalExperience.bipoc) values.push('bipoc');
    if (nonClinicalExperience.lgbq) values.push('lgbq');
    if (nonClinicalExperience.tgnc) values.push('tgnc');
    setCommunityCheckboxSelections(values);
  }, [nonClinicalExperience.bipoc, nonClinicalExperience.lgbq, nonClinicalExperience.tgnc]);

  const communityCheckboxes = (
    <S.CheckboxGroup
      aria-label="Communities of focus"
      data-cy="communities-field"
      direction="horizontal"
      size="small"
      value={communityCheckboxSelections}
      onChange={handleCommunityCheckboxesChange}
    >
      <GroupCheckbox
        aria-label="BIPOC"
        data-cy="bipoc-checkbox"
        value="bipoc"
      >
        BIPOC
      </GroupCheckbox>
      <GroupCheckbox
        aria-label="LGBQ"
        data-cy="lgbq-checkbox"
        value="lgbq"
      >
        LGBQ
      </GroupCheckbox>
      <GroupCheckbox
        aria-label="TGNC"
        data-cy="tgnc-checkbox"
        value="tgnc"
      >
        TGNC
      </GroupCheckbox>
    </S.CheckboxGroup>
  );

  const descriptionTextarea = (
    <TextField
      isExpandableMultiline
      isMultiline
      aria-label="Description"
      data-cy="description-field"
      placeholder="Description"
      value={nonClinicalExperience.description ?? ''}
      onChange={handleDescriptionChange}
    />
  );

  return (
    <>
      <BodyElementType
        $center
        $area="expand-collapse"
        $mobileAlignment="left"
        className="header-cell"
        role="gridcell"
      >
        <S.ExpandButton.Root
          aria-label={index === expandedRowIndex ? 'Collapse' : 'Expand'}
          data-cy="expand-row-button"
          isDisabled={isInvalid}
          onPress={() => handleExpandCollapseClick(index)}
        >
          <S.ExpandButton.Icon
            aria-hidden="true"
            as={index === expandedRowIndex || isInvalid ? ExpandLessIcon : ExpandMoreIcon}
            role="img"
          />
          {isInMobileBreakpointRange &&
            (index === expandedRowIndex || isInvalid ? 'Collapse' : 'Expand')}
        </S.ExpandButton.Root>
      </BodyElementType>
      <BodyElementType
        $area="type"
        $center={false}
        role="gridcell"
      >
        <TableSelect
          isRequired
          aria-label="Type*"
          data-cy="category-field"
          hideRequiredIndicator={isInMobileBreakpointRange}
          isInvalid={isInvalid && !isNonEmptyString(nonClinicalExperience.category)}
          label={isInMobileBreakpointRange ? 'Type' : undefined}
          maxListBoxHeight="13.5rem"
          placeholder="Type*"
          selectedKey={nonClinicalExperience.category}
          onSelectionChange={handleCategoryChange}
        >
          <Item key="volunteer">Volunteering</Item>
          <Item key="advocacy">Advocacy</Item>
          <Item key="teaching">Teaching</Item>
          <Item key="research">Research</Item>
          <Item key="leadership">Leadership</Item>
        </TableSelect>
      </BodyElementType>
      <BodyElementType
        $area="role"
        $center={false}
        role="gridcell"
      >
        <TextField
          isRequired
          aria-label="Role*"
          data-cy="role-field"
          hideRequiredIndicator={isInMobileBreakpointRange}
          isInvalid={isInvalid && !isNonEmptyString(nonClinicalExperience.role)}
          label={isInMobileBreakpointRange ? 'Role' : undefined}
          placeholder="Role*"
          validationBehavior="aria"
          value={nonClinicalExperience.role}
          onChange={handleRoleChange}
        />
      </BodyElementType>
      <BodyElementType
        $area="organization"
        $center={false}
        role="gridcell"
      >
        <TextField
          isRequired
          aria-label="Organization*"
          data-cy="organization-field"
          hideRequiredIndicator={isInMobileBreakpointRange}
          isInvalid={isInvalid && !isNonEmptyString(nonClinicalExperience.organization)}
          label={isInMobileBreakpointRange ? 'Organization' : undefined}
          placeholder="Organization*"
          validationBehavior="aria"
          value={nonClinicalExperience.organization}
          onChange={handleOrganizationChange}
        />
      </BodyElementType>
      <BodyElementType
        $center
        $area="current-role"
        $mobileAlignment="left"
        className="current-role-cell"
        role="gridcell"
      >
        <S.CurrentCheckbox
          aria-label="Current"
          data-cy="current-role-field"
          isSelected={nonClinicalExperience.current}
          onChange={handleCurrentChange}
        >
          {isInMobileBreakpointRange ? <S.CheckboxLabel>Current role</S.CheckboxLabel> : undefined}
        </S.CurrentCheckbox>
      </BodyElementType>
      <BodyElementType
        $area="dates"
        $center={false}
        className="dates-wrapper"
        role="gridcell"
      >
        <S.DateFields>
          <S.YearField
            isRequired
            aria-label="Start year*"
            data-cy="start-year-field"
            formatOptions={{
              useGrouping: false
            }}
            isInvalid={
              isInvalid &&
              (nonClinicalExperience.startYear === undefined ||
                nonClinicalExperience.startYear < new Date().getFullYear() - 70 ||
                nonClinicalExperience.startYear > new Date().getFullYear())
            }
            label={isInMobileBreakpointRange ? 'Start year' : undefined}
            placeholder="YYYY*"
            validationBehavior="aria"
            value={Number(nonClinicalExperience.startYear)}
            onChange={handleStartYearChange}
          />
          {!nonClinicalExperience.current && (
            <>
              <S.DateEnDash>-</S.DateEnDash>
              <S.YearField
                isRequired
                aria-label="End year*"
                data-cy="end-year-field"
                formatOptions={{
                  useGrouping: false
                }}
                isInvalid={
                  isInvalid &&
                  !nonClinicalExperience.current &&
                  (nonClinicalExperience.endYear === undefined ||
                    nonClinicalExperience.endYear < new Date().getFullYear() - 70 ||
                    nonClinicalExperience.endYear > new Date().getFullYear() ||
                    nonClinicalExperience.endYear < (nonClinicalExperience.startYear ?? 0))
                }
                label={isInMobileBreakpointRange ? 'End year' : undefined}
                placeholder="YYYY*"
                validationBehavior="aria"
                value={Number(nonClinicalExperience.endYear)}
                onChange={handleEndYearChange}
              />
            </>
          )}
        </S.DateFields>
      </BodyElementType>
      <BodyElementType
        $center
        $area="delete"
        $mobileAlignment="right"
        className="header-cell"
        role="gridcell"
      >
        <S.DeleteButton.Root
          aria-label="Delete"
          data-cy="delete-button"
          onPress={() => onRemove(index)}
        >
          <S.DeleteButton.Icon
            $fill="var(--purple-400)"
            aria-hidden="true"
            role="img"
          />
        </S.DeleteButton.Root>
      </BodyElementType>
      <BodyElementType
        $area="nested-table"
        $center={false}
        role="gridcell"
      >
        {isInMobileBreakpointRange ? (
          <S.NestedCard $isVisible={expandedRowIndex === index || isInvalid}>
            <S.NestedBodyElement>
              <S.Label id="communities-label">
                COMMUNITIES OF FOCUS
                <PopoverTrigger
                  content={<S.PopoverContent>{communitiesPopoverText}</S.PopoverContent>}
                >
                  <S.InfoButton.Root aria-label="Information">
                    <S.InfoButton.Icon
                      aria-hidden="true"
                      role="img"
                    />
                  </S.InfoButton.Root>
                </PopoverTrigger>
              </S.Label>
              {communityCheckboxes}
            </S.NestedBodyElement>
            <S.NestedBodyElement className="description-cell">
              <S.Label>Description</S.Label>
              {descriptionTextarea}
            </S.NestedBodyElement>
          </S.NestedCard>
        ) : (
          <S.NestedTable.Root
            $isVisible={expandedRowIndex === index || isInvalid}
            aria-label="Expanded content"
          >
            <S.NestedTable.Header role="rowgroup">
              <S.NestedTable.HeaderRow role="row">
                <S.NestedTable.HeaderCell role="columnheader">
                  <S.Label id="communities-label">
                    COMMUNITIES OF FOCUS
                    <PopoverTrigger
                      content={<S.PopoverContent>{communitiesPopoverText}</S.PopoverContent>}
                    >
                      <S.InfoButton.Root aria-label="Information">
                        <S.InfoButton.Icon
                          aria-hidden="true"
                          role="img"
                        />
                      </S.InfoButton.Root>
                    </PopoverTrigger>
                  </S.Label>
                </S.NestedTable.HeaderCell>
                <S.NestedTable.HeaderCell
                  colSpan={3}
                  role="columnheader"
                >
                  <S.Label>DESCRIPTION</S.Label>
                </S.NestedTable.HeaderCell>
              </S.NestedTable.HeaderRow>
            </S.NestedTable.Header>
            <S.NestedTable.Body role="rowgroup">
              <S.NestedTable.BodyRow role="row">
                <S.NestedTable.BodyCell role="gridtd">{communityCheckboxes}</S.NestedTable.BodyCell>
                <S.NestedTable.BodyCell role="gridtd">{descriptionTextarea}</S.NestedTable.BodyCell>
              </S.NestedTable.BodyRow>
            </S.NestedTable.Body>
          </S.NestedTable.Root>
        )}
      </BodyElementType>
    </>
  );
};

export default NonClinicalExperienceRow;
