import type { FC, SVGProps } from 'react';
import { useContext, useRef } from 'react';
import type { AriaRadioProps } from 'react-aria';
import { useFocusRing, useHover, useRadio } from 'react-aria';
import TooltipTrigger from '@/components/TooltipTrigger';

import { RadioContext } from '..';

import * as S from './styles';

interface Props extends AriaRadioProps {
  className?: string;
  'data-cy'?: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  tooltipContent?: JSX.Element | string;
}

const RadioButton = ({
  className,
  'data-cy': dataCy,
  icon,
  tooltipContent,
  ...ariaRadioProps
}: Props) => {
  const { children } = ariaRadioProps;
  const { state } = useContext(RadioContext)!;
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, isDisabled, isSelected } = useRadio(ariaRadioProps, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  const { hoverProps, isHovered } = useHover({ isDisabled });

  return tooltipContent !== undefined ? (
    <S.Radio
      $hasTooltip
      $isDisabled={isDisabled}
      $isFocused={isFocusVisible}
      $isHovered={isHovered}
      $isSelected={isSelected}
      className={className}
      data-cy={dataCy}
      {...hoverProps}
    >
      <S.VisuallyHidden>
        <S.Input
          {...inputProps}
          {...focusProps}
          ref={ref}
          required={state.isRequired}
          tabIndex={-1}
        />
      </S.VisuallyHidden>
      <TooltipTrigger
        content={<S.TooltipContent>{tooltipContent}</S.TooltipContent>}
        delay={200}
        placement="bottom start"
        theme="light"
      >
        <S.TooltipButtonWrapper onPress={() => state.setSelectedValue(ariaRadioProps.value)}>
          <S.Icon
            aria-hidden
            as={icon}
            role="img"
          />
          {children}
        </S.TooltipButtonWrapper>
      </TooltipTrigger>
    </S.Radio>
  ) : (
    <S.Radio
      $hasTooltip={false}
      $isDisabled={isDisabled}
      $isFocused={isFocusVisible}
      $isHovered={isHovered}
      $isSelected={isSelected}
      className={className}
      tabIndex={0}
      {...hoverProps}
    >
      <S.VisuallyHidden>
        <S.Input
          {...inputProps}
          {...focusProps}
          ref={ref}
          required={state.isRequired}
        />
      </S.VisuallyHidden>
      <S.Icon
        aria-hidden
        as={icon}
        role="img"
      />
      {children}
    </S.Radio>
  );
};

export default RadioButton;
