import CheckIcon from '@material-design-icons/svg/round/check_circle.svg?react';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../../../constants';

export const CheckedIcon = styled(CheckIcon)`
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--success-400);
`;

export const Option = styled.li`
  padding: 0.625rem 0.5rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: #262626;
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;

  &.is-focused,
  &.is-hovered {
    background-color: var(--gray-100);
    outline: 0;
  }

  &.is-disabled {
    pointer-events: none;
    color: var(--gray-400);
    font-style: italic;
  }
`;
