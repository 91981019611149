import { useFilter, useNumberFormatter } from 'react-aria';
import type { Key } from 'react-stately';
import { Item } from 'react-stately';
import TableComboBox from '@/components/Table/TableComboBox';
import TableTextField from '@/components/Table/TableTextField';
import useBreakpointRange from '@/hooks/useBreakpointRange';

import * as S from './styles';

interface Props {
  dataCyPrefix?: string;
  dateErrorMessage?: string;
  handleMonthChange: (month: Key | null) => void;
  handleYearChange: (year: string) => void;
  isInvalid?: boolean;
  label?: string;
  month: string;
  year: string;
}

const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

const MonthYearPicker = ({
  dataCyPrefix = 'date',
  dateErrorMessage,
  handleMonthChange,
  handleYearChange,
  isInvalid = false,
  label = 'Date',
  month,
  year
}: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const { contains } = useFilter({ sensitivity: 'base' });
  const digitNumFormatter = useNumberFormatter({
    minimumIntegerDigits: 2,
    useGrouping: true
  });

  return (
    <S.DateSegment>
      <TableComboBox
        isRequired
        showDropdownButton
        aria-label={`${label} month`}
        data-cy={`${dataCyPrefix}-month-field`}
        filter={contains}
        hideRequiredIndicator={isInMobileBreakpointRange}
        isInvalid={isInvalid && dateErrorMessage !== undefined}
        label={isInMobileBreakpointRange ? label : undefined}
        placeholder="MM"
        selectedKey={month}
        onSelectionChange={handleMonthChange}
      >
        {months.map(month => (
          <Item
            key={month}
            textValue={digitNumFormatter.format(Number(month))}
          >
            {digitNumFormatter.format(Number(month))}
          </Item>
        ))}
      </TableComboBox>
      <span>/</span>
      <TableTextField
        isRequired
        aria-label={`${label} year`}
        data-cy={`${dataCyPrefix}-year-field`}
        hideRequiredIndicator={isInMobileBreakpointRange}
        isInvalid={isInvalid && dateErrorMessage !== undefined}
        maxLength={4}
        pattern="[0-9]*"
        placeholder="YYYY"
        value={year}
        onChange={handleYearChange}
      />
      {isInvalid && dateErrorMessage !== undefined && (
        <S.DateErrorMessage data-cy={`${dataCyPrefix}-error`}>
          {dateErrorMessage}
        </S.DateErrorMessage>
      )}
    </S.DateSegment>
  );
};

export default MonthYearPicker;
