import BackIcon from '@material-symbols/svg-400/rounded/arrow_back.svg?react';
import CloseIcon from '@material-symbols/svg-400/rounded/close.svg?react';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import UnstyledButton from '../../UnstyledButton';

export const BackButtonIcon = styled(BackIcon)`
  display: flex;
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  color: var(--gray-500);

  path {
    stroke: var(--gray-500);
    stroke-width: 1rem;
  }
`;

export const CloseButton = styled(UnstyledButton)`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.625rem;
  border-radius: 6.25rem;
  background: var(--gray-100);

  &.is-hovered {
    background: var(--gray-200);
  }

  &.is-focused {
    background: var(--gray-200);
    outline: 2px solid var(--brand-bright);
    outline-offset: 2px;
  }
`;

export const CloseButtonIcon = styled(CloseIcon)`
  display: flex;
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--gray-500);

  path {
    stroke: var(--gray-500);
    stroke-width: 1rem;
  }
`;

export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  background-color: var(--white-light);
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  position: fixed;
`;

export const ModalDialogFullscreen = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  z-index: 100;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
`;

export const Title = styled.h3`
  margin: 0;
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-500);
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.0088rem;
`;
