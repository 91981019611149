import { Link } from 'react-router-dom';
import Logotype from '@/components/Logotype';
import ProgressBar from '@/components/ProgressBar';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const BarContainer = styled.div`
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media all and (width <= 951px) {
    padding: 1rem 3rem;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    max-width: 100%;
    overflow-y: auto;
    padding: 1rem;
  }

  @media all and (width > 951px) {
    padding: 0 3rem;
  }
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;
  gap: 0.5rem;

  @media all and (width <= 951px) {
    flex-direction: column;
    width: 100%;
  }

  @media all and (width > 951px) {
    flex-direction: row;
  }
`;

export const Logo = styled(Logotype)`
  display: block;

  @media all and (width <= 951px) {
    width: 5.0456rem;
    height: 1.4979rem;
  }

  @media all and (width > 951px) {
    width: 6.9754rem;
    height: 2.1524rem;
  }
`;

export const LogotypeLink = styled(Link)`
  padding: 1rem 1rem 1rem 0;

  &:focus-visible {
    border-color: var(--bright-purple-400);
    outline: none;
  }

  @media all and (width <= 951px) {
    padding: 0 1rem 1rem;
    align-self: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  @media all and (width > 951px) {
    align-self: start;
  }
`;

export const OnboardingProgressBar = styled(ProgressBar)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-right: 0.5rem;
    margin-top: 0.3rem;
  }
`;

export const StepText = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-400);
  text-align: left;
  margin: 0 1rem;
`;
