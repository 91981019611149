import { VisuallyHidden as BaseVisuallyHidden } from 'react-aria';
import UnstyledButton from '@/components/UnstyledButton';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../../constants';

export const Icon = styled.svg`
  height: 1.25rem;
  width: auto;
  fill: currentcolor;
`;

export const Input = styled.input`
  width: 1px;
  height: 1px;
`;

export const Radio = styled.label<RadioButtonProps>`
  ${TEXT_STYLES.bodyCopyMedium}
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.008rem;
  background: #fff;
  cursor: pointer;
  border-radius: 0.75rem;
  border: 1px solid var(--gray-200);

  ${({ $hasTooltip }) =>
    !$hasTooltip &&
    `
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
  `}

  ${({ $isHovered }) =>
    $isHovered &&
    `background: var(--gray-100);
  `}

  ${({ $isSelected }) =>
    $isSelected &&
    `
    border-color: var(--purple-400);
    background-color: var(--light-purple-25);
  `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    border-color: var(--gray-200);
    cursor: not-allowed;
    color: var(--gray-200);

    button, button:not(.is-disabled) {
      cursor: not-allowed;
    }
  `}

  ${({ $isFocused }) =>
    $isFocused &&
    `
    outline-color: var(--bright-purple-400);
    outline-offset: 4px;
    outline-width: 2px;
  `}

  &:focus-visible {
    outline-color: var(--bright-purple-400);
    outline-offset: 4px;
    outline-width: 2px;
  }
`;

interface RadioButtonProps {
  $hasTooltip: boolean;
  $isDisabled: boolean;
  $isFocused: boolean;
  $isHovered: boolean;
  $isSelected: boolean;
}

export const TooltipButtonWrapper = styled(UnstyledButton)`
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.75rem;
  width: 100%;

  div {
    border-radius: 0.75rem;
  }

  &:focus-visible {
    outline-color: var(--bright-purple-400);
    outline-offset: 5px;
    outline-width: 2px;
  }
`;

export const TooltipContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);

  strong {
    ${TEXT_STYLES.tooltipTitle}
    color: var(--gray-700);
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong + ul {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
`;

export const VisuallyHidden = styled(BaseVisuallyHidden)`
  top: 0;
  left: 0;
`;
