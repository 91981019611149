import { useEffect, useState } from 'react';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import useGetCollectionCompletions from '@/pages/Dashboard/hooks/useGetCollectionCompletions';

import DesktopTable from './DesktopTable';
import MobileCards from './MobileCards';

const CompletedCollectionsTable = () => {
  const COMPLETIONS_PER_PAGE = 20;
  const { isInDesktopBreakpointRange } = useBreakpointRange();

  const [page, setPage] = useState(1);
  const {
    collectionCompletions,
    isFetching,
    totalCompletionPages,
    updateCollectionCompletionsFilters
  } = useGetCollectionCompletions({
    page,
    per_page: COMPLETIONS_PER_PAGE
  });

  useEffect(() => {
    updateCollectionCompletionsFilters({ page, per_page: COMPLETIONS_PER_PAGE });
  }, [page, updateCollectionCompletionsFilters, COMPLETIONS_PER_PAGE]);

  if (isFetching) {
    return <Spinner withWrapper />;
  }

  return (
    <>
      {isInDesktopBreakpointRange ? (
        <DesktopTable collectionCompletions={collectionCompletions} />
      ) : (
        <MobileCards collectionCompletions={collectionCompletions} />
      )}
      <Pagination
        currentPage={page}
        setPage={setPage}
        totalPages={totalCompletionPages}
      />
    </>
  );
};

export default CompletedCollectionsTable;
