import { createContext, useContext } from 'react';

interface ReportAuthContextType {
  bearerToken: string;
}

// @ts-expect-error - Context value can be null during initialization
export const ReportAuthContext = createContext<ReportAuthContextType>(null);

export const useReportAuth = (): ReportAuthContextType => {
  const context = useContext(ReportAuthContext);
  if (context === null) {
    throw new Error('useReportAuth must be used within a ReportAuthContext.Provider');
  }
  return context;
};
