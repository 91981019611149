import CloseIconSvg from '@material-symbols/svg-400/rounded/close.svg?react';
import Alert from '@/components/Alert';
import UnstyledButton from '@/components/UnstyledButton';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

export const CloseButton = styled(UnstyledButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  right: 1rem;
  border-radius: 6.25rem;
  background: var(--gray-100);
  color: var(--gray-500);
  padding: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;

  &.is-hovered {
    background: var(--gray-200);
  }

  &.is-pressed {
    background: var(--gray-300);
  }

  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
    outline-offset: 2px;
  }
`;

export const CloseIcon = styled(CloseIconSvg)`
  fill: currentcolor;
  width: 1rem;
  height: 1rem;
`;

export const Dialog = styled.div`
  position: relative;
  max-height: 100%;
  border-radius: 1.5rem;
  background-color: var(--white-light);
  overflow: hidden;
  outline: none;
  border: 1px solid var(--gray-200);
  display: flex;
  flex-direction: column;

  ${MOBILE_BREAKPOINT_RANGE} {
    max-width: 37rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    width: 30rem;
  }
`;

export const DialogContent = styled.div`
  overflow-y: auto;
`;

export const List = styled.ul`
  margin: 0;
  padding-left: 0.8rem;
`;

export const NumList = styled.ol`
  margin: 0.5rem 0 0;
  padding-left: 0.8rem;
`;

export const StrongCount = styled.strong`
  font-weight: 700;
`;

export const TextBlock = styled.div`
  padding: 1rem 1.5rem 1.5rem;
  display: grid;
  row-gap: 1rem;
  color: var(--gray-800);
`;

export const Title = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Bold}
  margin: 0;
  color: var(--gray-800);
`;

export const VioAlert = styled(Alert)``;

export const VioImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 1.5rem 1.5rem 0 0;
`;

export const VioModalDialog = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  padding: var(--space-6);
  background: rgba(29, 41, 57, 0.5);
  z-index: 99;
`;
