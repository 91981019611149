import CloseIcon from '@material-design-icons/svg/round/close.svg?react';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import styled from 'styled-components';

import UnstyledButton from '../../../../../../components/UnstyledButton';
import { ProgressBar as BaseProgressBar } from '../../../../../components/ProgressBar';
import { MOBILE_HEADER_HEIGHT } from '../../../../constants';

export const CloseButton = {
  Icon: styled(CloseIcon)`
    display: block;
    fill: var(--gray-dark-dark);
    height: 1.8rem;
    width: auto;
    margin: -0.25rem -0.5rem;
  `,
  Root: styled(UnstyledButton)`
    position: absolute;
    top: var(--space-8);
    right: var(--space-8);
  `
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 47.75rem;
  margin-right: auto;
  margin-left: auto;
  padding: var(--space-8) var(--space-9) var(--space-16);
`;

export const Main = styled.main`
  position: relative;

  ${MOBILE_BREAKPOINT_RANGE} {
    min-height: calc(100dvh - ${MOBILE_HEADER_HEIGHT}px);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    min-height: 100dvh;
  }
`;

export const ProgressBar = styled(BaseProgressBar)`
  margin-top: var(--space-9);
  margin-bottom: var(--space-4);
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;

  ${MOBILE_BREAKPOINT_RANGE} {
    height: calc(100dvh - ${MOBILE_HEADER_HEIGHT}px);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    height: 100dvh;
  }
`;
