import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';

import useUser from '../../../hooks/useUser';
import type { Collection } from '../utils';
import { generateCollection } from '../utils';

import useApiRequest from './useApiRequest';

type FetchCollection = (skipSettingIsFetching?: boolean) => void;

type RefetchFetchCollection = FetchCollection;

interface UseGetCollectionResult {
  collection: Collection | undefined;
  error: Error | null;
  isFetching: boolean;
  refetchCollection: RefetchFetchCollection;
}

const useGetCollection = (id: string): UseGetCollectionResult => {
  const { user } = useUser();

  const [collection, setCollection] = useState<Collection | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { getRequest, reportError } = useApiRequest();

  const fetchCollection: FetchCollection = useCallback(
    async (skipSettingIsFetching = false) => {
      if (!id) return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }
      setError(null);

      try {
        const url = `${import.meta.env.VITE_API_V2_BASE_PATH}/course_collections/${id}`;
        const { data } = (await getRequest(url)) as {
          data?: APICourseCollectionsCollectionId['data'];
        };
        setCollection(data ? generateCollection(data, user) : undefined);
      } catch (error) {
        reportError(error);
        setError(error instanceof Error ? error : new Error('Failed to fetch collection'));
      } finally {
        if (!skipSettingIsFetching) {
          setIsFetching(false);
        }
      }
    },
    [id, user, getRequest, reportError]
  );

  const debouncedFetchCollection = useDebounce(fetchCollection, 200);

  useEffect(() => {
    setIsFetching(true);
    debouncedFetchCollection();

    return () => {
      setCollection(undefined);
      setError(null);
      setIsFetching(false);
    };
  }, [debouncedFetchCollection]);

  const refetchCollection: RefetchFetchCollection = useCallback(
    (skipSettingIsFetching = false) => {
      debouncedFetchCollection(skipSettingIsFetching);
    },
    [debouncedFetchCollection]
  );

  return { collection, error, isFetching, refetchCollection };
};

export default useGetCollection;
