import Alert from '@/components/Alert';
import styled from 'styled-components';

export const AlertBar = styled(Alert)`
  margin-bottom: 1.5rem;
`;

export const FormContainer = styled.form``;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
