import QuestionMarkIcon from '@material-design-icons/svg/round/help_outline.svg?react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import UnstyledButton from '@/components/UnstyledButton';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';
import { default as BaseButton } from '../../Buttons/Button';
import { RESOURCE_CARD_STYLES } from '../resource_card_styles';

export const Button = styled(BaseButton)``;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  align-items: end;
  justify-content: end;

  ${({ $isAccredited = false }) => {
    if ($isAccredited) {
      return `
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: 1fr 5.25rem;
      `;
    }
  }}

  ${DESKTOP_BREAKPOINT_RANGE} {
    a:last-child {
      width: auto;
    }
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
    gap: 0.5rem;
    grid-template-columns: 1fr;
  }
`;

interface ButtonWrapperProps {
  $isAccredited?: boolean;
}

export const CardContent = styled.div`
  ${RESOURCE_CARD_STYLES.cardContent}
`;

export const CardLabel = styled.div`
  ${RESOURCE_CARD_STYLES.cardLabel}
  background: var(--blue-50);
  color: var(--gray-800);
`;

export const CollectionCard = styled(motion.li)`
  ${RESOURCE_CARD_STYLES.card}
`;

export const Description = styled.p`
  ${RESOURCE_CARD_STYLES.cardDescription}
`;

export const DownloadButton = styled(BaseButton)``;

export const FaqButton = styled(UnstyledButton)`
  align-items: center;
  display: flex;

  &.is-hovered svg {
    fill: var(--gray-500);
  }

  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
    border-radius: 100%;
  }
`;

export const PopoverAccreditingOrganizationItem = styled.li`
  ${TEXT_STYLES.tooltipText}

  strong {
    font-weight: bold;
  }
`;

export const PopoverAccreditingOrganizationList = styled.ul`
  margin-left: 1rem;
  padding-left: 0;
  color: var(--gray-500);
  margin-bottom: 0;
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  max-width: 22.625rem;
`;

export const PopoverCredits = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: var(--purple-400);
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }
`;

export const PopoverTitle = styled.div`
  ${TEXT_STYLES.tooltipTitle}
  color: var(--gray-800);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
`;

export const QuestionMark = styled(QuestionMarkIcon)`
  fill: var(--gray-400);
  width: 1rem;
  height: 1rem;
`;

export const Tag = styled.li`
  ${RESOURCE_CARD_STYLES.cardTagItem}
`;

export const Tags = styled.ul`
  ${RESOURCE_CARD_STYLES.cardTags}
`;

export const TimeTag = styled.div`
  flex-grow: 1;
  text-align: right;

  @media screen and (width <= 345px) {
    display: block;
    width: 100%;
    margin-left: 1.6rem;
    text-align: left;
  }
`;

export const Title = styled(Link)`
  ${RESOURCE_CARD_STYLES.cardTitle}
`;
