import * as Sentry from '@sentry/react';
import { REPORT_HEIGHT, REPORT_WIDTH } from '@/components/Reporting/ReportWrapper/styles';

export const downloadPdf = async (
  element: HTMLElement,
  fileTitle: string,
  height = REPORT_HEIGHT,
  width = REPORT_WIDTH
) => {
  try {
    const { default: html2canvas } = await import('html2canvas');
    const canvas = await html2canvas(element, {
      height,
      scale: 1,
      width
    });
    const pngURL = canvas.toDataURL('image/png');

    const { jsPDF } = await import('jspdf');
    const pdf = new jsPDF({
      format: [canvas.width, canvas.height],
      orientation: 'landscape',
      unit: 'px'
    });
    pdf.addImage(pngURL, 'PNG', 0, 0, canvas.width, canvas.height);
    const pdfTitle = `${fileTitle}.pdf`;
    pdf.save(pdfTitle);
  } catch (error) {
    Sentry.captureException(error);
  }
};
