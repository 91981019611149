import isEqual from 'lodash/isEqual';
import { useCallback, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useIsFirstRender } from 'usehooks-ts';

import type { NetworkOrganizationMember } from '../utils';
import { generateNetworkOrgMembers } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchNetworkOrgMembers = (filters?: Filters, skipSettingIsFetching?: boolean) => Promise<void>;

interface Filters {
  'benchmark_communities[]'?: string[];
  order_by?: {
    column:
      | 'bipoc_inclusivity'
      | 'lgbq_inclusivity'
      | 'on_violet'
      | 'tgnc_inclusivity'
      | 'total_providers'
      | 'verified_inclusive';
    dir: 'asc' | 'desc';
  };
  org_is_on_violet?: boolean;
  'organizations[]'?: string[];
  page?: number;
  per_page?: number;
  'specialties[]'?: string[];
  'states[]'?: string[];
  verified_inclusive?: boolean;
}

interface UseGetNetworkOrgMembers {
  (filters?: Filters): {
    isFetching: boolean;
    networkOrgMembers: NetworkOrganizationMember[] | undefined;
    networkOrgMembersTotalPages: number;
    updateNetworkOrgMembersFilters: (filters: Filters) => void;
  };
}

const useGetNetworkOrgMembers: UseGetNetworkOrgMembers = filters => {
  const isFirstRender = useIsFirstRender();
  const [networkOrgMembers, setNetworkOrgMembers] = useState<
    NetworkOrganizationMember[] | undefined
  >();
  const [networkOrgMembersTotalPages, setNetworkOrgMembersTotalPages] = useState<number>(1);
  const [isFetching, setIsFetching] = useState(false);
  const [previousFilters, setPreviousFilters] = useState<Filters>(filters ?? {});
  const { getRequest, reportError } = useApiRequest();

  const fetchNetworkOrgMembers: FetchNetworkOrgMembers = useCallback(
    async (filters, skipSettingIsFetching = false) => {
      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const params = filtersToParams(filters ?? {});

      const url: RequestInfo = `${
        import.meta.env.VITE_API_BASE_PATH
      }/networks/organization_members${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APINetworksOrganizationMembers['data'];
          meta?: APINetworksOrganizationMembers['meta'];
        };
        setNetworkOrgMembersTotalPages(meta !== undefined ? meta.total_pages : 1);
        setNetworkOrgMembers(
          data !== undefined ? data.map(fields => generateNetworkOrgMembers(fields)) : undefined
        );
      } catch (error) {
        reportError(error);
      } finally {
        if (!skipSettingIsFetching) {
          setIsFetching(false);
        }
      }
    },
    [getRequest, reportError]
  );

  const debouncedFetchNetworkOrgMembers = useDebounce(fetchNetworkOrgMembers, 200);

  useDeepCompareEffect(() => {
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchNetworkOrgMembers(filters);

    return () => {
      setNetworkOrgMembers(undefined);
      setIsFetching(false);
      setPreviousFilters({});
    };
  }, [debouncedFetchNetworkOrgMembers, filters]);

  const updateNetworkOrgMembersFilters = (newFilters: Partial<Filters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof Filters] === undefined ? delete newFilters[key as keyof Filters] : {}
    );
    if (isEqual(newFilters, previousFilters)) return;

    setPreviousFilters({ ...newFilters });
    debouncedFetchNetworkOrgMembers(newFilters);
  };

  return {
    isFetching,
    networkOrgMembers,
    networkOrgMembersTotalPages,
    updateNetworkOrgMembersFilters
  };
};

export default useGetNetworkOrgMembers;
