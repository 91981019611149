import { Temporal } from '@js-temporal/polyfill';
import PopoverTrigger from '@/components/PopoverTrigger';
import type { Benchmark } from '@/pages/Dashboard/utils';
import isNonEmptyString from '@/utils/isNonEmptyString';
import { capitalize } from '@/utils/stringTransformations';

import * as S from './styles';

interface Props {
  benchmark: Benchmark;
  'data-cy'?: string;
}

const SingleBenchmark = ({ benchmark, ...args }: Props) => {
  const {
    day: dateEarnedDay,
    month: dateEarnedMonth,
    year: dateEarnedYear
  } = Temporal.PlainDate.from(
    benchmark.earnedAt[benchmark.earnedAt.length - 1] === 'Z'
      ? benchmark.earnedAt.slice(0, -1)
      : benchmark.earnedAt
  );
  const formattedDateEarned = `${dateEarnedMonth.toString().padStart(2, '0')}/${dateEarnedDay
    .toString()
    .padStart(2, '0')}/${dateEarnedYear}`;
  const imageUrl =
    benchmark.image.url.includes('http://') || benchmark.image.url.includes('https://')
      ? benchmark.image.url
      : `${import.meta.env.VITE_BASE_PATH}${benchmark.image.url}`;

  return (
    <PopoverTrigger
      key={benchmark.id}
      content={
        <S.Benchmarks.PopoverText data-cy="single-benchmark-popover-text">
          {/*
            Placeholder benchmarks are not expected to be returned in API responses
            But just in case, we'll handle them here
          */}
          {benchmark.scoreLevel === 'placeholder' ? (
            <S.Benchmarks.Title>
              {benchmark.scoreType.toUpperCase()} Benchmark not yet achieved.
            </S.Benchmarks.Title>
          ) : (
            <>
              <S.Benchmarks.Title>
                {benchmark.scoreType.toUpperCase()} {capitalize(benchmark.scoreLevel)} Benchmark
              </S.Benchmarks.Title>
              {isNonEmptyString(benchmark.earnedAt) && (
                <S.Benchmarks.DateEarned>
                  Date earned: {formattedDateEarned}
                </S.Benchmarks.DateEarned>
              )}
            </>
          )}
        </S.Benchmarks.PopoverText>
      }
      theme="dark"
    >
      <S.Benchmarks.Button>
        <S.Benchmarks.Benchmark
          alt={`${benchmark.scoreType} ${benchmark.scoreLevel} benchmark`}
          {...args}
          src={imageUrl}
        />
      </S.Benchmarks.Button>
    </PopoverTrigger>
  );
};

export default SingleBenchmark;
