import TrendingFlatIcon from '@material-symbols/svg-400/rounded/arrow_right_alt.svg?react';
import ThumbsDownIcon from '@material-symbols/svg-400/rounded/thumb_down.svg?react';
import ThumbsUpIcon from '@material-symbols/svg-400/rounded/thumb_up.svg?react';
import { useEffect, useState } from 'react';
import type { ServiceAreaComparisonTotals } from '@/components/ComparisonDataTooltipContent';
import ComparisonDataTooltipContent from '@/components/ComparisonDataTooltipContent';
import TooltipTrigger from '@/components/TooltipTrigger';
import type { ServiceAreaComparison, ServiceAreaComparisonMeta } from '@/pages/Dashboard/utils';
import type { VioletCommunity } from '@/utils';

import * as S from './styles';

interface Props {
  community: VioletCommunity;
  'data-cy'?: string;
  isFetching: boolean;
  serviceAreaComparisons: ServiceAreaComparison[];
  serviceAreaComparisonsMeta?: ServiceAreaComparisonMeta;
  totalNetworkInclusivePercentage: number;
  totalNetworkInclusiveProviders: number;
}

const TableComparisonCell = ({
  community,
  'data-cy': dataCy,
  isFetching,
  serviceAreaComparisons,
  serviceAreaComparisonsMeta,
  totalNetworkInclusivePercentage,
  totalNetworkInclusiveProviders
}: Props) => {
  const [comparisonTotals, setComparisonTotals] = useState<ServiceAreaComparisonTotals>({
    totalInclusivePercentage: serviceAreaComparisonsMeta?.totalInclusivePercentage[community] ?? 0,
    totalInclusiveProviders: serviceAreaComparisonsMeta?.totalInclusiveProviders[community] ?? 0
  });
  const serviceAreaComparisonsByCommunity = serviceAreaComparisons.filter(
    comparison => comparison.community.toUpperCase() === community.toUpperCase()
  );

  useEffect(() => {
    setComparisonTotals({
      totalInclusivePercentage:
        serviceAreaComparisonsMeta?.totalInclusivePercentage[community] ?? 0,
      totalInclusiveProviders: serviceAreaComparisonsMeta?.totalInclusiveProviders[community] ?? 0
    });
  }, [serviceAreaComparisonsMeta, community]);

  if (isFetching || serviceAreaComparisonsMeta === undefined) {
    return (
      <S.SmallSpinner
        withWrapper
        variant="bar"
      />
    );
  }

  return serviceAreaComparisonsByCommunity.length > 0 ? (
    <TooltipTrigger
      content={
        <ComparisonDataTooltipContent
          community={community}
          serviceAreaComparisons={serviceAreaComparisonsByCommunity}
          serviceAreaComparisonTotals={comparisonTotals}
          totalNetworkInclusivePercentage={totalNetworkInclusivePercentage}
          totalNetworkInclusiveProviders={totalNetworkInclusiveProviders}
        />
      }
      delay={300}
    >
      <S.ButtonCell
        aria-label={`View detailed ${community.toUpperCase()} service area comparison data.`}
        data-cy={dataCy}
      >
        {totalNetworkInclusivePercentage > comparisonTotals.totalInclusivePercentage ? (
          <S.AboveAvg>
            <ThumbsUpIcon
              aria-hidden="true"
              role="img"
            />
            {totalNetworkInclusivePercentage}%
          </S.AboveAvg>
        ) : totalNetworkInclusivePercentage < comparisonTotals.totalInclusivePercentage ? (
          <S.BelowAvg>
            <ThumbsDownIcon
              aria-hidden="true"
              role="img"
            />
            {totalNetworkInclusivePercentage}%
          </S.BelowAvg>
        ) : (
          <S.Avg>
            <TrendingFlatIcon
              aria-hidden="true"
              role="img"
            />
            {totalNetworkInclusivePercentage}%
          </S.Avg>
        )}
      </S.ButtonCell>
    </TooltipTrigger>
  ) : (
    <TooltipTrigger
      content={<S.NoData>Not enough data is available for comparison.</S.NoData>}
      delay={300}
    >
      <S.ButtonCell
        aria-label={`Not enough data for ${community.toUpperCase()} service area comparison.`}
        data-cy={dataCy}
      >
        {totalNetworkInclusivePercentage}%
      </S.ButtonCell>
    </TooltipTrigger>
  );
};

export default TableComparisonCell;
