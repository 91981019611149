import { GRAY_400_COLOR, PURPLE_400_COLOR } from '@/components/GlobalStyle';
import { VictoryPie } from 'victory';

import Label from './Label';
import * as S from './styles';

const violetDonutLabelStyles = {
  color: PURPLE_400_COLOR,
  fontFamily: 'niveau-grotesk, sans-serif',
  fontSize: '1rem',
  fontStyle: 'normal',
  letterSpacing: '0.08rem',
  lineHeight: '1rem',
  textTransform: 'uppercase',
  width: '4.5rem'
};

/* USAGE:
  - Built with the intention of the donut having two data items
  - Input expectations:
    - First data pair should be the inclusive group
    - Second data pair should be titled with an empty string
    - Currently styles are hardcoded to dimensions 162 x 162, radius 50

  - [FUTURE USE] To expand the slices used:
    - Add more colors to the colorScale prop
    - Use the LabelWithLine component
    - Add additional padding between the donut and the title to prevent overlap
*/
interface VictoryDonutDataProps {
  x: string;
  /* y = percentage */
  y: number;
}

interface LabelProps {
  datum: VictoryDonutDataProps;
  slice: {
    endAngle: number;
    startAngle: number;
  };
}

interface DonutChartProps {
  centerNumber: number | string;
  centerText: string;
  data: VictoryDonutDataProps[];
  height?: number;
  innerRadius?: number;
  radius?: number;
  title: string;
  width?: number;
}

const DonutChart = ({
  centerNumber,
  centerText,
  data,
  height = 162,
  innerRadius = 50,
  radius = 81,
  title,
  width = 162
}: DonutChartProps) => (
  <S.DonutChartContainer>
    <S.Title>{title}</S.Title>
    <S.DonutChart>
      <S.DonutSvg>
        <VictoryPie
          colorScale={[PURPLE_400_COLOR, GRAY_400_COLOR]}
          data={data}
          height={height}
          innerRadius={innerRadius}
          labelComponent={
            // @ts-expect-error - This is a valid prop
            <Label
              height={height}
              width={width}
            />
          }
          /* @ts-expect-error - This is a valid prop */
          labels={({ datum, slice }: LabelProps) => ({
            datum: {
              x: datum.x,
              y: datum.y
            },
            slice: {
              endAngle: slice.endAngle,
              startAngle: slice.startAngle
            }
          })}
          padAngle={1}
          radius={radius}
          standalone={false}
          style={{
            labels: {
              ...violetDonutLabelStyles
            }
          }}
          width={width}
        />
      </S.DonutSvg>
      <S.CenterLabel
        height={height}
        width={width}
      >
        <S.CenterNumber>{centerNumber}</S.CenterNumber>
        {centerText}
      </S.CenterLabel>
    </S.DonutChart>
  </S.DonutChartContainer>
);

export default DonutChart;
