import { VisuallyHidden } from 'react-aria';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';
import * as CheckboxStyles from '../Checkbox/styles';
import TextField from '../TextField';

export const CheckboxGroup = styled.div`
  position: relative;
`;

export const Checkboxes = styled.div`
  display: flex;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-wrap: wrap;
  }
`;

interface GroupProps {
  $direction: 'horizontal' | 'vertical';
  $size: 'regular' | 'small';
}

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);
`;

interface GroupLabelProps {
  $color: 'gray-800' | 'purple-400';
  $isAllCaps: boolean;
}

export const Group = styled.div<GroupProps>`
  ${({ $direction, $size }) => {
    if ($direction === 'horizontal') {
      const sharedStyles = `
        align-items: center;
      `;

      if ($size === 'small') {
        return `
          ${Checkboxes} {
            ${sharedStyles}
            column-gap: 1.375rem;
          }
        `;
      } else {
        return `
          ${Checkboxes} {
            ${sharedStyles}
            column-gap: 1.25rem;
          }
        `;
      }
    } else {
      const sharedStyles = `
        flex-direction: column;

        ${CheckboxStyles.Input} {
          align-self: start;
        }
      `;

      if ($size === 'small') {
        return `
          ${Checkboxes} {
            ${sharedStyles}
            row-gap: 1rem;
          }
        `;
      } else {
        return `
          ${Checkboxes} {
            ${sharedStyles}
            row-gap: 1.25rem;
          }
        `;
      }
    }
  }}

  ${({ $size }) => {
    if ($size === 'small') {
      return `
        ${GroupLabel} {
          margin-bottom: 1.625rem;
          ${TEXT_STYLES.headlineSectionLevel2Regular}
        }
      `;
    } else {
      return `
        ${GroupLabel} {
          ${TEXT_STYLES.headlineSectionLevel2Large}

          ${MOBILE_BREAKPOINT_RANGE} {
            margin-bottom: var(--space-5);
          }

          ${DESKTOP_BREAKPOINT_RANGE} {
            margin-bottom: var(--space-6);
          }
        }
      `;
    }
  }}
`;

interface OtherTextFieldProps {
  $size: 'regular' | 'small';
}

export const GroupLabel = styled.div<GroupLabelProps>`
  ${({ $color }) => {
    if ($color === 'purple-400') {
      return `
        color: var(--purple-400);
      `;
    } else {
      return `
        color: var(--gray-800);
      `;
    }
  }}

  ${({ $isAllCaps }) =>
    $isAllCaps &&
    `
    text-transform: uppercase;
  `}
`;

export const OtherTextField = styled(TextField)<OtherTextFieldProps>`
  min-width: 2.25rem;
`;

export const VisuallyHiddenContainer = styled(VisuallyHidden)`
  top: 1.25rem;
  left: 0.5rem;
`;

export const VisuallyHiddenValidation = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
