import type { PropsWithChildren } from 'react';
import OnboardingHeader from '@/components/Onboarding/OnboardingHeader';

import * as S from './styles';

interface Props {
  progressBarValue: number;
}

const OnboardingLayout = ({ children, progressBarValue }: PropsWithChildren<Props>) => (
  <S.Page>
    <S.Layout>
      <OnboardingHeader progressBarValue={progressBarValue} />
      <S.Main>{children}</S.Main>
    </S.Layout>
  </S.Page>
);

export default OnboardingLayout;
