import * as S from './styles';
import LinkedInPreview from './linkedin-example.png';
import Button from '@/components/Buttons/Button';
import { useEffect, useState } from 'react';
import type { Benchmark } from '../..';
import isNonEmptyString from '@/utils/isNonEmptyString';
import { Temporal } from '@js-temporal/polyfill';
import capitalize from 'lodash/capitalize';
import useAnalytics from '@/hooks/useAnalytics';
import useUser from '@/hooks/useUser';

const LINKEDIN_ORG_ID = '65014991';

interface Props {
  benchmark?: Benchmark;
  setHasShared: React.Dispatch<React.SetStateAction<boolean>>;
  source: 'announcement' | 'dashboard';
}

const LinkedInInstructions = ({ benchmark, setHasShared, source }: Props) => {
  const { analytics } = useAnalytics();
  const { user } = useUser();
  const [linkedInUrl, setLinkedInUrl] = useState('');

  const openNewTab = () => {
    window.open(linkedInUrl, '_blank');
    setHasShared(true);
    analytics?.track('Benchmark Shared', {
      user_id: user.id,
      email: user.email,
      community: benchmark?.score_type,
      level: benchmark?.score_level,
      source: source,
      channel: 'linkedin'
    });
  };

  useEffect(() => {
    if (!benchmark) {
      setLinkedInUrl('');
      return;
    }

    const earnedAtDate = Temporal.PlainDate.from(
      benchmark?.earned_at[benchmark?.earned_at.length - 1] === 'Z'
        ? benchmark?.earned_at.slice(0, -1)
        : benchmark?.earned_at
    );
    let url = 'https://www.linkedin.com/profile/add?';
    url += `startTask=CERTIFICATION_NAME`;
    url += `&name=${encodeURIComponent(`Cultural ${capitalize(benchmark?.score_level)} - ${benchmark?.score_type.toUpperCase()} communities`)}`;
    url += `&organizationId=${LINKEDIN_ORG_ID}`;
    url += `&issueYear=${earnedAtDate.year}`;
    url += `&issueMonth=${earnedAtDate.month}`;
    url += `&certUrl=${encodeURIComponent('https://www.joinviolet.com/benchmarks?utm_source=linkedin&utm_medium=benchmark_certificate')}`;

    setLinkedInUrl(url);
  }, [benchmark]);

  return (
    <>
      <S.Wrapper>
        <S.PreviewBlock>
          <S.Text>
            A new tab will open with your Benchmark information pre-populated. Be sure to click save
            to ensure your badge is added to your LinkedIn profile.
          </S.Text>
          <S.Image
            alt="linkedin preview"
            src={LinkedInPreview}
          />
        </S.PreviewBlock>
      </S.Wrapper>
      <S.ButtonGroup>
        <Button
          data-cy="back-button"
          size="large"
          variant="outline"
          onPress={() => setHasShared(false)}
        >
          Back
        </Button>
        <Button
          data-cy="open-linkedin-button"
          isDisabled={!isNonEmptyString(linkedInUrl)}
          size="large"
          onPress={() => openNewTab()}
        >
          Open LinkedIn
        </Button>
      </S.ButtonGroup>
    </>
  );
};

export default LinkedInInstructions;
