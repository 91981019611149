import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';

export const Caption = styled.div`
  margin-top: var(--space-1);
  ${TEXT_STYLES.bodyCopySmall}
`;

export const Description = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-dark-dark);
`;

export const ErrorMessage = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);

  &.single {
    margin-top: 0.5rem;
  }
`;

export const sharedInputTextAreaStyles = `
  width: 100%;
  ${TEXT_STYLES.formsLabelRegular}
  color: #000;
  background-color: var(--white-light);
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  outline: none;

  &::placeholder {
    color: var(--gray-500);
  }

  &.is-focused {
    border-width: 2px;
    border-color: var(--brand-bright);

    &::placeholder {
      color: var(--gray-800);
    }
  }

  &.is-invalid {
    border-color: var(--error-500);

    &:not(:read-only):focus {
      padding: calc(0.75rem - 2px) calc(1rem - 2px);
      border-width: 2px;
    }

    &::placeholder {
      color: var(--error-500);
    }
  }
`;

// eslint-disable-next-line sort-exports/sort-exports
export const GrowthWrapper = styled.div`
  display: grid;

  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }

  > textarea {
    resize: none;
    overflow: hidden;
    min-height: 2rem;
  }

  > textarea,
  &::after {
    ${sharedInputTextAreaStyles}
    padding: calc(0.75rem - 1px) calc(1rem - 1px);
    grid-area: 1 / 1 / 2 / 2;
    max-height: calc(1.5rem * 3);
  }
`;

// sharedInputTextAreaStyles HAS to be before Input to be able to reference it
export const Input = styled.input`
  ${sharedInputTextAreaStyles}
  height: 3rem;
  padding: calc(0.75rem - 1px) calc(1rem - 1px);

  &.is-focused {
    padding: calc(0.75rem - 2px) calc(1rem - 2px);
  }

  &.is-invalid {
    border-color: var(--error-500);

    &:not(:read-only):focus {
      border-color: var(--error-500);
    }

    &::placeholder {
      color: var(--error-500);
    }
  }
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  padding-bottom: 0.5rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
`;

export const TextArea = styled.textarea`
  ${sharedInputTextAreaStyles}
  min-height: 7rem;
  height: 100%;
  padding: calc(0.75rem - 1px) calc(1rem - 1px);
  max-width: 100%;
  resize: none;

  &.is-focused {
    padding: calc(0.75rem - 2px) calc(1rem - 2px);
  }

  &.is-invalid {
    border-color: var(--error-500);

    &:not(:read-only):focus {
      border-color: var(--error-500);
    }

    &::placeholder {
      color: var(--error-500);
    }
  }
`;

export const TextField = styled.div`
  position: relative;

  &.is-disabled {
    ${Description} {
      color: var(--gray-800);
    }

    ${Input} {
      border: 1px solid var(--gray-200);

      &::placeholder {
        color: var(--gray-500);
      }
    }
  }
`;

export const TooltipContent = styled.div`
  max-width: 18.75rem;
  ${TEXT_STYLES.tooltipText}

  [data-theme='dark'] & {
    color: white;
  }

  [data-theme='light'] & {
    color: var(--gray-700);
  }
`;
