import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';

import type { OrganizationAssignmentDetails } from '../utils';
import { generateOrganizationAssignmentDetails } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchOrganizationAssignment = (filters: OrganizationAssignmentFilters) => Promise<void>;

interface OrganizationAssignmentFilters {
  assignment_completed?: string;
  completed_onboarding?: string;
  email?: string;
  is_clinical?: string;
  user_name?: string;
}

interface UseGetOrganizationAssignment {
  (
    organizationId: string,
    assignmentId: string,
    filters: OrganizationAssignmentFilters
  ): {
    assignment: OrganizationAssignmentDetails | undefined;
    isFetchingOrgAssignment: boolean;
    updateAssignmentFilters: (newFilters: OrganizationAssignmentFilters) => void;
  };
}

const useGetOrganizationAssignment: UseGetOrganizationAssignment = (
  organizationId,
  assignmentId,
  filters
) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { getRequest, reportError } = useApiRequest();

  const [assignment, setAssignment] = useState<OrganizationAssignmentDetails>();
  const [isFetchingOrgAssignment, setIsFetchingOrgAssignment] = useState(false);
  const [query, setQuery] = useState<OrganizationAssignmentFilters>(filters);

  const fetchOrganizationAssignment: FetchOrganizationAssignment = useCallback(
    async filters => {
      if (!organizationId || !assignmentId) return;
      setIsFetchingOrgAssignment(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        import.meta.env.VITE_API_V2_BASE_PATH
      }/organizations/${organizationId}/assignments/${assignmentId}${params ? `?${params}` : ''}`;

      try {
        const { data } = (await getRequest(url)) as {
          data?: APIOrganizationsOrganizationIdAssignmentId['data'];
        };

        setAssignment(data !== undefined ? generateOrganizationAssignmentDetails(data) : undefined);
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetchingOrgAssignment(false);
      }
    },
    [organizationId, assignmentId, getRequest, reportError]
  );

  const debouncedFetchOrganizationAssignment = useDebounce(fetchOrganizationAssignment, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetchingOrgAssignment(true);
    debouncedFetchOrganizationAssignment(filters);
    setIsFirstRender(false);

    return () => {
      setAssignment(undefined);
      setIsFetchingOrgAssignment(false);
      setQuery({});
    };
  }, [debouncedFetchOrganizationAssignment, filters, isFirstRender]);

  const updateAssignmentFilters = (newFilters: Partial<OrganizationAssignmentFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof OrganizationAssignmentFilters] === undefined
        ? delete newFilters[key as keyof OrganizationAssignmentFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchOrganizationAssignment(newFilters);
  };

  return {
    assignment,
    isFetchingOrgAssignment,
    updateAssignmentFilters
  };
};

export default useGetOrganizationAssignment;
