import CheckCircleIcon from '@material-design-icons/svg/round/check.svg?react';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

const progressBarThumbLength = 1.5;

export const CheckIcon = styled(CheckCircleIcon)`
  fill: #fff;
  width: 1.25rem;
  height: 1.25rem;
`;

export const Divider = styled.div`
  height: 0.125rem;
  border-radius: 0.125rem;
  width: 100%;
  align-self: start;
  margin-top: 0.75rem;

  &.green {
    background: var(--success-600);
  }

  &.gray {
    background: var(--gray-300);
  }
`;

export const Label = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-500);

  ${DESKTOP_BREAKPOINT_RANGE} {
    text-align: center;
  }

  @media all and (width > 800px) {
    white-space: nowrap;
  }

  @media all and (width <= 800px) {
    line-height: 1.1;
    margin-top: 0.25rem;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopySmall}
    text-align: left;
    line-height: 1.1;
    margin-top: 0;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;

  ${DESKTOP_BREAKPOINT_RANGE} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media all and (width <= 800px) {
    align-items: start;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    display: grid;
    grid-template-columns: repeat(6, 50%) 44%;
    max-width: 100%;
    overflow-y: auto;
  }
`;

export const ProgressCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: calc(${progressBarThumbLength}rem - 4px);
  height: calc(${progressBarThumbLength}rem - 4px);
  box-sizing: content-box;

  &.complete {
    border: 2px solid var(--success-600);
    background-color: var(--success-600);
  }

  &.in-progress {
    border: 2px solid var(--success-600);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: var(--success-600);
      width: 1rem;
      height: 1rem;
    }
  }

  &.incomplete {
    border: 2px solid var(--gray-300);
  }
`;

export const ProgressItem = styled.div`
  align-items: center;

  ${DESKTOP_BREAKPOINT_RANGE} {
    display: flex;
    width: calc(${progressBarThumbLength}rem + 0.25rem);
    flex-direction: column;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    display: grid;
    grid-template-columns: 1.5rem 1fr;
    gap: 0.25rem;
  }
`;
