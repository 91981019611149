import CheckIcon from '@material-design-icons/svg/round/check_circle.svg?react';
import UnstyledButton from '@/components/UnstyledButton';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const CheckedIcon = styled(CheckIcon)`
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--success-400);
`;

export const List = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  gap: 0.31rem 0.5rem;
  margin-top: 0.5rem;
`;

export const SectionHeading = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-500);
  padding: 0.31rem 0.5rem;
`;

export const SectionListItem = styled.li``;

export const ToggleAllInSectionButton = styled(UnstyledButton)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
