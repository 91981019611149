import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const BodyCell = styled.td<BodyCellProps>`
  ${TEXT_STYLES.formsLabelRegular}
  padding: 0.75rem;
  color: var(--gray-700);

  &.is-focused {
    outline-color: var(--bright-purple-400);
    outline-offset: 0;
    outline-width: 0.125rem;
  }

  ${({ $isEmpty }) => $isEmpty === true && `padding: 0;`}
`;

interface BodyCellProps {
  $isEmpty?: boolean;
}

export const CenterCellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
