import type { RedirectLoginOptions } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import type {
  OrganizationEducationOverview,
  OrganizationEducationTopResource,
  OrganizationOverview
} from '@/pages/Dashboard/utils';
import {
  generateOrganizationEducationOverview,
  generateOrganizationEducationTopResources,
  generateOrganizationOverview
} from '@/pages/Dashboard/utils';
import { downloadPdf } from '@/utils/downloadPdf';
import { downloadPng } from '@/utils/downloadPng';

import { useReportAuth } from '../ReportAuthContext';
import { ReportDownloadBar } from '../ReportDownloadBar';

import InclusivityScorecardReport from './InclusivityScorecardReport';
import * as S from './styles';

const InclusivityScorecardReportWrapper = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [orgId, setOrgId] = useState<string | undefined>(organizationId ?? undefined);
  const { bearerToken } = useReportAuth();
  const [organizationOverview, setOrganizationOverview] = useState<
    OrganizationOverview | undefined
  >(undefined);
  const inclusivityScorecardReportRef = useRef<HTMLDivElement>(null);
  const [educationOverview, setEducationOverview] = useState<
    OrganizationEducationOverview | undefined
  >(undefined);
  const [topCollections, setTopCollections] = useState<
    OrganizationEducationTopResource[] | undefined
  >(undefined);
  const profileTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const organizationTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const educationTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const topEducationTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const { loginWithRedirect } = useAuth0();

  const fetchOrganizationOverview = useCallback(
    async (orgId: string) => {
      if (organizationOverview || bearerToken === '') {
        return;
      }

      const url = `${import.meta.env.VITE_API_V2_BASE_PATH}/organizations/${orgId}/users/overview`;
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            const redirectLoginOptions: RedirectLoginOptions = {
              appState: { returnTo: `${window.location.pathname}${window.location.search}` }
            };
            loginWithRedirect(redirectLoginOptions);
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data } = (await response.json()) as APIOrganizationOverview;
        setOrganizationOverview(generateOrganizationOverview(data));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [bearerToken, loginWithRedirect, organizationOverview]
  );

  const fetchOrganizationEducationOverview = useCallback(
    async (orgId: string) => {
      if (educationOverview || bearerToken === '') {
        return;
      }

      const url = `${import.meta.env.VITE_API_V2_BASE_PATH}/organizations/${orgId}/educations/overview`;
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            const redirectLoginOptions: RedirectLoginOptions = {
              appState: { returnTo: `${window.location.pathname}${window.location.search}` }
            };
            loginWithRedirect(redirectLoginOptions);
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data } =
          (await response.json()) as APIOrganizationsOrganizationIdEducationsOverview;
        setEducationOverview(generateOrganizationEducationOverview(data));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [bearerToken, loginWithRedirect, educationOverview]
  );

  const fetchOrganizationEducationTopResources = useCallback(
    async (orgId: string) => {
      if (topCollections || bearerToken === '') {
        return;
      }

      const url = `${import.meta.env.VITE_API_V2_BASE_PATH}/organizations/${orgId}/educations/top_resources?resource_type=course_collection`;
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            const redirectLoginOptions: RedirectLoginOptions = {
              appState: { returnTo: `${window.location.pathname}${window.location.search}` }
            };
            loginWithRedirect(redirectLoginOptions);
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data } =
          (await response.json()) as APIOrganizationsOrganizationIdEducationsTopResources;
        setTopCollections(generateOrganizationEducationTopResources(data));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [bearerToken, loginWithRedirect, topCollections]
  );

  const fetchUserProfile = useCallback(async () => {
    const url = `${import.meta.env.VITE_API_V2_BASE_PATH}/profile`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          const redirectLoginOptions: RedirectLoginOptions = {
            appState: { returnTo: `${window.location.pathname}${window.location.search}` }
          };
          loginWithRedirect(redirectLoginOptions);
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APIProfile;
      const superuserMembership = data.organization_memberships.find(
        membership => membership.member_role === 'superuser'
      );
      if (superuserMembership) {
        setOrgId(superuserMembership.organization_id);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [bearerToken, loginWithRedirect]);

  useEffect(() => {
    if (orgId === undefined || orgId === '') {
      /*
        Prevents double profile API call from
        useEffect being re-run because of changes
      */
      if (profileTimeoutRef.current) {
        clearTimeout(profileTimeoutRef.current);
      }
      profileTimeoutRef.current = setTimeout(() => {
        fetchUserProfile();
      }, 200);
      return;
    }
    if (organizationOverview === undefined && !organizationTimeoutRef.current) {
      /*
        Prevents double organization overview API call from
        useEffect being re-run because of changes
      */
      organizationTimeoutRef.current = setTimeout(() => {
        fetchOrganizationOverview(orgId);
      }, 500);
    }
    if (educationOverview === undefined && !educationTimeoutRef.current) {
      /*
        Prevents double education overview API call from
        useEffect being re-run because of changes
      */
      educationTimeoutRef.current = setTimeout(() => {
        fetchOrganizationEducationOverview(orgId);
      }, 500);
    }
    if (topCollections === undefined && !topEducationTimeoutRef.current) {
      /*
        Prevents double education top resources API call from
        useEffect being re-run because of changes
      */
      educationTimeoutRef.current = setTimeout(() => {
        fetchOrganizationEducationTopResources(orgId);
      }, 500);
    }
  }, [
    orgId,
    organizationOverview,
    educationOverview,
    topCollections,
    fetchOrganizationOverview,
    fetchOrganizationEducationOverview,
    fetchOrganizationEducationTopResources,
    fetchUserProfile
  ]);

  const generateReportTitle = useCallback(() => {
    if (organizationOverview === undefined) {
      return '';
    }
    const date = new Date();
    const formattedDate = date
      .toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
      .replace(/\//g, '-');
    return `Violet_InclusivityScorecard_${organizationOverview.organizationName.replaceAll(
      ' ',
      ''
    )}_${formattedDate}`;
  }, [organizationOverview]);

  const handleDownloadButtonClick = async (downloadType: number | string): Promise<void> => {
    if (downloadType === 'png') {
      await downloadPng(
        inclusivityScorecardReportRef.current as HTMLElement,
        generateReportTitle()
      );
    } else if (downloadType === 'pdf') {
      await downloadPdf(
        inclusivityScorecardReportRef.current as HTMLElement,
        generateReportTitle()
      );
    }
  };

  return organizationOverview !== undefined && educationOverview !== undefined ? (
    <S.ReportWrapper>
      <S.ExportContainer>
        <InclusivityScorecardReport
          ref={inclusivityScorecardReportRef}
          educationOverview={educationOverview}
          organizationOverview={organizationOverview}
          topResources={topCollections ?? []}
        />
      </S.ExportContainer>
      <ReportDownloadBar
        reportName="inclusivity-scorecard"
        onDownloadClick={handleDownloadButtonClick}
      />
    </S.ReportWrapper>
  ) : (
    <Spinner withWrapper />
  );
};

export default InclusivityScorecardReportWrapper;
