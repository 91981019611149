import { useNumberFormatter } from 'react-aria';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';
import BooleanIcon from '@/components/BooleanIcon';
import ViewDetailsButton from '@/components/Buttons/ViewDetailsButton';
import HelpPopoverTrigger from '@/components/HelpPopoverTrigger';
import DataCardHeader from '@/components/Reporting/DataCardHeader';
import Spinner from '@/components/Spinner';
import TableNumberCell from '@/components/Table/TableNumberCell';
import type { NetworkOrganizationMember } from '@/pages/Dashboard/utils';

import { NETWORK_INCLUSIVITY_URL_W_PARAMS } from '../../../Wrapper';

import * as S from './styles';

interface Props {
  isFetchingNetworkOrgMembers: boolean;
  networkOrgMembers?: NetworkOrganizationMember[];
}

const MiniProviderOrganizationsTable = ({
  isFetchingNetworkOrgMembers,
  networkOrgMembers
}: Props) => {
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  return (
    <S.SingleTable>
      <DataCardHeader
        description="This table displays the largest provider organizations within your network. Click on an organization for information about their inclusivity breakdown."
        title="Provider organizations"
      />
      {isFetchingNetworkOrgMembers ? (
        <Spinner withWrapper />
      ) : networkOrgMembers && networkOrgMembers.length > 0 ? (
        <>
          <S.ProviderOrganizationsTable
            hasLinkedRows
            aria-label="Provider organizations data"
          >
            <TableHeader>
              <Column>Name</Column>
              <Column>
                <TableNumberCell alignRight>Providers</TableNumberCell>
              </Column>
              <Column>
                <S.CenterColumn>On Violet</S.CenterColumn>
              </Column>
              <Column>
                <S.CenterColumn>
                  Verified Inclusive
                  <HelpPopoverTrigger
                    inline
                    size="small"
                    type="info"
                  >
                    <p>Violet's criteria for Verified Inclusive Organizations:</p>
                    <ul>
                      <li>
                        At least 30% of a provider's clinical staff (including licensed
                        professionals, pre-licensed individuals, and interns) must onboard with
                        Violet, submitting their profiles to receive Benchmarks.
                      </li>
                      <li>
                        Of those, 40% must achieve an Awareness Benchmark in at least one of the
                        three communities—BIPOC, LGBQ, or TGNC.
                      </li>
                    </ul>
                  </HelpPopoverTrigger>
                </S.CenterColumn>
              </Column>
            </TableHeader>
            <TableBody>
              {networkOrgMembers.map(member => (
                <Row
                  key={member.id}
                  data-cy="provider-organization-row"
                >
                  <Cell>
                    <S.LinkToOrg
                      data-cy="provider-organization-link"
                      to={`${NETWORK_INCLUSIVITY_URL_W_PARAMS}&organizations%5B%5D=${member.id}`}
                    >
                      {member.name}
                    </S.LinkToOrg>
                  </Cell>
                  <Cell>
                    <TableNumberCell>
                      {commaNumFormatter.format(member.totalProviders)}
                    </TableNumberCell>
                  </Cell>
                  <Cell>
                    <S.CenterDataCell>
                      <BooleanIcon
                        hideFalseIcon
                        aria-label={member.onViolet ? 'On Violet' : 'Not yet on Violet'}
                        data-cy="org-on-violet"
                        value={member.onViolet}
                      />
                    </S.CenterDataCell>
                  </Cell>
                  <Cell>
                    <S.CenterDataCell>
                      <BooleanIcon
                        hideFalseIcon
                        aria-label={
                          member.verifiedInclusive
                            ? 'Verified Inclusive Organization'
                            : 'Not yet verified'
                        }
                        data-cy="verified-inclusive"
                        value={member.verifiedInclusive}
                      />
                    </S.CenterDataCell>
                  </Cell>
                </Row>
              ))}
            </TableBody>
          </S.ProviderOrganizationsTable>
          <ViewDetailsButton
            data-cy="providers-organizations-view-details-button"
            linkToPage={NETWORK_INCLUSIVITY_URL_W_PARAMS}
            overrideText="View all organizations"
          />
        </>
      ) : (
        <S.EmptyState>No organizations found.</S.EmptyState>
      )}
    </S.SingleTable>
  );
};

export default MiniProviderOrganizationsTable;
