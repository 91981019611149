import NumberField from '@/components/FormFields/NumberField';
import * as S from '@/components/FormFields/NumberField/styles';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const TableNumberField = styled(NumberField)`
  & ${S.Input} {
    all: unset;
    box-sizing: border-box;
    border-radius: 0.375rem;
    color: var(--gray-700);
    width: 100%;

    ${MOBILE_BREAKPOINT_RANGE} {
      height: 3.25rem;
      font-weight: 100;

      &:not(:read-only) {
        padding: calc(0.75rem - 1px) calc(1rem - 1px);
        background-color: var(--white-light);
        border: 1px solid var(--gray-5);
      }

      &:not(:read-only):focus {
        padding: calc(0.75rem - 2px) calc(1rem - 2px);
        border-width: 2px;
        border-color: var(--bright-purple-400);
      }
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      height: 2.25rem;
      padding-right: calc(0.75rem - 1px);
      padding-left: calc(0.75rem - 1px);
      text-align: end;

      &:not(:read-only) {
        border: 1px solid var(--gray-5);
        background-color: var(--white-light);
      }
    }

    &.is-invalid {
      border-color: var(--error-500);

      &:not(:read-only):focus {
        padding-right: calc(0.75rem - 2px);
        padding-left: calc(0.75rem - 2px);
        border-width: 2px;
      }

      &::placeholder {
        color: var(--error-500);
      }
    }
  }

  & ${S.Label} {
    ${MOBILE_BREAKPOINT_RANGE} {
      display: inline-flex;
      align-items: center;
      column-gap: 0.13rem;
      ${TEXT_STYLES.formsLabelMediumSmallCaps}
      color: var(--purple-600);
      padding-bottom: var(--space-2);
    }
  }
`;
