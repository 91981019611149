import { createContext } from 'react';
import type { AriaRadioGroupProps } from 'react-aria';
import { useRadioGroup } from 'react-aria';
import type { RadioGroupState } from 'react-stately';
import { useRadioGroupState } from 'react-stately';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface GroupContext {
  state: RadioGroupState;
}

export const RadioContext = createContext<GroupContext | null>(null);

interface Props extends AriaRadioGroupProps {
  children: JSX.Element[];
  className?: string;
}

const ButtonRadioGroup = ({ children, className, ...ariaRadioGroupProps }: Props) => {
  const { label } = ariaRadioGroupProps;
  const state = useRadioGroupState(ariaRadioGroupProps);
  const { errorMessageProps, isInvalid, labelProps, radioGroupProps, validationErrors } =
    useRadioGroup(ariaRadioGroupProps, state);

  return (
    <>
      <S.ButtonRadioGroup
        {...radioGroupProps}
        className={className}
      >
        {isNonEmptyString(label) && <S.Label {...labelProps}>{label}</S.Label>}
        <RadioContext.Provider value={{ state }}>{children}</RadioContext.Provider>
      </S.ButtonRadioGroup>
      {isInvalid && (
        <S.ErrorMessage {...errorMessageProps}>{validationErrors.join(' ')}</S.ErrorMessage>
      )}
    </>
  );
};

export default ButtonRadioGroup;
