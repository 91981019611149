import { Link } from 'react-router-dom';
import UnstyledButton from '@/components/UnstyledButton';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const ChildLink = styled(Link)`
  ${TEXT_STYLES.bodyCopyRegular}
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;

  &:focus {
    outline-color: var(--bright-purple-400);
  }
`;

export const ChildLinkWrapper = styled.li`
  padding: 0.5rem 1.5rem;
  border-radius: 0.75rem;
  text-align: left;
  width: 100%;

  ${ChildLink} {
    color: var(--gray-400);
  }

  &:hover {
    ${ChildLink} {
      color: var(--gray-800);
    }
  }

  &.is-selected {
    font-weight: 500;

    ${ChildLink} {
      color: var(--purple-400);
      font-weight: 500;
    }

    &:hover {
      color: var(--purple-600);
    }
  }
`;

export const ChildNavigation = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

export const ChildNavigationWrapper = styled.ul`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
`;

export const ParentLink = styled(Link)`
  ${TEXT_STYLES.bodyCopyRegular}
  justify-content: start;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s;
  padding: 1rem 0;
  width: 100%;
  display: flex;

  &:focus {
    outline-color: var(--bright-purple-400);
  }
`;

export const ParentLinkWrapper = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 0 2.5rem;
  width: 100%;
  border-bottom: 1px solid var(--gray-200);

  ${ParentLink} {
    color: var(--gray-400);
  }

  &:hover {
    ${ParentLink} {
      color: var(--gray-600);
    }

    background-color: var(--gray-100);
  }

  &.is-selected {
    font-weight: 500;
    background: var(--light-purple-50);

    ${ParentLink} {
      color: var(--purple-400);
      font-weight: 500;
    }

    ${ChildLink} {
      color: var(--purple-200);

      &:hover {
        color: var(--purple-400);
      }
    }

    ${ChildLinkWrapper} {
      &.is-selected {
        ${ChildLink} {
          color: var(--purple-400);
        }
      }
    }

    &:hover {
      background-color: var(--light-purple-100);
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const SecondaryNavToggleMenu = styled(UnstyledButton)`
  ${TEXT_STYLES.bodyCopyRegular}
  padding: 1rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray-200);

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
    outline-offset: 2px;
  }
`;

export const SecondaryNavToggleMenuTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1rem;
  font-weight: 500;
`;

export const SecondaryNavigationBar = styled.ul`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid var(--gray-200);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
`;
