import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const StatusCircle = styled.div`
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;

  &.active {
    background-color: var(--success-500);
  }

  &.inactive {
    background-color: var(--gray-400);
  }
`;

export const StatusLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const StatusPill = styled.div`
  ${TEXT_STYLES.tagLarge}
  display: inline-flex;
  padding: 0.125rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 1.9375rem;

  &.complete {
    color: var(--success-900);
    background-color: var(--success-100);
  }

  &.not-started {
    color: var(--warning-900);
    background-color: var(--warning-100);
  }

  &.in-progress {
    color: var(--blue-600);
    background-color: var(--blue-50);
  }
`;
