import clsx from 'clsx';
import { useRef } from 'react';
import type { AriaCheckboxProps } from 'react-aria';
import { mergeProps, useCheckbox, useFocusRing } from 'react-aria';
import { useToggleState } from 'react-stately';

import * as S from './styles';

const TableCheckbox = (props: AriaCheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const state = useToggleState(props);
  const { inputProps } = useCheckbox(props, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <S.CheckboxWrapper>
      <S.Input
        {...mergeProps(focusProps, inputProps)}
        ref={ref}
        className={clsx({ 'is-checked': state.isSelected }, { 'is-focused': isFocusVisible })}
      />
      {state.isSelected && <S.CheckedIcon />}
    </S.CheckboxWrapper>
  );
};

export default TableCheckbox;
