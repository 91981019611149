import ArrowDownIcon from '@material-design-icons/svg/round/arrow_downward.svg?react';
import ArrowUpIcon from '@material-design-icons/svg/round/arrow_upward.svg?react';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

const arrowIconSize = `
  height: 1rem;
  width: 1rem;
`;

interface ArrowProps {
  $isActive: boolean;
}

export const ArrowDown = styled(ArrowDownIcon)<ArrowProps>`
  fill: var(--gray-400);
  ${arrowIconSize}

  ${({ $isActive }) => $isActive && `fill: var(--purple-600);`}
`;

export const ArrowUp = styled(ArrowUpIcon)<ArrowProps>`
  fill: var(--gray-400);
  ${arrowIconSize}

  ${({ $isActive }) => $isActive && `fill: var(--purple-600);`}
`;

interface ArrowWrapperProps {
  $rightAligned?: boolean;
}

export const ArrowWrapper = styled.span<ArrowWrapperProps>`
  ${arrowIconSize}
  margin-left: 0.125rem;

  ${({ $rightAligned = false }) =>
    $rightAligned &&
    `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
  `}
`;

interface CellWrapperProps {
  $isCentered?: boolean;
}

export const CellWrapper = styled.div<CellWrapperProps>`
  display: flex;
  align-items: center;
  text-align: left;

  ${({ $isCentered = false }) =>
    $isCentered &&
    `
    text-align: center;
    justify-content: center;
  `}
`;

interface HeaderCellProps {
  $isEmpty?: boolean;
  $rightAligned?: boolean;
}

export const HeaderCell = styled.th<HeaderCellProps>`
  ${TEXT_STYLES.formsLabelMediumSmallCaps}
  color: var(--purple-400);
  padding: 0.75rem;
  position: relative;

  &.is-sortable {
    cursor: pointer;
  }

  &.is-wide {
    text-align: center;
  }

  &.is-focused,
  &:focus-visible {
    outline-color: var(--bright-purple-400);
    outline-offset: 0;
    outline-width: 0.125rem;
  }

  ${({ $isEmpty }) => $isEmpty === true && `padding: 0;`}
`;
