import Button from '@/components/Buttons/Button';
import type { Dispatch, SetStateAction } from 'react';
import * as S from './styles';
import useAnalytics from '@/hooks/useAnalytics';
import useUser from '@/hooks/useUser';
import type { Benchmark } from '../..';

interface Props {
  isDisabled: boolean;
  selectedBenchmarks: Benchmark[];
  setHasShared: Dispatch<SetStateAction<boolean>>;
  source: 'announcement' | 'dashboard';
}

const WebSelectionButtons = ({ isDisabled, selectedBenchmarks, setHasShared, source }: Props) => {
  const { analytics } = useAnalytics();
  const { user } = useUser();

  const clickedToShareToWeb = () => {
    setHasShared(true);
    analytics?.track('Benchmark Shared', {
      user_id: user.id,
      email: user.email,
      community: selectedBenchmarks.map(b => b.score_type).join(','),
      level: selectedBenchmarks.map(b => b.score_level).join(','),
      source: source,
      channel: 'web'
    });
  };

  return (
    <S.ButtonGroup $align="right">
      <Button
        data-cy="generate-code-block-button"
        isDisabled={isDisabled}
        size="large"
        onPress={clickedToShareToWeb}
      >
        Generate code block
      </Button>
    </S.ButtonGroup>
  );
};

export default WebSelectionButtons;
