import { transparentize } from 'polished';
import { BLACK_CHARCOAL_COLOR } from '@/components/GlobalStyle';
import styled from 'styled-components';

export const Modal = styled.div`
  min-height: 0;
`;

export const Underlay = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  padding: var(--space-6);
  background-color: ${transparentize(0.3, BLACK_CHARCOAL_COLOR)};
`;
