import { useRef } from 'react';
import type { AriaTagProps } from 'react-aria';
import { mergeProps, useFocusRing, useHover, useTag } from 'react-aria';
import type { ListState } from 'react-stately';

import * as S from './styles';

interface Props<T> extends AriaTagProps<T> {
  size: 'large' | 'small';
  state: ListState<T>;
  variant: 'blue' | 'dark' | 'gray' | 'green' | 'light';
}

const Tag = <T extends object>({ size, state, variant, ...ariaTagProps }: Props<T>) => {
  const { item } = ariaTagProps;

  const ref = useRef<HTMLDivElement>(null);
  const { allowsRemoving, gridCellProps, isDisabled, isPressed, removeButtonProps, rowProps } =
    useTag(ariaTagProps, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing({ within: true });
  const { hoverProps, isHovered } = useHover({ isDisabled });

  return (
    <S.Tag
      {...mergeProps(rowProps, focusProps, hoverProps)}
      ref={ref}
      $allowsRemoving={allowsRemoving}
      $isDisabled={isDisabled}
      $isFocusVisible={isFocusVisible}
      $isHovered={isHovered}
      $isPressed={isPressed}
      $size={size}
      $variant={variant}
      data-cy="tag"
    >
      <S.GridCell {...gridCellProps}>
        {item.rendered}
        {allowsRemoving && (
          <S.RemoveButton
            {...removeButtonProps}
            isDisabled={isDisabled}
          >
            <S.CloseIcon
              aria-hidden="true"
              role="img"
            />
          </S.RemoveButton>
        )}
      </S.GridCell>
    </S.Tag>
  );
};

export default Tag;
