import type { Analytics } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

// AnalyticsContext
interface AnalyticsContextProps {
  analytics: Analytics | undefined;
}

const defaultAnalyticsContext = {
  analytics: undefined
};

const AnalyticsContext = createContext<AnalyticsContextProps>(defaultAnalyticsContext);

// AnalyticsProvider
interface AnalyticsProviderProps {
  writeKey: string;
}

export const AnalyticsProvider: FC<PropsWithChildren<AnalyticsProviderProps>> = ({
  children,
  writeKey
}) => {
  const [analytics, setAnalytics] = useState<Analytics>();

  const loadAnalytics = async () => {
    if (analytics || !writeKey) {
      return;
    }

    const [response] = await AnalyticsBrowser.load({ writeKey });
    setAnalytics(response);
  };

  useEffect(() => {
    loadAnalytics();
  });

  return <AnalyticsContext.Provider value={{ analytics }}>{children}</AnalyticsContext.Provider>;
};

const useAnalytics = () => {
  const result = useContext(AnalyticsContext);

  return result;
};

export default useAnalytics;
