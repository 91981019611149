import CloseIcon from '@material-design-icons/svg/round/close.svg?react';
import Button from '@/components/Buttons/Button';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';
import TextField from '../../FormFields/TextField';
import UnstyledButton from '../../UnstyledButton';
import { Radio as BaseRadio, Group as BaseRadioGroup } from '../../UnstyledRadioGroup';

export const CloseButton = {
  Icon: styled(CloseIcon)`
    display: block;
    fill: var(--gray-400);
    height: 1.5rem;
    width: auto;
  `,
  Root: styled(UnstyledButton)`
    align-self: end;
    margin-bottom: 0.625rem;
    margin-top: -0.3125rem;
  `
};

export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(100vw - var(--space-6) * 2);
  max-height: 100%;
  width: 28rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 1.5rem;
  box-shadow: var(--elevation-modal-regular);
  overflow: hidden auto;
  z-index: 2;
`;

export const EducationCompletedModalDialog = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  padding: var(--space-6);
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 2;
`;

export const LargeText = styled.p`
  margin-bottom: 1.5rem;
  ${TEXT_STYLES.bodyCopyLarge}
  color: var(--gray-600);
`;

export const ModalButton = styled(Button)`
  align-self: end;
`;

export const NextCourseTitle = styled.div`
  ${TEXT_STYLES.headlineSectionLevel1Regular}
  width: 100%;
  margin-bottom: 1.5rem;
  color: var(--gray-900);
`;

export const Radio = styled(BaseRadio)`
  cursor: pointer;
  font-family:
    'Apple Color Emoji', 'Segoe UI Emoji', 'Noto Color Emoji', 'Android Emoji', EmojiSymbols,
    'EmojiOne Mozilla', 'Twemoji Mozilla', 'Segoe UI Symbol', 'Noto Color Emoji Compat', emoji,
    noto-emojipedia-fallback, sans-serif;
  border-radius: 100%;
  background-color: var(--gray-50);
  border: 2px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  &.is-focused,
  &.is-hovered {
    border-color: var(--gray-200);
  }

  &.is-selected {
    border-color: var(--purple-400);
    background-color: var(--bright-purple-25);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    font-size: 1.25rem;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.0625rem 0 0;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    font-size: 2rem;
    width: 4.0625rem;
    height: 4.0625rem;
    padding: 0.6875rem 0.625rem 0.625rem;
  }
`;

export const RadioGroup = styled(BaseRadioGroup)`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    column-gap: 0.75rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    column-gap: 1rem;
  }
`;

export const Separator = styled.div`
  width: calc(100% + 3rem);
  margin: 0 -1.5rem 1.5rem;
  height: 0.125rem;
  background-color: var(--gray-100);
`;

export const SubTitle = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  font-weight: 700;
  color: var(--gray-900);
  width: 100%;
  margin-bottom: 0.25rem;
`;

export const Text = styled.p`
  margin-bottom: 1.5rem;
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-600);
`;

export const TextArea = styled(TextField)`
  width: 100%;
  margin-bottom: var(--space-7);

  textarea {
    width: calc(100% - 0.125rem);
    border-color: var(--gray-200);
    height: 3.25rem;
    padding: calc(1rem - 0.0625rem);
    min-height: unset;

    &:focus {
      width: calc(100% - 0.25rem);
      padding: calc(1rem - 0.125rem);
    }
  }
`;

export const Title = styled.div`
  ${TEXT_STYLES.headlineSectionLevel1Regular}
  width: 100%;
  margin-bottom: 2.5rem;
  color: var(--gray-900);
`;
