import type { ReactNode } from 'react';
import { isValidElement, useEffect, useState } from 'react';
import CollapsibleButton from '@/components/CollapsibleButton';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

type PageTitleVariantTypes = 'h1' | 'h2';

interface Props {
  actionButton?: ReactNode;
  className?: string;
  collapsibleOnMobile?: boolean;
  'data-cy'?: string;
  description?: ReactNode | string;
  id?: string;
  labelComponent?: ReactNode;
  title: string;
  titleVariant: PageTitleVariantTypes;
}

const PageTitle = ({
  actionButton,
  className,
  collapsibleOnMobile = false,
  'data-cy': dataCy,
  description,
  id,
  labelComponent,
  title,
  titleVariant
}: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const [showCollapsibleButton, setShowCollapsibleButton] = useState(
    isInMobileBreakpointRange && collapsibleOnMobile
  );
  const Title = titleVariant === 'h1' ? S.TitleH1 : S.TitleH2;
  const hasDescription =
    (typeof description === 'string' && isNonEmptyString(description)) ||
    isValidElement(description);
  const [collapsed, setCollapsed] = useState(isInMobileBreakpointRange);

  useEffect(() => {
    setCollapsed(isInMobileBreakpointRange);
    setShowCollapsibleButton(isInMobileBreakpointRange && collapsibleOnMobile);
  }, [isInMobileBreakpointRange, collapsibleOnMobile]);

  return (
    <S.TitleContainer
      className={className}
      data-cy={dataCy}
      id={id}
    >
      <Title>
        {title}
        {labelComponent}
      </Title>
      {actionButton !== undefined && <S.ButtonWrapper>{actionButton}</S.ButtonWrapper>}
      {hasDescription && (!collapsibleOnMobile || !showCollapsibleButton) && (
        <S.Description>{description}</S.Description>
      )}
      {hasDescription && collapsibleOnMobile && showCollapsibleButton && (
        <S.CollapsibleDescription $collapsed={collapsed}>
          <S.Description>{description}</S.Description>
          <CollapsibleButton
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        </S.CollapsibleDescription>
      )}
    </S.TitleContainer>
  );
};

export default PageTitle;
