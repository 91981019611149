import { Temporal } from '@js-temporal/polyfill';
import ExportIcon from '@material-design-icons/svg/round/download.svg?react';
import * as Sentry from '@sentry/react';
import type { Key } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { AriaComboBoxOptions } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import { Item } from 'react-stately';
import Alert from '@/components/Alert';
import FilterDropdown from '@/components/FilterDropdown';
import PageTitle from '@/components/PageTitle';
import DemographicGraph from '@/components/Reporting/DemographicGraph';
import TagGroup from '@/components/TagGroup';
import useOpenErrorModalDialog from '@/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import { downloadXlsx } from '@/utils/downloadXlsx';
import { filtersObjectToParams, sortFilterParams } from '@/utils/filterUtils';
import isNonEmptyString from '@/utils/isNonEmptyString';
import { capitalize } from '@/utils/stringTransformations';

import Button from '@/components/Buttons/Button';
import Spinner from '@/components/Spinner';
import useUser from '@/hooks/useUser';
import useGetOrganizationDemographics from '@/pages/Dashboard/hooks/useGetOrganizationDemographics';
import { USER_TYPES } from '@/pages/Dashboard/hooks/useGetOrganizationUsers';
import {
  organizationFiltersToSearch,
  organizationSearchToFilters
} from '../../organizationFilters';

import * as S from './styles';

const Demographics = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/vnd.ms-excel'
    }
  };
  const organization = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization;

  /***** Setup initial states from search params *****/

  const [searchParams, setSearchParams] = useSearchParams();
  const draftState = useRef<{
    clinical: string;
  }>({
    clinical:
      searchParams.get('clinical') === 'true'
        ? 'clinical'
        : searchParams.get('clinical') === 'false'
          ? 'non-clinical'
          : 'all'
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            !key.includes('order_by') && !key.includes('page') && !key.includes('resource_type')
        )
        .flatMap(([key, value]) =>
          value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
        )
    )
  );

  const [selectedUserType, setSelectedUserType] = useState<string>(
    searchParams.get('clinical') === 'true'
      ? 'clinical'
      : searchParams.get('clinical') === 'false'
        ? 'non-clinical'
        : 'all'
  );

  const {
    isFetching: isFetchingOrganizationDemographics,
    organizationDemographics,
    updateOrganizationDemographicsFilters
  } = useGetOrganizationDemographics(organization?.id ?? '', {
    clinical:
      searchParams.get('clinical') === 'true'
        ? true
        : searchParams.get('clinical') === 'false'
          ? false
          : undefined,
    completed_onboarding: true,
    status: 'activated'
  });

  /***** Handle Exporting XLSX *****/

  const [isExportingXlsx, setIsExportingXlsx] = useState(false);

  const handleExportXlsxButtonPress = async () => {
    if (!organizationDemographics || !organization) {
      return;
    }
    setIsExportingXlsx(true);

    const exportParams = new URLSearchParams(searchParams);

    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/organizations/${
      organization.id
    }/users/demographics/export?status=activated&completed_onboarding=true${
      exportParams.size === 0 ? '' : `&${exportParams}`
    }`;

    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { organization } = user.organization_memberships.find(
        membership => membership.member_role === 'superuser'
      )!;

      const formattedDate = Temporal.Now.plainDateISO().toLocaleString('en-US');
      const organizationName = organization.name.replace(/\s/g, '');

      await downloadXlsx(response, `Violet_Demographics_${organizationName}_${formattedDate}.xlsx`);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setIsExportingXlsx(false);
  };

  /***** Handle Filter Selections *****/

  const handleUserTypeSelectionChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    const value = key as string;
    setSelectedUserType(value);
  };

  /***** Handle Filter Changes *****/

  const preserveChanges = () => {
    draftState.current.clinical = selectedUserType;
    const clinicalFilterString = `User type:${selectedUserType === 'all' ? 'undefined' : selectedUserType === 'clinical' ? 'true' : 'false'}`;
    const updatedFilters = [clinicalFilterString].filter(
      entry =>
        !entry.includes('undefined') &&
        !entry.includes(':all') &&
        isNonEmptyString(entry.split(':')[1])
    );
    setAppliedFilters(new Set(updatedFilters));
    refreshURLparams(new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    draftState.current = {
      clinical: 'all'
    };
    setSelectedUserType('all');
    setAppliedFilters(new Set());
    refreshURLparams(new Set());
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('User type')) {
        const value = keyString.split(':')[1];
        draftState.current.clinical = value === 'true' ? 'clinical' : 'non-clinical';
        setSelectedUserType(value === 'true' ? 'clinical' : 'non-clinical');
      }
    });
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(updatedFilters);
  };

  const refreshURLparams = (updatedFilters: Set<Key>) => {
    const finalParams = filtersObjectToParams(organizationFiltersToSearch, updatedFilters);
    const sortedParams = sortFilterParams(finalParams);
    setSearchParams(sortedParams);
  };

  /***** Handle Changed Search Params *****/

  useEffect(() => {
    setSelectedUserType(
      searchParams.get('clinical') === 'true'
        ? 'clinical'
        : searchParams.get('clinical') === 'false'
          ? 'non-clinical'
          : 'all'
    );
    draftState.current = {
      clinical:
        searchParams.get('clinical') === 'true'
          ? 'clinical'
          : searchParams.get('clinical') === 'false'
            ? 'non-clinical'
            : 'all'
    };
    setAppliedFilters(
      new Set(
        Array.from(searchParams.entries())
          .filter(
            ([key]) =>
              !key.includes('order_by') && !key.includes('page') && !key.includes('resource_type')
          )
          .flatMap(([key, value]) =>
            value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
          )
      )
    );
  }, [searchParams]);

  useEffect(() => {
    updateOrganizationDemographicsFilters({
      clinical:
        searchParams.get('clinical') === 'true'
          ? true
          : searchParams.get('clinical') === 'false'
            ? false
            : undefined,
      completed_onboarding: true,
      status: 'activated'
    });
  }, [searchParams, updateOrganizationDemographicsFilters]);

  return (
    <>
      <PageTitle
        title="Demographics"
        titleVariant="h1"
      />
      <S.ActionWrapper>
        <FilterDropdown>
          <FilterDropdown.Filters
            fitContent
            customGridTemplateColumns="1fr"
            onApplyChanges={preserveChanges}
          >
            <S.UserTypeSelect
              aria-label="Filter by user type"
              data-cy="user-type-select"
              placeholder="All users"
              selectedKey={selectedUserType}
              onSelectionChange={handleUserTypeSelectionChange}
            >
              {USER_TYPES.map(userType => (
                <Item key={userType}>{`${capitalize(userType)} users`}</Item>
              ))}
            </S.UserTypeSelect>
          </FilterDropdown.Filters>
          <FilterDropdown.Tags
            onClear={handleClearFilters}
            onRemove={handleRemoveFilter}
          >
            {Array.from(appliedFilters).map(key => {
              const label = key.toString().split(':')[0];
              const value = key.toString().split(':')[1];
              return (
                <Item key={key}>
                  {label === 'User type'
                    ? value === 'true'
                      ? 'Clinical users'
                      : 'Non-clinical users'
                    : `${label}:${value}`}
                </Item>
              );
            })}
          </FilterDropdown.Tags>
        </FilterDropdown>
        <Button
          data-cy="export-demographics-btn"
          isDisabled={!organizationDemographics}
          isLoading={isExportingXlsx}
          size="regular"
          trailingIcon={ExportIcon}
          variant="primary"
          onPress={handleExportXlsxButtonPress}
        >
          Export
        </Button>
      </S.ActionWrapper>
      {isFetchingOrganizationDemographics ? (
        <S.SpinnerWrapper>
          <Spinner />
        </S.SpinnerWrapper>
      ) : (
        <S.Cards>
          <S.ChartCard data-cy="race-ethnicity-data">
            <S.ChartTitle>Race & ethnicity</S.ChartTitle>
            {organizationDemographics ? (
              <DemographicGraph
                data={organizationDemographics.raceEthnicity}
                type="raceEthnicity"
              />
            ) : (
              <Alert
                isBlock
                data-cy="no-data"
                header="No data available."
                id="demographics-race-ethnicity-no-data-alert"
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartCard>
          <S.ChartCard data-cy="gender-identity-data">
            <S.ChartTitle>Gender identity</S.ChartTitle>
            {organizationDemographics ? (
              <DemographicGraph
                data={organizationDemographics.genderIdentity}
                type="genderIdentity"
              />
            ) : (
              <Alert
                isBlock
                data-cy="no-data"
                header="No data available."
                id="demographics-gender-identity-no-data-alert"
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartCard>
          <S.ChartCard data-cy="sexual-orientation-data">
            <S.ChartTitle>Sexual orientation</S.ChartTitle>
            {organizationDemographics ? (
              <DemographicGraph
                data={organizationDemographics.sexualOrientation}
                type="sexualOrientation"
              />
            ) : (
              <Alert
                isBlock
                data-cy="no-data"
                header="No data available."
                id="demographics-sexual-orientation-no-data-alert"
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartCard>
          <S.ChartCard data-cy="language-data">
            <S.ChartTitle>Care delivery languages</S.ChartTitle>
            {organizationDemographics ? (
              organizationDemographics.language.length > 0 ? (
                <>
                  <S.LanguageDescription id="language-description">
                    The number of team members delivering care in each of the following languages:
                  </S.LanguageDescription>
                  <TagGroup
                    aria-labelledby="language-description"
                    data-cy="languages-data"
                    variant="light"
                  >
                    {organizationDemographics.language.map(item => (
                      <Item
                        key={item.label}
                        textValue={`${item.percentage}% ${item.label}`}
                      >
                        <S.LanguagePercentage>{item.percentage}%</S.LanguagePercentage>
                        {item.label}
                      </Item>
                    ))}
                  </TagGroup>
                </>
              ) : (
                <S.LanguageDescription data-cy="languages-data">
                  No known team members speak a language other than English.
                </S.LanguageDescription>
              )
            ) : (
              <Alert
                isBlock
                data-cy="no-data"
                header="No data available."
                id="demographics-languages-no-data-alert"
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartCard>
        </S.Cards>
      )}
    </>
  );
};

export default Demographics;
