import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';
import type { Education } from '@/pages/Dashboard/utils';

interface ContextValue {
  draftEducations: Education[];
  invalidRowIndices: number[];
  originalEducations: Education[];
  setDraftEducations: Dispatch<SetStateAction<Education[]>>;
  setInvalidRowIndices: Dispatch<SetStateAction<number[]>>;
  setOriginalEducations: Dispatch<SetStateAction<Education[]>>;
}

export const EducationsContext = createContext<ContextValue>({
  draftEducations: [],
  invalidRowIndices: [],
  originalEducations: [],
  setDraftEducations: () => {},
  setInvalidRowIndices: () => {},
  setOriginalEducations: () => {}
});

const useEducationsContext = () => useContext(EducationsContext);

export const isInvalidNumber = (value: number) => isNaN(value) || value <= 0;

export const revalidateEducationRows = (draftEducations: Education[]) => {
  const invalidIndices = draftEducations.reduce<number[]>((invalidArray, education, index) => {
    if (
      isInvalidNumber(Number(education.credits)) ||
      Number(education.credits) < 0 ||
      education.organization === '' ||
      education.courseName === '' ||
      isInvalidNumber(Number(education.year)) ||
      Number(education.year) < new Date().getFullYear() - 70 ||
      Number(education.year) > new Date().getFullYear()
    ) {
      invalidArray.push(index);
    }
    return invalidArray;
  }, []);
  return invalidIndices;
};

export default useEducationsContext;
