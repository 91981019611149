import Select from '@/components/FormFields/Select';
import * as S from '@/components/FormFields/Select/styles';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const TableSelect = styled(Select)`
  ${S.Icon} {
    width: 1rem;
    height: auto;
  }

  ${S.Trigger} {
    box-sizing: border-box;
    text-overflow: ellipsis;
    border-radius: 0.375rem;
    color: var(--gray-700);
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background-color: var(--white-light);
    border: 1px solid var(--gray-5);

    span {
      flex-grow: 1;
    }

    ${MOBILE_BREAKPOINT_RANGE} {
      font-weight: 100;
      height: 3.25rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      height: 2.25rem;
    }

    &:focus {
      border-width: 2px;
      border-color: var(--brand-bright);

      ${S.Icon} {
        right: calc(0.75rem - 2px);
      }
    }
  }

  ${S.Trigger}.is-invalid {
    border-color: var(--error-500);
    color: var(--error-500);

    &::placeholder {
      color: var(--error-500);
    }
  }

  & ${S.Label} {
    display: inline-flex;
    align-items: center;
    column-gap: 0.13rem;
    ${TEXT_STYLES.formsLabelMediumSmallCaps}
    color: var(--purple-600);
    padding-bottom: var(--space-2);
  }
`;
