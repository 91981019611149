import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import type { NonClinicalExperience } from '../utils';
import { generateNonClinicalExperience, generateNonClinicalExperiences } from '../utils';

import useApiRequest, { snakeCaseKeys } from './useApiRequest';

type FetchNonClinicalExperiences = () => void;

type RefetchNonClinicalExperiences = FetchNonClinicalExperiences;

interface UseGetNonClinicalExperiences {
  (): {
    createNonClinicalExperience: (
      nonClinicalExperience: NonClinicalExperience
    ) => Promise<NonClinicalExperience | undefined>;
    deleteNonClinicalExperience: (id: string) => Promise<void>;
    isFetching: boolean;
    nonClinicalExperiences: NonClinicalExperience[] | undefined;
    patchNonClinicalExperience: (
      nonClinicalExperience: NonClinicalExperience
    ) => Promise<NonClinicalExperience | undefined>;
    refetchNonClinicalExperiences: RefetchNonClinicalExperiences;
  };
}

const useGetNonClinicalExperiences: UseGetNonClinicalExperiences = () => {
  const isFirstRender = useIsFirstRender();
  const [nonClinicalExperiences, setNonClinicalExperiences] = useState<NonClinicalExperience[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { deleteRequest, getRequest, patchRequest, postRequest, reportError } = useApiRequest();

  const fetchNonClinicalExperience: FetchNonClinicalExperiences = useCallback(async () => {
    setIsFetching(true);

    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/non_clinical_experiences`;

    try {
      const { data } = (await getRequest(url)) as { data?: APINonClinicalExperience['data'] };
      setNonClinicalExperiences(data !== undefined ? generateNonClinicalExperiences(data) : []);
    } catch (error) {
      reportError(error);
    } finally {
      setIsFetching(false);
    }
  }, [getRequest, reportError]);

  const createNonClinicalExperience = async (nonClinicalExperience: NonClinicalExperience) => {
    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/non_clinical_experiences`;

    try {
      const { data } = (await postRequest(url, {
        non_clinical_experience: snakeCaseKeys(nonClinicalExperience)
      })) as {
        data?: APINonClinicalExperience['data'][0];
      };
      if (data !== undefined) {
        const newEntry = generateNonClinicalExperience(data);
        const newNonClinicalExperiences = [...nonClinicalExperiences, newEntry];
        setNonClinicalExperiences(newNonClinicalExperiences);
        return newEntry;
      }
    } catch (error) {
      reportError(error);
    }
  };

  const patchNonClinicalExperience = async (nonClinicalExperience: NonClinicalExperience) => {
    if (nonClinicalExperience.id === '') return;
    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/non_clinical_experiences/${nonClinicalExperience.id}`;
    try {
      const { data } = (await patchRequest(url, {
        non_clinical_experience: snakeCaseKeys(nonClinicalExperience)
      })) as {
        data?: APINonClinicalExperience['data'][0];
      };
      if (data !== undefined) {
        const updatedEntry = generateNonClinicalExperience(data);
        const newNonClinicalExperiences = nonClinicalExperiences.map(experience => {
          if (experience.id === data.id) {
            return updatedEntry;
          }
          return experience;
        });
        setNonClinicalExperiences(newNonClinicalExperiences);
        return updatedEntry;
      }
    } catch (error) {
      reportError(error);
    }
  };

  const deleteNonClinicalExperience = async (id: string) => {
    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/non_clinical_experiences/${id}`;

    try {
      await deleteRequest(url);
      const newNonClinicalExperiences = nonClinicalExperiences.filter(
        nonClinicalExperience => nonClinicalExperience.id !== id
      );
      setNonClinicalExperiences(newNonClinicalExperiences);
    } catch (error) {
      reportError(error);
    }
  };

  const debouncedFetchNonClinicalExperiences = useDebounce(fetchNonClinicalExperience, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchNonClinicalExperiences();

    return () => {
      setNonClinicalExperiences([]);
      setIsFetching(false);
    };
  }, [debouncedFetchNonClinicalExperiences, isFirstRender]);

  const refetchNonClinicalExperiences: RefetchNonClinicalExperiences = useCallback(() => {
    debouncedFetchNonClinicalExperiences();
  }, [debouncedFetchNonClinicalExperiences]);

  return {
    createNonClinicalExperience,
    deleteNonClinicalExperience,
    isFetching,
    nonClinicalExperiences,
    patchNonClinicalExperience,
    refetchNonClinicalExperiences
  };
};

export default useGetNonClinicalExperiences;
