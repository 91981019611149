import CheckCircleIcon from '@material-symbols/svg-400/rounded/check_circle.svg?react';
import { RESOURCE_CARD_STYLES } from '@/components/Education/resource_card_styles';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const CollapsibleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const CollapsibleDescription = styled.div<{ $collapsed: boolean }>`
  display: flex;
  gap: 0.5rem;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    ${CollapsibleContent} {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    `}
`;

export const Image = styled.img`
  width: 100%;
  max-width: 100%;
  border-radius: 1rem 1rem 0 0;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  background-color: #fff;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  box-shadow: 0 0 16px -4px rgba(16, 24, 40, 0.05);
`;

export const SkillCheckIcon = styled(CheckCircleIcon)`
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--gray-400);
`;

export const SkillsItem = styled.li`
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  gap: 0.25rem;
`;

export const SkillsList = styled.ul`
  ${TEXT_STYLES.bodyCopyMedium}
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.75rem;
`;

export const Tag = styled.li`
  ${RESOURCE_CARD_STYLES.cardTagItem}
`;

export const Tags = styled.ul`
  ${RESOURCE_CARD_STYLES.cardTags}
  width: 100%;
  margin-top: 0.75rem;
`;

export const TextBlock = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-600);

  div {
    width: 100%;
  }
`;

export const TextBold = styled.div`
  font-weight: 500;
  color: var(--gray-800);
`;
