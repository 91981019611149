import styled from 'styled-components';

import { TEXT_STYLES } from '../../../../../constants';

export const Badges = {
  AssessedOn: styled.div`
    font-weight: 500;
  `,
  Badge: styled.img`
    display: grid;
    place-content: center;
    width: 2rem;
  `,
  DateEarned: styled.div``,
  PopoverText: styled.div`
    ${TEXT_STYLES.tooltipText}
    text-align: center;
    color: var(--gray-700);
    width: 9.375rem;
  `,
  Root: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 100%;
    min-width: 8.4375rem; /* 135px -> prevents badges from touching on small screens */
  `,
  Title: styled.div`
    margin-bottom: 8px;
    font-size: 0.875rem;
    font-weight: 500;
  `
};

export const BenchmarksColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  min-width: 8.4375rem; /* 135px -> prevents badges from touching on small screens */
  text-align: center;
`;

export const FiltersAndActionsWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InclusivityOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 14rem;
`;

export const TruncateLongText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (width <= 1399px) {
    /* wide screen and less */
    max-width: 15.625rem; /* 250px */
  }

  @media (width >= 1400px) {
    /* wide screen and larger */
    max-width: 25rem; /* 400px */
  }
`;
