import ForwardToInboxIcon from '@material-design-icons/svg/round/forward_to_inbox.svg?react';
import LeadingHero from '@/components/heroes/LeadingHero';
import styled from 'styled-components';

export const Hero = styled(LeadingHero)`
  max-width: 34rem;

  h1,
  h2,
  p,
  a {
    color: var(--white-light);
  }
`;

export const Icon = styled(ForwardToInboxIcon)`
  width: 3rem;
  height: auto;
  margin-bottom: var(--space-4);
  fill: var(--white-light);
`;

export const Invitation = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
  height: 100dvh;
  padding-left: var(--space-6);
  padding-right: var(--space-6);
  text-align: center;
  background-color: var(--purple-600);
`;
