import UnstyledButton from '@/components/UnstyledButton';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

import type { DataCardHeaderTitleVariantTypes } from '.';

interface DescriptionProps {
  $titleVariant: DataCardHeaderTitleVariantTypes;
}

export const BadgeWrapper = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default !important;
`;

interface H3CardTitleProps {
  $titleVariant: DataCardHeaderTitleVariantTypes;
}

export const Description = styled.div<DescriptionProps>`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);

  ${({ $titleVariant }) => {
    if ($titleVariant === 'h3') {
      return `
        margin-top: 0.25rem;
        margin-bottom: 1.75rem;
      `;
    }
  }}
`;

export const H3CardTitle = styled.h3<H3CardTitleProps>`
  ${TEXT_STYLES.headlineSectionLevel2Large}
  color: var(--gray-800);
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 0;

  ${({ $titleVariant }) => {
    if ($titleVariant === 'h3-report') {
      return `
        padding-bottom: 1.5rem;
      `;
    } else if ($titleVariant === 'h3') {
      return `
      ${TEXT_STYLES.headlineSectionLevel2Regular}
      `;
    }
  }}
`;

export const TitleHeader = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export const Tooltip = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);
  max-width: 18.75rem;
`;
