import ActionsIcon from '@material-symbols/svg-400/rounded/more_vert-fill.svg?react';
import LinkIcon from '@material-symbols/svg-400/rounded/open_in_new.svg?react';
import PersonIcon from '@material-symbols/svg-400/rounded/person-fill.svg?react';
import ButtonWithMenu from '@/components/Buttons/ButtonWithMenu';
import FilterDropdown from '@/components/FilterDropdown';
import PageTitle from '@/components/PageTitle';
import Table from '@/components/Table';
import TooltipTrigger from '@/components/TooltipTrigger';
import UnstyledButton from '@/components/UnstyledButton';
import UnstyledToggleButton from '@/components/UnstyledToggleButton';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const AssignmentButtonMenu = styled(ButtonWithMenu)`
  padding: 0.5rem;
`;

export const AssignmentFilters = styled(FilterDropdown)`
  margin-bottom: 1rem;
`;

export const AssignmentsPageTitle = styled(PageTitle)`
  width: 100%;
`;

export const AssignmentsTable = styled(Table)`
  tr th:first-child {
    padding: 0 0.25rem;
  }
`;

export const CenterCell = styled.div`
  width: 100%;
  display: grid;
  place-content: center;
`;

export const LinkOutIcon = styled(LinkIcon)`
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  fill: var(--gray-400);
`;

export const MemberIcon = styled(PersonIcon)`
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  fill: currentcolor;
`;

export const MemberTooltipButton = styled(UnstyledToggleButton)`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
  position: relative;
`;

export const MemberTooltipTrigger = styled(TooltipTrigger)`
  display: flex;
  justify-content: end;

  .is-focused,
  :focus {
    outline: 2px solid var(--brand-bright);
    outline-offset: 4px;
    border-radius: 0.5rem;
  }
`;

export const OpenDetailsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const StatusCircle = styled.div`
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;

  &.active {
    background-color: var(--success-500);
  }

  &.inactive {
    background-color: var(--gray-400);
  }
`;

export const StatusLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Tooltip = styled(TooltipTrigger)`
  display: inline-flex;
  justify-content: center;
`;

export const TooltipText = styled.span`
  ${TEXT_STYLES.tooltipText}
`;

export const TruncatedCell = styled(UnstyledButton)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 24.25rem; /* 400px - 0.75rem */
`;

export const VerticalDotIcon = styled(ActionsIcon)`
  path {
    stroke: currentcolor;
    stroke-width: 4rem;
  }
`;
