import { getLocalTimeZone, parseAbsoluteToLocal, today } from '@internationalized/date';
import ExpandLessIcon from '@material-design-icons/svg/round/expand_less.svg?react';
import ExpandMoreIcon from '@material-design-icons/svg/round/expand_more.svg?react';
import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';
import type { AriaCheckboxProps, AriaComboBoxProps, AriaTextFieldProps } from 'react-aria';
import { useNumberFormatter } from 'react-aria';
import DemographicCompositionNumberField from '@/components/FormFields/DemographicCompositionNumberField';
import MonthYearPicker from '@/components/FormFields/MonthYearPicker';
import PopoverTrigger from '@/components/PopoverTrigger';
import TextField from '@/components/Table/TableTextField';
import { BREAKPOINT } from '@/constants';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import isNonEmptyString from '@/utils/isNonEmptyString';
import { isValidMonthAndYear } from '@/utils/isValidMonthAndYear';

import type { WorkExperienceFormType } from '../../WorkExperience';

import * as S from './styles';

interface Props {
  checkValidityRef: MutableRefObject<(() => boolean)[] | undefined>;
  className?: string;
  expandedRowIndex: number;
  handleExpandCollapseClick: (index: number) => void;
  handleValidityChange: () => void;
  hasAttemptedSubmit?: boolean;
  index: number;
  initialState?: WorkExperienceType;
  isInvalid?: boolean;
  onRemove: (index: number) => Promise<void>;
  workExperience: WorkExperienceFormType;
}

const WorkExperienceRow = ({
  checkValidityRef,
  expandedRowIndex,
  handleExpandCollapseClick,
  handleValidityChange,
  hasAttemptedSubmit = false,
  index,
  initialState,
  isInvalid = false,
  onRemove,
  workExperience
}: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  const [endDateFieldIsVisible, setEndDateFieldIsVisible] = useState<boolean>(
    !initialState || (initialState.end_date !== undefined && initialState.end_date !== null)
  );

  const [startMonth, setStartMonth] = useState<string>(
    initialState?.start_date !== undefined && isNonEmptyString(initialState.start_date)
      ? parseAbsoluteToLocal(initialState.start_date).month.toString()
      : ''
  );
  const [startYear, setStartYear] = useState<string>(
    initialState?.start_date !== undefined && isNonEmptyString(initialState.start_date)
      ? parseAbsoluteToLocal(initialState.start_date).year.toString()
      : ''
  );
  const [endMonth, setEndMonth] = useState<string>(
    initialState?.end_date !== undefined && initialState.end_date !== null
      ? parseAbsoluteToLocal(initialState.end_date).month.toString()
      : ''
  );
  const [endYear, setEndYear] = useState<string>(
    initialState?.end_date !== undefined && initialState.end_date !== null
      ? parseAbsoluteToLocal(initialState.end_date).year.toString()
      : ''
  );
  const [startDateFieldErrorMessage, setStartDateFieldErrorMessage] = useState<string>();
  const [endDateFieldErrorMessage, setEndDateFieldErrorMessage] = useState<string>();

  const BodyElementType = window.innerWidth < BREAKPOINT ? S.BodyFieldElement : S.BodyCell;
  const digitNumFormatter = useNumberFormatter({
    minimumIntegerDigits: 2,
    useGrouping: true
  });

  /*
   * Only if the role is current
   * should the end date be visible and required
   */
  const handleCheckboxChange: AriaCheckboxProps['onChange'] = isSelected => {
    setEndDateFieldIsVisible(!isSelected);

    if (isSelected && (endMonth || endYear)) {
      setEndMonth('');
      setEndYear('');
    }

    handleValidityChange();
  };

  const handleStartMonthChange: AriaComboBoxProps<string>['onSelectionChange'] = value => {
    setStartMonth(value as string);
  };

  const handleStartYearChange: AriaTextFieldProps['onChange'] = value => {
    setStartYear(value);
  };

  const handleEndMonthChange: AriaComboBoxProps<string>['onSelectionChange'] = value => {
    setEndMonth(value as string);
  };

  const handleEndYearChange: AriaTextFieldProps['onChange'] = value => {
    setEndYear(value);
  };

  useEffect(() => {
    const todayYear = today(getLocalTimeZone()).year;
    const todayMonth = today(getLocalTimeZone()).month;
    const dateCannotBeToolOldError = 'Date must be more recent.';
    const dateCannotBeInTheFutureError = 'Date cannot be in the future.';
    const pleaseFillOutThisFieldError = 'Please include a valid month and year.';

    if (startMonth && startYear && endMonth && endYear) {
      if (startYear.length < 4 || !/^\d+$/.test(startYear)) {
        setStartDateFieldErrorMessage(pleaseFillOutThisFieldError);
        setEndDateFieldErrorMessage(undefined);
      } else if (endYear.length < 4 || !/^\d+$/.test(endYear)) {
        setEndDateFieldErrorMessage(pleaseFillOutThisFieldError);
        setStartDateFieldErrorMessage(undefined);
      } else if (
        Number(startYear) > todayYear ||
        (Number(startYear) === todayYear && Number(startMonth) > todayMonth)
      ) {
        setStartDateFieldErrorMessage(dateCannotBeInTheFutureError);
        setEndDateFieldErrorMessage(undefined);
      } else if (
        Number(endYear) > todayYear ||
        (Number(endYear) === todayYear && Number(endMonth) > todayMonth)
      ) {
        setEndDateFieldErrorMessage(dateCannotBeInTheFutureError);
        setStartDateFieldErrorMessage(undefined);
      } else if (Number(startYear) < todayYear - 50) {
        setStartDateFieldErrorMessage(dateCannotBeToolOldError);
        setEndDateFieldErrorMessage(undefined);
      } else if (Number(endYear) < todayYear - 50) {
        setEndDateFieldErrorMessage('Date must be more recent.');
        setStartDateFieldErrorMessage(undefined);
      } else if (
        Number(startYear) > Number(endYear) ||
        (Number(startYear) === Number(endYear) && Number(startMonth) > Number(endMonth))
      ) {
        setStartDateFieldErrorMessage('Start date cannot be after the end date.');
        setEndDateFieldErrorMessage('End date cannot be before the start date.');
      } else {
        setStartDateFieldErrorMessage(undefined);
        setEndDateFieldErrorMessage(undefined);
      }
    } else if (startMonth && startYear) {
      if (startYear.length < 4 || !/^\d+$/.test(startYear)) {
        setStartDateFieldErrorMessage(pleaseFillOutThisFieldError);
      } else if (
        Number(startYear) > todayYear ||
        (Number(startYear) === todayYear && Number(startMonth) > todayMonth)
      ) {
        setStartDateFieldErrorMessage(dateCannotBeInTheFutureError);
      } else if (Number(startYear) < todayYear - 50) {
        setStartDateFieldErrorMessage(dateCannotBeToolOldError);
      } else {
        setStartDateFieldErrorMessage(undefined);
      }

      if (endDateFieldIsVisible && (!endMonth || !endYear || endYear === '')) {
        setEndDateFieldErrorMessage(pleaseFillOutThisFieldError);
      } else {
        setEndDateFieldErrorMessage(undefined);
      }
    } else if (endDateFieldIsVisible && endMonth && endYear) {
      if (endYear.length < 4 || !/^\d+$/.test(endYear)) {
        setEndDateFieldErrorMessage(pleaseFillOutThisFieldError);
      } else if (
        Number(endYear) > todayYear ||
        (Number(endYear) === todayYear && Number(endMonth) > todayMonth)
      ) {
        setEndDateFieldErrorMessage(dateCannotBeInTheFutureError);
      } else if (Number(endYear) < todayYear - 50) {
        setEndDateFieldErrorMessage(dateCannotBeToolOldError);
      } else {
        setEndDateFieldErrorMessage(undefined);
      }

      if (!startMonth || !startYear || startYear === '') {
        setStartDateFieldErrorMessage(pleaseFillOutThisFieldError);
      } else {
        setStartDateFieldErrorMessage(undefined);
      }
    } else {
      if (!startMonth || !startYear || startYear === '') {
        setStartDateFieldErrorMessage(pleaseFillOutThisFieldError);
      } else {
        setStartDateFieldErrorMessage(undefined);
      }
      if (endDateFieldIsVisible && (!endMonth || !endYear || endYear === '')) {
        setEndDateFieldErrorMessage(pleaseFillOutThisFieldError);
      } else {
        setEndDateFieldErrorMessage(undefined);
      }
    }
  }, [endMonth, endYear, startMonth, digitNumFormatter, startYear, endDateFieldIsVisible]);

  useEffect(() => {
    checkValidityRef.current![index] = () => {
      let errorsFound = 0;

      const {
        averagePanelSize,
        bipocPercentage,
        department,
        jobTitle,
        lgbqPercentage,
        organization,
        tgncPercentage
      } = workExperience;

      const htmlInputFields = [
        averagePanelSize.current!,
        bipocPercentage.current!,
        department.current!,
        jobTitle.current!,
        lgbqPercentage.current!,
        organization.current!,
        tgncPercentage.current!
      ];

      if (
        htmlInputFields.some(htmlInput => !htmlInput.validity.valid) ||
        startDateFieldErrorMessage !== undefined ||
        endDateFieldErrorMessage !== undefined
      )
        errorsFound++;

      if (!startMonth || !startYear || startYear.length < 4 || !/^\d+$/.test(startYear)) {
        errorsFound++;
      }

      if (
        endDateFieldIsVisible &&
        (!endMonth || !endYear || endYear.length < 4 || !/^\d+$/.test(endYear))
      ) {
        errorsFound++;
      }

      return errorsFound === 0;
    };
  }, [
    checkValidityRef,
    endMonth,
    endYear,
    endDateFieldIsVisible,
    index,
    startMonth,
    startYear,
    workExperience,
    startDateFieldErrorMessage,
    endDateFieldErrorMessage
  ]);

  useEffect(
    () => () => {
      checkValidityRef.current!.splice(index, 1);
    },
    [checkValidityRef, index]
  );

  useEffect(() => {
    if (isValidMonthAndYear({ month: endMonth, year: endYear })) {
      workExperience.endDate.current = `${endYear}-${digitNumFormatter.format(
        Number(endMonth)
      )}-01`;
    } else {
      workExperience.endDate.current = null;
    }
  }, [endYear, endMonth, workExperience.endDate, digitNumFormatter]);

  useEffect(() => {
    if (isValidMonthAndYear({ month: startMonth, year: startYear })) {
      workExperience.startDate.current = `${startYear}-${digitNumFormatter.format(
        Number(startMonth)
      )}-01`;
    } else {
      workExperience.startDate.current = null;
    }
  }, [startMonth, startYear, workExperience.startDate, digitNumFormatter]);

  return (
    <>
      <BodyElementType
        $center
        $area="expand-collapse"
        $mobileAlignment="left"
        className="header-cell"
        role="gridcell"
      >
        <S.ExpandButton.Root
          aria-label={index === expandedRowIndex ? 'Collapse' : 'Expand'}
          data-cy="expand-row-button"
          isDisabled={isInvalid}
          onPress={() => handleExpandCollapseClick(index)}
        >
          <S.ExpandButton.Icon
            aria-hidden="true"
            as={index === expandedRowIndex || isInvalid ? ExpandLessIcon : ExpandMoreIcon}
            role="img"
          />
          {isInMobileBreakpointRange &&
            (index === expandedRowIndex || isInvalid ? 'Collapse' : 'Expand')}
        </S.ExpandButton.Root>
      </BodyElementType>
      <BodyElementType
        $area="job-title"
        $center={false}
        role="gridcell"
      >
        <TextField
          ref={workExperience.jobTitle}
          isRequired
          aria-label="Job title*"
          autoFocus={initialState?.job_title === '' || initialState?.job_title === undefined}
          data-cy="job-title-field"
          defaultValue={initialState?.job_title}
          errorMessage={workExperience.jobTitle.current?.validationMessage}
          hideRequiredIndicator={isInMobileBreakpointRange}
          isInvalid={
            hasAttemptedSubmit && workExperience.jobTitle.current?.validity.valid === false
          }
          label={isInMobileBreakpointRange ? 'Job title' : undefined}
          placeholder="Job title*"
          onChange={handleValidityChange}
        />
      </BodyElementType>
      <BodyElementType
        $area="organization"
        $center={false}
        role="gridcell"
      >
        <TextField
          ref={workExperience.organization}
          isRequired
          aria-label="Organization*"
          data-cy="organization-field"
          defaultValue={initialState?.organization}
          errorMessage={workExperience.organization.current?.validationMessage}
          hideRequiredIndicator={isInMobileBreakpointRange}
          isInvalid={
            hasAttemptedSubmit && workExperience.organization.current?.validity.valid === false
          }
          label={isInMobileBreakpointRange ? 'Organization' : undefined}
          placeholder="Organization*"
          onChange={handleValidityChange}
        />
      </BodyElementType>
      <BodyElementType
        $area="department"
        $center={false}
        role="gridcell"
      >
        <TextField
          ref={workExperience.department}
          isRequired
          aria-label="Department*"
          data-cy="department-field"
          defaultValue={initialState?.department}
          errorMessage={workExperience.department.current?.validationMessage}
          hideRequiredIndicator={isInMobileBreakpointRange}
          isInvalid={
            hasAttemptedSubmit && workExperience.department.current?.validity.valid === false
          }
          label={isInMobileBreakpointRange ? 'Department' : undefined}
          placeholder="Department*"
          onChange={handleValidityChange}
        />
      </BodyElementType>
      <BodyElementType
        $center
        $area="current-role"
        $mobileAlignment="left"
        className="current-role-cell"
        role="gridcell"
      >
        <S.CurrentCheckbox
          ref={workExperience.isCurrentRole}
          aria-label="Current role"
          data-cy="current-role-field"
          defaultSelected={!endDateFieldIsVisible}
          onChange={handleCheckboxChange}
        >
          {isInMobileBreakpointRange ? <S.CheckboxLabel>Current role</S.CheckboxLabel> : undefined}
        </S.CurrentCheckbox>
      </BodyElementType>
      <BodyElementType
        $area="dates"
        $center={false}
        className="dates-wrapper"
        role="gridcell"
      >
        <S.DateFields>
          <MonthYearPicker
            dataCyPrefix="start-date"
            dateErrorMessage={startDateFieldErrorMessage}
            handleMonthChange={handleStartMonthChange}
            handleYearChange={handleStartYearChange}
            isInvalid={hasAttemptedSubmit && isInvalid}
            label="Start date"
            month={startMonth}
            year={startYear}
          />
          {endDateFieldIsVisible && (
            <>
              <S.DateEnDash>-</S.DateEnDash>
              <MonthYearPicker
                dataCyPrefix="end-date"
                dateErrorMessage={endDateFieldErrorMessage}
                handleMonthChange={handleEndMonthChange}
                handleYearChange={handleEndYearChange}
                isInvalid={hasAttemptedSubmit && isInvalid}
                label="End date"
                month={endMonth}
                year={endYear}
              />
            </>
          )}
        </S.DateFields>
      </BodyElementType>
      <BodyElementType
        $center
        $area="delete"
        $mobileAlignment="right"
        className="header-cell"
        role="gridcell"
      >
        <S.DeleteButton.Root
          aria-label="Delete"
          data-cy="delete-button"
          onPress={() => onRemove(workExperience.index)}
        >
          <S.DeleteButton.Icon
            $fill="var(--purple-400)"
            aria-hidden="true"
            role="img"
          />
        </S.DeleteButton.Root>
      </BodyElementType>
      <BodyElementType
        $area="nested-table"
        $center={false}
        role="gridcell"
      >
        {isInMobileBreakpointRange ? (
          <S.NestedCard $isVisible={expandedRowIndex === index || isInvalid}>
            <S.NestedBodyElement>
              <S.Label id="patients-clients-seen-per-week-label">
                PATIENTS / CLIENTS SEEN PER WEEK
                <PopoverTrigger
                  content={
                    <S.PopoverContent>
                      Estimate the number of patients you typically see in a week, on average.
                    </S.PopoverContent>
                  }
                >
                  <S.InfoButton.Root aria-label="Information">
                    <S.InfoButton.Icon
                      aria-hidden="true"
                      role="img"
                    />
                  </S.InfoButton.Root>
                </PopoverTrigger>
              </S.Label>
              <S.AveragePanelSizeField
                ref={workExperience.averagePanelSize}
                isRequired
                aria-label="Patients per week"
                data-cy="average-panel-size-field"
                defaultValue={initialState?.average_panel_size ?? undefined}
                errorMessage={workExperience.averagePanelSize.current?.validationMessage}
                formatOptions={{ maximumFractionDigits: 0 }}
                hideRequiredIndicator={isInMobileBreakpointRange}
                isInvalid={
                  hasAttemptedSubmit &&
                  workExperience.averagePanelSize.current?.validity.valid === false
                }
                maxValue={999}
                minValue={1}
                placeholder="Number of patients*"
                onChange={handleValidityChange}
              />
            </S.NestedBodyElement>
            <S.NestedBodyElement className="demographics-cell">
              <S.Label>
                DEMOGRAPHIC COMPOSITION
                <PopoverTrigger
                  content={
                    <S.PopoverContent>
                      Please estimate the percentage of your patients who identify as belonging to
                      the following communities. Note that patients may belong to multiple
                      communities when estimating the percentage.
                    </S.PopoverContent>
                  }
                >
                  <S.InfoButton.Root aria-label="Information">
                    <S.InfoButton.Icon
                      aria-hidden="true"
                      role="img"
                    />
                  </S.InfoButton.Root>
                </PopoverTrigger>
              </S.Label>
              <S.PercentageContainer>
                <DemographicCompositionNumberField
                  ref={workExperience.bipocPercentage}
                  isRequired
                  aria-label="Percentage of BIPOC patients"
                  data-cy="bipoc-percentage-field"
                  defaultValue={initialState?.bipoc_percentage ?? undefined}
                  errorMessage={workExperience.bipocPercentage.current?.validationMessage}
                  isInvalid={
                    hasAttemptedSubmit &&
                    workExperience.bipocPercentage.current?.validity.valid === false
                  }
                  placeholder="0*"
                  suffix="% BIPOC"
                  onChange={handleValidityChange}
                />
                <DemographicCompositionNumberField
                  ref={workExperience.lgbqPercentage}
                  isRequired
                  aria-label="Percentage of LGBQ patients"
                  data-cy="lgbq-percentage-field"
                  defaultValue={initialState?.lgbq_percentage ?? undefined}
                  errorMessage={workExperience.lgbqPercentage.current?.validationMessage}
                  isInvalid={
                    hasAttemptedSubmit &&
                    workExperience.lgbqPercentage.current?.validity.valid === false
                  }
                  placeholder="0*"
                  suffix="% LGBQ"
                  onChange={handleValidityChange}
                />
                <DemographicCompositionNumberField
                  ref={workExperience.tgncPercentage}
                  isRequired
                  aria-label="Percentage of TGNC patients"
                  data-cy="tgnc-percentage-field"
                  defaultValue={initialState?.tgnc_percentage ?? undefined}
                  errorMessage={workExperience.tgncPercentage.current?.validationMessage}
                  isInvalid={
                    hasAttemptedSubmit &&
                    workExperience.tgncPercentage.current?.validity.valid === false
                  }
                  placeholder="0*"
                  suffix="% TGNC"
                  onChange={handleValidityChange}
                />
              </S.PercentageContainer>
            </S.NestedBodyElement>
          </S.NestedCard>
        ) : (
          <S.NestedTable.Root
            $isVisible={expandedRowIndex === index || isInvalid}
            aria-label="Expanded content"
          >
            <S.NestedTable.Header role="rowgroup">
              <S.NestedTable.HeaderRow role="row">
                <S.NestedTable.HeaderCell role="columnheader">
                  <S.Label id="patients-clients-seen-per-week-label">
                    PATIENTS / CLIENTS SEEN PER WEEK
                    <PopoverTrigger
                      content={
                        <S.PopoverContent>
                          Estimate the number of patients you typically see in a week, on average.
                        </S.PopoverContent>
                      }
                    >
                      <S.InfoButton.Root aria-label="Information">
                        <S.InfoButton.Icon
                          aria-hidden="true"
                          role="img"
                        />
                      </S.InfoButton.Root>
                    </PopoverTrigger>
                  </S.Label>
                </S.NestedTable.HeaderCell>
                <S.NestedTable.HeaderCell
                  $center
                  colSpan={3}
                  role="columnheader"
                >
                  <S.Label>
                    DEMOGRAPHIC COMPOSITION
                    <PopoverTrigger
                      content={
                        <S.PopoverContent>
                          Please estimate the percentage of your patients who identify as belonging
                          to the following communities. Note that patients may belong to multiple
                          communities when estimating the percentage.
                        </S.PopoverContent>
                      }
                    >
                      <S.InfoButton.Root aria-label="Information">
                        <S.InfoButton.Icon
                          aria-hidden="true"
                          role="img"
                        />
                      </S.InfoButton.Root>
                    </PopoverTrigger>
                  </S.Label>
                </S.NestedTable.HeaderCell>
              </S.NestedTable.HeaderRow>
            </S.NestedTable.Header>
            <S.NestedTable.Body role="rowgroup">
              <S.NestedTable.BodyRow role="row">
                <S.NestedTable.BodyCell role="gridtd">
                  <S.AveragePanelSizeField
                    ref={workExperience.averagePanelSize}
                    isRequired
                    aria-label="Patients per week"
                    data-cy="average-panel-size-field"
                    defaultValue={initialState?.average_panel_size ?? undefined}
                    errorMessage={workExperience.averagePanelSize.current?.validationMessage}
                    formatOptions={{ maximumFractionDigits: 0 }}
                    hideRequiredIndicator={isInMobileBreakpointRange}
                    isInvalid={
                      hasAttemptedSubmit &&
                      workExperience.averagePanelSize.current?.validity.valid === false
                    }
                    maxValue={999}
                    minValue={1}
                    placeholder="Number of patients*"
                    onChange={handleValidityChange}
                  />
                </S.NestedTable.BodyCell>
                <S.NestedTable.BodyCell role="gridtd">
                  <DemographicCompositionNumberField
                    ref={workExperience.bipocPercentage}
                    isRequired
                    aria-label="Percentage of BIPOC patients"
                    data-cy="bipoc-percentage-field"
                    defaultValue={initialState?.bipoc_percentage ?? undefined}
                    errorMessage={workExperience.bipocPercentage.current?.validationMessage}
                    isInvalid={
                      hasAttemptedSubmit &&
                      workExperience.bipocPercentage.current?.validity.valid === false
                    }
                    placeholder="0*"
                    suffix="% BIPOC"
                    onChange={handleValidityChange}
                  />
                </S.NestedTable.BodyCell>
                <S.NestedTable.BodyCell role="gridtd">
                  <DemographicCompositionNumberField
                    ref={workExperience.lgbqPercentage}
                    isRequired
                    aria-label="Percentage of LGBQ patients"
                    data-cy="lgbq-percentage-field"
                    defaultValue={initialState?.lgbq_percentage ?? undefined}
                    errorMessage={workExperience.lgbqPercentage.current?.validationMessage}
                    isInvalid={
                      hasAttemptedSubmit &&
                      workExperience.lgbqPercentage.current?.validity.valid === false
                    }
                    placeholder="0*"
                    suffix="% LGBQ"
                    onChange={handleValidityChange}
                  />
                </S.NestedTable.BodyCell>
                <S.NestedTable.BodyCell role="gridtd">
                  <DemographicCompositionNumberField
                    ref={workExperience.tgncPercentage}
                    isRequired
                    aria-label="Percentage of TGNC patients"
                    data-cy="tgnc-percentage-field"
                    defaultValue={initialState?.tgnc_percentage ?? undefined}
                    errorMessage={workExperience.tgncPercentage.current?.validationMessage}
                    isInvalid={
                      hasAttemptedSubmit &&
                      workExperience.tgncPercentage.current?.validity.valid === false
                    }
                    placeholder="0*"
                    suffix="% TGNC"
                    onChange={handleValidityChange}
                  />
                </S.NestedTable.BodyCell>
              </S.NestedTable.BodyRow>
            </S.NestedTable.Body>
          </S.NestedTable.Root>
        )}
      </BodyElementType>
    </>
  );
};

export default WorkExperienceRow;
