import DownloadIcon from '@material-design-icons/svg/round/download.svg?react';

import * as S from './styles';

interface Props {
  collectionName: string;
  fileUrl: string;
}

const CertificateDownload = ({ collectionName, fileUrl }: Props) => {
  const downloadUrl = `${import.meta.env.VITE_BASE_PATH}${fileUrl}`;
  return (
    <S.Container>
      <S.Title>Your certificate is ready.</S.Title>
      <S.Description>
        Congratulations on completing {collectionName} Your certificate can also be accessed at any
        time by going to your profile under Education.
      </S.Description>
      <S.ActionContainer>
        <S.DownloadButton
          data-cy="download-now-button"
          href={downloadUrl}
          size="large"
          target="_blank"
          trailingIcon={DownloadIcon}
        >
          Download now
        </S.DownloadButton>
        <S.BackToEducationButton
          size="large"
          to="/dashboard/education"
          variant="outline"
        >
          Return to education
        </S.BackToEducationButton>
      </S.ActionContainer>
    </S.Container>
  );
};

export default CertificateDownload;
