import ArrowRightAltIcon from '@material-design-icons/svg/round/arrow_forward.svg?react';
import { useRef } from 'react';
import {
  FocusScope,
  OverlayContainer,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll
} from 'react-aria';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../../../../components/Buttons/Button';

import * as S from './styles';

interface Props {
  close: () => void;
}

const ExitConfirmationModalDialog = ({ close }: Props) => {
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const { overlayProps, underlayProps } = useOverlay(
    { isDismissable: true, isOpen: true, onClose: close },
    ref
  );

  usePreventScroll();
  const { modalProps } = useModal();

  const { dialogProps, titleProps } = useDialog({}, ref);

  const handleCancelButtonPress = () => {
    close();
  };

  const handleCloseButtonPress = () => {
    close();
  };

  const handleYesButtonPress = () => {
    close();
    navigate('/dashboard/education/collections');
  };

  return (
    <OverlayContainer>
      <S.ExitConfirmationModalDialog {...underlayProps}>
        <FocusScope
          autoFocus
          contain
          restoreFocus
        >
          <S.Dialog
            {...overlayProps}
            {...dialogProps}
            {...modalProps}
            ref={ref}
            data-cy="exit-confirmation-modal-dialog"
          >
            <S.CloseButton.Root onPress={handleCloseButtonPress}>
              <S.CloseButton.Icon />
            </S.CloseButton.Root>
            <S.Title {...titleProps}>Are you sure you want to exit?</S.Title>
            <S.Text>
              Your progress may be lost. You can always come back to complete your assessment from
              the collection page, or your profile, under Education.
            </S.Text>
            <S.Buttons>
              <Button
                data-cy="yes-button"
                size="large"
                trailingIcon={ArrowRightAltIcon}
                onPress={handleYesButtonPress}
              >
                Yes
              </Button>
              <Button
                size="large"
                variant="outline"
                onPress={handleCancelButtonPress}
              >
                Cancel
              </Button>
            </S.Buttons>
          </S.Dialog>
        </FocusScope>
      </S.ExitConfirmationModalDialog>
    </OverlayContainer>
  );
};

export default ExitConfirmationModalDialog;
