import ScheduleIcon from '@material-design-icons/svg/round/schedule.svg?react';
import VerifiedIcon from '@material-design-icons/svg/round/verified.svg?react';
import * as Sentry from '@sentry/react';
import { produce } from 'immer';
import { useEffect, useRef, useState } from 'react';
import {
  FocusScope,
  OverlayContainer,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
  VisuallyHidden
} from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import { Item } from 'react-stately';
import PopoverTrigger from '@/components/PopoverTrigger';
import TagGroup from '@/components/TagGroup';
import useUser from '@/hooks/useUser';
import getContentMediumTag from '@/utils/getContentMediumTag';
import { useLocalStorage } from 'usehooks-ts';

import useAnalytics from '../../../hooks/useAnalytics';
import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../hooks/useOpenSignedOutModalDialog';
import type { EducationalResource } from '../../../pages/Dashboard/utils';
import Button from '../../Buttons/Button';

import * as S from './styles';
import useGetFaqLinks from '@/utils/getFaqLinks';

interface Props {
  close: () => void;
  educationalResource: EducationalResource;
  openBeginEvaluationModalDialog?: () => void;
  openEducationCompletedModalDialog: (isFirstCompletedEducation?: boolean) => void;
  refetchEducationalResources: (skipSettingIsFetching?: boolean) => void;
}

const CourseDetailsModalDialog = ({
  close,
  educationalResource,
  openBeginEvaluationModalDialog,
  openEducationCompletedModalDialog,
  refetchEducationalResources
}: Props) => {
  const { bearerToken, setUser, user } = useUser();
  const [searchParams] = useSearchParams();
  const FaqLinks = useGetFaqLinks();
  const [accessedEducationalResources, setAccessedEducationalResources] = useLocalStorage<string[]>(
    `${user.id}-accessedEducationalResources`,
    []
  );
  const [dismissedAccreditationPrompts] = useLocalStorage<string[]>(
    'dismissedAccreditationPrompts',
    []
  );

  const openErrorModalDialog = useOpenErrorModalDialog();

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    { isDismissable: true, isOpen: true, onClose: close },
    ref
  );

  const [isMarkingComplete, setIsMarkingComplete] = useState(false);

  const collectionIds = educationalResource.courseCollections.map(collection => collection.id);
  const hasBeenDismissed = collectionIds.some(id => dismissedAccreditationPrompts.includes(id));

  useEffect(() => {
    if (
      educationalResource.completionStatus === 'complete' &&
      searchParams.get('source') === 'scormcloud'
    ) {
      close();
      openEducationCompletedModalDialog(searchParams.get('isFirstCompletion') === 'true');
    } else if (
      openBeginEvaluationModalDialog &&
      window.location.href.includes('all-education') &&
      !hasBeenDismissed
    ) {
      close();
      openBeginEvaluationModalDialog();
    }
  }, [
    close,
    hasBeenDismissed,
    openBeginEvaluationModalDialog,
    openEducationCompletedModalDialog,
    educationalResource.completionStatus,
    searchParams
  ]);

  useEffect(() => {
    /* Add listener for if URL changes
     *  to know if details modal should be closed
     *  when user navigates back in browser
     */
    const eventListener = () => {
      if (!window.location.pathname.includes(`${educationalResource.id}`)) {
        close();
      }
    };
    window.addEventListener('popstate', eventListener);

    return () => {
      // Clear listener when modal is unmounted
      window.removeEventListener('popstate', eventListener);
    };
    // run once -- rerunning this effect will break the listener
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePreventScroll();
  const { modalProps } = useModal();

  const { dialogProps, titleProps } = useDialog({}, ref);

  const handleCloseButtonPress = () => {
    close();
  };

  const { analytics } = useAnalytics();

  const handleAccessEducationButtonPress = () => {
    analytics?.track('Education - accessed', {
      education_resource_id: educationalResource.id
    });

    setAccessedEducationalResources([...accessedEducationalResources, educationalResource.id]);
  };

  const handleMarkCompleteButtonPress = async () => {
    let url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/courses/${educationalResource.id}/complete`;

    let options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    };

    setIsMarkingComplete(true);

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const {
        data: { completed_first_education: isFirstCompletedEducation }
      } = (await response.json()) as APICoursesCourseIdComplete;

      setIsMarkingComplete(false);

      close();
      openEducationCompletedModalDialog(isFirstCompletedEducation);

      url = `${import.meta.env.VITE_API_V2_BASE_PATH}/profile`;

      options = {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data: user } = (await response.json()) as APIProfile;

        setUser(
          produce(user, draft => {
            draft.user_info.educations = user.user_info.educations;
          })
        );
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }

      refetchEducationalResources(true);
    } catch (error) {
      Sentry.captureException(error);

      setIsMarkingComplete(false);

      close();
      openErrorModalDialog();
    }
  };

  const handleRateCourseButtonPress = () => {
    close();
    openEducationCompletedModalDialog();
  };
  const currentPath = window.location.pathname;

  const isVioletCourse = educationalResource.organization === 'Violet';

  return (
    <OverlayContainer>
      <S.CourseDetailsModalDialog {...underlayProps}>
        <FocusScope
          autoFocus
          contain
          restoreFocus
        >
          <S.Dialog
            {...dialogProps}
            {...modalProps}
            {...overlayProps}
            ref={ref}
            data-cy="course-details-modal-dialog"
          >
            <S.Header>
              <S.CloseButton
                data-cy="close-course-details-modal"
                onPress={handleCloseButtonPress}
              >
                <S.CloseIcon aria-hidden="true" />
              </S.CloseButton>
              <S.Title {...titleProps}>{educationalResource.title}</S.Title>
              <S.AuthorAndOrganization>
                <S.Author>
                  <VisuallyHidden>{'Taught by '}</VisuallyHidden>
                  {educationalResource.author}
                </S.Author>
                <S.Organization>
                  <VisuallyHidden>{'Presented by '}</VisuallyHidden>
                  {educationalResource.organization}
                </S.Organization>
              </S.AuthorAndOrganization>
              <S.InformationAndTags>
                {educationalResource.tags.length !== 0 && (
                  <TagGroup
                    aria-label="Community tags"
                    variant="dark"
                  >
                    <Item>{educationalResource.tags}</Item>
                  </TagGroup>
                )}
                <TagGroup
                  aria-label="Content medium, number of credits, and duration"
                  variant="light"
                >
                  {getContentMediumTag(educationalResource.contentMedium)}
                  <Item textValue={educationalResource.credits}>
                    <VerifiedIcon
                      aria-hidden="true"
                      role="img"
                    />
                    {educationalResource.credits}
                  </Item>
                  <Item textValue={educationalResource.length}>
                    <ScheduleIcon
                      aria-hidden="true"
                      role="img"
                    />
                    {educationalResource.length}
                  </Item>
                </TagGroup>
              </S.InformationAndTags>
            </S.Header>
            <S.Body>
              <S.Description>{educationalResource.description}</S.Description>
              {isVioletCourse && (
                <PopoverTrigger
                  content={
                    <S.DisclosureContainer data-cy="financialDisclosures">
                      {educationalResource.financialDisclosures.length === 0 ? (
                        <>
                          Violet evaluates all educators for relevant financial relationships in
                          line with best practice. There are no disclosures for this activity.
                        </>
                      ) : (
                        <>
                          Violet evaluates all educators for relevant financial relationships in
                          line with best practice. The following disclosures were made and mitigated
                          for this activity.
                        </>
                      )}
                      {educationalResource.financialDisclosures.map((disclosure, i) => (
                        <div key={i}>{disclosure}</div>
                      ))}
                    </S.DisclosureContainer>
                  }
                  placement="top left"
                >
                  <S.DisclosureButton data-cy="DisclosuresBtn">
                    Disclosures
                    <S.QuestionMark />
                  </S.DisclosureButton>
                </PopoverTrigger>
              )}
              {educationalResource.specialties.length !== 0 && (
                <S.Specialty>
                  <S.TagLabel id="specialty-label">Specialty</S.TagLabel>
                  <TagGroup
                    aria-labelledby="specialty-label"
                    size="small"
                    variant="gray"
                  >
                    {educationalResource.specialties.map(specialty => (
                      <Item key={specialty}>{specialty}</Item>
                    ))}
                  </TagGroup>
                </S.Specialty>
              )}
              {educationalResource.ceCreditTypes !== null &&
                educationalResource.ceCreditTypes.length !== 0 && (
                  <div>
                    <S.TagLabel id="ce-credit-type-label">
                      CE Credit Type{' '}
                      <PopoverTrigger
                        content={
                          <S.FaqPopoverContent>
                            For information on Violet’s CE credit offering please{' '}
                            <S.FaqLink
                              href={FaqLinks.ceCredit}
                              target="_blank"
                            >
                              visit our FAQ.
                            </S.FaqLink>
                          </S.FaqPopoverContent>
                        }
                      >
                        <S.FaqButton data-cy="faq-link">
                          <S.QuestionMark />
                        </S.FaqButton>
                      </PopoverTrigger>
                    </S.TagLabel>
                    <TagGroup
                      aria-label="ce-credit-type-label"
                      size="small"
                      variant="gray"
                    >
                      {educationalResource.ceCreditTypes.map(ceCreditType => (
                        <Item key={ceCreditType}>{ceCreditType}</Item>
                      ))}
                    </TagGroup>
                  </div>
                )}
            </S.Body>
            <S.Footer>
              {educationalResource.isScormcloud ? (
                <S.Buttons>
                  {(educationalResource.completionStatus === 'rated' ||
                    educationalResource.completionStatus === 'complete') && <S.EducationComplete />}
                  <Button
                    data-educational-resource-title={educationalResource.title}
                    href={`/dashboard/education/${educationalResource.id}/view?redirect_uri=${currentPath}`}
                    size="large"
                    target="_blank"
                    onPress={handleAccessEducationButtonPress}
                  >
                    Access education
                  </Button>
                  {educationalResource.completionStatus === 'incomplete' && (
                    <Button
                      isDisabled
                      data-cy="rate-course-button"
                      size="large"
                      variant="outline"
                    >
                      Rate course
                    </Button>
                  )}
                  {educationalResource.completionStatus === 'complete' && (
                    <Button
                      data-cy="rate-course-button"
                      size="large"
                      variant="outline"
                      onPress={handleRateCourseButtonPress}
                    >
                      Rate course
                    </Button>
                  )}
                </S.Buttons>
              ) : (
                <S.Buttons>
                  {(educationalResource.completionStatus === 'rated' ||
                    educationalResource.completionStatus === 'complete') && <S.EducationComplete />}
                  <Button
                    data-cy="access-course-button"
                    data-educational-resource-title={educationalResource.title}
                    href={`${educationalResource.url}?redirect_uri=${currentPath}`}
                    size="large"
                    target="_blank"
                    onPress={handleAccessEducationButtonPress}
                  >
                    Access education
                  </Button>
                  {educationalResource.completionStatus === 'incomplete' && (
                    <Button
                      data-cy="complete-course-button"
                      isDisabled={!accessedEducationalResources.includes(educationalResource.id)}
                      isLoading={isMarkingComplete}
                      size="large"
                      onPress={handleMarkCompleteButtonPress}
                    >
                      Mark complete
                    </Button>
                  )}
                  {educationalResource.completionStatus === 'complete' && (
                    <Button
                      data-cy="rate-course-button"
                      size="large"
                      variant="outline"
                      onPress={handleRateCourseButtonPress}
                    >
                      Rate course
                    </Button>
                  )}
                </S.Buttons>
              )}
            </S.Footer>
          </S.Dialog>
        </FocusScope>
      </S.CourseDetailsModalDialog>
    </OverlayContainer>
  );
};

export default CourseDetailsModalDialog;
