import AddCircleIcon from '@material-design-icons/svg/round/add_circle.svg?react';
import { produce } from 'immer';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import { OverlayContainer, VisuallyHidden } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import PopoverTrigger from '@/components/PopoverTrigger';
import Spinner from '@/components/Spinner';
import TableNumberCell from '@/components/Table/TableNumberCell';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import useUser from '@/hooks/useUser';
import useGetEducations from '@/pages/Dashboard/hooks/useGetEducations';
import type { Education } from '@/pages/Dashboard/utils';

import AttachCertificateModalDialog from '../../../components/AttachCertificateModalDialog';

import EducationEntryRow from './EducationEntryRow';
import * as S from './styles';
import useEducationsContext, { revalidateEducationRows } from './useEducationsContext';
import WhatEducationToAddPopover from './WhatEducationToAddPopover';

const UserEducationForm = ({ hasAttemptedSubmit }: { hasAttemptedSubmit: boolean }) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const { user } = useUser();
  const isOnboarding = window.location.href.includes('onboarding');

  const { educations, isFetching } = useGetEducations({
    per_page: 1000,
    user_entered: true
  });

  const {
    draftEducations,
    invalidRowIndices,
    setDraftEducations,
    setInvalidRowIndices,
    setOriginalEducations
  } = useEducationsContext();

  const [expandedRowIndex, setExpandedRowIndex] = useState<number>(-1);
  const [educationInModal, setEducationInModal] = useState<Education | null>(null);

  const handleExpandCollapseClick = (index: number) => {
    setExpandedRowIndex(prevState => (prevState === index ? -1 : index));
  };

  const handleAddEducationButtonPress = () => {
    const prevLength = draftEducations.length;
    setDraftEducations(prevState => [
      ...prevState,
      {
        bipocTag: false,
        communities: [],
        completedAt: null,
        courseId: null,
        courseName: '',
        createdAt: '',
        credits: NaN,
        id: nanoid(),
        image: null,
        lgbqTag: false,
        organization: '',
        tgncTag: false,
        updatedAt: '',
        year: NaN
      } as Education
    ]);
    setExpandedRowIndex(prevLength === 0 ? 0 : prevLength);
  };

  const removeEducation = (index: number) => {
    setDraftEducations(prevState => prevState.filter((_, i) => i !== index));
    if (expandedRowIndex === index) {
      setExpandedRowIndex(-1);
    }
  };

  const updateEducation = (index: number, updatedEducation: Education) => {
    setDraftEducations(prevState =>
      prevState.map((educations, i) => (i === index ? updatedEducation : educations))
    );
  };

  const revalidateRows = useCallback(() => {
    const invalidRows = revalidateEducationRows(draftEducations);
    setInvalidRowIndices(invalidRows);
  }, [draftEducations, setInvalidRowIndices]);

  useEffect(() => {
    setDraftEducations(
      educations && educations.length > 0
        ? educations
        : isOnboarding
          ? [
              {
                bipocTag: false,
                communities: [],
                completedAt: null,
                courseId: null,
                courseName: '',
                createdAt: '',
                credits: NaN,
                id: nanoid(),
                image: null,
                lgbqTag: false,
                organization: '',
                tgncTag: false,
                updatedAt: '',
                year: NaN
              } as Education
            ]
          : []
    );
    setOriginalEducations(educations ?? []);
  }, [educations, setDraftEducations, isOnboarding, setOriginalEducations]);

  useEffect(() => {
    revalidateRows();
  }, [draftEducations, revalidateRows]);

  const handleCourseAttachmentUpload =
    (education: Education | null) => (attachment: File | null, attachmentUrl: string) => {
      if (education === null) {
        return;
      }
      const index = draftEducations.findIndex(e => e.id === education.id);
      setDraftEducations(
        produce(draftEducations, draft => {
          draft[index].image =
            attachment === null
              ? null
              : attachment instanceof File
                ? attachment
                : { url: attachmentUrl };
        })
      );
    };

  const {
    close: closeAttachCertificateModalDialog,
    isOpen: attachCertificateDialogIsOpen,
    open: openAttachCertificateModalDialog
  } = useOverlayTriggerState({});

  const handleCourseAttachmentButtonPress = () => {
    openAttachCertificateModalDialog();
  };

  const handleCloseAttachCertificateModalDialog = () => {
    closeAttachCertificateModalDialog();
  };

  if (isFetching) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <>
      <S.EditableEducationTable
        aria-label="Education experience table"
        emptyMessage="You have not added any education history."
        expandedRowIndex={expandedRowIndex}
        hasAttemptedSubmit={hasAttemptedSubmit}
        invalidRowIndices={invalidRowIndices}
      >
        <thead>
          <tr>
            <th>
              {isInMobileBreakpointRange && <VisuallyHidden>EXPAND / COLLAPSE</VisuallyHidden>}
            </th>
            <th key="name">NAME</th>
            <th>ORGANIZATION</th>
            <th>
              <TableNumberCell alignRight>YEAR</TableNumberCell>
            </th>
            <th>
              <TableNumberCell alignRight>CREDITS/HOURS</TableNumberCell>
            </th>
            <th>
              <PopoverTrigger
                content={
                  <S.PopoverContent>
                    Please indicate which communities were the primary focus in your coursework or
                    training. If your education did not focus on BIPOC, LGBQ or TGNC communities,
                    you do not need to include it.
                  </S.PopoverContent>
                }
              >
                <S.PopoverButton>
                  COMMUNITIES OF FOCUS
                  <S.HelpIcon />
                </S.PopoverButton>
              </PopoverTrigger>
            </th>
            <th>
              <S.CenterText>ATTACHMENT</S.CenterText>
            </th>
            <th>
              <VisuallyHidden>DELETE</VisuallyHidden>
            </th>
            <th>
              {isInMobileBreakpointRange && <VisuallyHidden>EXPANDED CONTENT</VisuallyHidden>}
            </th>
          </tr>
        </thead>
        <tbody>
          {draftEducations.length > 0
            ? draftEducations.map((education, index) => (
                <EducationEntryRow
                  key={index}
                  data-cy="user-education-row"
                  education={education}
                  handleExpandCollapseClick={handleExpandCollapseClick}
                  index={index}
                  isExpanded={expandedRowIndex === index}
                  isInvalid={hasAttemptedSubmit && invalidRowIndices.includes(index)}
                  openCertificateModalDialog={course => {
                    setEducationInModal(course);
                    handleCourseAttachmentButtonPress();
                  }}
                  updateEducation={updateEducation}
                  onRemove={removeEducation}
                />
              ))
            : null}
        </tbody>
      </S.EditableEducationTable>
      {attachCertificateDialogIsOpen && (
        <OverlayContainer>
          <AttachCertificateModalDialog
            close={handleCloseAttachCertificateModalDialog}
            course={educationInModal}
            onAttachmentUpload={handleCourseAttachmentUpload(educationInModal)}
          />
        </OverlayContainer>
      )}
      {user.is_clinical ? (
        <S.ClinicalActions>
          <S.AddEducationButton
            data-cy="add-education-button"
            trailingIcon={AddCircleIcon}
            variant="plain"
            onPress={handleAddEducationButtonPress}
          >
            Add education
          </S.AddEducationButton>
          {!isOnboarding && <WhatEducationToAddPopover />}
        </S.ClinicalActions>
      ) : (
        <S.Spacer />
      )}
    </>
  );
};

export default UserEducationForm;
