import BackNext from '@/components/Buttons/BackNext';
import OnboardingLayout from '../components/OnboardingLayout';

import * as S from './styles';
import UserEducationForm from '@/pages/components/UserEducationForm';
import type { FormEventHandler } from 'react';
import { useMemo, useRef, useState } from 'react';
import useGetEducations from '@/pages/Dashboard/hooks/useGetEducations';
import { useNavigate } from 'react-router-dom';
import type { Education } from '@/pages/Dashboard/utils';
import {
  EducationsContext,
  revalidateEducationRows
} from '@/pages/components/UserEducationForm/useEducationsContext';

const EducationExperience = () => {
  const { createEducation, deleteEducation, patchEducation } = useGetEducations({}, true);

  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [invalidRowIndices, setInvalidRowIndices] = useState<number[]>([]);
  const [originalEducations, setOriginalEducations] = useState<Education[]>([]);
  const [draftEducations, setDraftEducations] = useState<Education[]>([]);

  const handleFormSubmit: FormEventHandler = event => {
    event.preventDefault();
    const invalidRowIndices = revalidateEducationRows(draftEducations);
    setInvalidRowIndices(invalidRowIndices);

    setHasAttemptedSubmit(true);

    if (invalidRowIndices.length > 0) {
      /* Don't attempt submission if any rows are invalid */
      if (!formRef.current) {
        window.scrollTo(0, 0);
      } else {
        formRef.current.reportValidity();
      }
      return;
    }

    if (formRef.current?.checkValidity() === false) {
      formRef.current.reportValidity();
      return;
    }

    /* Thou may proceed to handle submission */
    setFormIsSubmitting(true);

    let encounteredError = 0;

    /* Save all education entries */
    draftEducations.forEach(async (educationDraft, index) => {
      if (educationDraft.createdAt === '' || educationDraft.createdAt === null) {
        /* No createdAt === create */
        try {
          const newEducation = await createEducation(educationDraft);
          if (newEducation) {
            setDraftEducations(prevState => {
              const newState = [...prevState];
              newState[index] = newEducation;
              return newState;
            });
            setOriginalEducations(prevState => [...prevState, newEducation]);
          }
        } catch (_error) {
          encounteredError++;
        }
      } else if (originalEducations.find(e => e.id === educationDraft.id)) {
        /* Id exists in educations === patch */
        try {
          const changedEducation = await patchEducation(educationDraft);
          if (changedEducation) {
            setDraftEducations(prevState => {
              const newState = [...prevState];
              newState[index] = changedEducation;
              return newState;
            });
            setOriginalEducations(prevState => {
              const newState = [...prevState];
              const originalIndex = prevState.findIndex(e => e.id === changedEducation.id);
              newState[originalIndex] = changedEducation;
              return newState;
            });
          }
        } catch (_error) {
          encounteredError++;
        }
      }
    });

    originalEducations.forEach(async educationDraft => {
      if (!draftEducations.find(e => e.id === educationDraft.id)) {
        /* Id no longer in draftEducations === delete */
        try {
          await deleteEducation(educationDraft.id);
          setOriginalEducations(prevState => prevState.filter(e => e.id !== educationDraft.id));
        } catch (_error) {
          encounteredError++;
        }
      }
    });

    if (encounteredError > 0) {
      setFormIsSubmitting(false);
      return;
    }

    navigate('/onboarding/work-experience');

    setFormIsSubmitting(false);
    setHasAttemptedSubmit(false);
  };

  const memoizedEducationContextValue = useMemo(
    () => ({
      draftEducations,
      invalidRowIndices,
      originalEducations,
      setDraftEducations,
      setInvalidRowIndices,
      setOriginalEducations
    }),
    [originalEducations, draftEducations, invalidRowIndices]
  );

  return (
    <OnboardingLayout progressBarValue={3}>
      <EducationsContext.Provider value={memoizedEducationContextValue}>
        <form
          ref={formRef}
          noValidate
          onSubmit={handleFormSubmit}
        >
          <S.OnboardingHero
            copy={
              <>
                To provide you with the most accurate Benchmarks we'll need to learn more about any
                completed educational training specifically related to BIPOC, LGBQ, and TGNC
                communities. If you don't have any relevant education, please{' '}
                <S.SkipLink to="/onboarding/work-experience">skip this step</S.SkipLink>
                .
                <br />
                <S.WhatToAddPopover />
              </>
            }
            graphicType="education"
            header="Education history."
          />
          <UserEducationForm hasAttemptedSubmit={hasAttemptedSubmit} />
          <BackNext
            includeSkip
            backTo="/onboarding/cultural-competencies#TGNC"
            nextIsLoading={formIsSubmitting}
            nextLabel="Next: Work experience"
            skipTo="/onboarding/work-experience"
          />
        </form>
      </EducationsContext.Provider>
    </OnboardingLayout>
  );
};

export default EducationExperience;
