import WarningIcon from '@material-symbols/svg-400/outlined/warning.svg?react';
import { OverlayContainer } from 'react-aria';
import * as Sentry from '@sentry/react';
import type { ToastState } from '@react-stately/toast';
import type { VioletToast } from '@/components/ToastProvider';
import { useEffect, useState } from 'react';
import TabNavigation from '@/components/TabNavigation';
import CopyBenchmarkCode from './Views/CopyBenchmarkCode';
import LinkedInInstructions from './Views/LinkedInInstructions';
import BenchmarkCard from './Views/BenchmarkCard';
import WebSelectionButtons from './Views/WebSelectionButtons';
import Button from '@/components/Buttons/Button';
import useUser from '@/hooks/useUser';
import * as S from './styles';
import Spinner from '@/components/Spinner';
import { Link } from 'react-router-dom';
import useGetFaqLinks from '@/utils/getFaqLinks';
type TabOptions = 'linkedin' | 'email' | 'html' | 'success';

export interface Benchmark {
  created_at: string;
  earned_at: string;
  image: { url: string };
  is_active: boolean;
  score_level: string;
  score_type: string;
  total_score: number;
  user_info_id: string;
}

interface Props {
  close: () => void;
  source: 'announcement' | 'dashboard';
  toastState?: ToastState<VioletToast>;
}

const SHARE_TEXT = {
  linkedin:
    'Select the Benchmark you’d like to share to your network. Your Verified Inclusive Provider badge will be added as a certificate to the bottom of your LinkedIn profile. Keep in mind you can only share one at a time.',
  html: "Select the Benchmarks you’d like to showcase. We'll generate an HTML embed code, making it easy to add a Verified Inclusive Provider badge to your personal website.",
  email: "Select the Benchmark(s) you'd like to share.",
  success: 'Success! Your personalized HTML embed code has been copied.'
};

const ShareBenchmarksModalDialog = ({ close, source, toastState }: Props) => {
  const { bearerToken } = useUser();
  const [benchmarks, setBenchmarks] = useState<Benchmark[]>([]);
  const [hasShared, setHasShared] = useState(false);
  const [selectedBenchmarks, setSelectedBenchmarks] = useState<Benchmark[]>([]);
  const [activeTab, setActiveTab] = useState<TabOptions>('linkedin');
  const [isLoading, setIsLoading] = useState(true);
  const FaqLinks = useGetFaqLinks();

  useEffect(() => {
    const fetchLatestBenchmarks = async () => {
      try {
        const url = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard`;

        const options = {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        };

        const response = await fetch(url, options);
        const data = (await response.json()) as { data: Benchmark[] };
        const benchmarks = data.data ?? [];
        const activeBenchmarksWithoutPlaceholders = benchmarks
          .filter(b => b.score_level.toLowerCase() !== 'placeholder' && b.is_active)
          .sort((a, b) => {
            // Sort by community name alphabetically (ie BIPOC, LGBQ, TGNC, etc.)
            const communityA = a.score_type.toLowerCase();
            const communityB = b.score_type.toLowerCase();
            if (communityA === communityB) {
              return 0;
            }
            return communityA < communityB ? -1 : 1;
          });
        setBenchmarks(activeBenchmarksWithoutPlaceholders);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestBenchmarks();
  }, [bearerToken]);

  const checkBenchmarkChange = (benchmark: Benchmark) => {
    if (activeTab === 'linkedin') {
      if (selectedBenchmarks.find(b => b === benchmark)) {
        setSelectedBenchmarks([]);
      } else {
        setSelectedBenchmarks([benchmark]);
      }
    } else {
      setSelectedBenchmarks(prev => {
        const isSelected = prev.some(
          b => b.score_type === benchmark.score_type && b.score_level === benchmark.score_level
        );
        if (isSelected) {
          return prev.filter(
            b => !(b.score_type === benchmark.score_type && b.score_level === benchmark.score_level)
          );
        }
        return [...prev, benchmark];
      });
    }
  };

  const handleBenchmarkClick = (benchmark: Benchmark) => {
    checkBenchmarkChange(benchmark);
  };

  const handleDoneSharing = () => {
    setActiveTab('success');
  };

  const changeTab = (tab: TabOptions) => {
    setActiveTab(tab);
    setSelectedBenchmarks([]);
  };

  return (
    <OverlayContainer>
      <S.ShareBenchmarksModal
        isDismissable
        isOpen
        data-cy="share-benchmarks-modal-dialog"
        title={'Share Your Benchmarks.'}
        onClose={close}
      >
        {!hasShared && activeTab !== 'success' && (
          <S.SelectionSection>
            <TabNavigation
              activeTab={activeTab}
              tabs={[
                {
                  label: 'LinkedIn',
                  value: 'linkedin',
                  onPress: () => changeTab('linkedin')
                },
                {
                  label: 'HTML',
                  value: 'html',
                  onPress: () => changeTab('html')
                }
              ]}
            />
            <S.Text>{SHARE_TEXT[activeTab]}</S.Text>
            {isLoading ? (
              <Spinner withWrapper />
            ) : benchmarks.length > 0 ? (
              <S.BenchmarkGroup>
                {benchmarks.map(benchmark => (
                  <BenchmarkCard
                    key={`${benchmark.score_type}-${benchmark.score_level}`}
                    activeTab={activeTab}
                    benchmark={benchmark}
                    checkBenchmarkChange={checkBenchmarkChange}
                    handleBenchmarkClick={handleBenchmarkClick}
                    selectedBenchmarks={selectedBenchmarks}
                  />
                ))}
              </S.BenchmarkGroup>
            ) : (
              <S.NoBenchmarksText data-cy="no-benchmarks-text">
                <WarningIcon aria-hidden="true" />
                <div>
                  <strong>Oops!</strong> You don't yet have any benchmarks eligible for sharing.
                </div>
              </S.NoBenchmarksText>
            )}
          </S.SelectionSection>
        )}
        {activeTab === 'success' && (
          <>
            <S.Text>{SHARE_TEXT[activeTab]}</S.Text>
            <S.Text>
              As a reminder, check out{' '}
              <Link
                rel="noopener noreferrer"
                target="_blank"
                to={FaqLinks.sharingBenchmarks}
              >
                these instructions
              </Link>{' '}
              for adding custom HTML to your website.
            </S.Text>
            <S.ButtonGroup>
              <Button
                fullWidth
                data-cy="close-button"
                size="large"
                variant="outline"
                onPress={() => setActiveTab('html')}
              >
                Back
              </Button>
            </S.ButtonGroup>
          </>
        )}
        {activeTab === 'linkedin' && !hasShared && (
          <S.ButtonGroup>
            <Button
              data-cy="close-button"
              size="large"
              variant="outline"
              onPress={close}
            >
              Close
            </Button>
            <Button
              data-cy="share-benchmark-button"
              isDisabled={selectedBenchmarks.length === 0}
              size="large"
              onPress={() => setHasShared(true)}
            >
              Share benchmark
            </Button>
          </S.ButtonGroup>
        )}
        {activeTab === 'html' && !hasShared && (
          <WebSelectionButtons
            isDisabled={selectedBenchmarks.length === 0}
            selectedBenchmarks={selectedBenchmarks}
            setHasShared={setHasShared}
            source={source}
          />
        )}
        {activeTab === 'html' && hasShared && (
          <CopyBenchmarkCode
            handleDoneSharing={handleDoneSharing}
            selectedBenchmarks={selectedBenchmarks}
            setHasShared={setHasShared}
            toastState={toastState}
          />
        )}
        {activeTab === 'linkedin' && hasShared && (
          <LinkedInInstructions
            benchmark={selectedBenchmarks.length > 0 ? selectedBenchmarks[0] : undefined}
            setHasShared={setHasShared}
            source={source}
          />
        )}
      </S.ShareBenchmarksModal>
    </OverlayContainer>
  );
};

export default ShareBenchmarksModalDialog;
