import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 0.5rem;
`;

export const TableDescription = styled.p`
  color: var(--grey-800);
  ${TEXT_STYLES.bodyCopyRegular}
  margin: 0;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0.75rem 0.5rem;
`;

export const TableTitle = styled.h2`
  color: var(--purple-400);
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  margin: 0;
`;

export const TableWrapper = styled.div`
  border: 1px solid var(--gray-200);
  background-color: #fff;
  border-radius: 1rem;
  padding: 0 0.625rem 0.125rem;
  overflow-x: auto;
  width: 100%;

  ${MOBILE_BREAKPOINT_RANGE} {
    overflow-x: auto;
    width: 100%;
  }
`;
