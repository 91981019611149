import ComparisonDataTooltipContent from '@/components/ComparisonDataTooltipContent';
import ComparisonMeters from '@/components/Reporting/ComparisonMeters';
import type {
  NetworkOverview,
  ServiceAreaComparison,
  ServiceAreaComparisonMeta
} from '@/pages/Dashboard/utils';
import { VIOLET_COMMUNITIES, VIOLET_COMMUNITY_DEFINITIONS } from '@/utils';

import * as S from './styles';

interface Props {
  networkOverview: NetworkOverview;
  serviceAreaComparisons: ServiceAreaComparison[];
  serviceAreaComparisonsMeta: ServiceAreaComparisonMeta;
  size?: 'small' | 'large';
}

const CommunityInclusivityComparisons = ({
  networkOverview,
  serviceAreaComparisons,
  serviceAreaComparisonsMeta,
  size = 'small'
}: Props) => {
  const allLocations = Array.from(
    new Set(serviceAreaComparisons.map(comparison => comparison.locationName))
  ).join(', ');

  return (
    <S.ComparisonMetersWrapper>
      {VIOLET_COMMUNITIES.map((community, index) => (
        <ComparisonMeters
          key={`${community}-comparison-meter`}
          comparisonKey={
            allLocations.length > 0 ? `All providers - ${allLocations}` : 'All providers'
          }
          comparisonPopoverContent={
            <ComparisonDataTooltipContent
              community={community}
              serviceAreaComparisons={serviceAreaComparisons}
              serviceAreaComparisonTotals={{
                totalInclusivePercentage:
                  serviceAreaComparisonsMeta.totalInclusivePercentage[community],
                totalInclusiveProviders:
                  serviceAreaComparisonsMeta.totalInclusiveProviders[community]
              }}
              totalNetworkInclusivePercentage={
                networkOverview.networkInclusivityPercentages[community]
              }
              totalNetworkInclusiveProviders={networkOverview.networkInclusivityTotals[community]}
            />
          }
          comparisonValue={serviceAreaComparisonsMeta.totalInclusivePercentage[community]}
          isLast={index === VIOLET_COMMUNITIES.length - 1}
          isUnavailable={
            serviceAreaComparisons.find(
              comparison => comparison.community.toUpperCase() === community.toUpperCase()
            ) === undefined
          }
          label={`${community.toUpperCase()} inclusive`}
          labelPopoverText={VIOLET_COMMUNITY_DEFINITIONS[community]}
          showKey={index === VIOLET_COMMUNITIES.length - 1}
          size={size}
          value={networkOverview.networkInclusivityPercentages[community]}
          valueKey="Network providers"
        />
      ))}
    </S.ComparisonMetersWrapper>
  );
};

export default CommunityInclusivityComparisons;
