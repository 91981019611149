import TextField from '@/components/FormFields/TextField';
import styled from 'styled-components';

import {
  DESKTOP_BREAKPOINT_RANGE,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../../constants';

export const EmailField = styled(TextField)`
  input {
    color: var(--gray-500);
    background-color: var(--gray-50);
    cursor: not-allowed;
  }
`;

export const FormHeading = styled.h2`
  color: var(--gray-800);
  ${TEXT_STYLES.headlineSectionLevel2Regular}
`;

export const FormRow = styled.div`
  display: grid;
  margin-bottom: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    row-gap: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    column-gap: var(--space-4);
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
`;

export const FormWrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  padding: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: 2rem;
  }
`;

export const Label = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
  margin-bottom: 0.75rem;
`;

export const Link = styled.a`
  color: var(--purple-400);
  text-decoration: underline;

  &:hover,
  &:focus {
    color: var(--purple-500);
  }
`;
