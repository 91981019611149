import { useObjectRef } from '@react-aria/utils';
import { forwardRef, useRef, useState } from 'react';
import type { AriaListBoxProps } from 'react-aria';
import { VisuallyHidden } from 'react-aria';
import type { Key } from 'react-stately';
import { Item, useOverlayTriggerState } from 'react-stately';
import type { Selection } from 'react-stately';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import useSize from '@/hooks/useSize';
import isNonEmptyString from '@/utils/isNonEmptyString';

import TextField from '../TextField';

import * as S from './styles';

interface Props extends AriaListBoxProps<object> {
  className?: string;
  customLabel?: JSX.Element;
  'data-cy'?: string;
  expandBeyondInput?: boolean;
  maxWidth?: string;
  placeholder?: string;
}

const MultiSelect = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      customLabel,
      'data-cy': dataCy,
      expandBeyondInput = false,
      label,
      maxWidth,
      onSelectionChange,
      placeholder,
      selectedKeys,
      ...props
    }: Props,
    forwardedRef
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useObjectRef(forwardedRef);
    const popoverRef = useRef<HTMLDivElement>(null);
    const size = useSize(ref);
    const { isInDesktopBreakpointRange } = useBreakpointRange();

    const [searchText, setSearchText] = useState('');

    const state = useOverlayTriggerState({});

    const updateSelection = (keys: Selection) => {
      setSearchText('');

      if (onSelectionChange === undefined) return;
      onSelectionChange(keys);
    };

    const removeItem = (keys: Set<Key>) => {
      if (selectedKeys !== undefined && selectedKeys !== 'all') {
        const selectedKeysSet = new Set(selectedKeys);
        const newSelectedKeys = new Set([...selectedKeysSet].filter(key => !keys.has(key)));
        updateSelection(newSelectedKeys as Selection);
      } else {
        updateSelection(new Set() as Selection);
      }
      setSearchText('');
      state.close();
    };

    const filterOptions = (value: string) => {
      setSearchText(value);
      state.open();
    };

    return (
      <S.MultiSelect
        ref={ref}
        className={className}
        data-cy={dataCy}
      >
        {customLabel && label === undefined && customLabel}
        {isNonEmptyString(label) && customLabel === undefined ? (
          <S.Label>{label}</S.Label>
        ) : (
          <VisuallyHidden aria-label="Select option(s)">Select option(s)</VisuallyHidden>
        )}
        <S.SearchWrapper>
          <TextField
            ref={inputRef}
            aria-label={isNonEmptyString(label) ? String(label) : 'Select option(s)'}
            data-cy="multi-select-search"
            placeholder={placeholder ?? 'Search option(s)'}
            value={searchText}
            onChange={filterOptions}
            onKeyDown={state.open}
          />
          <S.OpenCloseButton
            aria-label={`${state.isOpen ? 'Close options' : 'Open options'}`}
            onPress={() => state.toggle()}
          >
            <S.Icon
              aria-hidden="true"
              className={state.isOpen ? 'is-closed' : 'is-open'}
              role="img"
            />
          </S.OpenCloseButton>
        </S.SearchWrapper>
        {state.isOpen && (
          <S.Popover
            $width={expandBeyondInput && isInDesktopBreakpointRange ? undefined : size.width}
            placement="bottom start"
            popoverRef={popoverRef}
            state={state}
            triggerRef={inputRef}
          >
            <S.OptionsDropdown
              {...props}
              $maxWidth={maxWidth}
              filterText={searchText}
              label={label}
              selectedKeys={selectedKeys}
              // eslint-disable-next-line react/jsx-sort-props
              onSelectionChange={(keys: Selection) => updateSelection(keys)}
              popoverState={state}
            />
          </S.Popover>
        )}
        {selectedKeys !== undefined && selectedKeys !== 'all' && (
          <S.SelectedTags
            aria-label={`Selected ${label}`}
            data-cy="selected-tags"
            variant="light"
            onRemove={removeItem}
          >
            {[...selectedKeys].map(key => (
              <Item
                key={key}
                textValue={String(key)}
              >
                {key}
              </Item>
            ))}
          </S.SelectedTags>
        )}
      </S.MultiSelect>
    );
  }
);

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;
