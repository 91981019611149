import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ScreenSizeWarning from '@/components/ScreenSizeWarning';
import SecondaryNavigation from '@/components/SecondaryNavigation';
import ToastProvider from '@/components/ToastProvider';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import useUser from '@/hooks/useUser';
import { STATES } from '@/pages/constants';

import useGetNetworks from '../../hooks/useGetNetworks';
import type { ServiceAreaLocationFilter } from '../../hooks/useGetNetworkServiceAreaComparisons';

import * as S from './styles';
import { NetworksContext } from './useNetworks';

const INCLUSIVITY_PARAMS =
  'order_by_providers%5Bcolumn%5D=full_name&order_by_providers%5Bdir%5D=asc&order_by_organizations%5Bcolumn%5D=on_violet&order_by_organizations%5Bdir%5D=desc';

const NETWORK_INCLUSIVITY_BASE_URL = '/dashboard/my-network/inclusivity';

export const NETWORK_INCLUSIVITY_URL_W_PARAMS = `${NETWORK_INCLUSIVITY_BASE_URL}?${INCLUSIVITY_PARAMS}`;

const MyNetworkWrapper = () => {
  const { user } = useUser();
  const { networks } = useGetNetworks();
  const [defaultComparisonStates, setDefaultComparisonStates] = useState<
    ServiceAreaLocationFilter[]
  >([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user.member_roles.includes('network_manager')) {
      /* user does not have permission to access network data */
      navigate('/dashboard', { replace: true });
    }
  }, [user.member_roles, navigate]);

  useEffect(() => {
    if (networks.length > 0) {
      const allStates = networks.flatMap(network => network.defaultComparisonStates);
      const uniqueStates = [...new Set(allStates)];
      const uniqueStatesForFilter = uniqueStates.map(state => ({
        location_name: STATES.find(s => s.abbreviation === state)?.name ?? state,
        location_type: 'state' as const,
        state_code: state
      }));
      setDefaultComparisonStates(uniqueStatesForFilter);
    } else {
      setDefaultComparisonStates([]);
    }
  }, [networks]);

  const { isInMobileBreakpointRange } = useBreakpointRange();

  if (isInMobileBreakpointRange) {
    return <ScreenSizeWarning />;
  }

  return (
    <NetworksContext.Provider value={{ defaultComparisonStates, networks }}>
      <ToastProvider placement="top">
        {toastState => (
          <>
            <SecondaryNavigation
              linkList={[
                { exact: true, link: '/dashboard/my-network', title: 'Overview' },
                {
                  children: [
                    {
                      exact: true,
                      link: `?${INCLUSIVITY_PARAMS}`,
                      title: 'Overview'
                    },
                    {
                      link: `/service-areas?order_by%5Bcolumn%5D=provider_count&order_by%5Bdir%5D=desc&page=1&view=county`,
                      title: 'Service areas'
                    },
                    {
                      link: `/diversity`,
                      title: 'Diversity'
                    }
                  ],
                  link: NETWORK_INCLUSIVITY_URL_W_PARAMS,
                  parentLink: NETWORK_INCLUSIVITY_BASE_URL,
                  title: 'Inclusivity'
                },
                {
                  link: `/dashboard/my-network/education-engagement?order_by%5Bcolumn%5D=user_name&order_by%5Bdir%5D=asc&resource_type=course_collection`,
                  title: 'Education engagement'
                }
              ]}
              navTitle="My network"
            />
            <S.Main data-cy="network-page">
              <Outlet context={toastState} />
            </S.Main>
          </>
        )}
      </ToastProvider>
    </NetworksContext.Provider>
  );
};

export default MyNetworkWrapper;
