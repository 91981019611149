import type { ComponentPropsWithRef, ReactElement } from 'react';
import { cloneElement, useRef } from 'react';
import type { Placement, TooltipTriggerProps } from 'react-aria';
import { Overlay, useTooltipTrigger } from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';
import type UnstyledButton from '@/components/UnstyledButton';

import Tooltip from './Tooltip';

interface Props extends TooltipTriggerProps {
  children: ReactElement<ComponentPropsWithRef<typeof UnstyledButton>>;
  className?: string;
  content: ReactElement | string;
  hideArrow?: boolean;
  placement?: Placement;
  theme?: 'dark' | 'light';
}

const TooltipTrigger = ({
  children,
  className,
  content,
  hideArrow = false,
  placement = 'top',
  theme = 'light',
  ...tooltipTriggerProps
}: Props) => {
  const ref = useRef<HTMLAnchorElement | HTMLButtonElement>(null);

  const state = useTooltipTriggerState(tooltipTriggerProps);

  const { tooltipProps, triggerProps } = useTooltipTrigger(tooltipTriggerProps, state, ref);

  return (
    <div className={className}>
      {cloneElement(children, { ref, tooltipTriggerProps: triggerProps })}
      {state.isOpen && (
        <Overlay>
          <Tooltip
            content={content}
            hideArrow={hideArrow}
            placement={placement}
            state={state}
            targetRef={ref}
            theme={theme}
            {...tooltipProps}
          />
        </Overlay>
      )}
    </div>
  );
};

export default TooltipTrigger;
