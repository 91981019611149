import * as Sentry from '@sentry/react';
import PageTitle from '@/components/PageTitle';
import type { SortDescriptor } from 'react-stately';
import ExportIcon from '@material-design-icons/svg/round/download.svg?react';
import { Cell, Column, Item, TableHeader } from 'react-stately';
import { Row } from 'react-stately';
import Table from '@/components/Table';
import Pagination from '@/components/Pagination';
import { OnboardingStatus } from '../../utils';
import { TableBody } from 'react-stately';
import EmptyTableState from '@/components/Table/EmptyState';
import SearchField from '@/components/FormFields/SearchField';
import Button from '@/components/Buttons/Button';
import Spinner from '@/components/Spinner';
import type { OrganizationUser } from '../../utils';
import Badge from '@/components/Badge';
import type { Key } from 'react';
import { useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import { useSearchParams } from 'react-router-dom';
import type { AriaSearchFieldProps } from 'react-aria';
import useGetOrganizationUsers from '../../hooks/useGetOrganizationUsers';
import type { AriaSelectProps } from 'react-aria';
import { downloadCsv } from '@/utils/downloadCsv';
import useUser from '@/hooks/useUser';
import { filtersToParams } from '../../hooks/useApiRequest';
import isNonEmptyString from '@/utils/isNonEmptyString';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import useOpenErrorModalDialog from '@/hooks/useOpenErrorModalDialog';

import * as S from './styles';
import { organizationFiltersToSearch, organizationSearchToFilters } from '../organizationFilters';
import { sortFilterParams } from '@/utils/filterUtils';
import { filtersObjectToParams } from '@/utils/filterUtils';
import FilterDropdown from '@/components/FilterDropdown';

enum SendEmailReminderButtonState {
  Hidden,
  Sending,
  Sent,
  Unsent
}

const Team = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { bearerToken, user } = useUser();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
  );
  const [isExporting, setIsExporting] = useState(false);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'full_name',
    direction: 'ascending'
  });

  const organizationId = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization_id;
  const organizationName = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization.name;

  const draftState = useRef<{
    clinical: 'true' | 'false' | 'all';
    full_name: string;
    member_role: 'member' | 'superuser' | 'all';
    status: 'active' | 'onboarding' | 'invited' | 'all';
  }>({
    full_name: searchParams.get('full_name') ?? '',
    clinical:
      searchParams.get('clinical') !== null
        ? (searchParams.get('clinical') as 'true' | 'false')
        : 'all',
    member_role:
      searchParams.get('member_role') !== null
        ? (searchParams.get('member_role') as 'member' | 'superuser')
        : 'all',
    status:
      searchParams.get('status') !== null
        ? (searchParams.get('status') as 'active' | 'onboarding' | 'invited')
        : 'all'
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
        .flatMap(([key, value]) =>
          value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
        )
    )
  );

  const {
    isFetchingOrgUsers,
    totalUserPages,
    updateOrganizationFilters,
    users: organizationUsers
  } = useGetOrganizationUsers(
    {
      full_name: searchParams.get('full_name') ?? '',
      clinical:
        searchParams.get('clinical') !== null ? Boolean(searchParams.get('clinical')) : undefined,
      member_role:
        searchParams.get('member_role') !== null
          ? (searchParams.get('member_role') as 'member' | 'superuser')
          : undefined,
      status:
        searchParams.get('status') === 'active'
          ? 'activated'
          : searchParams.get('status') === 'onboarding' || searchParams.get('status') === 'invited'
            ? 'created'
            : undefined,
      completed_onboarding:
        searchParams.get('status') === 'active'
          ? true
          : searchParams.get('status') === 'onboarding'
            ? false
            : undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'full_name',
        dir: (searchParams.get('order_by[dir]') ?? 'asc') as 'asc' | 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1')
    },
    organizationId
  );
  const [users, setUsers] = useState<OrganizationUser[]>([]);

  useEffect(() => {
    setUsers(organizationUsers);
  }, [organizationUsers]);

  const handleProvidersExportButtonPress = async () => {
    if (organizationId === undefined) {
      return;
    }

    setIsExporting(true);

    const exportFilters = {
      full_name: searchParams.get('full_name') ?? '',
      clinical:
        searchParams.get('clinical') !== null ? Boolean(searchParams.get('clinical')) : undefined,
      member_role:
        searchParams.get('member_role') !== null
          ? (searchParams.get('member_role') as 'member' | 'superuser')
          : undefined,
      status:
        searchParams.get('status') === 'active'
          ? 'activated'
          : searchParams.get('status') === 'onboarding' || searchParams.get('status') === 'invited'
            ? 'created'
            : undefined,
      completed_onboarding:
        searchParams.get('status') === 'active'
          ? true
          : searchParams.get('status') === 'onboarding'
            ? false
            : undefined
    };
    const params = filtersToParams(exportFilters);

    const usersExportUrl: RequestInfo = `${
      import.meta.env.VITE_API_V2_BASE_PATH
    }/organizations/${organizationId}/users/export${isNonEmptyString(params) ? `?${params}` : ''}`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };

    try {
      const response = await fetch(usersExportUrl, options);

      let isSignedOut = false;

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          isSignedOut = true;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      if (isSignedOut) return;

      const date = new Date();
      const formattedDate = date
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        })
        .replace(/\//g, '-');
      await downloadCsv(
        response,
        `Violet_Users_${organizationName?.replaceAll(' ', '')}_${formattedDate}.csv`
      );

      setIsExporting(false);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
      setIsExporting(false);
    }
  };

  const handleSendEmailReminderButtonPress = (membershipId?: string) => async () => {
    if (membershipId === undefined) {
      return;
    }

    setUsers(
      produce(organizationUsers, draft => {
        draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
          SendEmailReminderButtonState.Sending;
      })
    );

    const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard/organizations/send_invite/${membershipId}`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      },
      method: 'POST'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      setUsers(
        produce(organizationUsers, draft => {
          draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
            SendEmailReminderButtonState.Sent;
        })
      );
    } catch (error) {
      setUsers(
        produce(organizationUsers, draft => {
          draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
            SendEmailReminderButtonState.Unsent;
        })
      );

      Sentry.captureException(error);
      openErrorModalDialog();
    }
  };

  const handleSearchByNameFieldChange: AriaSearchFieldProps['onChange'] = value => {
    draftState.current.full_name = value;
  };

  const handleUserTypeSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    draftState.current.clinical = key as 'true' | 'false' | 'all';
  };

  const handleUserRoleSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    draftState.current.member_role = key as 'member' | 'superuser' | 'all';
  };

  const handleUserStatusSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    draftState.current.status = key as 'active' | 'onboarding' | 'invited' | 'all';
  };

  const preserveChanges = () => {
    const updatedFilters = [
      `Provider:${draftState.current.full_name}`,
      `User role:${draftState.current.member_role}`,
      `User type:${draftState.current.clinical}`,
      `User status:${draftState.current.status}`
    ].filter(
      entry =>
        !entry.includes('null') &&
        !entry.includes('undefined') &&
        !entry.includes('all') &&
        isNonEmptyString(entry.split(':')[1])
    );
    setCurrentPage(1);
    setAppliedFilters(new Set(updatedFilters));
    refreshURLparams(new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    draftState.current = {
      full_name: '',
      clinical: 'all',
      member_role: 'all',
      status: 'all'
    };
    setAppliedFilters(new Set());
    refreshURLparams(new Set());
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('Provider:')) {
        draftState.current.full_name = '';
      } else if (keyString.includes('User type:')) {
        draftState.current.clinical = 'all';
      } else if (keyString.includes('User role:')) {
        draftState.current.member_role = 'all';
      } else if (keyString.includes('User status:')) {
        draftState.current.status = 'all';
      }
    });
    setCurrentPage(1);
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(updatedFilters);
  };

  const refreshURLparams = (updatedFilters: Set<Key>) => {
    const finalParams = filtersObjectToParams(organizationFiltersToSearch, updatedFilters);
    const orderAndPageParams: [string, string][] = [
      ['order_by[column]', sortDescriptor.column as string],
      ['order_by[dir]', sortDescriptor.direction === 'ascending' ? 'asc' : 'desc'],
      ['page', currentPage.toString()]
    ];
    const sortedParams = sortFilterParams(orderAndPageParams.concat(finalParams));
    setSearchParams(sortedParams);
  };

  useEffect(() => {
    searchParams.set('page', currentPage.toString());
  }, [currentPage, searchParams]);

  useEffect(() => {
    setAppliedFilters(
      new Set(
        Array.from(searchParams.entries())
          .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
          .flatMap(([key, value]) =>
            value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
          )
      )
    );

    draftState.current = {
      full_name: searchParams.get('full_name') ?? '',
      clinical:
        searchParams.get('clinical') !== null
          ? (searchParams.get('clinical') as 'true' | 'false')
          : 'all',
      member_role:
        searchParams.get('member_role') !== null
          ? (searchParams.get('member_role') as 'member' | 'superuser')
          : 'all',
      status:
        searchParams.get('status') !== null
          ? (searchParams.get('status') as 'active' | 'onboarding' | 'invited')
          : 'all'
    };

    setCurrentPage(
      searchParams.get('page') !== null ? parseInt(searchParams.get('page') ?? '1') : 1
    );
    setSortDescriptor({
      column: searchParams.get('order_by[column]') ?? 'full_name',
      direction: searchParams.get('order_by[dir]') === 'asc' ? 'ascending' : 'descending'
    });
  }, [searchParams]);

  useEffect(() => {
    updateOrganizationFilters({
      clinical:
        searchParams.get('clinical') !== null ? Boolean(searchParams.get('clinical')) : undefined,
      full_name: searchParams.get('full_name') ?? undefined,
      member_role:
        searchParams.get('member_role') !== null
          ? (searchParams.get('member_role') as 'member' | 'superuser')
          : undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'full_name',
        dir: (searchParams.get('order_by[dir]') ?? 'asc') as 'asc' | 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1'),
      status:
        searchParams.get('status') === 'active'
          ? 'activated'
          : searchParams.get('status') === 'onboarding' || searchParams.get('status') === 'invited'
            ? 'created'
            : undefined,
      completed_onboarding:
        searchParams.get('status') === 'active'
          ? true
          : searchParams.get('status') === 'onboarding'
            ? false
            : undefined
    });
  }, [searchParams, updateOrganizationFilters]);

  return (
    <>
      <PageTitle
        title="Team"
        titleVariant="h1"
      />
      <S.FiltersAndActionsWrapper>
        <FilterDropdown>
          <FilterDropdown.Filters onApplyChanges={preserveChanges}>
            <SearchField
              aria-label="Search by user name"
              data-cy="name-search-field"
              defaultValue={draftState.current.full_name}
              placeholder="User name"
              onChange={handleSearchByNameFieldChange}
            />
            <S.TeamMemberSelect
              aria-label="Filter by user role"
              data-cy="user-role-filter"
              placeholder="Filter by user role"
              selectedKey={draftState.current.member_role}
              onSelectionChange={handleUserRoleSelectionChange}
            >
              <Item key="all">All roles</Item>
              <Item key="member">Member</Item>
              <Item key="superuser">Admin</Item>
            </S.TeamMemberSelect>
            <S.TeamMemberSelect
              aria-label="Filter by user type"
              data-cy="user-type-filter"
              placeholder="Filter by user type"
              selectedKey={draftState.current.clinical}
              onSelectionChange={handleUserTypeSelectionChange}
            >
              <Item key="all">All types</Item>
              <Item key="true">Clinical</Item>
              <Item key="false">Non-clinical</Item>
            </S.TeamMemberSelect>
            <S.TeamMemberSelect
              aria-label="Filter by user status"
              data-cy="user-status-filter"
              placeholder="Filter by user status"
              selectedKey={draftState.current.status}
              onSelectionChange={handleUserStatusSelectionChange}
            >
              <Item key="all">All statuses</Item>
              <Item key="invited">Invited</Item>
              <Item key="onboarding">Onboarding in progress</Item>
              <Item key="active">Active</Item>
            </S.TeamMemberSelect>
          </FilterDropdown.Filters>
          <FilterDropdown.Tags
            onClear={handleClearFilters}
            onRemove={handleRemoveFilter}
          >
            {Array.from(appliedFilters).map(key => {
              const label = key.toString().split(':')[0];
              let value = key.toString().split(':')[1];
              if (label.toLowerCase().includes('user type')) {
                value = value === 'true' ? 'Clinical' : 'Non-clinical';
              }
              return (
                <Item key={key}>
                  {label}
                  {': '}
                  {value}
                </Item>
              );
            })}
          </FilterDropdown.Tags>
        </FilterDropdown>

        <Button
          data-cy="export-users-csv-button"
          isLoading={isExporting}
          size="regular"
          trailingIcon={ExportIcon}
          onPress={handleProvidersExportButtonPress}
        >
          Export CSV
        </Button>
      </S.FiltersAndActionsWrapper>
      {isFetchingOrgUsers ? (
        <Spinner withWrapper />
      ) : (
        <>
          <Table
            aria-label="Users"
            data-cy="organization-members"
            renderEmptyState={() => (
              <EmptyTableState
                colSpan={6}
                message="No team members have been found."
              />
            )}
            sortDescriptor={sortDescriptor}
            onSortChange={({ column, direction }) => {
              setSortDescriptor({ column, direction });
              setCurrentPage(1);
              setSearchParams(prev => {
                if (prev.get('page') !== null) {
                  prev.delete('page');
                }
                prev.set('order_by[column]', column as string);
                prev.set('order_by[dir]', direction === 'ascending' ? 'asc' : 'desc');
                return prev;
              });
            }}
          >
            <TableHeader>
              <Column
                key="full_name"
                allowsSorting
              >
                Name
              </Column>
              <Column key="email">Email</Column>
              <Column key="role">Role</Column>
              <Column key="user_type">User type</Column>
              <Column key="onboarded">Status</Column>
              <Column key="actions">Actions</Column>
            </TableHeader>
            <TableBody>
              {users.map((member: OrganizationUser) => (
                <Row
                  key={member.id}
                  data-cy="organization-member-row"
                >
                  <Cell>{member.name}</Cell>
                  <Cell>{member.email}</Cell>
                  <Cell>{member.memberRole === 'superuser' ? 'Admin' : 'Member'}</Cell>
                  <Cell>{member.isClinical ? 'Clinical' : 'Non-clinical'}</Cell>
                  <Cell>
                    {member.onboardingStatus === OnboardingStatus.Complete ? (
                      <Badge
                        color="green"
                        size="small"
                      >
                        Active
                      </Badge>
                    ) : member.inviteStatus === 'can send' ||
                      member.inviteStatus === 'already sent' ? (
                      <Badge
                        color="gray"
                        size="small"
                      >
                        Invited
                      </Badge>
                    ) : (
                      <Badge
                        color="yellow"
                        size="small"
                      >
                        Onboarding in progress
                      </Badge>
                    )}
                  </Cell>
                  <Cell>
                    {member.sendEmailReminderButtonState === SendEmailReminderButtonState.Sent ? (
                      <S.EmailReminderSentText>
                        <S.EmailReminderCheck />
                        Email reminder sent
                      </S.EmailReminderSentText>
                    ) : member.sendEmailReminderButtonState ===
                        SendEmailReminderButtonState.Sending ||
                      member.sendEmailReminderButtonState ===
                        SendEmailReminderButtonState.Unsent ? (
                      <Button
                        data-cy="send-email-reminder-button"
                        isDisabled={
                          member.sendEmailReminderButtonState ===
                          SendEmailReminderButtonState.Sending
                        }
                        variant="outline"
                        onPress={handleSendEmailReminderButtonPress(member.membershipId)}
                      >
                        {member.sendEmailReminderButtonState ===
                        SendEmailReminderButtonState.Sending ? (
                          <Spinner />
                        ) : (
                          'Send email reminder'
                        )}
                      </Button>
                    ) : null}
                  </Cell>
                </Row>
              ))}
            </TableBody>
          </Table>
          <Pagination
            currentPage={currentPage}
            setPage={setCurrentPage}
            totalPages={totalUserPages}
          />
        </>
      )}
    </>
  );
};

export default Team;
