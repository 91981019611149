import DownArrow from '@material-design-icons/svg/round/keyboard_arrow_down.svg?react';
import UpArrow from '@material-design-icons/svg/round/keyboard_arrow_up.svg?react';
import FilterIcon from '@material-symbols/svg-400/rounded/filter_alt.svg?react';
import type { ReactElement, ReactNode } from 'react';
import { Component } from 'react';
import { VisuallyHidden } from 'react-aria';
import type { Key } from 'react-stately';
import { Item, useOverlayTriggerState } from 'react-stately';

import PopoverTrigger from '../PopoverTrigger';

import * as S from './styles';

interface Props {
  children: ReactNode;
  className?: string;
}

interface FiltersProps {
  children: ReactNode;
  customGridTemplateColumns?: string;
  fitContent?: boolean;
  onApplyChanges: () => void;
}

interface TagsProps {
  children?: (ReactElement | null)[];
  onClear?: () => void;
  onRemove: (keys: Set<Key>) => void;
}

class FilterDropdown extends Component<Props> {
  static Filters: React.FC<FiltersProps>;
  static Tags: React.FC<TagsProps>;

  render() {
    const { children, className } = this.props;
    return <S.FilterDropdownWrapper className={className}>{children}</S.FilterDropdownWrapper>;
  }
}

const Filters = ({
  children,
  customGridTemplateColumns,
  fitContent = false,
  onApplyChanges
}: {
  children: ReactNode;
  customGridTemplateColumns?: string;
  fitContent?: boolean;
  onApplyChanges: () => void;
}) => {
  const {
    close: closeFilters,
    isOpen: FiltersIsOpen,
    open: openFilters
  } = useOverlayTriggerState({});

  const saveFilterChanges = () => {
    onApplyChanges();
    closeFilters();
  };

  return (
    <S.FiltersPopoverTrigger
      content={
        <S.FiltersPopoverContent $fitContent={fitContent}>
          <S.FiltersContainer
            $customGridTemplateColumns={customGridTemplateColumns}
            data-cy="filters-popover"
          >
            {children}
          </S.FiltersContainer>
          <S.FilterActionsWrapper>
            <S.ApplyFiltersButton
              data-cy="close-filters-btn"
              variant="outline"
              onPress={closeFilters}
            >
              Cancel
            </S.ApplyFiltersButton>
            <S.ApplyFiltersButton
              data-cy="apply-filters-btn"
              variant="primary"
              onPress={saveFilterChanges}
            >
              Apply filters
            </S.ApplyFiltersButton>
          </S.FilterActionsWrapper>
        </S.FiltersPopoverContent>
      }
      isOpen={FiltersIsOpen}
      placement="bottom left"
      onOpenChange={FiltersIsOpen ? closeFilters : openFilters}
    >
      <S.FiltersButton
        data-cy="filters-btn"
        trailingIcon={FilterIcon}
        variant="plain"
      >
        Filters
      </S.FiltersButton>
    </S.FiltersPopoverTrigger>
  );
};

const Tags = ({
  children,
  onClear,
  onRemove
}: {
  children?: (ReactElement | null)[];
  onClear?: () => void;
  onRemove: (keys: Set<Key>) => void;
}) => {
  const {
    close: closeAdditionalFilters,
    isOpen: additionalFiltersIsOpen,
    open: openAdditionalFilters
  } = useOverlayTriggerState({});
  const childrenWithoutNull = children !== undefined ? children.filter(item => item !== null) : [];
  const childrenToDisplay = childrenWithoutNull.slice(0, 2);
  const additionalChildrenToDisplay =
    childrenWithoutNull.length > 2 ? childrenWithoutNull.slice(2) : [];

  const generateFilterItems = (children: ReactElement[]) =>
    children.map(item => (
      <Item
        key={item.key}
        textValue={String(item.props.children)}
      >
        {}
        {item.props.children}
      </Item>
    ));

  return childrenWithoutNull.length > 0 ? (
    <>
      {onClear !== undefined && (
        <S.FiltersButton
          data-cy="clear-filters-btn"
          variant="plain"
          onPress={onClear}
        >
          Clear
        </S.FiltersButton>
      )}
      <S.FilterTagGroup
        aria-label="Applied filters"
        data-cy="applied-filter-tags"
        variant="light"
        onRemove={onRemove}
      >
        {generateFilterItems(childrenToDisplay as ReactElement[])}
      </S.FilterTagGroup>
      {additionalChildrenToDisplay.length > 0 && (
        <PopoverTrigger
          content={
            <S.AdditionalTagGroup
              aria-label="Applied filters"
              data-cy="additional-filters-popover"
              variant="light"
              onRemove={onRemove}
            >
              {generateFilterItems(additionalChildrenToDisplay as ReactElement[])}
            </S.AdditionalTagGroup>
          }
          isOpen={additionalFiltersIsOpen}
          onOpenChange={additionalFiltersIsOpen ? closeAdditionalFilters : openAdditionalFilters}
        >
          <S.MoreFiltersButton data-cy="view-more-filters-btn">
            +{additionalChildrenToDisplay.length} filter
            {additionalChildrenToDisplay.length > 1 ? 's' : ''}
            <div className="arrow-icon-container">
              {additionalFiltersIsOpen ? (
                <UpArrow className="arrow-icon" />
              ) : (
                <DownArrow className="arrow-icon" />
              )}
            </div>
          </S.MoreFiltersButton>
        </PopoverTrigger>
      )}
    </>
  ) : (
    <VisuallyHidden>No filters applied.</VisuallyHidden>
  );
};

FilterDropdown.Filters = Filters;
FilterDropdown.Tags = Tags;

export default FilterDropdown;
