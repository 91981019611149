import TimeIcon from '@material-symbols/svg-400/rounded/schedule-fill.svg?react';
import EducationIcon from '@material-symbols/svg-400/rounded/school-fill.svg?react';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import type { Pathway } from '@/pages/Dashboard/utils';
import { getHoursAndMinutes } from '@/utils/getHoursAndMinutes';

import * as S from './styles';

interface Props {
  pathway: Pathway;
}

const PathwayMetaData = ({ pathway }: Props) => {
  const { isInDesktopBreakpointRange } = useBreakpointRange();
  const { hours, minutes } = getHoursAndMinutes(pathway.hours);

  return (
    <S.MetaData data-cy="pathway-metadata">
      <S.MetaDataItem>
        <TimeIcon
          aria-hidden
          role="img"
        />
        <S.MetaDataItemHeader>Time</S.MetaDataItemHeader>
        <S.MetaDataItemValue data-cy="pathway-metadata-time">
          {isInDesktopBreakpointRange ? (
            <>
              {hours > 0 && `${hours} hour${hours > 1 ? 's' : ''}`}{' '}
              {minutes > 0 && `${minutes} min${minutes > 1 ? 's' : ''}`}
            </>
          ) : (
            <>
              {hours > 0 && `${hours} hr`} {minutes > 0 && `${minutes} m`}
            </>
          )}
        </S.MetaDataItemValue>
      </S.MetaDataItem>
      <S.MetaDataItem>
        <EducationIcon
          aria-hidden
          role="img"
        />
        <S.MetaDataItemHeader>
          CE/CME{isInDesktopBreakpointRange && ' eligible'}
        </S.MetaDataItemHeader>
        <S.MetaDataItemValue data-cy="pathway-metadata-accreditation">
          {pathway.isAccredited ? 'Yes' : 'No'}
        </S.MetaDataItemValue>
      </S.MetaDataItem>
    </S.MetaData>
  );
};

export default PathwayMetaData;
