import advancedIcon from '@material-design-icons/svg/filled/local_florist.svg?react';
import intermediateIcon from '@material-design-icons/svg/filled/spa.svg?react';
import foundationIcon from '@material-design-icons/svg/filled/sports_rugby.svg?react';
import beginnerIcon from '@material-symbols/svg-400/rounded/psychiatry-fill.svg?react';
import capitalize from 'lodash/capitalize';
import type { FC, SVGProps } from 'react';
import styled from 'styled-components';

const FoundationIcon = styled(foundationIcon)`
  transform: scale(0.8) rotate(-45deg);
  margin-left: -2px;
  margin-right: -2px;
`;

const getLevelIcon = (level: string) => {
  let Icon: FC<SVGProps<SVGSVGElement>>;

  if (capitalize(level) === 'Foundation') {
    Icon = FoundationIcon;
  } else if (capitalize(level) === 'Beginner') {
    Icon = beginnerIcon;
  } else if (capitalize(level) === 'Intermediate') {
    Icon = intermediateIcon;
  } else if (capitalize(level) === 'Advanced') {
    Icon = advancedIcon;
  } else {
    throw new Error('Invalid `level` value.');
  }
  return Icon;
};

export default getLevelIcon;
