import * as S from './styles';

interface Props {
  activeTab: string;
  alignment?: 'left' | 'center';
  tabs: {
    isHidden?: boolean;
    label: string;
    onPress: (tab: string) => void;
    value: string;
  }[];
}

const TabNavigation = ({ activeTab, alignment = 'left', tabs }: Props) => (
  <S.Navigation $alignment={alignment}>
    {tabs.map(
      tab =>
        !tab.isHidden && (
          <S.NavButton
            key={`${tab.value}-tab`}
            className={activeTab === tab.value ? 'active' : ''}
            data-cy={`${tab.value}-tab`}
            onPress={() => tab.onPress(tab.value)}
          >
            <S.Tab className={activeTab === tab.value ? 'active' : ''}>{tab.label}</S.Tab>
          </S.NavButton>
        )
    )}
  </S.Navigation>
);

export default TabNavigation;
