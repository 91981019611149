import capitalize from 'lodash/capitalize';
import type { Key } from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Item } from 'react-stately';
import PageTitle from '@/components/PageTitle';
import CompletedCollectionsTable from '@/pages/components/CompletedCollectionsTable';
import CompletedCoursesTable from '@/pages/components/CompletedCoursesTable';

import * as S from './styles';

const CompletionsAndCertificates = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState<'collections' | 'courses'>(
    searchParams.get('view') !== null
      ? (searchParams.get('view') as 'collections' | 'courses')
      : 'collections'
  );

  const handleViewChange = (view: Key) => {
    setSearchParams(params => {
      params.set('view', view.toString());
      return params;
    });
  };

  useEffect(() => {
    setView(
      searchParams.get('view') !== null
        ? (searchParams.get('view') as 'collections' | 'courses')
        : 'collections'
    );
  }, [searchParams]);

  return (
    <S.FullWidthMain>
      <PageTitle
        description="Want to refer back to something you learned through Violet? Not to worry&mdash;all of your completed education lives in this tab for easy access. For each collection, you'll need to complete a short assessment and your CE/CME certificate will be available instantly."
        title="Completions & CE/CME certificates"
        titleVariant="h1"
      />
      <S.TableActionsWrapper>
        <S.CompletionsViewButton
          customWidth={220}
          data-cy="completions-view-button"
          label={`View: ${capitalize(view)}`}
          placement="bottom end"
          variant="plain"
          onAction={handleViewChange}
        >
          <Item key="collections">
            <S.OptionWrapper>
              Collections{' '}
              {view === 'collections' ? (
                <S.CheckedIcon
                  aria-label="checked"
                  data-cy="is-selected-icon"
                  role="img"
                />
              ) : null}
            </S.OptionWrapper>
          </Item>
          <Item key="courses">
            <S.OptionWrapper>
              Courses{' '}
              {view === 'courses' ? (
                <S.CheckedIcon
                  aria-label="checked"
                  data-cy="is-selected-icon"
                  role="img"
                />
              ) : null}
            </S.OptionWrapper>
          </Item>
        </S.CompletionsViewButton>
      </S.TableActionsWrapper>
      {view === 'collections' ? <CompletedCollectionsTable /> : <CompletedCoursesTable />}
    </S.FullWidthMain>
  );
};

export default CompletionsAndCertificates;
