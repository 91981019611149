import type { Auth0ProviderOptions } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import type { PropsWithChildren } from 'react';
import { StrictMode, useEffect } from 'react';
import { OverlayProvider } from 'react-aria';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigationType
} from 'react-router-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import 'modern-normalize';

import App from './App';
import ErrorState from './components/ErrorState';
import GlobalStyle from './components/GlobalStyle';
import { AnalyticsProvider } from './hooks/useAnalytics';
import Reports from './pages/Reports';
import InclusivityScorecardReportWrapper from './pages/Reports/InclusivityScorecard';
import NetworkOverviewReportWrapper from './pages/Reports/Network';
import UsersInvitation from './pages/Users/Invitation';
import UsersRequestInvite from './pages/Users/RequestInvite';
import UsersSignUp from './pages/Users/SignUp';
import reportWebVitals from './reportWebVitals';
import './utils/posthog';

Sentry.init({
  dsn: 'https://aca98b21da194d0981fb411c1cd05b7d@o527013.ingest.sentry.io/5803324',
  enabled: import.meta.env.VITE_ENV === 'staging' || import.meta.env.VITE_ENV === 'production',
  environment: import.meta.env.VITE_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType
    })
  ],
  tracesSampleRate: 1.0
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Auth0ProviderWithNavigate = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const handleRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = appState => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
        redirect_uri: window.location.origin,
        scope: import.meta.env.VITE_AUTH0_SCOPE
      }}
      cacheLocation="localstorage"
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      onRedirectCallback={handleRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const theme = {
  // Add your theme values here if needed
};

const container = document.getElementById('root')!;
const root = createRoot(container);

// Create a wrapper component to handle styled-components setup
const StyledComponentsWrapper = ({ children }: { children: React.ReactNode }) => (
  <StyleSheetManager
    enableVendorPrefixes
    disableCSSOMInjection={false}
  >
    {children}
  </StyleSheetManager>
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <StyledComponentsWrapper>
          <GlobalStyle key="global-style" />
          <AnalyticsProvider writeKey={import.meta.env.VITE_SEGMENT_WRITE_KEY}>
            <Sentry.ErrorBoundary fallback={<ErrorState />}>
              <OverlayProvider>
                <Auth0ProviderWithNavigate>
                  <SentryRoutes>
                    <Route
                      element={<UsersInvitation />}
                      path="users/invitation"
                    />
                    <Route
                      element={<UsersRequestInvite />}
                      path="users/request-invite"
                    />
                    <Route
                      element={<UsersSignUp />}
                      path="sign-up"
                    />
                    <Route
                      element={<Reports />}
                      path="reports"
                    >
                      <Route
                        element={<NetworkOverviewReportWrapper />}
                        path="network"
                      />
                      <Route
                        element={<NetworkOverviewReportWrapper />}
                        path="network/:organizationId"
                      />
                      <Route
                        element={<InclusivityScorecardReportWrapper />}
                        path="provider/scorecard"
                      />
                      <Route
                        element={<InclusivityScorecardReportWrapper />}
                        path="provider/:organizationId/scorecard"
                      />
                    </Route>
                    <Route
                      element={<App />}
                      path="*"
                    />
                  </SentryRoutes>
                </Auth0ProviderWithNavigate>
              </OverlayProvider>
            </Sentry.ErrorBoundary>
          </AnalyticsProvider>
        </StyledComponentsWrapper>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
