import type { Key } from 'react';

export const filterNameToKey = (
  filterMapping: { filterName: string; key: string }[],
  key: string,
  value: string
) => {
  const filter = filterMapping.find(filter => filter.filterName === key);
  return [filter?.key ?? key, value];
};

export const filtersObjectToParams = (
  filterMapping: (key: string, value: string) => string[],
  appliedFilters: Set<Key>
) => {
  const fromObjectToParamsKeyValuePairs = Array.from(new Set(appliedFilters))
    .map(key => {
      // converts our filters to array of key-value pairs
      // i.e. 'Organization:124' => ['organizations[]', '124']
      // i.e. 'State:CA' => ['states[]', 'CA']
      // eslint-disable-next-line prefer-const
      let [paramKey, paramValue] = key.toString().split(':');
      const [newKey, value] = filterMapping(paramKey, paramValue);
      return [newKey, value] as [string, string];
    })
    // removes any filters that are empty strings or null
    .filter(([_key, value]) => value !== 'null' && value !== '' && value !== 'undefined')
    // combines duplicate keys into a single key with multiple values
    .reduce((acc, [newKey, value]) => {
      if (acc.has(newKey)) {
        acc.set(newKey, `${acc.get(newKey)},${value}`);
      } else {
        acc.set(newKey, value);
      }
      return acc;
    }, new Map<string, string>())
    // converts the map back to an array of key-value pairs
    .entries();
  return Array.from(fromObjectToParamsKeyValuePairs);
};

export const keyToFilterName = (
  filterMapping: { filterName: string; key: string }[],
  key: string,
  value: string
) => {
  const filter = filterMapping.find(filter => filter.key === key);
  return `${filter?.filterName ?? key}:${value}`;
};

export const sortFilterParams = (params: [string, string][]) => {
  const searchParams = new URLSearchParams();
  params.forEach(([key, value]) => searchParams.set(key, value));
  params.sort();
  params.filter(([_key, value]) => value !== 'null' && value !== '' && value !== 'undefined');
  return params;
};
