import type { GridNode } from '@react-types/grid';
import { useRef } from 'react';
import { useTableColumnHeader, useTableSelectAllCheckbox, VisuallyHidden } from 'react-aria';
import type { TableState } from 'react-stately';

import TableCheckbox from '../TableCheckbox';

import * as S from './styles';

interface Props {
  column: GridNode<object>;
  state: TableState<object>;
}

const HeaderSelectAllCell = ({ column, state }: Props) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const { columnHeaderProps } = useTableColumnHeader({ node: column }, state, ref);
  const { checkboxProps } = useTableSelectAllCheckbox(state);

  return (
    <S.HeaderSelectAllCell
      {...columnHeaderProps}
      ref={ref}
    >
      {state.selectionManager.selectionMode === 'single' ? (
        <VisuallyHidden>{checkboxProps['aria-label']}</VisuallyHidden>
      ) : (
        <TableCheckbox {...checkboxProps} />
      )}
    </S.HeaderSelectAllCell>
  );
};

export default HeaderSelectAllCell;
