import styled from 'styled-components';

interface MeterGroupProps {
  $isVertical?: boolean;
  $size?: 'small' | 'large';
}

export const MeterGroup = styled.div<MeterGroupProps>`
  display: grid;
  gap: 1rem;

  ${({ $isVertical = false, $size = 'small' }) => {
    if ($isVertical && $size === 'small') {
      return `
        gap: 1.5rem;
      `;
    } else if ($isVertical && $size === 'large') {
      return `
        gap: 2.5rem;

        > div:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      `;
    }
  }}
`;

export const MeterWrapper = styled.div<MeterGroupProps>`
  display: grid;
  gap: 1rem;

  ${({ $isVertical = false }) => {
    if ($isVertical) {
      return `
        gap: 1.5rem;
        flex-grow: 1;
        align-content: space-between;
      `;
    } else {
      return `
        margin-top: auto;
      `;
    }
  }}
`;
