import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);
`;

interface RadioGroupProps {
  $size: 'regular' | 'small';
}

export const Label = styled.span``;

export const RadioGroup = styled.div<RadioGroupProps>`
  display: flex;
  column-gap: var(--space-5);

  ${({ $size }) => {
    if ($size === 'small') {
      return `
        column-gap: var(--space-5);
      `;
    } else {
      return `
        column-gap: var(--space-4);
      `;
    }
  }}

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
    row-gap: var(--space-5);
  }
`;
