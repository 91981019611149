import { useRef } from 'react';
import { useDialog } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import * as S from './styles';

import Button from '@/components/Buttons/Button';

interface Props {
  state: OverlayTriggerState;
}

const Dialog = ({ state }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const { dialogProps, titleProps } = useDialog({}, ref);

  const handleCloseButtonPress = () => {
    state.close();
  };

  const handleImAllSetButtonPress = () => {
    state.close();
  };

  return (
    <S.Dialog
      data-cy="benchmarks-explainer-modal-dialog"
      {...dialogProps}
      ref={ref}
    >
      <S.Header>
        <S.CloseButton.Root onPress={handleCloseButtonPress}>
          <S.CloseButton.Icon aria-hidden="true" />
        </S.CloseButton.Root>
        <S.BenchmarkingGraphicImage aria-hidden="true" />
      </S.Header>
      <S.Body>
        <S.Title {...titleProps}>What are Violet Benchmarks?</S.Title>
        <S.Text>
          <p>
            Violet Benchmarks are derived from a standardized framework that objectively quantifies
            culturally competent care delivery to LGBQ, TGNC, and BIPOC communities.
            <br />
            <br />
            The information you enter factors into your Benchmarks for each individual community.
            <br />
            <br />
            Once you’re benchmarked you can:
          </p>
          <br />
          <ul>
            <li>
              Gain a better understanding of how you compare to your peers in providing inclusive
              care.
            </li>
            <li>Continue to hone your skills through our educational curriculum.</li>
            <li>Update your profile as you gain more experience to grow your Benchmarks.</li>
          </ul>
        </S.Text>
      </S.Body>
      <S.Footer>
        <Button
          data-cy="im-all-set-button"
          size="large"
          onPress={handleImAllSetButtonPress}
        >
          I’m all set
        </Button>
      </S.Footer>
    </S.Dialog>
  );
};

export default Dialog;
