import InputGroup from '@/components/FormFields/InputGroup';
import TextField from '@/components/FormFields/TextField';
import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const Description = styled.p`
  ${TEXT_STYLES.bodyCopyRegular};
  color: var(--gray-500);
  margin-bottom: 1rem;
`;

export const Heading = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Regular};
  color: var(--purple-400);
  margin-bottom: 0.5rem;
`;

export const SliderGroup = styled(InputGroup)`
  margin-bottom: 3rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-top: 2rem;
  }
`;

export const TextAreaContainer = styled(TextField)`
  label {
    ${TEXT_STYLES.headlineSectionLevel2Regular}
    color: var(--purple-400);
    margin-bottom: 0.75rem;
  }
`;
