import TrendingFlatIcon from '@material-symbols/svg-400/rounded/arrow_right_alt.svg?react';
import TrendingDownIcon from '@material-symbols/svg-400/rounded/trending_down.svg?react';
import TrendingUpIcon from '@material-symbols/svg-400/rounded/trending_up.svg?react';
import { useCallback, useEffect, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import type { ServiceAreaComparison } from '@/pages/Dashboard/utils';
import type { VioletCommunity } from '@/utils';

import * as S from './styles';

export interface ServiceAreaComparisonTotals {
  totalInclusivePercentage: number;
  totalInclusiveProviders: number;
}

interface Props {
  community: VioletCommunity;
  serviceAreaComparisonTotals: ServiceAreaComparisonTotals;
  serviceAreaComparisons: ServiceAreaComparison[];
  totalNetworkInclusivePercentage: number;
  totalNetworkInclusiveProviders: number;
}

interface ComparedLocation {
  locationName: string;
  locationType: 'county' | 'state' | 'zip';
  stateCode: string;
}

const ComparisonDataTooltipContent = ({
  community,
  serviceAreaComparisonTotals,
  serviceAreaComparisons,
  totalNetworkInclusivePercentage,
  totalNetworkInclusiveProviders
}: Props): JSX.Element => {
  const getUniqueLocations = useCallback((): ComparedLocation[] => {
    const locationMap = new Map<string, ComparedLocation>();

    serviceAreaComparisons.forEach(comparison => {
      const key = `${comparison.locationName}-${comparison.locationType}-${comparison.stateCode}`;
      if (!locationMap.has(key)) {
        locationMap.set(key, {
          locationName: comparison.locationName,
          locationType: comparison.locationType,
          stateCode: comparison.stateCode
        });
      }
    });

    return Array.from(locationMap.values());
  }, [serviceAreaComparisons]);

  const [comparedLocations, setComparedLocations] =
    useState<ComparedLocation[]>(getUniqueLocations());
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  useEffect(() => {
    setComparedLocations(getUniqueLocations());
  }, [getUniqueLocations]);

  return (
    <S.TooltipContent data-cy="comparison-data-tooltip-content">
      <S.TooltipTitle>{community.toUpperCase()} inclusivity comparison</S.TooltipTitle>
      <S.ComparisonTable>
        {comparedLocations.map(serviceAreaComparison => (
          <S.ComparisonTableRow
            key={`${serviceAreaComparison.locationName}-${serviceAreaComparison.locationType}-${serviceAreaComparison.stateCode}`}
            data-cy="comparison-service-area-row"
          >
            <S.ComparisonLabel $bolded>
              Service area ({serviceAreaComparison.locationType})
            </S.ComparisonLabel>
            <S.ComparisonValue>{serviceAreaComparison.locationName}</S.ComparisonValue>
          </S.ComparisonTableRow>
        ))}
        <S.ComparisonTableRow
          key="evaluation"
          data-cy="comparison-evaluation-row"
        >
          <S.ComparisonLabel $bolded>Evaluation</S.ComparisonLabel>
          <S.ComparisonValue>
            {comparedLocations.length === 0 ? (
              'Not enough data'
            ) : totalNetworkInclusivePercentage >
              serviceAreaComparisonTotals.totalInclusivePercentage ? (
              <S.AboveAvg>
                <TrendingUpIcon
                  aria-hidden="true"
                  role="img"
                />
                Above average
              </S.AboveAvg>
            ) : totalNetworkInclusivePercentage <
              serviceAreaComparisonTotals.totalInclusivePercentage ? (
              <S.BelowAvg>
                <TrendingDownIcon
                  aria-hidden="true"
                  role="img"
                />
                Below average
              </S.BelowAvg>
            ) : (
              <S.Avg>
                <TrendingFlatIcon
                  aria-hidden="true"
                  role="img"
                />
                Average
              </S.Avg>
            )}
          </S.ComparisonValue>
        </S.ComparisonTableRow>
        <S.ComparisonTableRow key="your-network-header">
          <S.ComparisonLabel $bolded>Your network</S.ComparisonLabel>
        </S.ComparisonTableRow>
        <S.ComparisonTableRow
          key="your-network-percentage"
          $indent
          data-cy="comparison-your-network-percentage-row"
        >
          <S.ComparisonLabel>Inclusive Percentage</S.ComparisonLabel>
          <S.ComparisonValue $bolded>{totalNetworkInclusivePercentage}%</S.ComparisonValue>
        </S.ComparisonTableRow>
        <S.ComparisonTableRow
          key="your-network-providers"
          $indent
          data-cy="comparison-your-network-providers-row"
        >
          <S.ComparisonLabel>Providers</S.ComparisonLabel>
          <S.ComparisonValue>
            {commaNumFormatter.format(totalNetworkInclusiveProviders)}
          </S.ComparisonValue>
        </S.ComparisonTableRow>
        <S.ComparisonTableRow key="all-providers-header">
          <S.ComparisonLabel $bolded>All providers</S.ComparisonLabel>
        </S.ComparisonTableRow>
        {comparedLocations.length > 0 ? (
          <>
            <S.ComparisonTableRow
              key="all-providers-percentage"
              $indent
              data-cy="comparison-all-providers-percentage-row"
            >
              <S.ComparisonLabel>Inclusive Percentage</S.ComparisonLabel>
              <S.ComparisonValue $bolded>
                {serviceAreaComparisonTotals.totalInclusivePercentage}%
              </S.ComparisonValue>
            </S.ComparisonTableRow>
            <S.ComparisonTableRow
              key="all-providers-count"
              $indent
              $last
              data-cy="comparison-all-providers-count-row"
            >
              <S.ComparisonLabel>Providers</S.ComparisonLabel>
              <S.ComparisonValue>
                {commaNumFormatter.format(serviceAreaComparisonTotals.totalInclusiveProviders)}
              </S.ComparisonValue>
            </S.ComparisonTableRow>
          </>
        ) : (
          <S.ComparisonTableRow
            $indent
            data-cy="comparison-not-enough-data-row"
          >
            <S.ComparisonValue>Not enough data is available for comparison.</S.ComparisonValue>
          </S.ComparisonTableRow>
        )}
      </S.ComparisonTable>
    </S.TooltipContent>
  );
};

export default ComparisonDataTooltipContent;
