import { useEffect, useRef } from 'react';
import { Overlay, useModalOverlay } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { FADE_IN_TRANSITION_DURATION_MS } from '@/constants';

import Dialog from './Dialog';
import * as S from './styles';

const BenchmarksModalDialog = () => {
  const ref = useRef<HTMLDivElement>(null);

  const state = useOverlayTriggerState({});

  const { modalProps, underlayProps } = useModalOverlay({ isDismissable: true }, state, ref);

  const openRef = useRef(state.open);

  useEffect(() => {
    const timeout = setTimeout(() => {
      openRef.current();
    }, FADE_IN_TRANSITION_DURATION_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!state.isOpen) return null;

  return (
    <Overlay>
      <S.Underlay {...underlayProps}>
        <S.Modal
          {...modalProps}
          ref={ref}
        >
          <Dialog state={state} />
        </S.Modal>
      </S.Underlay>
    </Overlay>
  );
};

export default BenchmarksModalDialog;
