import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CommunityGraphs from '@/components/Reporting/CommunityGraphs';
import DataCard from '@/components/Reporting/DataCard';
import DataCardHeader from '@/components/Reporting/DataCardHeader';
import DataContainer from '@/components/Reporting/DataContainer';
import DataHighlight from '@/components/Reporting/DataHighlight';
import DataReportRow from '@/components/Reporting/DataReportRow';
import Spinner from '@/components/Spinner';
import { STATES } from '@/pages/constants';
import type { LocationFilter } from '@/pages/Dashboard/hooks/useGetNetworkLocations';
import type { ServiceAreaLocationFilter } from '@/pages/Dashboard/hooks/useGetNetworkServiceAreaComparisons';
import useGetNetworkServiceAreaComparisons from '@/pages/Dashboard/hooks/useGetNetworkServiceAreaComparisons';
import type { NetworkOverview } from '@/pages/Dashboard/utils';

import CommunityInclusivityComparisons from '../../Components/CommunityInclusivityComparisons';
import useNetworks from '../../Wrapper/useNetworks';
import { mapLocationsFromKeysToAPIObject } from '../ServiceAreas/useExportServiceAreas';

interface Props {
  networkOverview?: NetworkOverview;
  showDiversity?: boolean;
  showVIOMetrics?: boolean;
}

export const NetworkOverviewSection = ({
  networkOverview,
  showDiversity = false,
  showVIOMetrics = false
}: Props) => {
  const { defaultComparisonStates } = useNetworks();
  const isComparativeDataEnabled = useFeatureFlagEnabled('comparative_network_data');
  const numFormatter = Intl.NumberFormat('en', { notation: 'compact' });

  const [searchParams] = useSearchParams();

  const getSelectedFilterLocations = useCallback(() => {
    const states = new Set(searchParams.get('states[]')?.split(',') ?? []);
    const statesAsLocations = Array.from(states).map(state => ({
      location_name: STATES.find(s => s.abbreviation === state)?.name ?? state,
      location_type: 'state' as const,
      state_code: state
    }));
    const locations = mapLocationsFromKeysToAPIObject(
      searchParams.get('locations[]')?.split(',') ?? []
    );
    const locationsToFilters = locations.map((location: AtLeastOne<LocationFilter>) => {
      const locationType =
        'city' in location
          ? 'city'
          : 'county' in location
            ? 'county'
            : 'zip' in location
              ? 'zip'
              : 'state';
      const locationName =
        locationType === 'state'
          ? location.state
          : locationType === 'city'
            ? location.city
            : locationType === 'county'
              ? location.county
              : location.zip;
      return {
        location_name: locationName ?? '',
        location_type: locationType,
        state_code: location.state ?? ''
      } as ServiceAreaLocationFilter;
    });
    return locationsToFilters.length > 0 ? locationsToFilters : statesAsLocations;
  }, [searchParams]);

  const [selectedLocations, setSelectedLocations] = useState<ServiceAreaLocationFilter[]>(
    getSelectedFilterLocations
  );

  const {
    isFetching: isFetchingServiceAreaComparisons,
    serviceAreaComparisons,
    serviceAreaComparisonsMeta,
    updateServiceAreaComparisonsFilters
  } = useGetNetworkServiceAreaComparisons({
    locations: selectedLocations.length > 0 ? selectedLocations : defaultComparisonStates
  });

  useEffect(() => {
    updateServiceAreaComparisonsFilters({
      locations: selectedLocations.length > 0 ? selectedLocations : defaultComparisonStates
    });
  }, [defaultComparisonStates, selectedLocations, updateServiceAreaComparisonsFilters]);

  useEffect(() => {
    setSelectedLocations(getSelectedFilterLocations());
  }, [searchParams, getSelectedFilterLocations]);

  const renderVioMetrics = () => (
    <>
      <DataHighlight
        data-cy="total-inclusive-network-providers"
        detailText="Providers with a Benchmark"
        numberHighlight={numFormatter.format(
          networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
        )}
        percent={
          networkOverview?.totalNetworkProviders === 0
            ? 0
            : ((networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0) /
                (networkOverview?.totalNetworkProviders ?? 1)) *
              100
        }
        popoverContent="Includes all providers who have earned a Violet Benchmark, either through claims data analysis or by completing their profile on Violet to achieve Verified Inclusive Provider status."
        variant="small"
      />
      <DataHighlight
        data-cy="total-network-organizations"
        detailText="Provider organizations"
        numberHighlight={numFormatter.format(networkOverview?.totalNetworkOrganizations ?? 0)}
        popoverContent="Total number of provider organizations linked to your network, based on the roster provided."
        variant="small"
      />
      <DataHighlight
        data-cy="total-network-verified-organizations"
        detailText="Verified Inclusive Organizations"
        numberHighlight={numFormatter.format(networkOverview?.totalVioNetworkOrganizations ?? 0)}
        percent={
          networkOverview !== undefined && networkOverview.totalNetworkOrganizations > 0
            ? (networkOverview.totalVioNetworkOrganizations /
                networkOverview.totalNetworkOrganizations) *
              100
            : 0
        }
        popoverContent={
          <>
            <p>
              Total number of organizations within your network that have an active partership with
              Violet and meet the following criteria for verification:
            </p>
            <ul>
              <li>
                At least 30% of a provider's clinical staff (including licensed professionals,
                pre-licensed individuals, and interns) must onboard with Violet, submitting their
                profiles to receive Benchmarks.
              </li>
              <li>
                Of those, 40% must achieve an Awareness Benchmark in at least one of the three
                communities—BIPOC, LGBQ, or TGNC.
              </li>
            </ul>
          </>
        }
        variant="small"
      />
    </>
  );

  const renderNonVioMetrics = () => (
    <>
      <DataHighlight
        data-cy="total-inclusive-providers"
        detailText="Providers with a Benchmark"
        numberHighlight={numFormatter.format(
          networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
        )}
        popoverContent="Includes all providers who have earned a Violet Benchmark, either through claims data analysis or by completing their profile on Violet to achieve Verified Inclusive Provider status."
        variant="small"
      />
      <DataHighlight
        data-cy="total-active-network-providers"
        detailText="Providers on Violet"
        numberHighlight={numFormatter.format(networkOverview?.totalActiveNetworkProviders ?? 0)}
        popoverContent="Total number of providers who have completed their Violet profiles in order to be benchmarked."
        variant="small"
      />
      <DataHighlight
        data-cy="total-vio-network-providers"
        detailText="Verified Inclusive Providers"
        numberHighlight={numFormatter.format(networkOverview?.totalVioNetworkProviders ?? 0)}
        popoverContent={
          <>
            <p>Verified Inclusive Providers are providers within the network who have:</p>
            <ul>
              <li>Claimed and submitted their Violet profile</li>
              <li>
                Achieved an Awareness Benchmark or higher in at least one community (BIPOC, LGBQ, or
                TGNC)
              </li>
            </ul>
          </>
        }
        variant="small"
      />
    </>
  );

  return (
    <DataContainer
      hideBackground
      hideBorder
      data-cy="network-overview"
    >
      <DataReportRow layout="quarters">
        <DataHighlight
          data-cy="total-network-providers"
          detailText="Total providers"
          numberHighlight={numFormatter.format(networkOverview?.totalNetworkProviders ?? 0)}
          popoverContent="Total number of clinical providers linked to your network, based on your provided roster or inferred from third-party data. Clinical users include all licensed and pre-licensed providers, as well as interns actively delivering care."
          variant="small"
        />
        {showVIOMetrics ? renderVioMetrics() : renderNonVioMetrics()}
      </DataReportRow>
      <DataReportRow layout={showDiversity ? 'fifty-fifty' : 'single'}>
        <DataCard>
          <DataCardHeader
            badgeColor="gray"
            badgeText="All providers"
            description={
              isComparativeDataEnabled === true
                ? 'This chart shows the community competencies of all in-network providers benchmarked by Violet. The comparative bar below highlights how your network’s providers compare to those in the same service areas.'
                : 'This chart represents providers within the network who have had their cultural competence assessed and benchmarked by Violet. This encompasses both active providers who have directly onboarded onto the platform and non-active providers whose benchmarks are inferred through data analytics.'
            }
            title="Cultural competencies"
            titleVariant="h3"
          />
          {isComparativeDataEnabled === true ? (
            isFetchingServiceAreaComparisons ||
            networkOverview === undefined ||
            serviceAreaComparisonsMeta === undefined ? (
              <Spinner withWrapper />
            ) : (
              <CommunityInclusivityComparisons
                networkOverview={networkOverview}
                serviceAreaComparisons={serviceAreaComparisons}
                serviceAreaComparisonsMeta={serviceAreaComparisonsMeta}
              />
            )
          ) : (
            <CommunityGraphs
              data={networkOverview?.networkInclusivityPercentages}
              dataType="benchmarks"
            />
          )}
        </DataCard>
        {showDiversity && (
          <DataCard isVertical={isComparativeDataEnabled === true}>
            <DataCardHeader
              badgeColor="green"
              badgeText="On Violet"
              description={
                isComparativeDataEnabled === true
                  ? 'This chart represents the percentage of providers in your network who have voluntarily self-identified as part of a community. Providers share this information when activating their Violet profiles, improving the accuracy of REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics.'
                  : "This chart represents active providers who have voluntarily submitted profile information onto Violet's platform, enabling more accurate REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics."
              }
              title="Diversity"
              titleVariant="h3"
            />
            <CommunityGraphs
              data={networkOverview?.networkDiversityPercentages}
              dataType="demographics"
              isVertical={isComparativeDataEnabled === true}
              linkToPage="/dashboard/my-network/inclusivity/diversity"
            />
          </DataCard>
        )}
      </DataReportRow>
    </DataContainer>
  );
};
