import BaseCloseIcon from '@material-design-icons/svg/round/close.svg?react';
import QuestionMarkIcon from '@material-design-icons/svg/round/help_outline.svg?react';
import { default as BaseEducationComplete } from '@/components/Education/EducationComplete';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';
import UnstyledButton from '../../UnstyledButton';

export const Author = styled.div`
  font-weight: 700;
`;

export const AuthorAndOrganization = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-gap: 0.25rem;
  ${TEXT_STYLES.ctaBaseXSmall}
  letter-spacing: 0.05em;
`;

export const Body = styled.div`
  background-color: white;
  padding: 1.5rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-grow: 1;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  ${MOBILE_BREAKPOINT_RANGE} {
    gap: 1rem;
    flex-direction: column;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    gap: 0.5rem;
  }
`;

export const CloseButton = styled(UnstyledButton)`
  display: block;
  margin-left: auto;
`;

export const CloseIcon = styled(BaseCloseIcon)`
  display: block;
  fill: var(--gray-800);
  width: 1rem;
  height: 1rem;
`;

export const CourseDetailsModalDialog = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

export const Description = styled.div`
  margin-bottom: 1rem;
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
`;

export const Dialog = styled.div`
  outline: none;

  ${MOBILE_BREAKPOINT_RANGE} {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    width: 41.6875rem; /* 667px */
  }
`;

export const DisclosureButton = styled(UnstyledButton)`
  margin-bottom: 1rem;
  color: var(--gray-400);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.is-hovered {
    color: var(--gray-500);

    svg {
      fill: var(--gray-500);
    }
  }
`;

export const DisclosureContainer = styled.div`
  ${TEXT_STYLES.tooltipText}
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-gap: var(--space-3);
  width: 9.375rem; /* 150px */

  ${DESKTOP_BREAKPOINT_RANGE} {
    width: 18.75rem; /* 300px */
  }
`;

export const EducationComplete = styled(BaseEducationComplete)`
  justify-self: flex-start;
  flex-grow: 1;
`;

export const FaqButton = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;

  &.is-hovered svg {
    fill: var(--gray-500);
  }
`;

export const FaqLink = styled(UnstyledButton)`
  text-decoration: underline;
`;

export const FaqPopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  width: 9.375rem; /* 150px */

  ${DESKTOP_BREAKPOINT_RANGE} {
    width: 12.5rem; /* 200px */
  }
`;

export const Footer = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-top: 2px solid var(--gray-light-light);

  ${DESKTOP_BREAKPOINT_RANGE} {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
`;

export const Header = styled.div`
  position: relative;
  padding: 1.5rem 1.5rem 1rem;
  background-color: #f0e1ff;

  ${DESKTOP_BREAKPOINT_RANGE} {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
`;

export const InformationAndTags = styled.div`
  display: flex;
  padding-top: 1rem;

  & > div {
    margin-right: 0.75rem;
  }
`;

export const Organization = styled.div`
  font-weight: 400;
`;

export const QuestionMark = styled(QuestionMarkIcon)`
  fill: var(--gray-400);
  margin-left: 0.25rem;
  width: 1rem;
  height: 1rem;
`;

export const Specialty = styled.div`
  margin-bottom: 1rem;
`;

export const TagLabel = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  margin: 1rem 0 2rem;
  ${TEXT_STYLES.headlineSectionLevel2Bold}
`;
