import { BREAKPOINT, DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import styled from 'styled-components';

const CUSTOM_MIDDLE_BREAKPOINT = BREAKPOINT + 400;
const CUSTOM_MOBILE_RANGE = `@media (max-width: ${BREAKPOINT - 1}px)`;
const CUSTOM_MIDDLE_RANGE = `@media (max-width: ${CUSTOM_MIDDLE_BREAKPOINT}px) and (min-width: ${BREAKPOINT}px)`;
const CUSTOM_DESKTOP_RANGE = `@media (min-width: ${CUSTOM_MIDDLE_BREAKPOINT}px)`;

const FILTER_GROUP_STYLES = `
  border: 1px solid var(--gray-200);
  color: var(--gray-800);
  border-radius: 1rem;
  grid-gap: 0.5rem;
  padding: 1rem;
  display: grid;
  margin-bottom: 1rem;
`;

export const ActionBlock = styled.div`
  margin-left: auto;
  gap: 0.5rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const FilterBlock = styled.div`
  flex-grow: 1;
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr 1fr;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
  }
`;

export const FilterGroup = styled.div`
  ${FILTER_GROUP_STYLES};
  grid-template-rows: 1fr;
`;

export const HeaderActionsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: start;
`;

interface SingleFilterBlockProps {
  $filterCount: number;
}

export const SingleFilterBlock = styled.div<SingleFilterBlockProps>`
  flex-grow: 1;
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;

  ${CUSTOM_MOBILE_RANGE} {
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
  }

  ${({ $filterCount }) => {
    if ($filterCount < 3) {
      return `
        ${CUSTOM_DESKTOP_RANGE} {
          grid-template-columns: 1fr 1fr;
        }
      `;
    } else {
      return `
        ${CUSTOM_DESKTOP_RANGE} {
          grid-template-columns: 1fr 1fr 1fr;
          max-width: 1504px;
        }
      `;
    }
  }}
`;

interface SingleFilterGroupProps {
  $hasExportButton: boolean;
}

export const SingleFilterGroup = styled.div<SingleFilterGroupProps>`
  ${FILTER_GROUP_STYLES}

  ${({ $hasExportButton }) => {
    if ($hasExportButton) {
      return `
        ${CUSTOM_DESKTOP_RANGE} {
          grid-template-columns: 3fr 0.5fr
        }

        ${CUSTOM_MIDDLE_RANGE} {
          grid-template-columns: 2fr 0.5fr;
        }
      `;
    } else {
      return `
        ${CUSTOM_DESKTOP_RANGE} {
          grid-template-columns: 1fr;
        }
      `;
    }
  }}

  ${CUSTOM_MOBILE_RANGE} {
    grid-template-rows: 1fr;
  }
`;

export const TopBlock = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
