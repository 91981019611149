import type { ReactNode } from 'react';
import { useHover } from 'react-aria';

import * as S from './styles';

interface Props {
  dataCy: string;
  isAutoPopulated: boolean;
  isExpanded: boolean;
  isInvalid: boolean;
  row: ReactNode;
}

const BodyRow = ({ dataCy, isAutoPopulated, isExpanded, isInvalid, row }: Props) => {
  const { hoverProps, isHovered } = useHover({});

  return (
    <S.BodyRow
      $isAutoPopulated={isAutoPopulated}
      $isExpanded={isExpanded}
      $isHovered={isHovered}
      $isInvalid={isInvalid}
      data-cy={dataCy}
      role="row"
      {...hoverProps}
    >
      {row}
    </S.BodyRow>
  );
};

export default BodyRow;
