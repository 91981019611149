import { Temporal } from '@js-temporal/polyfill';
import DownloadIcon from '@material-design-icons/svg/round/download.svg?react';
import ArrowLeftIcon from '@material-symbols/svg-400/rounded/arrow_back.svg?react';
import ArrowRightIcon from '@material-symbols/svg-400/rounded/arrow_forward.svg?react';
import { useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import Button from '@/components/Buttons/Button';
import BeginEvaluationModalDialog from '@/components/Education/BeginEvaluationModalDialog';
import CourseDetailsModalDialog from '@/components/Education/CourseDetailsModalDialog';
import EducationCompletedModalDialog from '@/components/Education/EducationCompletedModalDialog';
import ResourceCard from '@/components/Education/ResourceCard';
import MiniProgressBar from '@/components/MiniProgressBar';
import useGetCollection from '@/pages/Dashboard/hooks/useGetCollection';
import useGetCourse from '@/pages/Dashboard/hooks/useGetCourse';
import isNonEmptyString from '@/utils/isNonEmptyString';

import type { EducationalResource, Pathway } from '../../../utils';
import Spinner from '../../components/Spinner';

import CollectionMetaData from './CollectionMetaData';
import * as S from './styles';

const Collection = () => {
  const { collectionId, courseId } = useParams<{ collectionId: string; courseId: string }>();
  const { collection, refetchCollection } = useGetCollection(collectionId!);
  const { changeCourseId, course, refetchCourse } = useGetCourse(courseId);
  const location = useLocation();
  const navigate = useNavigate();
  const collectionUrl = `/dashboard/education/collections/${collectionId}`;
  const downloadUrl =
    collection?.certificates && collection.certificates.length > 0
      ? `${import.meta.env.VITE_BASE_PATH}${collection.certificates[0]?.file.url}`
      : undefined;
  const { state } = location as { state: LocationState };
  const cameFromPathways = state?.referrer.includes('pathways') === true;
  const cameFromCollections = state?.referrer.includes('collections') === true;

  const [collectionDescription, setCollectionDescription] = useState<string>();
  const [collectionTitle, setCollectionTitle] = useState<string>();
  const [educationalResources, setEducationalResources] = useState<Pathway['resources']>([]);
  const [nextResourceId, setNextResourceId] = useState<string | null>(null);
  const [isIncomplete, setIsIncomplete] = useState<boolean>(true);
  const [completedAt, setCompletedAt] = useState<string | null>(null);
  const [isFirstCompletedEducation, setIsFirstCompletedEducation] = useState(false);

  const {
    close: closeBeginEvaluationModalDialog,
    isOpen: beginEvaluationModalDialogIsOpen,
    open: openBeginEvaluationModalDialog
  } = useOverlayTriggerState({});

  const {
    close: closeCourseDetailsModalDialog,
    isOpen: courseDetailsModalDialogIsOpen,
    open: openCourseDetailsModalDialog
  } = useOverlayTriggerState({
    defaultOpen: useMatch(`${collectionUrl}/${courseId}`) !== null
  });

  const {
    close: closeEducationCompletedModalDialog,
    isOpen: educationCompletedModalDialogIsOpen,
    open: openEducationCompletedModalDialog
  } = useOverlayTriggerState({});

  const handleBackToButtonPress = () => {
    if (cameFromPathways) {
      navigate(state.referrer);
    } else {
      const cameFromCollectionsWithFilters =
        cameFromCollections && isNonEmptyString(state.referrerSearch);
      navigate(
        `/dashboard/education/collections${
          cameFromCollectionsWithFilters ? state.referrerSearch : ''
        }`
      );
    }
  };

  const handleCloseCourseDetailsModalDialog = () => {
    closeCourseDetailsModalDialog();
    refetchCollection(true);
    if (window.location.pathname === `${collectionUrl}/${courseId}`) {
      window.history.pushState(null, document.title, collectionUrl);
    }
  };

  const handleNextCoursePress = () => {
    openCourseDetailsModalDialog();

    if (window.location.pathname.includes(collectionUrl)) {
      window.history.pushState(null, document.title, `${collectionUrl}/${nextResourceId}`);
      changeCourseId(nextResourceId ?? '');
    }
  };

  const mapCoursesToResources = (courses: EducationalResource[]) =>
    courses.map(course => ({
      communities: undefined,
      completedAt: course.completedAt ?? null,
      contentMedium: course.contentMedium,
      credits: course.creditsNumber,
      description: course.description,
      hours: course.hours,
      id: course.id,
      level: undefined,
      name: course.fullTitle,
      position: 0,
      resourceType: 'Course',
      totalCompletedCourses: undefined,
      totalCourses: undefined
    })) as Pathway['resources'];

  useEffect(() => {
    if (collection) {
      setCollectionTitle(collection.title);
      setCollectionDescription(collection.description);
      setEducationalResources(mapCoursesToResources(collection.courses));
      setNextResourceId(
        collection.courses.find(course => !isNonEmptyString(course.completedAt))?.id ?? null
      );
      const isIncomplete = !isNonEmptyString(collection.completedAt);
      const completedAt = isIncomplete ? null : collection.completedAt;
      setIsIncomplete(isIncomplete);
      setCompletedAt(completedAt ?? null);
    }
  }, [collection]);

  if (!collection) return <Spinner />;

  return (
    <S.MainWrapper>
      <div>
        <S.BackToCollectionsButton
          data-cy="back-button"
          leadingIcon={ArrowLeftIcon}
          variant="outline"
          onPress={handleBackToButtonPress}
        >
          Back to {cameFromPathways ? 'pathway' : 'collections'}
        </S.BackToCollectionsButton>
        <S.CollectionPageTitle
          collapsibleOnMobile
          data-cy="collection-page-title"
          description={collectionDescription}
          title={collectionTitle ?? ''}
          titleVariant="h1"
        />
        <S.ActionBar>
          <S.CollectionProgressBar className={!isIncomplete ? 'completed' : ''}>
            <S.CollectionProgressLabel>
              {isIncomplete ? 'Progress' : 'Completed'}
              {completedAt !== null ? (
                <S.CollectionCompletedDate>
                  {Temporal.PlainDate.from(
                    completedAt[completedAt.length - 1] === 'Z'
                      ? completedAt.slice(0, -1)
                      : completedAt
                  ).toLocaleString('en-US', { dateStyle: 'long' })}
                </S.CollectionCompletedDate>
              ) : null}
            </S.CollectionProgressLabel>
            <MiniProgressBar
              completedSteps={collection.completionCount}
              inProgressStep={undefined}
              label="Pathway progress"
              totalSteps={collection.courses.length}
            />
          </S.CollectionProgressBar>
          {isIncomplete && (
            <Button
              data-cy="collection-page-cta"
              size="large"
              trailingIcon={ArrowRightIcon}
              onPress={handleNextCoursePress}
            >
              {collection.completionCount > 0 ? 'Resume' : 'Start collection'}
            </Button>
          )}
          {collection.isAccredited && collection.canBeEvaluated && (
            <Button
              data-cy="claim-ce-cme-credit-button"
              size="large"
              onPress={openBeginEvaluationModalDialog}
            >
              Claim CE/CME credit
            </Button>
          )}
          {collection.isAccredited && collection.certificates.length > 0 && (
            <Button
              data-cy="download-certificate-button"
              href={downloadUrl}
              size="large"
              target="_blank"
              trailingIcon={DownloadIcon}
            >
              Download certificate
            </Button>
          )}
          {collection.isAccredited &&
            collection.evaluation &&
            collection.certificates.length === 0 && (
              <Button
                data-cy="generate-certificate-button"
                size="large"
                to={`/dashboard/education/collections/${collectionId}/accreditation-certificate`}
              >
                Generate certificate
              </Button>
            )}
        </S.ActionBar>

        <CollectionMetaData collection={collection} />
        <S.ResourceList>
          {educationalResources.map((educationalResource, index) => (
            <ResourceCard
              key={educationalResource.id}
              index={index}
              maxResources={educationalResources.length}
              nextResourceId={nextResourceId}
              resource={educationalResource}
            />
          ))}
        </S.ResourceList>
      </div>

      {beginEvaluationModalDialogIsOpen && (
        <BeginEvaluationModalDialog
          close={closeBeginEvaluationModalDialog}
          collectionId={collectionId!}
        />
      )}
      {courseDetailsModalDialogIsOpen && course && (
        <CourseDetailsModalDialog
          close={handleCloseCourseDetailsModalDialog}
          educationalResource={course}
          openEducationCompletedModalDialog={(isFirstCompletedEducation = false) => {
            setIsFirstCompletedEducation(isFirstCompletedEducation);
            openEducationCompletedModalDialog();
          }}
          refetchEducationalResources={refetchCourse}
        />
      )}
      {educationCompletedModalDialogIsOpen && course && (
        <EducationCompletedModalDialog
          close={closeEducationCompletedModalDialog}
          educationalResource={course}
          isFirstCompletedEducation={isFirstCompletedEducation}
          openBeginEvaluationModalDialog={openBeginEvaluationModalDialog}
          refetchEducationalResources={refetchCourse}
        />
      )}
    </S.MainWrapper>
  );
};

export default Collection;
