import CommunityGraphs from '@/components/Reporting/CommunityGraphs';
import DataCard from '@/components/Reporting/DataCard';
import DataHighlight from '@/components/Reporting/DataHighlight';
import ListHighlight from '@/components/Reporting/ListHighlight';
import ReportCard from '@/components/Reporting/ReportCard';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const CardTitle = styled.div`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
`;

export const ChartDataReportRow = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 3fr 2fr;
`;

export const ChartReportDataCard = styled(DataCard)`
  padding: 1.5rem;
  border: 2px solid var(--gray-100);
`;

export const ChartsColumnReportCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.88rem;
  min-width: 56;
`;

export const CommunityGraphNoSpacing = styled.div`
  height: unset;
`;

export const HighlightDataReportRow = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

export const HighlightGroupingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
`;

export const HighlightsReportCard = styled(ReportCard)`
  padding: 1.5rem;
  width: 25.1rem;
  border: 1px solid var(--gray-300);
`;

export const InclusivityReportCard = styled(ReportCard)`
  padding: 1.5rem;
  border: 1px solid var(--gray-300);
`;

export const InclusivityScorecardBodyContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.88rem;
  margin-top: 2.5rem;
`;

export const SpacingLi = styled.li`
  margin-top: 1rem;
  margin-bottom: 1;
`;

/* eslint-disable-next-line sort-exports/sort-exports */
export const CommunitiesSpacingLi = styled(SpacingLi)`
  margin-top: 1rem;
`;

export const InclusivityCommunityGraphs = styled(CommunityGraphs)`
  gap: 2rem;

  .report-community-graph-wrapper {
    gap: 1.5rem;
  }
`;

export const InclusivityDataHighlight = styled(DataHighlight)`
  border: 2px solid var(--gray-100);
`;

export const TextReportCard = styled.div`
  background-color: none;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  width: 29rem;

  h2 {
    ${TEXT_STYLES.headlineSectionLevel1Regular}
    margin: 0;
  }
`;

export const TextReportCardDefinition = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);

  strong::after {
    content: ': ';
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
  }
`;

export const TextReportCardDescription = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);
  margin-bottom: 1.88rem;
  margin-top: 0.5rem;
`;

export const TextReportCardTitle = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel1Regular}
  color: var(--purple-400);
  margin: 0;
`;

export const TopCourseItem = styled.li`
  counter-increment: counter;
  display: flex;
  min-height: 20px;
  margin-bottom: 0.25rem;
`;

export const TopCourseItemNumber = styled.span`
  color: var(--purple-400);
  font-weight: bold;
  margin-right: 0.5rem;

  &::before {
    content: counter(counter) ' ';
  }
`;

export const TopCourseItemText = styled.span`
  background-color: var(--gray-25);
  border-radius: 0.25rem;
`;

export const TopCoursesList = styled.ol`
  ${TEXT_STYLES.tagLarge}
  color: var(--gray-800);
  padding-left: 0;
  display: grid;
  gap: 0.25rem;
  margin-top: 0.75rem;

  counter-reset: counter;
  list-style: none;
`;

export const TopListHighlight = styled(ListHighlight)`
  border: 2px solid var(--gray-100);
`;
