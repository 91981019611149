import type { PropsWithChildren } from 'react';
import { Children, isValidElement } from 'react';

import * as S from './styles';

interface Props {
  actionButton?: JSX.Element;
  className?: string;
  headerActions?: JSX.Element | JSX.Element[];
  isSingleRow?: boolean;
}

const Filters = ({
  actionButton,
  children,
  className,
  headerActions,
  isSingleRow = false
}: PropsWithChildren<Props>) => {
  const nonCommunityFilters = Children.map(children, child => {
    if (isValidElement(child)) {
      const hasCommunityFilterClass = (child.props as { className?: string }).className?.includes(
        'CommunityFilter'
      );
      if (hasCommunityFilterClass === true) {
        return undefined;
      }
    }
    return child;
  });

  const nonCommunityFiltersCount = Children.count(nonCommunityFilters) - 1;

  return isSingleRow ? (
    <S.SingleFilterGroup
      $hasExportButton={actionButton !== undefined}
      className={className}
    >
      <S.SingleFilterBlock $filterCount={nonCommunityFiltersCount}>{children}</S.SingleFilterBlock>
      <S.ActionBlock>{actionButton !== undefined && actionButton}</S.ActionBlock>
    </S.SingleFilterGroup>
  ) : (
    <S.FilterGroup className={className}>
      {(actionButton || headerActions) && (
        <S.TopBlock>
          {headerActions && <S.HeaderActionsWrapper>{headerActions}</S.HeaderActionsWrapper>}
          {actionButton !== undefined && actionButton}
        </S.TopBlock>
      )}
      <S.FilterBlock className="filter-block">{children}</S.FilterBlock>
    </S.FilterGroup>
  );
};

export default Filters;
