import LanguageSearch from '@/components/FormFields/LanguageSearch';
import styled from 'styled-components';

import {
  DESKTOP_BREAKPOINT_RANGE,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../../constants';

export const CheckboxGroupWrapper = styled.div`
  display: grid;
  margin-bottom: 2rem;
  background-color: #fff;
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  padding: 1.5rem;

  .group-label {
    ${TEXT_STYLES.headlineSectionLevel2Regular}
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    gap: 3.125rem;
    grid-template-columns: 1fr;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }

  @media (min-width >= 768px) and (max-width <= 900px) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

export const LanguageSearchSection = styled(LanguageSearch)`
  margin-bottom: 2rem;
  margin-top: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: #fff;

  h3 {
    color: var(--gray-600);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 1.8rem */
    letter-spacing: 0.015rem;
    margin-bottom: 0;
  }

  h3 + p {
    ${TEXT_STYLES.bodyCopyMedium}
    color: var(--gray-600);
  }
`;
