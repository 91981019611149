import Badge from '@/components/Badge';
import TooltipTrigger from '@/components/TooltipTrigger';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

export type DataCardHeaderTitleVariantTypes = 'h3-report' | 'h3-scorecard' | 'h3';

interface Props {
  badgeColor?: 'gray' | 'green' | 'yellow';
  badgeText?: string;
  badgeTooltip?: string;
  className?: string;
  description?: string;
  title: string;
  titleVariant?: DataCardHeaderTitleVariantTypes;
}

const DataCardHeader = ({
  badgeColor = 'green',
  badgeText,
  badgeTooltip,
  className,
  description,
  title,
  titleVariant = 'h3'
}: Props) => (
  <S.TitleHeader className={className}>
    <S.H3CardTitle $titleVariant={titleVariant}>
      {title}
      {isNonEmptyString(badgeText) &&
        (isNonEmptyString(badgeTooltip) ? (
          <TooltipTrigger
            content={<S.Tooltip>{badgeTooltip}</S.Tooltip>}
            delay={300}
          >
            <S.BadgeWrapper>
              <Badge
                color={badgeColor}
                size="small"
              >
                {badgeText}
              </Badge>
            </S.BadgeWrapper>
          </TooltipTrigger>
        ) : (
          <Badge
            color={badgeColor}
            size="small"
          >
            {badgeText}
          </Badge>
        ))}
    </S.H3CardTitle>
    {isNonEmptyString(description) && (
      <S.Description $titleVariant={titleVariant}>{description}</S.Description>
    )}
  </S.TitleHeader>
);

export default DataCardHeader;
