import { Temporal } from '@js-temporal/polyfill';
import VerifiedIcon from '@material-design-icons/svg/round/verified.svg?react';
import GroupIcon from '@material-symbols/svg-400/rounded/group-fill.svg?react';
import TimeIcon from '@material-symbols/svg-400/rounded/schedule-fill.svg?react';
import clsx from 'clsx';
import capitalize from 'lodash/capitalize';
import { useParams } from 'react-router-dom';
import { Item } from 'react-stately';
import MiniPercentageProgressBar from '@/components/MiniPercentageProgressBar';
import type { Pathway } from '@/pages/Dashboard/utils';
import getContentMediumIcon from '@/utils/getContentMediumIcon';
import { getHoursAndMinutes } from '@/utils/getHoursAndMinutes';
import getLevelIcon from '@/utils/getLevelIcon';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  index: number;
  maxResources: number;
  nextResourceId: string | null;
  resource: Pathway['resources'][0];
}

const ResourceCard = ({ index, maxResources, nextResourceId, resource }: Props) => {
  const { collectionId, pathwayId } = useParams<{ collectionId: string; pathwayId: string }>();

  const getResourceUrl = () => {
    if (resource.resourceType === 'Course') {
      const basePath = isNonEmptyString(pathwayId)
        ? `pathways/${pathwayId}`
        : `collections/${collectionId}`;
      return `/dashboard/education/${basePath}/${resource.id}`;
    }
    return `/dashboard/education/collections/${resource.id}`;
  };

  const { hours, minutes } = getHoursAndMinutes(
    isNonEmptyString(resource.hours) ? resource.hours : '0'
  );
  const hourString = `${hours} hour${hours > 1 ? 's' : ''}`;
  const minutesString = ` ${minutes} min${minutes > 1 ? 's' : ''}`;
  const hoursAndMinutesString = `${hours > 0 ? hourString : ''}${minutes > 0 ? minutesString : ''}`;
  const ContentMediumIcon = getContentMediumIcon(resource.contentMedium ?? 'e-learning');
  const LevelIcon = getLevelIcon(resource.level ?? 'foundation');
  const isIncompleteCollection =
    resource.resourceType === 'CourseCollection' &&
    resource.completedAt === null &&
    resource.totalCompletedCourses !== undefined &&
    resource.totalCompletedCourses > 0;

  return (
    <S.ResourceItem
      key={resource.id}
      className={clsx({
        first: index === 0,
        last: index === maxResources - 1
      })}
      data-cy="resource-card"
    >
      <S.ResourceLabel className={resource.resourceType === 'Course' ? 'course' : 'collection'}>
        {resource.resourceType === 'Course' ? 'Course' : 'Collection'}
      </S.ResourceLabel>
      <S.ResourceBody>
        <S.ResourceContent>
          <S.ResourceTitle
            data-cy="resource-title"
            state={{ referrer: window.location.pathname, referrerSearch: window.location.search }}
            to={getResourceUrl()}
          >
            {index + 1}. {resource.name}
          </S.ResourceTitle>
          <S.ResourceDescription data-cy="resource-description">
            {resource.description}
          </S.ResourceDescription>
          {resource.resourceType === 'Course' ? (
            <S.Tags data-cy="resource-tags">
              {/* ----- COURSE META ------*/}
              {resource.credits !== undefined && (
                <S.TagItem data-cy="course-tag-credit">
                  <VerifiedIcon
                    aria-hidden="true"
                    role="img"
                  />
                  {Number(resource.credits)} credit
                  {Number(resource.credits) > 1 && 's'}
                </S.TagItem>
              )}
              <S.TagItem data-cy="course-tag-time">
                <TimeIcon
                  aria-hidden="true"
                  role="img"
                />
                {hoursAndMinutesString}
              </S.TagItem>
              <S.TagItem data-cy="course-tag-content-medium">
                <ContentMediumIcon
                  aria-hidden="true"
                  role="img"
                />
                {resource.contentMedium}
              </S.TagItem>
            </S.Tags>
          ) : (
            <S.Tags data-cy="resource-tags">
              {/* ----- COLLECTION META ------*/}
              {resource.communities !== undefined && resource.communities.length > 0 && (
                <S.TagItem data-cy="collection-tag-communities">
                  <GroupIcon
                    aria-hidden="true"
                    role="img"
                  />
                  {resource.communities.map(c => c.toUpperCase()).join(', ')}
                </S.TagItem>
              )}
              <S.TagItem data-cy="collection-tag-level">
                <LevelIcon
                  aria-hidden="true"
                  role="img"
                />
                {capitalize(resource.level)}
              </S.TagItem>
              <S.TagItem data-cy="collection-tag-time">
                <TimeIcon
                  aria-hidden="true"
                  role="img"
                />
                {hoursAndMinutesString}
              </S.TagItem>
            </S.Tags>
          )}
        </S.ResourceContent>
        <S.ResourceProgress>
          {((nextResourceId === resource.id && !isIncompleteCollection) ||
            resource.completedAt !== null) && (
            <S.ResourceTagGroup
              aria-label="Resource progress tags"
              data-cy="resource-progress-tag"
              variant={nextResourceId === resource.id ? 'blue' : 'green'}
            >
              {nextResourceId === resource.id ? (
                <Item textValue="Next up">Next up</Item>
              ) : resource.completedAt !== null ? (
                <Item textValue="Completed">
                  Completed{' '}
                  {Temporal.PlainDate.from(
                    resource.completedAt[resource.completedAt.length - 1] === 'Z'
                      ? resource.completedAt.slice(0, -1)
                      : resource.completedAt
                  ).toLocaleString('en-US', { dateStyle: 'long' })}
                </Item>
              ) : (
                <div />
              )}
            </S.ResourceTagGroup>
          )}
          {isIncompleteCollection && (
            <S.ProgressBarTag>
              <MiniPercentageProgressBar
                aria-label={`${resource.name} progress`}
                completed={resource.totalCompletedCourses}
                data-cy="resource-progress-bar"
                percentage={
                  resource.totalCompletedCourses !== undefined &&
                  resource.totalCourses !== undefined &&
                  resource.totalCourses > 0
                    ? (resource.totalCompletedCourses / resource.totalCourses) * 100
                    : 0
                }
                total={resource.totalCourses}
              />
            </S.ProgressBarTag>
          )}
        </S.ResourceProgress>
      </S.ResourceBody>
    </S.ResourceItem>
  );
};

export default ResourceCard;
