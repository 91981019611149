import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import * as S from './styles';

interface Props {
  className?: string;
  headerComponent: JSX.Element;
}

const ReportWrapper = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, className, headerComponent }, forwardedRef) => (
    <S.ReportTemplate
      ref={forwardedRef}
      className={className}
    >
      {headerComponent}
      <S.ReportContent>{children}</S.ReportContent>
    </S.ReportTemplate>
  )
);

ReportWrapper.displayName = 'ReportWrapper';

export default ReportWrapper;
