import type { ReactNode } from 'react';

import * as S from './styles';

interface Props {
  children: ReactNode;
}

const ButtonWrapper = ({ children }: Props) => <S.ButtonWrapper>{children}</S.ButtonWrapper>;

export default ButtonWrapper;
