import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import styled from 'styled-components';

export const Layout = styled.div`
  ${MOBILE_BREAKPOINT_RANGE} {
    padding-right: var(--space-6);
    padding-left: var(--space-6);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 78.125rem; /* 1250px */
    margin-right: auto;
    margin-left: auto;
    padding-right: var(--space-11);
    padding-left: var(--space-11);
  }
`;

export const Main = styled.main``;

export const Page = styled.div`
  min-height: 100dvh;
  background-color: var(--white-light);
`;
