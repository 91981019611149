import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import { MOBILE_HEADER_HEIGHT, SECONDARY_NAVIGATION_HEIGHT } from '@/pages/Dashboard/constants';
import styled from 'styled-components';

export const SecondaryNavigation = {
  Root: styled.nav`
    position: sticky;
    background-color: white;
    z-index: 2;
    border-bottom: 1px solid var(--gray-200);

    ${MOBILE_BREAKPOINT_RANGE} {
      top: ${MOBILE_HEADER_HEIGHT}px;
      height: ${SECONDARY_NAVIGATION_HEIGHT.mobile}px;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      top: 0;
      height: ${SECONDARY_NAVIGATION_HEIGHT.desktop}px;
      transition: height 0.5s;

      &.active-has-children {
        height: calc(${SECONDARY_NAVIGATION_HEIGHT.desktop}px + 3.5rem);
      }
    }
  `,
  Text: styled.div`
    font-size: 2.5625rem;
    font-weight: 500;
    color: var(--purple-400);
  `,
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    max-width: 1420px;
    margin-right: auto;
    margin-left: auto;
    padding-left: var(--space-9);

    ${DESKTOP_BREAKPOINT_RANGE} {
      height: ${SECONDARY_NAVIGATION_HEIGHT.desktop}px;
    }

    ${MOBILE_BREAKPOINT_RANGE} {
      height: auto;
    }

    & > div:last-child {
      display: contents;
    }
  `
};
