import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const Bar = styled.div`
  width: 100%;
  background: var(--gray-100);
  height: 1.5rem;
  border-radius: 3.1875rem;
  transition: background 0.3s ease-in-out;

  &.is-disabled {
    background: repeating-linear-gradient(
      -45deg,
      var(--gray-100),
      var(--gray-100) 10px,
      var(--gray-200) 10px,
      var(--gray-200) 20px
    );
  }

  &.is-small {
    height: 0.75rem;
  }
`;

export const Meter = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  width: 100%;
`;

export const PercentValue = styled.div`
  ${TEXT_STYLES.ctaBaseSmall}
  align-items: flex-start;
  color: var(--purple-400);
  display: flex;
  justify-content: end;
  text-align: right;
  width: 3.5rem;
`;

export const ValueBar = styled.div`
  height: 1.5rem;
  border-radius: 3.1875rem;

  &.primary {
    background: var(--purple-400);
  }

  &.secondary {
    background: var(--gray-300);
  }

  &.is-small {
    height: 0.75rem;
  }
`;
