import type { Key } from 'react';

interface LanguageRecord {
  id?: string;
  language: Key | null;
  proficiency: Key;
}

const LanguageProficiencies = class {
  apiUrl: string;
  store: LanguageRecord[];
  requestOptions: RequestInit;

  constructor(bearerToken: string) {
    this.apiUrl = `${import.meta.env.VITE_API_V2_BASE_PATH}/language_proficiencies`;
    this.store = [];
    this.requestOptions = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      }
    };
  }

  findAll = async () => {
    const response = await fetch(this.apiUrl, this.requestOptions);
    const json = (await response.json()) as APILanguageProficiencies;
    if (response.ok) {
      this.store = json.data;
    }

    return { json, response };
  };

  updateAll = (drafts: LanguageRecord[]) => {
    const createAndUpdateRequests = this.processCreatesAndUpdates(drafts);
    const deleteRequests = this.processDeletes(drafts);
    const allRequests = [...createAndUpdateRequests, ...deleteRequests];

    if (allRequests.length > 0) {
      return Promise.all(allRequests).then(responses => responses.every(response => response.ok));
    }

    return Promise.resolve(true);
  };

  update = async (draft: LanguageRecord): Promise<Response> => {
    const options = {
      method: 'PUT',
      ...this.requestOptions,
      body: JSON.stringify({
        language_proficiency: draft
      })
    };

    return await fetch(`${this.apiUrl}/${draft.id}`, options);
  };

  create = async (draft: LanguageRecord): Promise<Response> => {
    const options = {
      method: 'POST',
      ...this.requestOptions,
      body: JSON.stringify({
        language_proficiency: draft
      })
    };

    return await fetch(`${this.apiUrl}`, options);
  };

  destroy = async (record: LanguageRecord) => {
    const options = {
      method: 'DELETE',
      ...this.requestOptions
    };

    return await fetch(`${this.apiUrl}/${record.id}`, options);
  };

  processDeletes = (drafts: LanguageRecord[]) => {
    const requests: Promise<Response>[] = [];

    this.store
      .filter(record => !drafts.some(draft => draft.language === record.language))
      .forEach(record => requests.push(this.destroy(record)));

    return requests;
  };

  processCreatesAndUpdates = (drafts: LanguageRecord[]) => {
    const requests: Promise<Response>[] = [];

    drafts.forEach(draft => {
      if (draft.id === undefined) {
        if (this.existingStorageRecord(draft) === undefined) {
          // this is a brand new language record to create
          requests.push(this.create(draft));
        } else {
          // this is an existing langauge record we need to update
          requests.push(
            this.update({
              ...draft,
              id: this.existingStorageRecord(draft)!.id
            })
          );
        }
      }
    });

    return requests;
  };

  existingStorageRecord = (draft: LanguageRecord) => {
    const foundRecord = this.store.find(storeRecord => storeRecord.language === draft.language);

    return foundRecord;
  };
};

export default LanguageProficiencies;
