import type { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  className?: string;
  'data-cy'?: string;
  size?: 'dense' | 'regular' | 'small';
}

const ContentContainer = ({
  children,
  className,
  'data-cy': dataCy,
  size = 'regular'
}: PropsWithChildren<Props>) => (
  <S.Container
    $size={size}
    className={className}
    data-cy={dataCy}
  >
    {children}
  </S.Container>
);

export default ContentContainer;
