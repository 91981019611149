import { Outlet, useLocation } from 'react-router-dom';
import SecondaryNavigation from '@/components/SecondaryNavigation';
import ToastProvider from '@/components/ToastProvider';
import useUser from '@/hooks/useUser';
import styles from '@/components/Wrapper.module.css';

const Wrapper = () => {
  const { user } = useUser();

  const location = useLocation();

  const profileLinks = [
    {
      link: '/dashboard/profile/account-information',
      title: 'Account information'
    },
    {
      hideCondition: user.is_clinical,
      link: '/dashboard/profile/identity-and-languages',
      title: 'Identity & languages'
    },
    {
      children: [
        {
          link: '/identity-and-languages',
          title: 'Identity & languages'
        },
        {
          link: '/cultural-competencies',
          title: 'Community engagement'
        },
        {
          link: '/work-experience',
          title: 'Work experience'
        },
        {
          link: '/education-history',
          title: 'Education history'
        },
        {
          link: '/additional-experience',
          title: 'Additional experience'
        }
      ],
      hideCondition: !user.is_clinical,
      link: '/dashboard/profile/identity-and-languages',
      parentLink: '/dashboard/profile',
      title: 'Background'
    },
    {
      link: '/dashboard/my-education/completions-and-certificates',
      title: 'My education'
    }
  ];

  return (
    <ToastProvider>
      {toastState => (
        <>
          <SecondaryNavigation
            linkList={profileLinks}
            navTitle="Profile"
          />
          <div
            key={location.pathname}
            className={styles.dashboardContentWrapper}
          >
            <Outlet context={toastState} />
          </div>
        </>
      )}
    </ToastProvider>
  );
};

export default Wrapper;
