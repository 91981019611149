import BaseCloseIcon from '@material-design-icons/svg/round/close.svg?react';
import UnstyledButton from '@/components/UnstyledButton';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const CloseIcon = styled(BaseCloseIcon)`
  display: block;
  width: 1rem;
  height: 1rem;
  fill: currentcolor;
`;

export const GridCell = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;

  & > svg {
    width: 1rem;
    height: 1rem;
    fill: currentcolor;
  }
`;

export const RemoveButton = styled(UnstyledButton)`
  border-radius: 100%;
`;

interface TagProps {
  $allowsRemoving: boolean;
  $isDisabled: boolean;
  $isFocusVisible: boolean;
  $isHovered: boolean;
  $isPressed: boolean;
  $size: 'large' | 'small';
  $variant: 'blue' | 'dark' | 'gray' | 'green' | 'light';
}

export const Tag = styled.div<TagProps>`
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  outline: none;
  cursor: default;
  user-select: none;

  ${({ $isFocusVisible }) =>
    $isFocusVisible &&
    `
    outline: 2px solid var(--bright-purple-400);
    outline-offset: 1px;
  `}

  ${({ $size }) => {
    if ($size === 'large') {
      return `
        ${TEXT_STYLES.tagLarge}
      `;
    } else {
      return `
        ${TEXT_STYLES.tagSmall}
      `;
    }
  }}

  ${({ $allowsRemoving, $isDisabled, $isHovered, $isPressed, $variant }) => {
    if ($isDisabled) {
      return `
        color: var(--gray-500);
        background-color: var(--gray-50);
      `;
    }

    if ($variant === 'blue') {
      if ($allowsRemoving) {
        return `
          color: var(--blue-600);
          background-color: var(--blue-50);

          ${RemoveButton}.is-hovered {
            background-color: var(--blue-100);
          }

          ${RemoveButton}.is-pressed {
            background-color: var(--blue-200);
          }
        `;
      } else {
        return `
          color: var(--blue-600);
          background-color: var(--blue-50);
        `;
      }
    } else if ($variant === 'green') {
      if ($allowsRemoving) {
        return `
          color: var(--success-600);
          background-color: var(--success-50);

          ${RemoveButton}.is-hovered {
            background-color: var(--success-100);
          }

          ${RemoveButton}.is-pressed {
            background-color: var(--success-200);
          }
        `;
      } else {
        return `
          color: var(--success-600);
          background-color: var(--success-50);
        `;
      }
    } else if ($variant === 'dark') {
      if ($allowsRemoving) {
        return `
          color: var(--white-light);
          background-color: var(--purple-400);

          ${RemoveButton}.is-hovered {
            background-color: var(--purple-600);
          }

          ${RemoveButton}.is-pressed {
            background-color: var(--brand-bright);
          }
        `;
      } else {
        return `
          color: var(--white-light);
          background-color: var(--purple-400);
        `;
      }
    } else if ($variant === 'gray') {
      if ($allowsRemoving) {
        return `
          color: var(--gray-800);
          background-color: var(--gray-50);

          ${RemoveButton}.is-hovered {
            background-color: var(--gray-300);
          }

          ${RemoveButton}.is-pressed {
            background-color: var(--gray-300);
          }
        `;
      } else {
        if ($isPressed) {
          return `
            color: var(--gray-800);
            background-color: var(--gray-400);
          `;
        } else if ($isHovered) {
          return `
            color: var(--gray-800);
            background-color: var(--gray-100);
          `;
        } else {
          return `
            color: var(--gray-800);
            background-color: var(--gray-50);
          `;
        }
      }
    } else {
      if ($allowsRemoving) {
        return `
          color: var(--purple-600);
          background-color: var(--light-purple-50);

          ${RemoveButton}.is-hovered {
            background-color: var(--light-purple-300);
          }

          ${RemoveButton}.is-pressed {
            background-color: var(--light-purple-300);
          }
        `;
      } else {
        if ($isPressed) {
          return `
            color: var(--purple-600);
            background-color: var(--light-purple-100);
          `;
        } else {
          return `
            color: var(--purple-600);
            background-color: var(--light-purple-50);
          `;
        }
      }
    }
  }}
`;
