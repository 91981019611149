import { OverlayContainer } from 'react-aria';
import { useNavigate } from 'react-router-dom';

import ModalDialog from '../ModalDialogOld';

import * as S from './styles';

interface Props {
  close: () => void;
}

const ForbiddenModalDialog = ({ close }: Props) => {
  const navigate = useNavigate();

  const handleGoBackButtonPress = () => {
    navigate('/dashboard');
    close();
  };

  return (
    <OverlayContainer>
      <ModalDialog
        isOpen
        data-cy="forbidden-modal-dialog"
        title="You don't have permission to view this content."
      >
        <S.Text>
          If you think you are receiving this message in error, please contact your organization
          admin or reach out to{' '}
          <S.EmailLink href="mailto:support@joinviolet.com">support@joinviolet.com</S.EmailLink>.
        </S.Text>
        <S.Button
          data-cy="return-to-dashboard-button"
          size="large"
          onPress={handleGoBackButtonPress}
        >
          Return to dashboard
        </S.Button>
      </ModalDialog>
    </OverlayContainer>
  );
};

export default ForbiddenModalDialog;
