import type { RefObject } from 'react';
import type { AriaListBoxOptions } from 'react-aria';
import { useListBox } from 'react-aria';
import type { ComboBoxState } from 'react-stately';

import ListBoxSection from './ListBoxSection';
import Option from './Option';
import * as S from './styles';

interface Props extends AriaListBoxOptions<object> {
  className?: string;
  listBoxRef: RefObject<HTMLUListElement>;
  state: ComboBoxState<object>;
}

const ListBox = ({ className, listBoxRef, selectedKeys, state, ...ariaListBoxProps }: Props) => {
  const { listBoxProps } = useListBox(ariaListBoxProps, state, listBoxRef);

  return (
    <S.List
      {...listBoxProps}
      ref={listBoxRef}
      className={className}
    >
      {[...state.collection].map(item =>
        item.type === 'section' ? (
          <ListBoxSection
            key={item.key}
            section={item}
            selectedKeys={selectedKeys instanceof Set ? selectedKeys : new Set(selectedKeys)}
            state={state}
          />
        ) : (
          <Option
            key={item.key}
            isDisabled={state.disabledKeys.has(item.key)}
            isSelected={
              selectedKeys === 'all' ||
              (selectedKeys instanceof Set
                ? selectedKeys.has(item.key)
                : Array.from(selectedKeys ?? []).includes(item.key))
            }
            item={item}
            state={state}
          />
        )
      )}
    </S.List>
  );
};

export default ListBox;
