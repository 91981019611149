import type { RedirectLoginOptions } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import type { NetworkOverview } from '@/pages/Dashboard/utils';
import { generateNetworkOverview } from '@/pages/Dashboard/utils';
import { downloadPdf } from '@/utils/downloadPdf';
import { downloadPng } from '@/utils/downloadPng';
import isNonEmptyString from '@/utils/isNonEmptyString';

import { useReportAuth } from '../ReportAuthContext';
import { ReportDownloadBar } from '../ReportDownloadBar';

import NetworkOverviewReport from './NetworkOverviewReport';
import * as S from './styles';

const NetworkOverviewReportWrapper = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { bearerToken } = useReportAuth();
  const [networkOverview, setNetworkOverview] = useState<NetworkOverview | undefined>(undefined);
  const networkOverviewReportRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const { loginWithRedirect } = useAuth0();

  const fetchOverview = useCallback(async () => {
    if (networkOverview || bearerToken === '') {
      return;
    }
    const url = `${import.meta.env.VITE_API_BASE_PATH}/networks/overview${
      organizationId !== undefined ? `?organization_id=${organizationId}` : ''
    }`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          const redirectLoginOptions: RedirectLoginOptions = {
            appState: { returnTo: `${window.location.pathname}${window.location.search}` }
          };
          loginWithRedirect(redirectLoginOptions);
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APINetworksOverview;
      setNetworkOverview(generateNetworkOverview(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [bearerToken, loginWithRedirect, networkOverview, organizationId]);

  useEffect(() => {
    if (bearerToken && !networkOverview) {
      /*
        Prevents double overview API call from
        useEffect being re-run because of changes
      */
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        fetchOverview();
      }, 200);
    }
  }, [bearerToken, fetchOverview, networkOverview]);

  const generateReportTitle = useCallback(() => {
    if (networkOverview === undefined) {
      return '';
    }
    const date = new Date();
    const formattedDate = date
      .toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
      .replace(/\//g, '-');
    return `Violet_NetworkOverview_${networkOverview.organizationName?.replaceAll(
      ' ',
      ''
    )}_${formattedDate}`;
  }, [networkOverview]);

  const handleDownloadButtonClick = async (downloadType: number | string): Promise<void> => {
    if (downloadType === 'png') {
      await downloadPng(networkOverviewReportRef.current as HTMLElement, generateReportTitle());
    } else if (downloadType === 'pdf') {
      await downloadPdf(networkOverviewReportRef.current as HTMLElement, generateReportTitle());
    }
  };

  return networkOverview !== undefined ? (
    <S.ReportWrapper className={isNonEmptyString(organizationId) ? 'is-admin' : ''}>
      <S.ExportContainer>
        <NetworkOverviewReport
          ref={networkOverviewReportRef}
          networkOverview={networkOverview}
        />
      </S.ExportContainer>
      {/* only show download for Admins */}
      {isNonEmptyString(organizationId) && (
        <ReportDownloadBar
          reportName="network-overview"
          onDownloadClick={handleDownloadButtonClick}
        />
      )}
    </S.ReportWrapper>
  ) : (
    <Spinner withWrapper />
  );
};

export default NetworkOverviewReportWrapper;
