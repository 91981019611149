import type { ChangeEventHandler, ReactElement } from 'react';
import { Children, createContext, isValidElement, useEffect, useRef, useState } from 'react';
import type { AriaCheckboxGroupProps, AriaCheckboxProps, AriaTextFieldProps } from 'react-aria';
import { useCheckboxGroup } from 'react-aria';
import type { CheckboxGroupState } from 'react-stately';
import { useCheckboxGroupState } from 'react-stately';
import isNonEmptyString from '@/utils/isNonEmptyString';

import GroupCheckbox from './GroupCheckbox';
import * as S from './styles';

interface GroupContext {
  size: 'regular' | 'small';
  state: CheckboxGroupState;
}

export const CheckboxGroupContext = createContext<GroupContext | null>(null);

interface Props extends AriaCheckboxGroupProps {
  allowOther?: boolean;
  children: ReactElement<AriaCheckboxProps> | ReactElement<AriaCheckboxProps>[];
  className?: string;
  direction?: 'horizontal' | 'vertical';
  groupLabelColor?: 'gray-800' | 'purple-400';
  groupLabelIsAllCaps?: boolean;
  otherLabel?: string;
  size?: 'regular' | 'small';
}

const CheckboxGroup = ({
  allowOther = false,
  children,
  className,
  direction = 'vertical',
  groupLabelColor = 'gray-800',
  groupLabelIsAllCaps = false,
  otherLabel,
  size = 'regular',
  ...ariaCheckboxGroupProps
}: Props) => {
  const { errorMessage, isInvalid, isRequired = false, label } = ariaCheckboxGroupProps;
  const state = useCheckboxGroupState(ariaCheckboxGroupProps);
  const { errorMessageProps, groupProps, labelProps, validationErrors } = useCheckboxGroup(
    ariaCheckboxGroupProps,
    state
  );
  const [hiddenValue, setHiddenValue] = useState(state.value.join(',').trim());
  const [otherValue, setOtherValue] = useState('');
  const validationRef = useRef<HTMLInputElement>(null);
  const allPresetValuesRef = useRef(
    Children.map(children, element => {
      if (!isValidElement(element)) return;
      return element.props.value;
    })
  );
  const isFirstRender = useRef(true);

  const updateOtherSelection: AriaTextFieldProps['onChange'] = value => {
    setOtherValue(value);
    const currentSelections = allPresetValuesRef.current.filter(presetValue =>
      state.value.includes(presetValue)
    );
    if (value === '') {
      state.setValue(currentSelections);
    } else {
      state.setValue([...currentSelections, value]);
    }
  };

  const handleHiddenValueChange: ChangeEventHandler<HTMLInputElement> = event => {
    setHiddenValue(event.target.value);
  };

  useEffect(() => {
    if (validationRef.current === null) return;
    if (state.value.length === 0 && isRequired) {
      validationRef.current.setCustomValidity('Please check at least one option.');
    } else {
      validationRef.current.setCustomValidity('');
    }
  }, [isRequired, state.value, validationRef]);

  useEffect(() => {
    if (!isFirstRender.current) return;

    state.value.forEach(value => {
      if (!allPresetValuesRef.current.includes(value)) {
        setOtherValue(value.trim());
      }
    });
  }, [state.value]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    setHiddenValue(state.value.join(',').trim());
  }, [state.value]);

  return (
    <S.Group
      {...groupProps}
      $direction={direction}
      $size={size}
      className={className}
    >
      {(isNonEmptyString(label) || isValidElement(label)) && (
        <S.GroupLabel
          {...labelProps}
          $color={groupLabelColor}
          $isAllCaps={groupLabelIsAllCaps}
          className="group-label"
        >
          {label}
        </S.GroupLabel>
      )}
      <CheckboxGroupContext.Provider value={{ size, state }}>
        <S.CheckboxGroup>
          <S.VisuallyHiddenContainer>
            <S.VisuallyHiddenValidation
              ref={validationRef}
              aria-label="Please ignore this field"
              required={isRequired}
              tabIndex={-1}
              type="text"
              value={hiddenValue}
              onChange={handleHiddenValueChange}
            />
          </S.VisuallyHiddenContainer>
          <S.Checkboxes className="checkbox-container">
            {children}
            {allowOther && otherLabel !== undefined && (
              <GroupCheckbox
                aria-label="Other (custom answer)"
                isDisabled={!otherValue && otherValue === ''}
                value={otherValue && otherValue !== '' ? otherValue : 'other'}
              >
                <S.OtherTextField
                  $size={size}
                  aria-label="Enter your custom answer"
                  placeholder={otherLabel}
                  size={otherLabel.length}
                  value={otherValue !== '' ? otherValue : ''}
                  onChange={updateOtherSelection}
                />
              </GroupCheckbox>
            )}
          </S.Checkboxes>
        </S.CheckboxGroup>
        {isInvalid === true && (
          <S.ErrorMessage {...errorMessageProps}>
            {errorMessage !== null && typeof errorMessage !== 'function'
              ? errorMessage
              : validationErrors
                  .join(' ')
                  .replace(
                    'Please check this box if you want to proceed.',
                    'Please check at least one option.'
                  )}
          </S.ErrorMessage>
        )}
      </CheckboxGroupContext.Provider>
    </S.Group>
  );
};

export default CheckboxGroup;
