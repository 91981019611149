import MeterWithGoal from '@/components/Reporting/MeterWithGoal';
import type { OrganizationOverview } from '@/pages/Dashboard/utils';

import * as S from './styles';

interface Props {
  className?: string;
  organizationOverview: OrganizationOverview;
}

const VioRequirements = ({ className, organizationOverview }: Props) => (
  <S.VioRequirementsCard className={className}>
    <S.RequirementsTitle>Requirements</S.RequirementsTitle>
    <MeterWithGoal
      currentTotal={organizationOverview.userMetrics.onboarded.clinical}
      data-cy="vio-users-onboarded"
      goalValue={organizationOverview.verifiedInclusiveCriteria.percentageOnboardedThreshold}
      label="Onboarding rate"
      labelPopoverText="Total number of providers who have completed their Violet profiles to receive their Benchmarks."
      outOfTotal={organizationOverview.userMetrics.clinical.total}
      value={organizationOverview.userMetrics.onboarded.clinicalPercentage}
    />
    <MeterWithGoal
      currentTotal={organizationOverview.userMetrics.benchmarked.total}
      data-cy="vio-users-benchmarked"
      goalValue={organizationOverview.verifiedInclusiveCriteria.percentageBenchmarkedThreshold}
      isLocked={
        organizationOverview.userMetrics.onboarded.clinicalPercentage <
        organizationOverview.verifiedInclusiveCriteria.percentageOnboardedThreshold
      }
      label="Verified Inclusive Providers"
      labelPopoverText="Percentage and total number of onboarded providers who have achieved an Awareness Benchmark or greater in at least one community (BIPOC, LGBQ, or TGNC). This requirement will appear locked if your organization has not met the threshold for onboarded providers."
      outOfTotal={organizationOverview.userMetrics.onboarded.clinical}
      value={organizationOverview.verifiedInclusiveCriteria.percentageBenchmarked}
    />
  </S.VioRequirementsCard>
);

export default VioRequirements;
