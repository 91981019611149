import type { FormEventHandler } from 'react';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackNext from '@/components/Buttons/BackNext';
import SliderAndNumberField from '@/components/FormFields/SliderAndNumberField';
import OnboardingHero from '@/components/heroes/OnboardingHero';
import useApiRequest from '@/pages/Dashboard/hooks/useApiRequest';
import useGetNonClinicalExperiences from '@/pages/Dashboard/hooks/useGetNonClinicalExperiences';
import type { NonClinicalExperience } from '@/pages/Dashboard/utils';

import useUser from '../../../hooks/useUser';
import NonClinicalExperienceTable from '../../components/NonClinicalExperienceTable';
import { NonClinicalExperiencesContext } from '../../components/NonClinicalExperienceTable/useNonClinicalExperiences';
import OnboardingLayout from '../components/OnboardingLayout';

import * as S from './styles';

const FurtherExperience = () => {
  const { setUser, user } = useUser();
  const navigate = useNavigate();
  const { patchRequest, postRequest } = useApiRequest();
  const { createNonClinicalExperience, deleteNonClinicalExperience, patchNonClinicalExperience } =
    useGetNonClinicalExperiences();

  const { further_experience } = user.user_info;

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [invalidRowIndices, setInvalidRowIndices] = useState<number[]>([]);

  const [originalNonClinicalExperiences, setOriginalNonClinicalExperiences] = useState<
    NonClinicalExperience[]
  >([]);
  const [draftNonClinicalExperiences, setDraftNonClinicalExperiences] = useState<
    NonClinicalExperience[]
  >([]);

  const [inequalityWithinHealthcareRecognition, setInequalityWithinHealthcareRecognition] =
    useState<number>(further_experience?.inequality_within_healthcare_recognition ?? 0);
  const [patientsDecisionAdvocate, setPatientsDecisionAdvocate] = useState<number>(
    further_experience?.patients_decisions_advocation ?? 0
  );
  const textareaRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const submitHandler: FormEventHandler = async event => {
    event.preventDefault();

    setHasAttemptedSubmit(true);

    if (invalidRowIndices.length > 0) {
      /* Don't attempt submission if any rows are invalid */
      if (!formRef.current) {
        window.scrollTo(0, 0);
      } else {
        formRef.current.reportValidity();
      }
      return;
    }

    if (formRef.current?.checkValidity() === false) {
      formRef.current.reportValidity();
      return;
    }

    /* Thou may proceed to handle submission */
    setFormIsSubmitting(true);

    let encounteredError = 0;

    /* Save all nonclinical experience entries */
    draftNonClinicalExperiences.forEach(async (experience, index) => {
      if (experience.id === '') {
        /* No id === create */
        try {
          const newExperience = await createNonClinicalExperience(experience);
          if (newExperience) {
            setDraftNonClinicalExperiences(prevState => {
              const newState = [...prevState];
              newState[index] = newExperience;
              return newState;
            });
            setOriginalNonClinicalExperiences(prevState => [...prevState, newExperience]);
          }
        } catch (_error) {
          encounteredError++;
        }
      } else if (originalNonClinicalExperiences.find(e => e.id === experience.id)) {
        /* Id exists in nonClinicalExperiences === patch */
        try {
          const changedExperience = await patchNonClinicalExperience(experience);
          if (changedExperience) {
            setDraftNonClinicalExperiences(prevState => {
              const newState = [...prevState];
              newState[index] = changedExperience;
              return newState;
            });
            setOriginalNonClinicalExperiences(prevState => {
              const newState = [...prevState];
              const originalIndex = prevState.findIndex(e => e.id === changedExperience.id);
              newState[originalIndex] = changedExperience;
              return newState;
            });
          }
        } catch (_error) {
          encounteredError++;
        }
      }
    });

    originalNonClinicalExperiences.forEach(async experience => {
      if (!draftNonClinicalExperiences.find(e => e.id === experience.id)) {
        /* Id no longer in draftNonClinicalExperiences === delete */
        try {
          await deleteNonClinicalExperience(experience.id);
          setOriginalNonClinicalExperiences(prevState =>
            prevState.filter(e => e.id !== experience.id)
          );
        } catch (_error) {
          encounteredError++;
        }
      }
    });

    if (encounteredError > 0) {
      setFormIsSubmitting(false);
      return;
    }

    /* Update further experience */
    try {
      const onboardResponse = (await patchRequest(
        `${import.meta.env.VITE_API_BASE_PATH}/users/onboard`,
        {
          user: {
            id: user.id,
            user_info: {
              further_experience: {
                affirmation_influence_recognition: null /* removed from onboarding ?s */,
                cultural_beliefs_about_health_acceptance: null /* removed from onboarding ?s */,
                cultural_differences_recognition: null /* removed from onboarding ?s */,
                free_text: textareaRef.current?.value ?? further_experience?.free_text ?? '',
                id: further_experience?.id,
                inequality_within_healthcare_recognition: inequalityWithinHealthcareRecognition,
                patients_decisions_advocation: patientsDecisionAdvocate,
                user_info_id: user.user_info.id
              },
              id: user.user_info.id,
              user_id: user.id
            }
          }
        }
      )) as APIUsersOnboard | undefined;
      if (onboardResponse !== undefined) {
        setUser(onboardResponse.data);
      }
    } catch (_error) {
      setFormIsSubmitting(false);
      return;
    }

    /* Mark onboarding as complete */
    try {
      await postRequest(`${import.meta.env.VITE_API_V2_BASE_PATH}/profile/complete_onboarding`, {});
      setUser({ ...user, completed_onboarding: true });
      navigate('/onboarding/submitted');
    } catch (_error) {
      setFormIsSubmitting(false);
      return;
    }

    setFormIsSubmitting(false);
  };

  const memoizedNonClinicalContextValue = useMemo(
    () => ({
      draftNonClinicalExperiences,
      invalidRowIndices,
      originalNonClinicalExperiences,
      setDraftNonClinicalExperiences,
      setInvalidRowIndices,
      setOriginalNonClinicalExperiences
    }),
    [originalNonClinicalExperiences, draftNonClinicalExperiences, invalidRowIndices]
  );

  return (
    <OnboardingLayout progressBarValue={5}>
      <form
        ref={formRef}
        noValidate
        onSubmit={submitHandler}
      >
        <NonClinicalExperiencesContext.Provider value={memoizedNonClinicalContextValue}>
          <OnboardingHero
            copy="Finally, we're interested in any additional relevant experiences beyond formal training or clinical practice as well as your self-assessment regarding your capacity to tailor care to a patient's cultural identity and recognize health care disparities."
            graphicType="self-efficacy"
            header="Self assessment."
          />
          <S.Heading>Additional experience</S.Heading>
          <S.Description>
            Please share any volunteer work, research or community engagement initiatives, teaching
            or leadership roles relevant to providing culturally competent care for LGBQ, TGNC, and
            BIPOC communities.
          </S.Description>
          <NonClinicalExperienceTable hasAttemptedSubmit={hasAttemptedSubmit} />
          <S.SliderGroup header="How important do you find the following statements?">
            <SliderAndNumberField
              data-cy="inequality-field"
              label="When I am meeting patients from different cultural groups, I recognize that they may have experienced inequality and disparities within the health care system."
              maxValue={10}
              minValue={0}
              setValue={setInequalityWithinHealthcareRecognition}
              value={inequalityWithinHealthcareRecognition}
            />
            <SliderAndNumberField
              data-cy="patient-advocacy-field"
              label="I advocate for a patient's decisions based on their cultural beliefs."
              maxValue={10}
              minValue={0}
              setValue={setPatientsDecisionAdvocate}
              value={patientsDecisionAdvocate}
            />
          </S.SliderGroup>
          <S.TextAreaContainer
            ref={textareaRef}
            isMultiline
            data-cy="further-thoughts-field"
            defaultValue={further_experience?.free_text ?? ''}
            label="Anything else you want us to know?"
            placeholder="Please type here..."
            type="textarea"
          />
          <BackNext
            backTo="/onboarding/work-experience"
            nextIsLoading={formIsSubmitting}
            nextLabel="Submit"
          />
        </NonClinicalExperiencesContext.Provider>
      </form>
    </OnboardingLayout>
  );
};

export default FurtherExperience;
