import CloseIcon from '@material-symbols/svg-400/rounded/close.svg?react';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import { BLACK_CHARCOAL_COLOR } from '../../GlobalStyle';
import UnstyledButton from '../../UnstyledButton';

export const CloseButton = {
  Icon: styled(CloseIcon)`
    display: block;
    fill: var(--gray-500);
    width: 1rem;
    height: 1rem;
  `,
  Root: styled(UnstyledButton)`
    align-self: end;
    display: flex;
    width: 1.5rem;
    padding: 0.25rem;
    align-items: center;
    margin-bottom: 1.5rem;
    border-radius: 100%;
    background: var(--gray-100);

    &.is-hovered {
      background: var(--gray-200);
    }

    &.is-focused {
      outline: 2px solid var(--bright-purple-400);
      outline-offset: 2px;
    }
  `
};

export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 1rem * 2);
  max-height: 100%;
  width: 37rem;
  padding: 1rem 1.5rem;
  background-color: var(--white-light);
  overflow-y: auto;
  border-radius: 1.5rem;
  border: 1px solid var(--gray-200);
`;

export const ModalDialog = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  padding: var(--space-6);
  background-color: ${transparentize(0.3, BLACK_CHARCOAL_COLOR)};
  z-index: 2;
`;

export const Title = styled.h3`
  margin-bottom: var(--space-4);
  ${TEXT_STYLES.headlineSectionLevel2Bold}
  color: var(--gray-800);
`;
