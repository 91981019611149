import expandScreenSizeImageSrc from './expand-screen-size.svg?url';
import * as S from './styles';

const ScreenSizeWarning = () => (
  <S.ScreenSizeWarning.Root>
    <S.ScreenSizeWarning.Container>
      <S.ScreenSizeWarning.Image src={expandScreenSizeImageSrc} />
      <S.ScreenSizeWarning.Text>
        Please come back to this page on a larger screen.
      </S.ScreenSizeWarning.Text>
    </S.ScreenSizeWarning.Container>
  </S.ScreenSizeWarning.Root>
);

export default ScreenSizeWarning;
