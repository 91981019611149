import DataContainer from '@/components/Reporting/DataContainer';
import Table from '@/components/Table';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const EmptyState = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
`;

export const OverviewLocationTable = styled(Table)`
  tr th:nth-child(2) {
    > div {
      text-align: end;
    }
  }

  tr th:nth-child(3) {
    background-color: var(--light-purple-50);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  tr th:nth-child(4) {
    background-color: var(--light-purple-50);
  }

  tr th:nth-child(5) {
    background-color: var(--light-purple-50);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  tr td {
    height: 3.125rem;
  }
`;

export const SingleTable = styled(DataContainer)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-bottom: 0;
`;
