import UnstyledToggleButton from '@/components/UnstyledToggleButton';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';

export const Bookmark = styled(UnstyledToggleButton)`
  & > svg {
    display: block;
    height: 1.5rem;
    width: auto;
    fill: var(--gray-800);
  }

  &.is-hovered:not(&.is-selected) > svg {
    fill: var(--purple-400);
  }

  &.is-selected > svg {
    fill: var(--purple-400);
  }

  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
    border-radius: 0.25rem;
  }
`;

export const Tooltip = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  white-space: nowrap;
  color: var(--gray-700);
`;
