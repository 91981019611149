import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

import { default as BaseOption } from './Option';

export const List = styled.ul`
  display: grid;
  width: 100%;
  row-gap: 0;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  list-style-type: none;
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
  z-index: 2;
  max-height: 11rem;
  overflow: auto;
  outline: 0;
`;

export const Option = styled(BaseOption)`
  padding: 0.625rem 0.5rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: #262626;
  cursor: default;
  border-radius: 0.5rem;
  outline: 0;

  &.is-focused,
  &.is-hovered {
    background-color: var(--gray-200);
  }

  &.is-selected {
    background-color: var(--purple-400);
    color: white;
  }
`;
