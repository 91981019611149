import type { RefObject } from 'react';
import { useLayoutEffect, useState } from 'react';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

const useSize = (target: RefObject<HTMLElement>) => {
  const [size, setSize] = useState<Size>({ height: undefined, width: undefined });

  useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect() ?? { height: undefined, width: undefined });
  }, [target]);

  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    box: 'border-box',
    onResize,
    ref: target
  });
  return size;
};

export default useSize;
