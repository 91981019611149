import greenCheckIcon from '@material-design-icons/svg/round/check.svg?react';
import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import { styled } from 'styled-components';
import Select from '@/components/FormFields/Select';

export const EmailReminderCheck = styled(greenCheckIcon)`
  width: 1rem;
  height: 1rem;
  fill: var(--success-600);
  margin-right: 0.25rem;
`;

export const EmailReminderSentText = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const FiltersAndActionsWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TeamMemberSelect = styled(Select)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: initial;
  }
`;
