import TagGroup from '@/components/TagGroup';
import UnstyledButton from '@/components/UnstyledButton';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const MiniCourseCard = {
  Container: styled(UnstyledButton)`
    display: block;
    border: 1px solid var(--gray-200);
    border-radius: 1rem;
    padding: 1.5rem;
    margin: 0 0 1.5rem;
    width: 100%;
  `,
  Tags: styled(TagGroup)`
    padding-top: 1rem;
  `,
  Title: styled.div`
    ${TEXT_STYLES.bodyCopyLarge}
    color: var(--gray-800);
  `
};
