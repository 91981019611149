import clsx from 'clsx';
import type { AriaMeterProps } from 'react-aria';
import { useMeter } from 'react-aria';

import * as S from './styles';

interface Props extends AriaMeterProps {
  isUnavailable?: boolean;
  size?: 'small' | 'large';
  variant?: 'primary' | 'secondary';
}

const Meter = ({ isUnavailable = false, size = 'small', variant = 'primary', ...props }: Props) => {
  const { maxValue = 100, minValue = 0, value = 0 } = props;
  const { meterProps } = useMeter(props);

  // Calculate the width of the progress bar as a percentage
  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;

  return (
    <S.Meter {...meterProps}>
      <S.Bar
        className={clsx('bar', { 'is-disabled': isUnavailable }, { 'is-small': size === 'small' })}
      >
        <S.ValueBar
          className={clsx(variant, { 'is-small': size === 'small' })}
          style={{ width: barWidth }}
        />
      </S.Bar>
      <S.PercentValue>{isUnavailable ? 'N/A' : `${value}%`}</S.PercentValue>
    </S.Meter>
  );
};

export default Meter;
