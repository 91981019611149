import styled, { keyframes } from 'styled-components';

const barAnimation = keyframes`
  0% {background-position: right}
`;

export const Bar = styled.div`
  width: 100%;
  height: 1.5rem;
  border-radius: 0.5rem;
  margin: 0 auto;
  background: linear-gradient(
      90deg,
      var(--gray-100) 33%,
      var(--light-purple-100) 50%,
      var(--gray-100) 66%
    )
    var(--gray-100);
  background-size: 300% 100%;
  animation: ${barAnimation} 1s infinite linear;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`;
