import { filterNameToKey, keyToFilterName } from '@/utils/filterUtils';

const organizationKeysToFilterNames = [
  {
    filterName: 'Provider',
    key: 'full_name'
  },
  {
    filterName: 'User',
    key: 'user_name'
  },
  {
    filterName: 'Resource name',
    key: 'resource_name'
  },
  {
    filterName: 'Language',
    key: 'language'
  },
  {
    filterName: 'Community',
    key: 'has_scored'
  },
  {
    filterName: 'User type',
    key: 'clinical'
  },
  {
    filterName: 'Active',
    key: 'active'
  },
  {
    filterName: 'Community of focus',
    key: 'communities[]'
  },
  {
    filterName: 'User type',
    key: 'clinical'
  },
  {
    filterName: 'Range',
    key: 'date_range'
  },
  {
    filterName: 'User role',
    key: 'member_role'
  },
  {
    filterName: 'User status',
    key: 'status'
  }
];

export const organizationFiltersToSearch = (key: string, value: string) =>
  filterNameToKey(organizationKeysToFilterNames, key, value);

export const organizationSearchToFilters = (key: string, value: string) =>
  keyToFilterName(organizationKeysToFilterNames, key, value);
