// TODO: import `isIOS` and `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { isIOS, useObjectRef } from '@react-aria/utils';
import { forwardRef, useEffect, useState } from 'react';
import TextField from '@/components/FormFields/TextField';

const PHONE_NUMBER_LENGTH = 10;
const PHONE_PATTERNS = {
  default: '^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$',
  ios: `^[0-9]{${PHONE_NUMBER_LENGTH}}$`
} as const;

interface Props {
  className?: string;
  defaultValue: string;
}

const PhoneNumberField = forwardRef<HTMLInputElement, Props>(
  ({ className, defaultValue }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);
    const [errorMessage, setErrorMessage] = useState('');
    const [isValid, setIsValid] = useState(false);

    const formatNumber = (value: string) => {
      // Remove all non-numeric characters
      const digits = value.replace(/[^0-9]/g, '').slice(0, PHONE_NUMBER_LENGTH);

      if (isIOS()) {
        return digits;
      }

      // Format: (XXX) XXX-XXXX
      const groups = {
        areaCode: digits.slice(0, 3),
        lineNumber: digits.slice(6, 10),
        prefix: digits.slice(3, 6)
      };

      if (digits.length < 4) return groups.areaCode;
      if (digits.length < 7) return `(${groups.areaCode}) ${groups.prefix}`;
      return `(${groups.areaCode}) ${groups.prefix}-${groups.lineNumber}`;
    };

    const [phoneNumber, setPhoneNumber] = useState(formatNumber(defaultValue));

    const handlePhoneNumberChange = (value: string) => {
      setPhoneNumber(formatNumber(value));
    };

    useEffect(() => {
      const phoneNumberField = ref.current;
      const isValid = phoneNumber.replace(/[^0-9]/g, '').length === PHONE_NUMBER_LENGTH;

      if (phoneNumberField) {
        setIsValid(isValid);
        setErrorMessage(isValid ? '' : 'Please enter a complete phone number.');
      }
    }, [phoneNumber, ref]);

    return (
      <TextField
        ref={ref}
        autoComplete="tel-national"
        className={className}
        data-cy="phone-number-field"
        errorMessage={errorMessage}
        isInvalid={!isValid}
        label="Phone number"
        name="phoneNumber"
        pattern={isIOS() ? PHONE_PATTERNS.ios : PHONE_PATTERNS.default}
        placeholder="Phone number"
        type="tel"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
    );
  }
);

PhoneNumberField.displayName = 'PhoneNumberField';

export default PhoneNumberField;
