import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';

import type { AssignmentUser } from '../utils';
import { generateOrganizationAssignmentUser } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchOrganizationAssignmentUsers = (
  filters: OrganizationAssignmentUsersFilters
) => Promise<void>;

export interface OrganizationAssignmentUsersFilters {
  assignment_completed?: string;
  completed_onboarding?: string;
  email?: string;
  is_clinical?: string;
  order_by?: {
    column: string | undefined;
    dir: 'asc' | 'desc' | undefined;
  };
  page?: number;
  per_page?: number;
  user_name?: string;
}

interface UseGetOrganizationAssignmentUsers {
  (
    organizationId: string,
    assignmentId: string,
    filters: OrganizationAssignmentUsersFilters
  ): {
    isFetchingOrgAssignmentUsers: boolean;
    totalAssignmentUserPages: number;
    updateAssignmentUsersFilters: (newFilters: OrganizationAssignmentUsersFilters) => void;
    users: AssignmentUser[];
  };
}

const useGetOrganizationAssignmentUsers: UseGetOrganizationAssignmentUsers = (
  organizationId,
  assignmentId,
  filters
) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { getRequest, reportError } = useApiRequest();

  const [users, setUsers] = useState<AssignmentUser[]>([]);
  const [isFetchingOrgAssignmentUsers, setIsFetchingOrgAssignmentUsers] = useState(false);
  const [query, setQuery] = useState<OrganizationAssignmentUsersFilters>(filters);
  const [totalAssignmentUserPages, setTotalAssignmentUserPages] = useState(1);

  const fetchOrganizationAssignmentUsers: FetchOrganizationAssignmentUsers = useCallback(
    async filters => {
      if (!organizationId || !assignmentId) return;
      setIsFetchingOrgAssignmentUsers(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        import.meta.env.VITE_API_V2_BASE_PATH
      }/organizations/${organizationId}/assignments/${assignmentId}/users${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APIOrganizationsOrganizationIdAssignmentIdUsers['data'];
          meta?: APIOrganizationsOrganizationIdAssignmentIdUsers['meta'];
        };

        setUsers(
          data !== undefined ? data.map(fields => generateOrganizationAssignmentUser(fields)) : []
        );
        if (meta !== undefined) {
          setTotalAssignmentUserPages(Number(meta.total_pages));
        }
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetchingOrgAssignmentUsers(false);
      }
    },
    [organizationId, assignmentId, getRequest, reportError]
  );

  const debouncedFetchOrganizationAssignmentUsers = useDebounce(
    fetchOrganizationAssignmentUsers,
    200
  );

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetchingOrgAssignmentUsers(true);
    debouncedFetchOrganizationAssignmentUsers(filters);
    setIsFirstRender(false);

    return () => {
      setUsers([]);
      setIsFetchingOrgAssignmentUsers(false);
      setQuery({});
    };
  }, [debouncedFetchOrganizationAssignmentUsers, filters, isFirstRender]);

  const updateAssignmentUsersFilters = (
    newFilters: Partial<OrganizationAssignmentUsersFilters>
  ) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof OrganizationAssignmentUsersFilters] === undefined
        ? delete newFilters[key as keyof OrganizationAssignmentUsersFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchOrganizationAssignmentUsers(newFilters);
  };

  return {
    isFetchingOrgAssignmentUsers,
    totalAssignmentUserPages,
    updateAssignmentUsersFilters,
    users
  };
};

export default useGetOrganizationAssignmentUsers;
