import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { prepareLocationsParam } from '@/utils/prepareLocationParms';
import { useDebounce } from '@/utils/useDebounce';

import type { ServiceAreaComparison, ServiceAreaComparisonMeta } from '../utils';
import {
  generateNetworkServiceAreaComparisons,
  generateNetworkServiceAreaComparisonsMeta
} from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type ServiceAreaComparisonAPIData = ReturnType<
  typeof useGetNetworkServiceAreaComparisons
>['serviceAreaComparisons'];
type ServiceAreaComparisonAPIMeta = ReturnType<
  typeof useGetNetworkServiceAreaComparisons
>['serviceAreaComparisonsMeta'];

export interface ServiceAreaComparisonResponse {
  data: ServiceAreaComparisonAPIData;
  meta: ServiceAreaComparisonAPIMeta;
}

interface NetworkServiceAreaComparisonsFilters {
  locations?: ServiceAreaLocationFilter[] | string; // stringified array of objects for API or array of objects for frontend
  scope?: ('city' | 'county' | 'state' | 'zip')[];
}

export interface ServiceAreaLocationFilter {
  location_name: string;
  location_type: 'city' | 'county' | 'state' | 'zip';
  state_code: string;
}

interface UseGetServiceAreaComparisons {
  (filters: NetworkServiceAreaComparisonsFilters): {
    isFetching: boolean;
    serviceAreaComparisons: ServiceAreaComparison[];
    serviceAreaComparisonsMeta: ServiceAreaComparisonMeta | undefined;
    singleFetchServiceAreaComparisons: (
      filters: NetworkServiceAreaComparisonsFilters,
      signal?: AbortSignal
    ) => Promise<{
      data: ServiceAreaComparison[];
      meta: ServiceAreaComparisonMeta | undefined;
    }>;
    updateServiceAreaComparisonsFilters: (
      newFilters: Partial<NetworkServiceAreaComparisonsFilters>
    ) => void;
  };
}

const useGetNetworkServiceAreaComparisons: UseGetServiceAreaComparisons = filters => {
  const { getRequest, reportError } = useApiRequest();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [serviceAreaComparisons, setServiceAreaComparisons] = useState<ServiceAreaComparison[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [previousFilters, setPreviousFilters] = useState(filters);
  const [serviceAreaComparisonsMeta, setServiceAreaComparisonsMeta] = useState<
    ServiceAreaComparisonMeta | undefined
  >(undefined);

  const prepareRequestUrl = useCallback((filters: NetworkServiceAreaComparisonsFilters) => {
    const hasLocations =
      typeof filters.locations === 'string'
        ? filters.locations.length > 0
        : Array.isArray(filters.locations) && filters.locations.length > 0;

    if (!hasLocations) {
      return null;
    }

    const paramsToSend = { ...filters };
    paramsToSend.locations = prepareLocationsParam(paramsToSend.locations);
    const params = filtersToParams(paramsToSend);
    const queryString = typeof params === 'string' && params.length > 0 ? `?${params}` : '';

    const baseUrl = import.meta.env.VITE_API_BASE_PATH || '';
    return `${baseUrl}/networks/service_area_comparisons${queryString}`;
  }, []);

  const fetchData = useCallback(
    async (filters: NetworkServiceAreaComparisonsFilters, signal?: AbortSignal) => {
      const url = prepareRequestUrl(filters);
      if (url === null) {
        return { data: [], meta: undefined };
      }

      try {
        const { data, meta } = (await getRequest(url, signal)) as {
          data?: APINetworksServiceAreaComparisons['data'];
          meta?: APINetworksServiceAreaComparisons['meta'];
        };

        return {
          data: data?.map(fields => generateNetworkServiceAreaComparisons(fields)) ?? [],
          meta: meta ? generateNetworkServiceAreaComparisonsMeta(meta) : undefined
        };
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          return { data: [], meta: undefined };
        }
        reportError(error);
        return { data: [], meta: undefined };
      }
    },
    [getRequest, prepareRequestUrl, reportError]
  );

  const fetchServiceAreaComparisons = useCallback(
    async (filters: NetworkServiceAreaComparisonsFilters) => {
      setIsFetching(true);
      try {
        const { data, meta } = await fetchData(filters);
        setServiceAreaComparisons(data);
        setServiceAreaComparisonsMeta(meta);
      } finally {
        setIsFetching(false);
      }
    },
    [fetchData]
  );

  const debouncedFetchServiceAreaComparisons = useDebounce(fetchServiceAreaComparisons, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchServiceAreaComparisons(filters);
    setIsFirstRender(false);

    return () => {
      setServiceAreaComparisons([]);
      setServiceAreaComparisonsMeta(undefined);
      setIsFetching(false);
      setPreviousFilters({});
    };
  }, [isFirstRender, filters, debouncedFetchServiceAreaComparisons]);

  const updateServiceAreaComparisonsFilters = useCallback(
    (newFilters: Partial<NetworkServiceAreaComparisonsFilters>) => {
      const cleanedFilters = { ...newFilters };
      Object.keys(cleanedFilters).forEach(key => {
        if (cleanedFilters[key as keyof NetworkServiceAreaComparisonsFilters] === undefined) {
          delete cleanedFilters[key as keyof NetworkServiceAreaComparisonsFilters];
        }
      });

      if (isEqual(cleanedFilters, previousFilters)) return;

      setPreviousFilters(cleanedFilters);
      debouncedFetchServiceAreaComparisons(cleanedFilters);
    },
    [debouncedFetchServiceAreaComparisons, previousFilters]
  );

  const singleFetchServiceAreaComparisons = useCallback(
    (filters: NetworkServiceAreaComparisonsFilters, signal?: AbortSignal) =>
      fetchData(filters, signal),
    [fetchData]
  );

  return {
    isFetching,
    serviceAreaComparisons,
    serviceAreaComparisonsMeta,
    singleFetchServiceAreaComparisons,
    updateServiceAreaComparisonsFilters
  };
};

export default useGetNetworkServiceAreaComparisons;
