import CheckIcon from '@material-symbols/svg-400/rounded/check_circle-fill.svg?react';
import ButtonWithMenu from '@/components/Buttons/ButtonWithMenu';
import styled from 'styled-components';

import Main from '../components/Main';

export const CheckedIcon = styled(CheckIcon)`
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--success-400) !important;
`;

export const CompletionsViewButton = styled(ButtonWithMenu)`
  background-color: #fff;

  &:hover {
    background-color: var(--gray-100);
  }
`;

export const FullWidthMain = styled(Main)`
  grid-template-columns: 1fr;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 1rem;
`;
