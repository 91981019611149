import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import type { NetworkEducationOverview } from '../utils';
import { generateNetworkEducationOverview } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchNetworkEducationOverview = (filters: NetworkEducationOverviewFilters) => Promise<void>;

export interface NetworkEducationOverviewFilters {
  'communities[]'?: string[] | null;
  date_range?: 'last_12_months' | 'last_30_days' | 'last_90_days' | 'year_to_date';
  'organizations[]'?: string[] | null;
  resource_name?: string;
  resource_type?: 'course_collection' | 'course';
  user_name?: string;
}

interface UseGetNetworkEducationOverview {
  (filters: NetworkEducationOverviewFilters): {
    educationOverview: NetworkEducationOverview | undefined;
    isFetchingNetworkEducationOverview: boolean;
    updateEducationOverviewFilters: (newFilters: NetworkEducationOverviewFilters) => void;
  };
}

const useGetNetworkEducationOverview: UseGetNetworkEducationOverview = filters => {
  const { getRequest, reportError } = useApiRequest();
  const isFirstRender = useIsFirstRender();

  const [educationOverview, setEducationOverview] = useState<NetworkEducationOverview>();
  const [isFetchingNetworkEducationOverview, setIsFetchingNetworkEducationOverview] =
    useState(false);
  const [query, setQuery] = useState<NetworkEducationOverviewFilters>(filters);

  const fetchNetworkEducation: FetchNetworkEducationOverview = useCallback(
    async filters => {
      setIsFetchingNetworkEducationOverview(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        import.meta.env.VITE_API_V2_BASE_PATH
      }/networks/educations/overview${params ? `?${params}` : ''}`;

      try {
        const { data } = (await getRequest(url)) as {
          data?: APINetworksEducationsOverview['data'];
        };

        setEducationOverview(
          data !== undefined ? generateNetworkEducationOverview(data) : undefined
        );
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetchingNetworkEducationOverview(false);
      }
    },
    [getRequest, reportError]
  );

  const debouncedFetchNetworkEducationOverview = useDebounce(fetchNetworkEducation, 200);

  useEffect(() => {
    /*
      React StrictMode causes this initial fetch
      to happen twice even with no dependencies, resulting
      in a double API call w/ no filter changes.
      This is a workaround for now that will run the
      initial fetch only once after the component calling
      this hook has been mounted.
    */
    if (!isFirstRender) return;
    setIsFetchingNetworkEducationOverview(true);
    debouncedFetchNetworkEducationOverview(filters);

    return () => {
      setEducationOverview(undefined);
      setIsFetchingNetworkEducationOverview(false);
      setQuery({});
    };
  }, [debouncedFetchNetworkEducationOverview, isFirstRender, filters]);

  const updateEducationOverviewFilters = (newFilters: Partial<NetworkEducationOverviewFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof NetworkEducationOverviewFilters] === undefined
        ? delete newFilters[key as keyof NetworkEducationOverviewFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchNetworkEducationOverview(newFilters);
  };

  return {
    educationOverview,
    isFetchingNetworkEducationOverview,
    updateEducationOverviewFilters
  };
};

export default useGetNetworkEducationOverview;
