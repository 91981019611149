import Button from '@/components/Buttons/Button';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

import { EDUCATION_MAIN_TOP_PADDING } from '../../constants';

import { METADATA_S_BREAKPOINT_RANGE } from './PathwayMetaData/styles';

export const ContentGrid = styled.div`
  display: grid;
  gap: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: minmax(100px, 22.5rem) 1fr;
  }
`;

export const MainGrid = styled.div`
  ${MOBILE_BREAKPOINT_RANGE} {
    padding: 1.5rem 1.5rem 7.5rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 1420px;
    margin-right: auto;
    margin-left: auto;
    padding: ${EDUCATION_MAIN_TOP_PADDING.desktop}rem var(--space-9) var(--space-16);
  }
`;

export const NextActionButton = styled(Button)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-left: auto;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    align-self: center;
  }
`;

export const PathwayCompletedDate = styled.span`
  font-weight: 400;
`;

export const PathwayMetaContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
  }
`;

export const PathwayProgressBar = styled.div`
  display: flex;
  height: 3.5rem;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid #eaecf0;
  background: #fff;
  color: var(--gray-500);
  ${TEXT_STYLES.tagLarge}
  line-height: 140%;
  letter-spacing: 0.0088rem;

  &.completed {
    flex-grow: 1;
  }

  ${METADATA_S_BREAKPOINT_RANGE} {
    flex-grow: 1;
  }

  @media all and (width < 900px) {
    flex-direction: column;
    height: 4.5rem;
    width: 100%;
    align-items: start;
  }
`;

export const PathwayProgressLabel = styled.div`
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
`;

export const ResourceList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;
