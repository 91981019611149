import { motion } from 'framer-motion';
import UnstyledButton from '@/components/UnstyledButton';
import styled from 'styled-components';

import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';
import { default as BaseButton } from '../../Buttons/Button';
import { RESOURCE_CARD_STYLES } from '../resource_card_styles';

export const Button = styled(BaseButton)`
  width: auto;
  align-self: flex-end;

  ${MOBILE_BREAKPOINT_RANGE} {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.5rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
  }
`;

export const Byline = styled.div`
  color: var(--gray-400);
  ${TEXT_STYLES.bodyCopySmall}
  margin-bottom: 0.75rem;
`;

export const CardContent = styled.div`
  ${RESOURCE_CARD_STYLES.cardContent}
`;

export const CardLabel = styled.div`
  ${RESOURCE_CARD_STYLES.cardLabel}
  background: var(--warning-25);
  color: var(--gray-800);
`;

export const CourseCard = styled(motion.li)`
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  ${RESOURCE_CARD_STYLES.cardDescription}
`;

export const TagItem = styled.li`
  ${RESOURCE_CARD_STYLES.cardTagItem}
`;

export const Tags = styled.ul`
  ${RESOURCE_CARD_STYLES.cardTags}
`;

export const Title = styled(UnstyledButton)`
  ${RESOURCE_CARD_STYLES.cardTitle}
  margin-bottom: 0.25rem;
`;
