import AddCircleIcon from '@material-design-icons/svg/round/add_circle.svg?react';
import DeleteIcon from '@material-design-icons/svg/round/delete.svg?react';
import type { InputHTMLAttributes } from 'react';
import { useEffect, useRef, useState } from 'react';
import { FocusScope, useDialog, useModal, useOverlay, usePreventScroll } from 'react-aria';
import { pdfjs } from 'react-pdf';
import type { Education } from '@/pages/Dashboard/utils';
import isNonEmptyString from '@/utils/isNonEmptyString';

import Button from '../Buttons/Button';

import * as S from './styles';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Course {
  attachment: File | null;
  attachmentUrl: string;
  communities: string[];
  credits: number;
  id: string;
  isReadOnly: boolean;
  name: string;
  organization: string;
  year: number;
}

interface WithImage {
  image: File | { url: string } | null;
}

interface WithAttachment {
  attachment: File | null;
  attachmentUrl: string;
}

function hasImage(obj: object | null): obj is WithImage {
  return obj !== null && 'image' in obj;
}

function hasAttachment(obj: object | null): obj is WithAttachment {
  return obj !== null && 'attachment' in obj && 'attachmentUrl' in obj;
}

interface Props {
  close: () => void;
  course: Course | Education | null;
  onAttachmentUpload: (attachment: File | null, attachmentUrl: string) => void;
}

const AttachCertificateModalDialog = ({ close, course, onAttachmentUpload }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    { isDismissable: true, isOpen: true, onClose: close },
    ref
  );

  usePreventScroll();
  const { modalProps } = useModal();

  const { dialogProps, titleProps } = useDialog({}, ref);

  const uploadAttachmentInputRef = useRef<HTMLInputElement>(null);

  const existingAttachment = hasImage(course)
    ? course.image instanceof File
      ? course.image
      : null
    : hasAttachment(course)
      ? course.attachment
      : null;

  const existingAttachmentUrl = hasAttachment(course)
    ? course.attachmentUrl
    : hasImage(course) && course.image !== null && 'url' in course.image
      ? course.image.url
      : '';

  const [attachment, setAttachment] = useState<File | null | undefined>(existingAttachment);
  const [attachmentUrl, setAttachmentUrl] = useState<string | undefined>(
    existingAttachmentUrl ?? undefined
  );
  const [isUpdating, setIsUpdating] = useState(
    (attachment !== null && attachment !== undefined) || isNonEmptyString(attachmentUrl)
  );
  const [fullAttachmentUrl, setFullAttachmentUrl] = useState<string | undefined>(attachmentUrl);

  const handleAddAttachmentButtonPress = () => {
    uploadAttachmentInputRef.current!.click();
  };

  const handleCloseButtonPress = () => {
    close();
  };

  const handleRemoveAttachmentButtonPress = () => {
    onAttachmentUpload(null, '');
    close();
  };

  const handleReplaceButtonPress = () => {
    uploadAttachmentInputRef.current!.click();
  };

  const handleSaveButtonPress = () => {
    onAttachmentUpload(attachment!, attachmentUrl!);
    close();
  };

  const handleUploadAttachmentInputChange: InputHTMLAttributes<HTMLInputElement>['onChange'] =
    event => {
      const { files } = event.target;
      if (files) {
        setAttachment(files[0]);
        setAttachmentUrl(URL.createObjectURL(files[0]));
      }
      setIsUpdating(false);
    };

  useEffect(() => {
    if (attachmentUrl?.startsWith('blob') === true) {
      setFullAttachmentUrl(attachmentUrl);
    } else if (
      attachmentUrl?.startsWith('http://') === true ||
      attachmentUrl?.startsWith('https://') === true
    ) {
      // urls for our test suite are full URLs
      setFullAttachmentUrl(attachmentUrl);
    } else {
      setFullAttachmentUrl(`${import.meta.env.VITE_BASE_PATH}${attachmentUrl}`);
    }
  }, [attachmentUrl]);

  return (
    <S.AttachCertificateModalDialog {...underlayProps}>
      <FocusScope
        autoFocus
        contain
        restoreFocus
      >
        <S.Dialog
          {...dialogProps}
          {...modalProps}
          {...overlayProps}
          ref={ref}
        >
          <S.CloseButton.Root onPress={handleCloseButtonPress}>
            <S.CloseButton.Icon aria-hidden="true" />
          </S.CloseButton.Root>
          {(attachment !== null && attachment !== undefined) || isNonEmptyString(attachmentUrl) ? (
            <>
              <S.UploadedLeadingHero
                copy={isUpdating ? 'Replace or remove attachment.' : ' '}
                header={isUpdating ? 'Update certificate.' : 'Save and close.'}
                headerProps={titleProps}
                level={2}
              />
              {attachment?.type === 'application/pdf' ? (
                <S.PdfPreview.Document
                  data-cy="attachment-preview"
                  error="Your PDF was uploaded, but a preview image failed to be generated at this time."
                  file={fullAttachmentUrl}
                  loading=""
                >
                  <S.PdfPreview.Page
                    height={240}
                    loading=""
                    pageNumber={1}
                    renderAnnotationLayer={false}
                    renderMode="canvas"
                    renderTextLayer={false}
                    onLoadError={error => {
                      // eslint-disable-next-line no-console
                      console.error(error);
                    }}
                  />
                </S.PdfPreview.Document>
              ) : (
                <S.AttachmentPreview
                  alt=""
                  data-cy="attachment-preview"
                  src={fullAttachmentUrl}
                />
              )}
              <S.Actions>
                {isUpdating ? (
                  <>
                    <Button
                      trailingIcon={AddCircleIcon}
                      onPress={handleReplaceButtonPress}
                    >
                      Replace
                    </Button>
                    <input
                      ref={uploadAttachmentInputRef}
                      hidden
                      accept="application/pdf, image/jpeg, image/png"
                      data-cy="upload-attachment-input"
                      type="file"
                      onChange={handleUploadAttachmentInputChange}
                      onClick={event => {
                        event.currentTarget.value = '';
                      }}
                    />
                    <Button
                      data-cy="remove-attachment-button"
                      trailingIcon={DeleteIcon}
                      variant="outline"
                      onPress={handleRemoveAttachmentButtonPress}
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <Button
                    data-cy="save-attachment-button"
                    onPress={handleSaveButtonPress}
                  >
                    Save
                  </Button>
                )}
              </S.Actions>
            </>
          ) : (
            <>
              <S.UploadLeadingHero
                copy="To help you track your continuing education history, please attach a certificate if available."
                header="Attach certificate."
                headerProps={titleProps}
                level={2}
              />
              <S.UploadContainer>
                <Button
                  trailingIcon={AddCircleIcon}
                  onPress={handleAddAttachmentButtonPress}
                >
                  Add Attachment
                </Button>
                <input
                  ref={uploadAttachmentInputRef}
                  hidden
                  accept="application/pdf, image/jpeg, image/png"
                  data-cy="upload-attachment-input"
                  type="file"
                  onChange={handleUploadAttachmentInputChange}
                  onClick={event => {
                    event.currentTarget.value = '';
                  }}
                />
                <S.UploadInstructionText>
                  Select a .jpeg, .png, or .pdf no larger than 10MB.
                </S.UploadInstructionText>
              </S.UploadContainer>
            </>
          )}
        </S.Dialog>
      </FocusScope>
    </S.AttachCertificateModalDialog>
  );
};
export default AttachCertificateModalDialog;
