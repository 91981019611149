import QuestionMarkIcon from '@material-design-icons/svg/round/help_outline.svg?react';
import UnstyledButton from '@/components/UnstyledButton';
import { FOCUS_STYLES, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const CircleKey = styled.div`
  border-radius: 50%;
  height: 1rem;
  width: 1rem;

  &.primary {
    background: var(--purple-400);
  }

  &.secondary {
    background: var(--gray-300);
  }
`;

interface ComparisonMetersProps {
  $isLast?: boolean;
  $size?: 'small' | 'large';
}

export const ComparisonMeters = styled.div<ComparisonMetersProps>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  ${({ $isLast = false, $size = 'small' }) => {
    if ($size === 'large' && $isLast === false) {
      return `
        border-bottom: 1px solid var(--gray-200);
        padding-bottom: 1.15rem;
      `;
    }
  }}
`;

export const HelpIcon = styled(QuestionMarkIcon)`
  display: block;
  fill: var(--gray-400);
  width: 1rem;
  height: 1rem;
`;

export const Key = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const KeySection = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: auto;
`;

export const Label = styled.div`
  ${TEXT_STYLES.buttonTextLargeScreen}
  display: flex;
  align-items: center;
  column-gap: 0.12rem;
  color: var(--gray-800);
`;

export const MeterPopoverButton = styled(UnstyledButton)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.75rem;
  transition: background 0.3s ease-in-out;
  cursor: default !important;

  &.is-small {
    gap: 0.25rem;
  }

  &.is-focused,
  &.is-hovered {
    background: var(--light-purple-100);

    .bar {
      background: #fff;

      &.is-disabled {
        background: repeating-linear-gradient(
          -45deg,
          #fff,
          #fff 10px,
          var(--gray-100) 10px,
          var(--gray-100) 20px
        );
      }
    }
  }

  &.is-focused {
    outline: 2px solid var(--purple-400);
  }
`;

export const PopoverButton = styled(UnstyledButton)`
  &.is-focused {
    ${FOCUS_STYLES}
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-700);
  white-space: nowrap;
`;
