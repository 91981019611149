import CloseIcon from '@material-design-icons/svg/round/close.svg?react';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import { BLACK_CHARCOAL_COLOR } from '../../GlobalStyle';
import UnstyledButton from '../../UnstyledButton';

export const Anchor = styled.a`
  color: inherit;

  &:focus-visible {
    outline-offset: 0.0625rem;
    outline: 0.125rem solid var(--brand-bright);
    border-radius: 0.25rem;
  }
`;

export const BeginEvaluationModalDialog = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  padding: var(--space-6);
  background-color: ${transparentize(0.3, BLACK_CHARCOAL_COLOR)};
  z-index: 2;
`;

export const Buttons = styled.div`
  align-self: start;
  display: inline-flex;
  flex-direction: column;
  row-gap: var(--space-4);
`;

export const CloseButton = {
  Icon: styled(CloseIcon)`
    display: block;
    fill: var(--gray-dark-dark);
    height: 1.8rem;
    width: auto;
    margin: -0.25rem -0.5rem;
  `,
  Root: styled(UnstyledButton)`
    align-self: end;
    margin-bottom: 0.625rem;
  `
};

export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - var(--space-6) * 2);
  max-height: 100%;
  width: 37rem;
  padding: var(--space-8);
  background-color: var(--white-light);
  border-radius: 0.25rem;
  box-shadow: var(--elevation-modal-regular);
  overflow-y: auto;
`;

export const Text = styled.p`
  margin-bottom: var(--space-7);
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-dark-dark);
`;

export const Title = styled.h3`
  margin-bottom: var(--space-4);
  ${TEXT_STYLES.headlineStandardLevel2BoldRegular}
  color: var(--purple-600);
`;
