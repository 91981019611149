import styled from 'styled-components';
import SyntaxHighlighter from 'react-syntax-highlighter';
import UnstyledButton from '@/components/UnstyledButton';
import type { ButtonGroupProps } from '../../sharedStyles';
import { sharedModalStyles } from '../../sharedStyles';
import { TEXT_STYLES } from '@/constants';

export const ButtonGroup = styled.div<ButtonGroupProps>`
  ${sharedModalStyles.buttonGroup}
`;

export const ClipboardButton = styled(UnstyledButton)`
  position: absolute;
  top: 0.25rem;
  right: 1.25rem;
  border: 1px solid var(--gray-200);
  border-radius: 50%;
  height: 1.75rem;
  width: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s;

  svg {
    fill: var(--gray-500);
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover {
    opacity: 1;
    background: var(--gray-50);

    svg {
      fill: var(--gray-500);
    }
  }
`;

export const CodeBlock = styled(SyntaxHighlighter)`
  padding: 1rem 1.5rem 1rem 1rem !important;
  border-radius: 0.5rem 0.5rem 1rem 1rem;
  margin: 0 !important;
  max-width: 100% !important;
  max-height: 8.5rem;
  overflow: hidden auto !important;
  white-space: break-spaces !important;
  overflow-wrap: anywhere;

  code {
    white-space: break-spaces !important;
  }
`;

export const CodeBlockContainer = styled.div`
  position: relative;
`;

export const PreviewBlock = styled.div`
  ${sharedModalStyles.previewBlock}
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const PreviewText = styled.p`
  ${TEXT_STYLES.bodyCopyMedium}
  text-align: center;
  font-weight: 500;
  margin: 0.5rem auto 0;
`;

export const Text = styled.p`
  ${sharedModalStyles.text}
`;
