import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';

import type { Education } from '../utils';
import { generateEducation, generateEducations } from '../utils';

import useApiRequest, { filtersToParams, snakeCaseKeys } from './useApiRequest';

type FetchEducations = (filters: FetchEducationFilters) => void;

type RefetchEducations = FetchEducations;

interface FetchEducationFilters {
  page?: number;
  per_page?: number;
  user_entered?: boolean;
}

interface UseGetEducations {
  (
    filters: FetchEducationFilters,
    skipInitialFetch?: boolean
  ): {
    createEducation: (education: Education) => Promise<Education | undefined>;
    deleteEducation: (id: string) => Promise<void>;
    educations: Education[] | undefined;
    isFetching: boolean;
    patchEducation: (education: Education) => Promise<Education | undefined>;
    refetchEducations: RefetchEducations;
    totalEducationPages: number;
    updateEducationFilters: (newFilters: FetchEducationFilters) => void;
  };
}

const useGetEducations: UseGetEducations = (filters, skipInitialFetch = false) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [educations, setEducations] = useState<Education[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState<FetchEducationFilters>(filters);
  const [totalEducationPages, setTotalEducationPages] = useState(1);
  const { deleteRequest, getRequest, patchRequest, postRequest, reportError } = useApiRequest();

  const fetchEducations: FetchEducations = useCallback(
    async filters => {
      setIsFetching(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/educations${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APIEducations['data'];
          meta?: APIEducations['meta'];
        };

        setEducations(data !== undefined ? generateEducations(data) : []);

        if (meta !== undefined) {
          setTotalEducationPages(Number(meta.total_pages));
        }
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetching(false);
      }
    },
    [getRequest, reportError]
  );

  const getEducationFormData = (education: Education) => {
    const formData = new FormData();
    const educationData = snakeCaseKeys(education);

    // Handle communities array
    delete educationData.communities;
    education.communities.forEach(community => {
      formData.append('education[communities][]', community);
    });

    // Handle image
    if (education.image instanceof File) {
      // Add the image to the form data to preserve as file
      formData.append('education[image]', education.image);
      delete educationData.image;
    } else if (
      education.image !== null &&
      'url' in education.image &&
      education.image.url !== null
    ) {
      // Keep image unchanged
      delete educationData.image;
    } else if (education.image === null || education.image.url === null) {
      // Remove image if it's null
      formData.append('education[should_delete_image]', '1');
      delete educationData.image;
    }

    Object.entries(educationData).forEach(([key, value]) => {
      formData.append(`education[${key}]`, value as string);
    });
    return formData;
  };

  const createEducation = async (education: Education) => {
    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/educations`;

    try {
      const { data } = (await postRequest(url, getEducationFormData(education))) as {
        data?: APIEducations['data'][0];
      };
      if (data !== undefined) {
        const newEntry = generateEducation(data);
        const newEducations = [...educations, newEntry];
        setEducations(newEducations);
        return newEntry;
      }
    } catch (error) {
      reportError(error);
    }
  };

  const patchEducation = async (education: Education) => {
    if (education.id === '') return;
    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/educations/${education.id}`;
    try {
      const { data } = (await patchRequest(url, getEducationFormData(education))) as {
        data?: APIEducations['data'][0];
      };
      if (data !== undefined) {
        const updatedEntry = generateEducation(data);
        const newEducations = educations.map(experience => {
          if (experience.id === data.id) {
            return updatedEntry;
          }
          return experience;
        });
        setEducations(newEducations);
        return updatedEntry;
      }
    } catch (error) {
      reportError(error);
    }
  };

  const deleteEducation = async (id: string) => {
    const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/educations/${id}`;

    try {
      await deleteRequest(url);
      const newEducations = educations.filter(education => education.id !== id);
      setEducations(newEducations);
    } catch (error) {
      reportError(error);
    }
  };

  const debouncedFetchEducations = useDebounce(fetchEducations, 200);

  useEffect(() => {
    if (!isFirstRender || skipInitialFetch) return;
    setIsFetching(true);
    debouncedFetchEducations(filters);
    setIsFirstRender(false);

    return () => {
      setEducations([]);
      setIsFetching(false);
      setQuery({});
    };
  }, [debouncedFetchEducations, isFirstRender, filters, skipInitialFetch]);

  const refetchEducations: RefetchEducations = useCallback(() => {
    debouncedFetchEducations(filters);
  }, [debouncedFetchEducations, filters]);

  const updateEducationFilters = (newFilters: Partial<FetchEducationFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof FetchEducationFilters] === undefined
        ? delete newFilters[key as keyof FetchEducationFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchEducations(newFilters);
  };

  return {
    createEducation,
    deleteEducation,
    educations,
    isFetching,
    patchEducation,
    refetchEducations,
    totalEducationPages,
    updateEducationFilters
  };
};

export default useGetEducations;
