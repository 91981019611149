import HealthIcon from '@material-symbols/svg-400/rounded/cognition-fill.svg?react';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import CollapsibleButton from '@/components/CollapsibleButton';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import type { Pathway } from '@/pages/Dashboard/utils';
import getLevelIcon from '@/utils/getLevelIcon';

import * as S from './styles';

interface Props {
  pathway: Pathway;
}

const PathwaySidebar = ({ pathway }: Props) => {
  const LevelIcon = getLevelIcon(pathway.level);
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const [showCollapsibleButton, setShowCollapsibleButton] = useState(isInMobileBreakpointRange);
  const [collapsed, setCollapsed] = useState(isInMobileBreakpointRange);

  useEffect(() => {
    setCollapsed(isInMobileBreakpointRange);
    setShowCollapsibleButton(isInMobileBreakpointRange);
  }, [isInMobileBreakpointRange]);

  return (
    <S.Sidebar>
      <S.Image
        aria-hidden
        alt={pathway.name}
        data-cy="pathway-image"
        src={
          pathway.image.url !== null ? `${import.meta.env.VITE_BASE_PATH}${pathway.image.url}` : ''
        }
      />
      <S.TextBlock>
        <S.CollapsibleDescription $collapsed={collapsed}>
          <S.CollapsibleContent>
            <div data-cy="pathway-description">{pathway.longDescription}</div>
            <div>
              <S.TextBold>Skills you'll gain</S.TextBold>
              <S.SkillsList data-cy="pathway-skills">
                {pathway.skills.map(skill => (
                  <S.SkillsItem
                    key={skill}
                    data-cy="pathway-skill-item"
                  >
                    <S.SkillCheckIcon
                      aria-hidden
                      role="img"
                    />
                    {skill}
                  </S.SkillsItem>
                ))}
              </S.SkillsList>
            </div>
          </S.CollapsibleContent>

          {showCollapsibleButton && (
            <CollapsibleButton
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          )}
        </S.CollapsibleDescription>
        <div>
          <S.TextBold>Difficulty level & specialties</S.TextBold>
          <S.Tags>
            <S.Tag
              aria-label="Pathway level"
              data-cy="pathway-metadata-level"
            >
              <LevelIcon
                aria-hidden
                role="img"
              />
              {capitalize(pathway.level)}
            </S.Tag>
            <S.Tag
              aria-label="Specialty area(s)"
              data-cy="pathway-metadata-specialty-area"
            >
              <HealthIcon
                aria-hidden="true"
                role="img"
              />
              <div>
                {pathway.specialtyArea.length < 3
                  ? pathway.specialtyArea
                      .map(specialty => capitalize(specialty))
                      .join(' • ')
                      .replaceAll('_', ' ')
                  : 'All'}
              </div>
            </S.Tag>
          </S.Tags>
        </div>
      </S.TextBlock>
    </S.Sidebar>
  );
};

export default PathwaySidebar;
