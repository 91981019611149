import type { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  'data-cy'?: string;
  isVertical?: boolean;
}

const DataCard = ({ children, isVertical = false, ...args }: PropsWithChildren<Props>) => (
  <S.DataCard
    {...args}
    $isVertical={isVertical}
  >
    {children}
  </S.DataCard>
);

export default DataCard;
