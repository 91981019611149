import { TEXT_STYLES } from '@/constants';
import styled, { css } from 'styled-components';

export const CenterLabel = styled.div<{ height: number; width: number }>`
  ${TEXT_STYLES.tagSmall}
  align-items: center;
  color: var(--purple-400);
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 5rem;
  word-wrap: break-word;
  flex-direction: column;

  ${({ height }) => css`
    top: ${height / 2}px;
  `}

  ${({ width }) => css`
    left: ${width / 2}px;
  `}
`;

export const CenterNumber = styled.div`
  ${TEXT_STYLES.ctaBaseSmall}
  color: var(--purple-400);
`;

export const DonutChart = styled.div`
  position: relative;
  height: 164px;
  width: 18rem;
`;

export const DonutChartContainer = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
`;

export const DonutSvg = styled.svg`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const Title = styled.div`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--gray-800);
`;
