import ExpandIcon from '@material-design-icons/svg/round/arrow_downward.svg?react';
import HideIcon from '@material-design-icons/svg/round/arrow_upward.svg?react';
import FilterIcon from '@material-design-icons/svg/round/filter_alt.svg?react';
import { useRef } from 'react';
import {
  DismissButton,
  FocusScope,
  mergeProps,
  OverlayContainer,
  useDialog,
  useModal,
  useOverlay,
  useOverlayPosition,
  useOverlayTrigger
} from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import useBreakpointRange from '@/hooks/useBreakpointRange';

import * as S from './styles';

interface Props {
  filters: JSX.Element;
  handleSearchValueChange: (value: string) => void;
  hideSearch?: boolean;
  searchTextValue: string;
}

const EducationFilters = ({
  filters,
  handleSearchValueChange,
  hideSearch = false,
  searchTextValue
}: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();

  const triggerRef = useRef<HTMLButtonElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const state = useOverlayTriggerState({});

  const { dialogProps } = useDialog({}, overlayRef);
  const { modalProps } = useModal();
  const { overlayProps: overlayProps1 } = useOverlay(
    { isDismissable: true, isOpen: state.isOpen, onClose: state.close },
    overlayRef
  );
  const { overlayProps: positionProps } = useOverlayPosition({
    isOpen: state.isOpen,
    offset: 18,
    overlayRef,
    shouldFlip: false,
    targetRef: triggerRef
  });
  const { overlayProps: overlayProps2, triggerProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
    triggerRef
  );

  const handleCloseButtonPress = () => {
    state.close();
  };

  const handleFiltersTriggerButtonPress = () => {
    state.open();
  };

  return (
    <S.Filters.Root>
      {!hideSearch && (
        <S.Filters.SearchField
          aria-label="Search educational resources"
          data-cy="search-input"
          placeholder="Search"
          value={searchTextValue}
          onChange={handleSearchValueChange}
        />
      )}
      {isInMobileBreakpointRange ? (
        <>
          <S.Filters.TriggerButton
            {...triggerProps}
            ref={triggerRef}
            fullWidth
            leadingIcon={FilterIcon}
            trailingIcon={state.isOpen ? HideIcon : ExpandIcon}
            variant="outline"
            onPress={handleFiltersTriggerButtonPress}
          >
            Filters
          </S.Filters.TriggerButton>
          {state.isOpen && (
            <OverlayContainer>
              <FocusScope
                autoFocus
                contain
                restoreFocus
              >
                <S.Filters.Popover
                  {...mergeProps(
                    dialogProps,
                    modalProps,
                    overlayProps1,
                    overlayProps2,
                    positionProps
                  )}
                  ref={overlayRef}
                >
                  <S.Filters.Container style={{ height: positionProps.style?.maxHeight }}>
                    {filters}
                    <S.Filters.CloseButton
                      fullWidth
                      variant="outline"
                      onPress={handleCloseButtonPress}
                    >
                      Close
                    </S.Filters.CloseButton>
                  </S.Filters.Container>
                  <DismissButton onDismiss={state.close} />
                </S.Filters.Popover>
              </FocusScope>
            </OverlayContainer>
          )}
        </>
      ) : (
        <>
          <S.Filters.Title>Filters</S.Filters.Title>
          {filters}
        </>
      )}
    </S.Filters.Root>
  );
};

export default EducationFilters;
