import * as Sentry from '@sentry/react';
import { REPORT_HEIGHT, REPORT_WIDTH } from '@/components/Reporting/ReportWrapper/styles';

export const downloadPng = async (
  element: HTMLElement,
  fileTitle: string,
  height = REPORT_HEIGHT,
  width = REPORT_WIDTH
) => {
  try {
    const { default: html2canvas } = await import('html2canvas');
    const canvas = await html2canvas(element, {
      height,
      scale: 1,
      width
    });
    const pngURL = canvas.toDataURL('image/png');
    const dummyDoc = document.createElement('a');
    dummyDoc.href = pngURL;
    const pngTitle = `${fileTitle}.png`;
    dummyDoc.download = pngTitle;
    dummyDoc.click();
  } catch (error) {
    Sentry.captureException(error);
  }
};
