import UnstyledButton from '@/components/UnstyledButton';
import { FOCUS_STYLES, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const Benchmarks = {
  AssessedOn: styled.div`
    font-weight: 500;
  `,
  Benchmark: styled.img`
    display: grid;
    place-content: center;
    width: 2rem;
    height: 2rem;
  `,
  Button: styled(UnstyledButton)`
    display: inline-block;
    margin-top: calc(0.5rem - 1px);
    margin-bottom: calc(0.25rem - 1px);

    &.is-focused {
      ${FOCUS_STYLES}
    }
  `,
  DateEarned: styled.div``,
  PopoverText: styled.div`
    ${TEXT_STYLES.tooltipText}
    text-align: center;
  `,
  Root: styled.div`
    width: 100%;
  `,
  Title: styled.div`
    margin-bottom: 8px;
    font-size: 0.875rem;
    font-weight: 500;
  `
};
