import ExperienceIcon from '@material-symbols/svg-400/rounded/person-fill.svg?react';
import EducationIcon from '@material-symbols/svg-400/rounded/school-fill.svg?react';
import ShareIcon from '@material-design-icons/svg/round/share.svg?react';
import capitalize from 'lodash/capitalize';
import { useRef } from 'react';
import { FocusScope, useDialog, useModal, useOverlay, usePreventScroll } from 'react-aria';
import type { Notification } from '@/hooks/useNotifications';
import { BENCHMARK_EXPLANATION_TEXT } from '@/pages/strings';

import Button from '../../Buttons/Button';
import TooltipTrigger from '../../TooltipTrigger';

import ConfettiAnimation from './BenchmarkConfettiAnimation.webm';
import * as S from './styles';
import useShareBenchmarks from '@/hooks/useShareBenchmarks';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface Props {
  close: () => void;
  handleGoToPageClick: (path: string) => void;
  notification: Notification;
}

const NewBenchmarksNotification = ({ close, handleGoToPageClick, notification }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    { isDismissable: true, isOpen: true, onClose: close },
    ref
  );
  const shareBenchmarksIsEnabled = useFeatureFlagEnabled('benchmark_sharing');
  const { setSource, shareBenchmarksModalDialog } = useShareBenchmarks();

  const openShareBenchmarksModal = () => {
    setSource('announcement');
    shareBenchmarksModalDialog.open();
  };

  usePreventScroll();
  const { modalProps } = useModal();

  const { dialogProps } = useDialog({}, ref);

  const handleCloseButtonPress = () => {
    close();
  };

  const message = `You've earned ${notification.data.scores!.length} Benchmark${notification.data.scores!.length > 1 ? 's' : ''}.`;

  return (
    <S.NewBenchmarksModalDialog {...underlayProps}>
      <FocusScope
        autoFocus
        contain
        restoreFocus
      >
        <S.Dialog
          {...dialogProps}
          {...modalProps}
          {...overlayProps}
          ref={ref}
          data-cy="new-benchmarks-modal-dialog"
        >
          <S.CloseButton
            aria-label="Close notification"
            data-cy="close-new-benchmarks-button"
            onPress={handleCloseButtonPress}
          >
            <S.CloseIcon
              aria-hidden
              role="img"
            />
          </S.CloseButton>
          <S.ModalImageWrapper>
            {notification.data.scores !== undefined && (
              <S.EarnedBadgesWrapper>
                <S.ConfettiAnimation
                  autoPlay
                  muted
                  playsInline
                  poster={ConfettiAnimation}
                  preload="metadata"
                >
                  <source
                    id="video-source"
                    src={ConfettiAnimation}
                    type="video/webm"
                  />
                </S.ConfettiAnimation>
                {notification.data.scores.map(item => (
                  <TooltipTrigger
                    key={`${item.type}-${item.level}`}
                    content={
                      <S.TooltipContent>
                        <S.Header>
                          <S.TooltipTitle>
                            {capitalize(item.level)} in {item.type.toUpperCase()}
                          </S.TooltipTitle>
                        </S.Header>
                        <S.TooltipText>
                          {item.level.toUpperCase() === 'AWARENESS' &&
                            BENCHMARK_EXPLANATION_TEXT.awareness}
                          {item.level.toUpperCase() === 'PROFICIENCY' &&
                            BENCHMARK_EXPLANATION_TEXT.proficiency}
                          {item.level.toUpperCase() === 'EXCELLENCE' &&
                            BENCHMARK_EXPLANATION_TEXT.excellence}
                        </S.TooltipText>
                      </S.TooltipContent>
                    }
                    delay={0}
                  >
                    <S.BadgeImageContainer>
                      <S.BadgeImage
                        alt={`Earned ${item.level} for ${item.type.toUpperCase()} community`}
                        data-cy="earned-badge-image"
                        src={
                          item.image.startsWith('http://') || item.image.startsWith('https://')
                            ? item.image
                            : `${import.meta.env.VITE_BASE_PATH}${item.image}`
                        }
                      />
                    </S.BadgeImageContainer>
                  </TooltipTrigger>
                ))}
              </S.EarnedBadgesWrapper>
            )}
            <S.CongratulationsText>Congratulations!</S.CongratulationsText>
            <S.FireworkAnimationBefore />
            <S.FireworkAnimationAfter />
          </S.ModalImageWrapper>
          <S.TextBlock>
            <S.Title>{message}</S.Title>
            <S.Text>
              Your commitment to strengthening connections with culturally diverse patients is
              inspiring. This milestone reflects your dedication to delivering more effective,
              patient-centered care.
            </S.Text>
            <S.GrowthJourneyBlock>
              <S.GrowthJourneyTitle>🌱 Continue your growth journey.</S.GrowthJourneyTitle>
              <S.GrowthJourneyText>
                Build on your progress by exploring new courses, updating your profile, or sharing
                your achievements to motivate others.
              </S.GrowthJourneyText>
            </S.GrowthJourneyBlock>
          </S.TextBlock>
          <S.ButtonContainer>
            <Button
              fullWidth
              data-cy="more-education-button"
              leadingIcon={EducationIcon}
              size="large"
              variant="outline"
              onPress={() => handleGoToPageClick('/dashboard/education/collections')}
            >
              Keep learning
            </Button>
            {shareBenchmarksIsEnabled === true ? (
              <Button
                fullWidth
                data-cy="share-benchmarks-button"
                leadingIcon={ShareIcon}
                size="large"
                variant="primary"
                onPress={openShareBenchmarksModal}
              >
                Share benchmarks
              </Button>
            ) : (
              <Button
                fullWidth
                data-cy="update-experience-button"
                leadingIcon={ExperienceIcon}
                size="large"
                variant="outline"
                onPress={() => handleGoToPageClick('/dashboard/profile/work-experience')}
              >
                Update experience
              </Button>
            )}
          </S.ButtonContainer>
        </S.Dialog>
      </FocusScope>
    </S.NewBenchmarksModalDialog>
  );
};

export default NewBenchmarksNotification;
