import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const ListHighlightCard = styled.div`
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  padding: 1rem;
  background: var(--white-light);
`;

export const ListHighlightTitle = styled.div`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
`;
