import styled from 'styled-components';
import type { ButtonGroupProps } from '../../sharedStyles';
import { sharedModalStyles } from '../../sharedStyles';

export const ButtonGroup = styled.div<ButtonGroupProps>`
  ${sharedModalStyles.buttonGroup}
`;

export const Image = styled.img`
  ${sharedModalStyles.image}
`;

export const PreviewBlock = styled.div`
  ${sharedModalStyles.previewBlock}
`;

export const Text = styled.p`
  ${sharedModalStyles.text}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
