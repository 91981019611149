import type { OAuthError, RedirectLoginOptions } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Outlet, useParams } from 'react-router-dom';
import ErrorState from '@/components/ErrorState';

import { ReportAuthContext } from './ReportAuthContext';
import * as S from './styles';

const Reports = () => {
  const [isFetchingAuth, setIsFetchingAuth] = useState(true);
  const [userBearerToken, setUserBearerToken] = useState<string>();
  const accessTokenRef = useRef<string>();
  const [cookie] = useCookies(['admin-auth-token']);
  const { organizationId } = useParams<{ organizationId: string }>();

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  (async () => {
    if (!isFetchingAuth) {
      return;
    }
    if (organizationId !== undefined) {
      // check for admin auth
      setUserBearerToken(cookie['admin-auth-token'] as string);
      setIsFetchingAuth(false);
    } else {
      // check for user auth
      try {
        accessTokenRef.current = await getAccessTokenSilently({
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_API_AUDIENCE
          }
        });
        setUserBearerToken(accessTokenRef.current);
      } catch (error_) {
        const error = error_ as OAuthError;
        // This handles the case where the user opens the web app and their session has expired.
        // Since @auth0/auth0-react v2, `isAuthenticated` remains `true` even after the user's session has expired.
        if (error.error === 'login_required') {
          const redirectLoginOptions: RedirectLoginOptions = {
            appState: { returnTo: `${window.location.pathname}${window.location.search}` }
          };
          loginWithRedirect(redirectLoginOptions);
          return;
        }
      }
      setIsFetchingAuth(false);
    }
  })();

  useEffect(() => {
    const pageSpinner = document.querySelector<HTMLDivElement>('#page-spinner');
    const pageSpinnerStyles = document.querySelector<HTMLStyleElement>('#page-spinner-styles');

    // If the App component is mounted a second time, which happens during development because of React Fast Refresh, the page spinner and its styles will already have been removed.
    if (pageSpinner && pageSpinnerStyles && !isFetchingAuth) {
      pageSpinner.remove();
      pageSpinnerStyles.remove();
    }
  }, [isFetchingAuth]);

  return (
    <CookiesProvider>
      <S.Page>
        <ReportAuthContext.Provider value={{ bearerToken: userBearerToken ?? '' }}>
          {userBearerToken !== undefined && userBearerToken !== '' && !isFetchingAuth ? (
            <Outlet />
          ) : (
            <ErrorState />
          )}
        </ReportAuthContext.Provider>
      </S.Page>
    </CookiesProvider>
  );
};

export default Reports;
