import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import type { CompletedEducationalResource } from '../utils';
import { generateNetworkEducation } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchNetworkEducation = (filters: NetworkEducationFilters) => Promise<void>;

interface NetworkEducationFilters {
  'communities[]'?: string[] | null;
  date_range?: 'last_12_months' | 'last_30_days' | 'last_90_days' | 'year_to_date';
  order_by?: {
    column: string | undefined;
    dir: 'asc' | 'desc' | undefined;
  };
  'organizations[]'?: string[] | null;
  page?: number;
  per_page?: number;
  resource_name?: string;
  resource_type?: 'course_collection' | 'course';
  user_name?: string;
}

interface UseGetNetworkEducation {
  (filters: NetworkEducationFilters): {
    education: CompletedEducationalResource[];
    isFetchingNetworkEducation: boolean;
    totalNetworkEducationPages: number;
    updateEducationFilters: (newFilters: NetworkEducationFilters) => void;
  };
}

const useGetNetworkEducation: UseGetNetworkEducation = filters => {
  const { getRequest, reportError } = useApiRequest();
  const isFirstRender = useIsFirstRender();

  const [education, setEducation] = useState<CompletedEducationalResource[]>([]);
  const [isFetchingNetworkEducation, setIsFetchingNetworkEducation] = useState(false);
  const [query, setQuery] = useState<NetworkEducationFilters>(filters);
  const [totalNetworkEducationPages, setTotalNetworkEducationPages] = useState(1);

  const fetchNetworkEducation: FetchNetworkEducation = useCallback(
    async filters => {
      setIsFetchingNetworkEducation(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/networks/educations${
        params ? `?${params}` : ''
      }`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APINetworksEducations['data'];
          meta?: APINetworksEducations['meta'];
        };

        setEducation(
          data !== undefined ? data.map(fields => generateNetworkEducation(fields)) : []
        );
        if (meta !== undefined) {
          setTotalNetworkEducationPages(Number(meta.total_pages));
        }
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetchingNetworkEducation(false);
      }
    },
    [getRequest, reportError]
  );

  const debouncedFetchNetworkEducation = useDebounce(fetchNetworkEducation, 200);

  useEffect(() => {
    /*
      React StrictMode causes this initial fetch
      to happen twice even with no dependencies, resulting
      in a double API call w/ no filter changes.
      This is a workaround for now that will run the
      initial fetch only once after the component calling
      this hook has been mounted.
    */
    if (!isFirstRender) return;
    setIsFetchingNetworkEducation(true);
    debouncedFetchNetworkEducation(filters);

    return () => {
      setEducation([]);
      setIsFetchingNetworkEducation(false);
      setQuery({});
    };
  }, [debouncedFetchNetworkEducation, isFirstRender, filters]);

  const updateEducationFilters = (newFilters: Partial<NetworkEducationFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof NetworkEducationFilters] === undefined
        ? delete newFilters[key as keyof NetworkEducationFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchNetworkEducation(newFilters);
  };

  return {
    education,
    isFetchingNetworkEducation,
    totalNetworkEducationPages,
    updateEducationFilters
  };
};

export default useGetNetworkEducation;
