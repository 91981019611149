import SearchIcon from '@material-symbols/svg-400/rounded/search.svg?react';
import capitalize from 'lodash/capitalize';
import type { Key, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { AriaCheckboxGroupProps, AriaCheckboxProps, AriaComboBoxProps } from 'react-aria';
import { useFilter } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import type { SortDescriptor } from 'react-stately';
import { Item } from 'react-stately';
import FilterDropdown from '@/components/FilterDropdown';
import Checkbox from '@/components/FormFields/Checkbox';
import CheckboxGroup from '@/components/FormFields/CheckboxGroup';
import GroupCheckbox from '@/components/FormFields/CheckboxGroup/GroupCheckbox';
import CommunityFilter from '@/components/Table/Filters/CommunityFilter';
import useUser from '@/hooks/useUser';
import { NPI_SPECIALTIES, STATES } from '@/pages/constants';
import type { NetworkOrganizationMember } from '@/pages/Dashboard/utils';
import { filtersObjectToParams, sortFilterParams } from '@/utils/filterUtils';
import isNonEmptyString from '@/utils/isNonEmptyString';
import { loadNetworkOrgMemberName } from '@/utils/loadNetworkOrgMemberName';

import { networkFiltersToSearch, networkSearchToFilters } from '../../../networkFilters';

import * as S from './styles';

interface Props {
  exportButton: ReactNode;
  sortDescriptor: SortDescriptor;
}

const ServiceAreaFilters = ({ exportButton, sortDescriptor }: Props) => {
  const { bearerToken } = useUser();
  const { contains } = useFilter({ sensitivity: 'base' });
  const [searchParams, setSearchParams] = useSearchParams();

  const [orgName, setOrgName] = useState<string | null>(null);
  const [selectedOrganizationsFilter, setSelectedOrganizationsFilter] = useState<(string | null)[]>(
    searchParams.get('organizations[]')?.split(',') ?? []
  );
  const [activeProviderOrgsChecked, setActiveProviderOrgsChecked] = useState<boolean>(
    searchParams.get('org_is_on_violet') === 'true'
  );
  const [verifiedInclusiveChecked, setVerifiedInclusiveChecked] = useState<boolean>(
    searchParams.get('verified_inclusive') === 'true'
  );

  /**** INITIAL STATE ****/
  const draftState = useRef<{
    benchmark_communities: string[];
    locations: Set<Key>;
    org_is_on_violet: boolean;
    organizations: (string | null)[];
    specialties: (string | null)[];
    verified_inclusive: boolean;
  }>({
    benchmark_communities: searchParams.get('benchmark_communities[]')?.split(',') ?? [],
    locations: new Set(searchParams.get('locations[]')?.split(',') ?? []),
    org_is_on_violet: searchParams.get('org_is_on_violet') === 'true',
    organizations: searchParams.get('organizations[]')?.split(',') ?? [],
    specialties: searchParams.get('specialties[]')?.split(',') ?? [],
    verified_inclusive: searchParams.get('verified_inclusive') === 'true'
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
        .flatMap(([key, value]) => value.split(',').map(v => networkSearchToFilters(key, v) as Key))
    )
  );

  /**** INDIVIDUAL FILTER HANDLERS ****/
  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    draftState.current.benchmark_communities = [...communities].sort();
  };

  const handleOrganizationsFilterChange: AriaComboBoxProps<
    NetworkOrganizationMember['id']
  >['onSelectionChange'] = value => {
    draftState.current.organizations =
      value !== null && value.toString().trim() !== '' ? [value as string] : [];
  };

  const handleLocationsFilterChange = (newValues: Iterable<Key> | null) => {
    draftState.current.locations = new Set(newValues);
  };

  const handleSpecialtiesFilterChange: AriaComboBoxProps<string>['onSelectionChange'] = value => {
    draftState.current.specialties = [value as string];
  };

  const handleActiveProviderOrgsFilterChange: AriaCheckboxProps['onChange'] = value => {
    draftState.current.org_is_on_violet = value;
    setActiveProviderOrgsChecked(value);
  };

  const handleVerifiedInclusiveFilterChange: AriaCheckboxProps['onChange'] = value => {
    draftState.current.verified_inclusive = value;
    setVerifiedInclusiveChecked(value);
  };

  /**** FILTER HANDLERS ****/

  /* preserves all changes made to the filters */
  const preserveChanges = () => {
    setSelectedOrganizationsFilter(draftState.current.organizations);

    const communityFilters = draftState.current.benchmark_communities.map(
      community => `Community:${community.toString()}`
    );
    const locationFilters = Array.from(draftState.current.locations).map(
      location => `Location:${location.toString()}`
    );
    const updatedFilters = communityFilters
      .concat(locationFilters)
      .concat([
        `Organization:${draftState.current.organizations[0]}`,
        `Specialty:${draftState.current.specialties[0]}`,
        `Organizations on Violet:${draftState.current.org_is_on_violet ? 'true' : 'null'}`,
        `Verified Inclusive Organizations:${draftState.current.verified_inclusive ? 'true' : 'null'}`
      ])
      .filter(
        entry =>
          !entry.includes('null') &&
          !entry.includes('undefined') &&
          isNonEmptyString(entry.split(':')[1])
      );
    setAppliedFilters(new Set(updatedFilters));
    refreshURLparams(new Set(updatedFilters));
  };

  /* clears all filters and search params */
  const handleClearFilters = () => {
    setSelectedOrganizationsFilter([]);
    setActiveProviderOrgsChecked(false);
    setVerifiedInclusiveChecked(false);
    draftState.current = {
      benchmark_communities: [],
      locations: new Set([]),
      org_is_on_violet: false,
      organizations: [],
      specialties: [],
      verified_inclusive: false
    };
    setAppliedFilters(new Set());
    refreshURLparams(new Set());
  };

  /* removes a single filter from the applied filters and search params */
  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('Organization:')) {
        draftState.current.organizations = [];
        setSelectedOrganizationsFilter([]);
      } else if (keyString.includes('Specialty:')) {
        draftState.current.specialties = [];
      } else if (keyString.includes('Community:')) {
        const community = keyString.split(':')[1];
        draftState.current.benchmark_communities = draftState.current.benchmark_communities.filter(
          item => item !== community
        );
      } else if (keyString.includes('Location:')) {
        const location = keyString.split(':')[1];
        draftState.current.locations = new Set(
          Array.from(draftState.current.locations).filter(item => item !== (location as Key))
        );
      } else if (keyString.includes('Organizations on Violet')) {
        draftState.current.org_is_on_violet = false;
        setActiveProviderOrgsChecked(false);
      } else if (keyString.includes('Verified Inclusive Organizations:')) {
        draftState.current.verified_inclusive = false;
        setVerifiedInclusiveChecked(false);
      }
    });
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(updatedFilters);
  };

  /* sets preserved filters to SearchParams */
  const refreshURLparams = (updatedFilters: Set<Key>) => {
    const finalParams = filtersObjectToParams(networkFiltersToSearch, updatedFilters);
    const orderAndPageParams: [string, string][] = [
      ['order_by[column]', sortDescriptor.column.toString()],
      ['order_by[dir]', sortDescriptor.direction === 'descending' ? 'desc' : 'asc'],
      ['page', '1'],
      ['view', searchParams.get('view') ?? 'county']
    ];
    const sortedParams = sortFilterParams(orderAndPageParams.concat(finalParams));
    setSearchParams(sortedParams);
  };

  const getLocationLabel = (value: string) => {
    const childType = value.split('|')[0];
    if (childType === 'State') {
      return STATES.find(state => state.abbreviation === value.split('|')[1])?.name;
    }
    return `${value.split('|')[1]}, ${value.split('|')[2].toUpperCase()}`;
  };

  /**** FILTER HOOKS ****/

  /* sets the organization name when the organization filter changes */
  useEffect(() => {
    selectedOrganizationsFilter.forEach(id => {
      if (id === null) return;
      loadNetworkOrgMemberName(id, bearerToken).then(name => {
        setOrgName(name);
      });
    });
  }, [selectedOrganizationsFilter, bearerToken]);

  /* reset filters, draftState, and appliedFilters when searchParams changes */
  useEffect(() => {
    draftState.current = {
      benchmark_communities: searchParams.get('benchmark_communities[]')?.split(',') ?? [],
      locations: new Set(searchParams.get('locations[]')?.split(',') ?? []),
      org_is_on_violet: searchParams.get('org_is_on_violet') === 'true',
      organizations: searchParams.get('organizations[]')?.split(',') ?? [],
      specialties: searchParams.get('specialties[]')?.split(',') ?? [],
      verified_inclusive: searchParams.get('verified_inclusive') === 'true'
    };
    setAppliedFilters(
      new Set(
        Array.from(searchParams.entries())
          .filter(
            ([key]) => !key.includes('view') && !key.includes('order_by') && !key.includes('page')
          )
          .flatMap(([key, value]) =>
            value.split(',').map(v => networkSearchToFilters(key, v) as Key)
          )
      )
    );
    setSelectedOrganizationsFilter(searchParams.get('organizations[]')?.split(',') ?? []);
    setActiveProviderOrgsChecked(searchParams.get('org_is_on_violet') === 'true');
    setVerifiedInclusiveChecked(searchParams.get('verified_inclusive') === 'true');
  }, [searchParams]);

  return (
    <S.FiltersAndActionsWrapper>
      <FilterDropdown>
        <FilterDropdown.Filters onApplyChanges={preserveChanges}>
          <S.WideLocationsFilter
            ariaLabel="Search by city, state, county, or zip"
            dataCy="locations-filter"
            handleSelectionChange={handleLocationsFilterChange}
            placeholder="Search by city, state, county, or zip"
            selectedKeys={draftState.current.locations}
          />
          <S.FilterSectionTitle>Provider organizations</S.FilterSectionTitle>
          <S.WideNetworkOrganizationFilter
            aria-label="Search by provider organization name"
            data-cy="organizations-filter"
            handleSelectionChange={handleOrganizationsFilterChange as (value: Key | null) => void}
            placeholder="Name"
            selectedKey={draftState.current.organizations[0] as Key | undefined}
          />
          <S.OnVioletWrapper>
            <S.CheckboxWrapper>
              <Checkbox
                data-cy="organization-on-violet-filter"
                isSelected={activeProviderOrgsChecked}
                onChange={handleActiveProviderOrgsFilterChange}
              >
                On Violet
              </Checkbox>
              <Checkbox
                data-cy="verified-inclusive-organizations-filter"
                isSelected={verifiedInclusiveChecked}
                onChange={handleVerifiedInclusiveFilterChange}
              >
                Verified Inclusive Organizations
              </Checkbox>
            </S.CheckboxWrapper>
          </S.OnVioletWrapper>

          <S.FilterSectionTitle>Providers</S.FilterSectionTitle>
          <S.WideComboBox
            allowsCustomValue
            aria-label="Search by specialty"
            data-cy="specialties-filter"
            filter={contains}
            icon={SearchIcon}
            placeholder="Specialty"
            selectedKey={draftState.current.specialties[0]}
            onSelectionChange={handleSpecialtiesFilterChange}
          >
            {NPI_SPECIALTIES.map(specialty => (
              <Item key={specialty}>{specialty}</Item>
            ))}
          </S.WideComboBox>
          <CommunityFilter filterLabelText="Community Benchmark">
            <CheckboxGroup
              aria-labelledby="filter-label"
              defaultValue={draftState.current.benchmark_communities}
              direction="horizontal"
              onChange={handleFilterByCommunityChange}
            >
              <GroupCheckbox
                data-cy="bipoc-filter"
                value="bipoc"
              >
                BIPOC
              </GroupCheckbox>
              <GroupCheckbox
                data-cy="lgbq-filter"
                value="lgbq"
              >
                LGBQ
              </GroupCheckbox>
              <GroupCheckbox
                data-cy="tgnc-filter"
                value="tgnc"
              >
                TGNC
              </GroupCheckbox>
            </CheckboxGroup>
          </CommunityFilter>
        </FilterDropdown.Filters>
        <FilterDropdown.Tags
          onClear={handleClearFilters}
          onRemove={handleRemoveFilter}
        >
          {Array.from(appliedFilters).map(key => {
            const label = key.toString().split(':')[0];
            let value = key.toString().split(':')[1];
            if (label.toLowerCase().includes('community')) {
              value = value.toUpperCase();
            }
            return (
              <Item key={key}>
                {label === 'Location' ? value.split('|')[0] : label}
                {value !== 'true' && (
                  <>
                    {': '}
                    {label.toLowerCase().includes('organization') ? (
                      orgName !== null ? (
                        orgName
                      ) : (
                        <S.SmallSpinner />
                      )
                    ) : label === 'Range' ? (
                      capitalize(value.replaceAll('_', ' '))
                    ) : label === 'Location' ? (
                      getLocationLabel(value)
                    ) : (
                      value
                    )}
                  </>
                )}
              </Item>
            );
          })}
        </FilterDropdown.Tags>
      </FilterDropdown>
      {exportButton}
    </S.FiltersAndActionsWrapper>
  );
};

export default ServiceAreaFilters;
