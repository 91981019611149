import type { GridNode } from '@react-types/grid';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import { useTableHeaderRow } from 'react-aria';
import type { TableState } from 'react-stately';

interface Props {
  item: GridNode<object>;
  state: TableState<object>;
}

const HeaderRow = ({ children, item, state }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const { rowProps } = useTableHeaderRow({ node: item }, state, ref);

  return (
    <tr
      {...rowProps}
      ref={ref}
    >
      {children}
    </tr>
  );
};

export default HeaderRow;
