import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import { default as BaseButton } from '../../Buttons/Button';

export const Button = styled(BaseButton)`
  align-self: start;
`;

export const EmailLink = styled.a`
  color: var(--gray-800);
  text-decoration: underline;
`;

export const Text = styled.p`
  margin-bottom: var(--space-7);
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);
`;
