import { FOCUS_STYLES, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';
import UnstyledButton from '@/components/UnstyledButton';

export const BenchmarkDescription = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--gray-500);
  line-height: 1.25;
`;

export const BenchmarkImage = styled.img`
  width: auto;
  max-height: 100%;
`;

export const BenchmarkItem = styled(UnstyledButton)`
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  display: flex;
  padding: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  cursor: pointer;

  &.is-hovered {
    border: 1px solid var(--gray-300);
  }

  &.is-selected {
    border: 2px solid var(--purple-400);
    padding: calc(0.75rem - 1px);
  }

  &.is-focused {
    ${FOCUS_STYLES}
  }

  [data-cy='benchmark-checkbox'] {
    pointer-events: auto;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    align-items: flex-start;

    label {
      margin-top: 0.25rem;
    }
  }
`;

export const BenchmarkLabel = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  font-weight: 500;
  color: var(--gray-800);
`;

export const BenchmarkSubLabel = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-600);
`;

export const BenchmarkWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  background: #fff;
  border-radius: 50%;
  padding: 0.2rem;
  display: flex;
  align-items: end;
  justify-content: center;
  filter: drop-shadow(0 7.111px 10.667px rgba(16, 24, 40, 0.03))
    drop-shadow(0 0 10.667px rgba(16, 24, 40, 0.1));

  &.not-round {
    padding: 0.2rem 0.3rem;
  }
`;

export const DateLabel = styled.div`
  ${TEXT_STYLES.tagSmall}
  white-space: nowrap;
  color: var(--gray-600);
  margin-top: 0.125rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
