import { Item } from 'react-stately';
import Select from '@/components/FormFields/Select';
import TagGroup from '@/components/TagGroup';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../constants';
import Button from '../../Buttons/Button';
import SectionHero from '../../heroes/SectionHero';
import ComboBox from '../ComboBox';

export const LanguageComboBox = styled(ComboBox)`
  margin-top: 1rem;
  position: relative;

  input[type='text'] {
    padding: calc(0.5rem - 1px) calc(0.5rem + 0.75rem + (1rem - 1px)) calc(0.5rem - 1px)
      calc(1rem - 1px);
    background-color: var(--white-light);

    &:focus {
      padding: calc(0.5rem - 2px) calc(0.5rem + 0.75rem + (1rem - 2px)) calc(0.5rem - 2px)
        calc(1rem - 2px);
      background-position: right calc(1rem - 2px) center;
    }
  }
`;

export const LanguageHero = styled(SectionHero)`
  h3 {
    max-width: 700px;
  }

  p {
    ${TEXT_STYLES.bodyCopyMedium}
  }
`;

export const LanguagesTagGroup = styled(TagGroup)`
  margin-bottom: 1.5rem;

  & > div > div {
    box-shadow: 0 1px 3px 1px rgba(16, 24, 40, 0.1);
  }
`;

export const ProficiencyHeader = styled.h5`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  margin-bottom: 0.75rem;
`;

export const ProficiencyItem = styled(Item)``;

export const ProficiencyItemDesc = styled.div``;

export const ProficiencyItemName = styled.div`
  font-weight: 700;
  margin-bottom: 0.25rem;
`;

export const ProficiencySelect = styled(Select)`
  margin-top: 1rem;
  position: relative;
`;
export const SelectedContainer = styled.div`
  padding: 1rem 0;
  border-radius: 0.25rem;
  width: 100%;
  min-height: 4.5rem;

  &.empty {
    background-color: var(--gray-50);
  }
`;

export const SelectionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  grid-gap: 2rem;
  margin-top: 0.5rem;
`;

export const SelectorColumn = styled.div`
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  margin-top: 1.25rem;
`;
