import { useMenuSection, useSeparator } from 'react-aria';
import type { Node, TreeState } from 'react-stately';

import MenuItem from '../MenuItem';

import * as S from './styles';

interface Props {
  section: Node<object>;
  state: TreeState<object>;
}

const MenuSection = ({ section, state }: Props) => {
  const { groupProps, headingProps, itemProps } = useMenuSection({
    'aria-label': section['aria-label'],
    heading: section.rendered
  });

  const { separatorProps } = useSeparator({
    elementType: 'li'
  });

  // If the section is not the first, add a separator element.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && <S.Separator {...separatorProps} />}
      <S.SectionItem {...itemProps}>
        {section.rendered !== undefined && (
          <S.SectionHeader {...headingProps}>{section.rendered}</S.SectionHeader>
        )}
        <S.NestedList {...groupProps}>
          {Array.from(section.childNodes).map(node => (
            <MenuItem
              key={node.key}
              item={node}
              state={state}
            />
          ))}
        </S.NestedList>
      </S.SectionItem>
    </>
  );
};

export default MenuSection;
