import { createContext } from 'react';
import type { AriaRadioGroupProps } from 'react-aria';
import { useRadioGroup } from 'react-aria';
import type { RadioGroupState } from 'react-stately';
import { useRadioGroupState } from 'react-stately';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface GroupContext {
  size: 'regular' | 'small';
  state: RadioGroupState;
}

export const RadioContext = createContext<GroupContext | null>(null);

interface Props extends AriaRadioGroupProps {
  children: JSX.Element[];
  className?: string;
  size?: 'regular' | 'small';
}

const RadioGroup = ({ children, className, size = 'regular', ...ariaRadioGroupProps }: Props) => {
  const { label } = ariaRadioGroupProps;
  const state = useRadioGroupState(ariaRadioGroupProps);
  const { errorMessageProps, isInvalid, labelProps, radioGroupProps, validationErrors } =
    useRadioGroup(ariaRadioGroupProps, state);

  return (
    <>
      <S.RadioGroup
        {...radioGroupProps}
        $size={size}
        className={className}
      >
        {isNonEmptyString(label) && <S.Label {...labelProps}>{label}</S.Label>}
        <RadioContext.Provider value={{ size, state }}>{children}</RadioContext.Provider>
      </S.RadioGroup>
      {isInvalid && (
        <S.ErrorMessage {...errorMessageProps}>{validationErrors.join(' ')}</S.ErrorMessage>
      )}
    </>
  );
};

export default RadioGroup;
