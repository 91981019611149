import ExpandIcon from '@material-symbols/svg-400/rounded/expand_circle_down-fill.svg?react';
import FoldIcon from '@material-symbols/svg-400/rounded/expand_circle_up-fill.svg?react';
import type { Dispatch, SetStateAction } from 'react';

import * as S from './styles';

interface Props {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

const CollapsibleButton = ({ collapsed, setCollapsed }: Props) => (
  <S.CollapsibleButton
    aria-label={collapsed ? 'Show full description' : 'Collapse description'}
    onPress={() => setCollapsed(!collapsed)}
  >
    {collapsed ? (
      <ExpandIcon aria-label="Show full description" />
    ) : (
      <FoldIcon aria-label="Collapse description" />
    )}
  </S.CollapsibleButton>
);

export default CollapsibleButton;
