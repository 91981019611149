import type { ReactElement } from 'react';
import PopoverTrigger from '@/components/PopoverTrigger';
import TooltipTrigger from '@/components/TooltipTrigger';
import isNonEmptyString from '@/utils/isNonEmptyString';

import Meter from './Meter';
import * as S from './styles';

interface Props {
  comparisonKey?: string;
  comparisonPopoverContent: ReactElement;
  comparisonValue: number;
  isLast?: boolean;
  isUnavailable?: boolean;
  label: string;
  labelPopoverText?: string;
  showKey?: boolean;
  size?: 'small' | 'large';
  value: number;
  valueKey?: string;
}

const ComparisonMeters = ({
  comparisonKey,
  comparisonPopoverContent,
  comparisonValue,
  isLast = false,
  isUnavailable = false,
  label,
  labelPopoverText,
  showKey = false,
  size = 'small',
  value,
  valueKey
}: Props) => {
  const dataCyLabel = label.toLowerCase().replaceAll(' ', '-');
  return (
    <>
      <S.ComparisonMeters
        $isLast={isLast}
        $size={size}
      >
        <S.Label>
          {label}
          {isNonEmptyString(labelPopoverText) && (
            <PopoverTrigger
              content={<S.PopoverContent>{labelPopoverText}</S.PopoverContent>}
              placement="right"
            >
              <S.PopoverButton aria-label="Help">
                <S.HelpIcon
                  aria-hidden="true"
                  role="img"
                />
              </S.PopoverButton>
            </PopoverTrigger>
          )}
        </S.Label>
        <TooltipTrigger
          content={comparisonPopoverContent}
          delay={300}
          placement="top right"
        >
          <S.MeterPopoverButton
            aria-label="Details about comparison data."
            className={size === 'small' ? 'is-small' : ''}
          >
            <Meter
              aria-label={`${valueKey ?? label} meter`}
              data-cy={`${dataCyLabel}-meter`}
              size={size}
              value={value}
            />
            <Meter
              aria-label={`${comparisonKey ?? label} comparison meter`}
              data-cy={`${dataCyLabel}-comparison-meter`}
              isUnavailable={isUnavailable}
              size={size}
              value={comparisonValue}
              variant="secondary"
            />
          </S.MeterPopoverButton>
        </TooltipTrigger>
      </S.ComparisonMeters>
      {showKey && (
        <S.KeySection>
          <S.Key data-cy={`${dataCyLabel}-value-key`}>
            <S.CircleKey className="primary" />
            {valueKey ?? 'Value'}
          </S.Key>
          <S.Key data-cy={`${dataCyLabel}-comparison-key`}>
            <S.CircleKey className="secondary" />
            {comparisonKey ?? 'Comparison value'}
          </S.Key>
        </S.KeySection>
      )}
    </>
  );
};

export default ComparisonMeters;
