import PopoverTrigger from '@/components/PopoverTrigger';

import * as S from './styles';

const DataEmptyState = () => (
  <PopoverTrigger
    content={
      <S.PopoverContent>
        There is not enough data to populate this field. Try broadening your filters or checking
        back later.
      </S.PopoverContent>
    }
  >
    <S.PopoverButton>
      N/A
      <S.HelpIcon />
    </S.PopoverButton>
  </PopoverTrigger>
);

export default DataEmptyState;
