import type { PlacementAxis } from 'react-aria';
import styled from 'styled-components';

const arrowLength = '0.625rem';

interface ArrowProps {
  $placement?: PlacementAxis;
}

export const Arrow = styled.span<ArrowProps>`
  position: absolute;
  width: ${arrowLength};
  height: ${arrowLength};
  border-radius: 0.0625rem;
  rotate: 45deg;

  ${({ $placement = 'top' }) => {
    if ($placement === 'bottom') {
      return `
        top: -0.25rem;
        translate: calc(${arrowLength} / 2 * -1);
      `;
    } else if ($placement === 'left') {
      return `
        right: -0.25rem;
        translate: 0% calc(${arrowLength} / 2 * -1);
      `;
    } else if ($placement === 'right') {
      return `
        left: -0.25rem;
        translate: 0% calc(${arrowLength} / 2 * -1);
      `;
    } else if ($placement === 'top') {
      return `
        bottom: -0.25rem;
        translate: calc(${arrowLength} / 2 * -1);
      `;
    }
  }}
`;

interface PopoverProps {
  $containerPadding: number;
}

export const Popover = styled.div<PopoverProps>`
  ${({ $containerPadding }) => `
    max-width: calc(100vw - ${$containerPadding * 2}px);
  `}
`;

export const Underlay = styled.div`
  position: fixed;
  inset: 0;
`;
