import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

interface ContextValue {
  bearerToken: string;
  refetchUser: () => Promise<void>;
  setUser: Dispatch<SetStateAction<User>>;
  user: User;
}

// @ts-expect-error - Auth0 types are incomplete for getAccessTokenSilently
export const UserContext = createContext<ContextValue>(null);

const useUser = () => useContext(UserContext);

export default useUser;
