import CancelIcon from '@material-symbols/svg-400/rounded/cancel-fill.svg?react';
import CheckCircleIcon from '@material-symbols/svg-400/rounded/check_circle-fill.svg?react';
import DoNotDisturbIcon from '@material-symbols/svg-400/rounded/do_not_disturb_on.svg?react';
import styled from 'styled-components';

export const CheckIcon = styled(CheckCircleIcon)`
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--success-500);
`;

export const DashIcon = styled(DoNotDisturbIcon)`
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--gray-500);
`;

export const XIcon = styled(CancelIcon)`
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--error-500);
`;
