// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import clsx from 'clsx';
import { forwardRef } from 'react';
import type { AriaToggleButtonProps, TooltipTriggerAria } from 'react-aria';
import { mergeProps, useFocusRing, useHover, useToggleButton } from 'react-aria';
import { useToggleState } from 'react-stately';

import * as S from './styles';

interface Props extends AriaToggleButtonProps {
  className?: string;
  tooltipTriggerProps?: TooltipTriggerAria['triggerProps'];
}

const UnstyledToggleButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, tooltipTriggerProps, ...ariaToggleButtonProps }, forwardedRef) => {
    const { children, isDisabled = false } = ariaToggleButtonProps;

    const ref = useObjectRef(forwardedRef);

    const state = useToggleState(ariaToggleButtonProps);
    const { buttonProps, isPressed } = useToggleButton(ariaToggleButtonProps, state, ref);

    const { hoverProps, isHovered } = useHover({ isDisabled });
    const { focusProps, isFocusVisible } = useFocusRing();

    return (
      <S.UnstyledToggleButton
        {...mergeProps(buttonProps, focusProps, hoverProps, tooltipTriggerProps ?? {})}
        ref={ref}
        className={clsx(
          className,
          { 'is-disabled': isDisabled },
          { 'is-focused': isFocusVisible },
          { 'is-hovered': isHovered },
          { 'is-pressed': isPressed },
          { 'is-selected': state.isSelected }
        )}
      >
        {children}
      </S.UnstyledToggleButton>
    );
  }
);

UnstyledToggleButton.displayName = 'UnstyledToggleButton';

export default UnstyledToggleButton;
