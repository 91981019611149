import type { PropsWithChildren, RefObject } from 'react';
import { useRef } from 'react';
import type { AriaPopoverProps, DismissButtonProps } from 'react-aria';
import { DismissButton, Overlay, usePopover } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';
import type { SetOptional } from 'type-fest';

import * as S from './styles';

interface Props extends SetOptional<AriaPopoverProps, 'popoverRef'> {
  className?: string;
  showArrow?: boolean;
  state: OverlayTriggerState;
}

const Popover = ({
  children,
  className,
  showArrow = false,
  state,
  ...ariaPopoverProps
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);

  const { containerPadding = 12, isNonModal = false, popoverRef = ref } = ariaPopoverProps;

  const isModal = !isNonModal;

  const { arrowProps, placement, popoverProps, underlayProps } = usePopover(
    { ...ariaPopoverProps, containerPadding, popoverRef },
    state
  );

  const handleDismissButtonDismiss: DismissButtonProps['onDismiss'] = () => {
    state.close();
  };

  return (
    <Overlay>
      {isModal && <S.Underlay {...underlayProps} />}
      <S.Popover
        {...popoverProps}
        ref={popoverRef as RefObject<HTMLDivElement>}
        $containerPadding={containerPadding}
        className={className}
      >
        {showArrow && (
          <S.Arrow
            {...arrowProps}
            $placement={placement ?? undefined}
            className="arrow"
          />
        )}
        {isModal && <DismissButton onDismiss={handleDismissButtonDismiss} />}
        {children}
        <DismissButton onDismiss={handleDismissButtonDismiss} />
      </S.Popover>
    </Overlay>
  );
};

export default Popover;
