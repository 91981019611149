import type { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import type { FormEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Button from '@/components/Buttons/Button';
import ButtonGroup from '@/components/Buttons/ButtonGroup';
import CheckboxGroup from '@/components/FormFields/CheckboxGroup';
import GroupCheckbox from '@/components/FormFields/CheckboxGroup/GroupCheckbox';
import InputGroup from '@/components/FormFields/InputGroup';
import SliderAndNumberField from '@/components/FormFields/SliderAndNumberField';
import Spinner from '@/components/Spinner';
import type { VioletToast } from '@/components/ToastProvider';
import useOpenErrorModalDialog from '@/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import useUser from '@/hooks/useUser';
import type { ExperienceObject } from '@/pages/Onboarding/CulturalCompetencies';
import {
  communityDescriptions,
  experienceTypeOptions,
  initialCompetencyObject,
  livedExperienceOptions
} from '@/pages/Onboarding/CulturalCompetencies';

import * as S from './styles';

interface CommunityStringArray {
  BIPOC: string[];
  LGBQ: string[];
  TGNC: string[];
}

interface CommunityString {
  BIPOC: string;
  LGBQ: string;
  TGNC: string;
}

interface CommunityNumber {
  BIPOC: number[] | number;
  LGBQ: number[] | number;
  TGNC: number[] | number;
}

type CommunityOptions = 'BIPOC' | 'LGBQ' | 'TGNC';
const communities: CommunityOptions[] = ['BIPOC', 'LGBQ', 'TGNC'];

const CulturalCompetencies = () => {
  const { bearerToken, setUser, user } = useUser();
  const functionToCallAfterUserUpdateRef = useRef<() => void>();
  const toastState = useOutletContext();

  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const [isSettingData, setIsSettingData] = useState<boolean>(true);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);

  const [cultureCompetencyId, setCultureCompetencyId] = useState<CommunityString>({
    BIPOC: '',
    LGBQ: '',
    TGNC: ''
  });
  const [experienceTypes, setExperienceTypes] = useState<CommunityStringArray>({
    BIPOC: [],
    LGBQ: [],
    TGNC: []
  });
  const [livedExperience, setLivedExperience] = useState<CommunityStringArray>({
    BIPOC: [],
    LGBQ: [],
    TGNC: []
  });
  const [communityConfidence, setCommunityConfidence] = useState<CommunityNumber>({
    BIPOC: 0,
    LGBQ: 0,
    TGNC: 0
  });

  /***** Find and set saved community data hook */
  useEffect(() => {
    /***** Find or create initial cultural competence object */
    const culturalCompetences = user.user_info.cultural_competences;
    const mappedData = communities.map(community => {
      const userCommunityData = culturalCompetences.find(
        competency => competency.competence_type === community.toLowerCase()
      ) ?? { ...initialCompetencyObject, competence_type: community.toLowerCase() };

      /***** Set initial experience set data */
      const savedExperienceTypesObject: ExperienceObject = {
        community_of_interest: userCommunityData.community_of_interest,
        formal_education_training: userCommunityData.formal_education_training,
        lived_experience: (userCommunityData.lived_experience ?? []).length > 0,
        professional_experience: userCommunityData.professional_experience
      };
      const savedExperienceTypes = [];
      for (const key in savedExperienceTypesObject) {
        if (savedExperienceTypesObject[key]) {
          savedExperienceTypes.push(key);
        }
      }
      /***** return as easily mappable object to form states */
      return {
        communityConfidence: userCommunityData.confidence_level,
        competenceType: userCommunityData.competence_type.toLowerCase(),
        experienceTypes: savedExperienceTypes,
        id: userCommunityData.id,
        livedExperience: userCommunityData.lived_experience ?? []
      };
    });
    /*****  map to communities and set initial states */
    const bipoc = mappedData.find(item => item.competenceType === 'bipoc')!;
    const tgnc = mappedData.find(item => item.competenceType === 'tgnc')!;
    const lgbq = mappedData.find(item => item.competenceType === 'lgbq')!;
    setCultureCompetencyId({
      BIPOC: bipoc.id!,
      LGBQ: lgbq.id!,
      TGNC: tgnc.id!
    });
    setExperienceTypes({
      BIPOC: bipoc.experienceTypes,
      LGBQ: lgbq.experienceTypes,
      TGNC: tgnc.experienceTypes
    });
    setLivedExperience({
      BIPOC: bipoc.livedExperience,
      LGBQ: lgbq.livedExperience,
      TGNC: tgnc.livedExperience
    });
    setCommunityConfidence({
      BIPOC: bipoc.communityConfidence,
      LGBQ: lgbq.communityConfidence,
      TGNC: tgnc.communityConfidence
    });
    setIsSettingData(false);
  }, [user.user_info]);

  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }
  }, [user]);

  /***** Action Handlers */
  const handleConfidenceLevelChange = (
    confidenceLevel: number[] | number,
    community: CommunityOptions
  ) => {
    setCommunityConfidence({ ...communityConfidence, [community]: confidenceLevel });
  };

  const handleExperienceTypeChange = (experienceTypeSet: string[], community: CommunityOptions) => {
    setExperienceTypes({ ...experienceTypes, [community]: experienceTypeSet });
  };

  const handleLivedExperienceChange = (
    livedExperienceSet: string[],
    community: CommunityOptions
  ) => {
    setLivedExperience({ ...livedExperience, [community]: livedExperienceSet });
  };

  /***** Submit form and save data */
  const handleFormSubmit: FormEventHandler = async event => {
    event.preventDefault();
    setFormIsSubmitting(true);

    const requests = communities.map(community => {
      const id = cultureCompetencyId[community];
      const url: RequestInfo = `${
        import.meta.env.VITE_API_BASE_PATH
      }/users/dashboard/cultural_competences${id ? `/${id}` : ''}`;

      const options: RequestInit = {
        body: JSON.stringify({
          cultural_competence: {
            community_of_interest: experienceTypes[community].includes('community_of_interest'),
            competence_type: community.toLowerCase(),
            confidence_level: communityConfidence[community],
            formal_education_training: experienceTypes[community].includes(
              'formal_education_training'
            ),
            id,
            lived_experience: experienceTypes[community].includes('lived_experience')
              ? livedExperience[community]
              : [],
            professional_experience: experienceTypes[community].includes('professional_experience'),
            user_info_id: user.user_info.id
          }
        }),
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        method: id ? 'PATCH' : 'POST'
      };
      return fetch(url, options);
    });

    try {
      const responses = await Promise.all(requests);
      const updatedUser = cloneDeep(user); // TODO: replace with `structuredClone(user)` once browser support improves.

      /***** check for failures */
      let errorMessages = '';
      responses.forEach(response => {
        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            errorMessages += `${response.status} (${response.statusText})`;
            return;
          }
        }
      });

      if (errorMessages.length > 0) throw new Error(errorMessages);

      const competences = [];
      /***** Map new saved data and save to user */
      for (const response of responses) {
        const { data } =
          (await response.json()) as APIUsersDashboardCulturalCompetencesCulturalCompetenceId;

        competences.push(data);
      }
      updatedUser.user_info.cultural_competences = competences;

      setUser(updatedUser);

      functionToCallAfterUserUpdateRef.current = () => {
        window.scrollTo(0, 0);
        (toastState as ToastState<VioletToast>).add(
          {
            description: 'Your data has been saved.',
            type: 'success'
          },
          { timeout: 8000 }
        );
      };
    } catch (error) {
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setFormIsSubmitting(false);
  };

  /***** Generate each community row layout */
  /*
    TODO: Refactor
    @/pages/Onboarding/new-components/CulturalCompetencyForm/index.tsx
    To use object mapping on values
    So that can be re-used here and onboarding
  */
  const communityBlocks = communities.map(community => (
    <S.CommunityRow
      key={`${
        cultureCompetencyId[community] ? cultureCompetencyId[community] : `${community}-block`
      }`}
      data-cy={`${community.toLowerCase()}-row`}
    >
      <S.TextBlock>
        <S.CommunityTitle>{community}</S.CommunityTitle>
        <S.CommunityDescription>{communityDescriptions[community]}</S.CommunityDescription>
      </S.TextBlock>
      <S.FormBlock>
        <InputGroup
          copy={`For example, if you have a lot of experience and/or identify as a member of any of the ${community} communities, you might select a higher number.`}
          header={`What’s your confidence level working with ${community} patients?`}
        >
          <SliderAndNumberField
            isRequired
            aria-label={`What’s your confidence level working with ${community} patients?`}
            data-cy="confidence-field"
            maxValue={10}
            minValue={0}
            setValue={(value: number[] | number) => handleConfidenceLevelChange(value, community)}
            value={communityConfidence[community]}
          />
        </InputGroup>
        {Number(communityConfidence[community]) > 0 && (
          <InputGroup
            copy="Select all that are applicable."
            header={`Let us know more about your experience with ${community} communities.`}
          >
            <CheckboxGroup
              aria-label={`Let us know more about your experience with ${community} communities.`}
              data-cy="experience-type-field"
              direction="vertical"
              validationBehavior="native"
              value={experienceTypes[community]}
              onChange={value => handleExperienceTypeChange(value, community)}
            >
              {experienceTypeOptions.map(option => (
                <GroupCheckbox
                  key={option.label}
                  name={`${community}ExperienceTypes`}
                  value={option.value}
                >
                  {option.label}
                </GroupCheckbox>
              ))}
            </CheckboxGroup>
          </InputGroup>
        )}
        {experienceTypes[community].includes('lived_experience') &&
          Number(communityConfidence[community]) > 0 && (
            <InputGroup header="Please describe your lived experience.">
              <CheckboxGroup
                isRequired
                aria-label="Please describe your lived experience."
                data-cy="lived-experience-description-field"
                direction="vertical"
                validationBehavior="native"
                value={livedExperience[community]}
                onChange={value => handleLivedExperienceChange(value, community)}
              >
                {livedExperienceOptions(community).map(option => (
                  <GroupCheckbox
                    key={option.label}
                    name={`${community}LivedExperience`}
                    value={option.value}
                  >
                    {option.label}
                  </GroupCheckbox>
                ))}
              </CheckboxGroup>
            </InputGroup>
          )}
      </S.FormBlock>
    </S.CommunityRow>
  ));

  /***** Render page */
  return (
    <S.Form onSubmit={handleFormSubmit}>
      <S.StyledPageTitle
        title="Cultural competence"
        titleVariant="h1"
      />
      {isSettingData ? <Spinner withWrapper /> : communityBlocks}
      <ButtonGroup
        align="right"
        size="large"
      >
        <Button
          data-cy="save-button"
          isLoading={formIsSubmitting}
          size="large"
          type="submit"
        >
          Save
        </Button>
      </ButtonGroup>
    </S.Form>
  );
};

export default CulturalCompetencies;
