import type { ReactElement, RefObject } from 'react';
import { useRef } from 'react';
import type { AriaTooltipProps, Placement } from 'react-aria';
import { useOverlayPosition, useTooltip } from 'react-aria';
import type { TooltipTriggerState } from 'react-stately';
import { useEventListener } from 'usehooks-ts';

import * as S from './styles';

interface Props extends AriaTooltipProps {
  content: ReactElement | string;
  hideArrow: boolean;
  placement: Placement;
  state: TooltipTriggerState;
  targetRef: RefObject<Element>;
  theme: 'dark' | 'light';
}

const Tooltip = ({
  content,
  hideArrow,
  placement: placementProp,
  state,
  targetRef,
  theme,
  ...ariaTooltipProps
}: Props) => {
  const ref = useRef<HTMLSpanElement>(null);

  const { tooltipProps } = useTooltip(ariaTooltipProps, state);

  const { arrowProps, overlayProps, placement, updatePosition } = useOverlayPosition({
    isOpen: state.isOpen,
    offset: 10,
    overlayRef: ref,
    placement: placementProp,
    targetRef
  });

  useEventListener('scroll', updatePosition);
  const showArrow = !hideArrow;

  return (
    <S.Tooltip
      {...overlayProps}
      {...tooltipProps}
      ref={ref}
      $placement={placement ?? 'top'}
      $theme={theme}
      data-theme={theme}
    >
      <S.TooltipContent $maxHeight={Number(overlayProps.style?.maxHeight ?? 100)}>
        {content}
      </S.TooltipContent>
      {showArrow && (
        <S.Arrow
          {...arrowProps}
          $placement={placement ?? 'top'}
          className="arrow"
        />
      )}
    </S.Tooltip>
  );
};

export default Tooltip;
