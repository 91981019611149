import styled from 'styled-components';

interface DataCardProps {
  $isVertical?: boolean;
}

export const DataCard = styled.div<DataCardProps>`
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  padding: 2rem;
  background: var(--white-light);

  ${({ $isVertical = false }) => {
    if ($isVertical) {
      return `
        display: flex;
        flex-direction: column;
      `;
    }
  }}
`;
