import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { prepareLocationsParam } from '@/utils/prepareLocationParms';
import { useDebounce } from '@/utils/useDebounce';

import type { NetworkOverview } from '../utils';
import { generateNetworkOverview } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';
import type { LocationFilter } from './useGetNetworkLocations';

type FetchNetworkOverview = (
  filters: NetworkOverviewFilters,
  skipSettingIsFetching?: boolean
) => Promise<void>;

interface NetworkOverviewFilters {
  'benchmark_communities[]'?: string[];
  locations?: AtLeastOne<LocationFilter>[] | string; // stringified array of objects for API or array of objects for frontend
  org_is_on_violet?: boolean;
  'organizations[]'?: string[];
  'specialties[]'?: string[];
  'states[]'?: string[];
  verified_inclusive?: boolean;
}

interface UseGetNetworkOverview {
  (filters?: NetworkOverviewFilters): {
    isFetching: boolean;
    networkOverview: NetworkOverview | undefined;
    updateNetworkOverviewFilters: (newFilters: Partial<NetworkOverviewFilters>) => void;
  };
}

const useGetNetworkOverview: UseGetNetworkOverview = (filters = {}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [networkOverview, setNetworkOverview] = useState<NetworkOverview | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [previousFilters, setPreviousFilters] = useState<NetworkOverviewFilters>(filters);
  const { getRequest, reportError } = useApiRequest();

  const fetchNetworkOverview: FetchNetworkOverview = useCallback(
    async (filters: NetworkOverviewFilters) => {
      setIsFetching(true);

      /* TODO: considerations
        - opt A) get the BE working to interpret locations as an array of objects correctly
        - opt B) refactor filtersToParams to support stringifing arrays of objects in specific cases
      */
      const paramsToSend = { ...filters };
      paramsToSend.locations = prepareLocationsParam(paramsToSend.locations);
      const params = filtersToParams(paramsToSend);

      const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/networks/overview${
        params ? `?${params}` : ''
      }`;

      try {
        const { data } = (await getRequest(url)) as { data?: APINetworksOverview['data'] };
        setNetworkOverview(data !== undefined ? generateNetworkOverview(data) : undefined);
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetching(false);
      }
    },
    [getRequest, reportError]
  );

  const debouncedFetchNetworkOverview = useDebounce(fetchNetworkOverview, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchNetworkOverview(filters);
    setIsFirstRender(false);

    return () => {
      setNetworkOverview(undefined);
      setIsFetching(false);
      setPreviousFilters({});
    };
  }, [isFirstRender, filters, debouncedFetchNetworkOverview]);

  const updateNetworkOverviewFilters = (newFilters: Partial<NetworkOverviewFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof NetworkOverviewFilters] === undefined
        ? delete newFilters[key as keyof NetworkOverviewFilters]
        : {}
    );
    if (isEqual(newFilters, previousFilters)) return;
    setPreviousFilters({ ...newFilters });
    debouncedFetchNetworkOverview(newFilters);
  };

  return {
    isFetching,
    networkOverview,
    updateNetworkOverviewFilters
  };
};

export default useGetNetworkOverview;
