import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@/utils/useDebounce';

import type { Network } from '../utils';
import { generateNetwork } from '../utils';

import useApiRequest from './useApiRequest';

type FetchNetworks = (skipSettingIsFetching?: boolean) => Promise<void>;

interface UseGetNetworks {
  (): {
    isFetching: boolean;
    networks: Network[];
  };
}

const useGetNetworks: UseGetNetworks = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [networks, setNetworks] = useState<Network[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { getRequest, reportError } = useApiRequest();

  const fetchNetworks: FetchNetworks = useCallback(async () => {
    setIsFetching(true);

    const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/networks`;

    try {
      const { data } = (await getRequest(url)) as { data?: APINetworks['data'] };
      setNetworks(data !== undefined ? data.map(network => generateNetwork(network)) : []);
    } catch (error) {
      reportError(error);
    } finally {
      setIsFetching(false);
    }
  }, [getRequest, reportError]);

  const debouncedFetchNetworks = useDebounce(fetchNetworks, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchNetworks();
    setIsFirstRender(false);

    return () => {
      setNetworks([]);
      setIsFetching(true);
    };
  }, [isFirstRender, debouncedFetchNetworks]);

  return {
    isFetching,
    networks
  };
};

export default useGetNetworks;
