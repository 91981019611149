import { useEffect, useState } from 'react';
import CollectionCard from '@/components/Education/CollectionCard';
import type { ResourceList } from '@/components/Education/EducationCarousel';
import PathwayCard from '@/components/Education/PathwayCard';
import PageTitle from '@/components/PageTitle';
import Spinner from '@/components/Spinner';
import type { Collection, Pathway } from '@/pages/Dashboard/utils';
import isNonEmptyString from '@/utils/isNonEmptyString';

import useGetCollections from '../../hooks/useGetCollections';
import useGetPathways from '../../hooks/useGetPathways';

import * as S from './styles';

const InProgressEducation = () => {
  const [inProgressResources, setInProgressResources] = useState<ResourceList | null>(null);
  /*
    TODO:
    Replace with a single API call to get all in-progress resources when it exists
    that can also be paginated
  */
  const { collections: inProgressCollections, isFetching: isFetchingCollections } =
    useGetCollections({
      page: 1,
      per_page: 100,
      status: ['in_progress']
    });
  const { isFetching: isFetchingPathways, pathways: inProgressPathways } = useGetPathways({
    page: 1,
    per_page: 100,
    'status[]': ['in_progress']
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isFetchingPathways || isFetchingCollections) return;
    const combinedInProgressResources = (
      [
        ...inProgressPathways.map(pathway => ({ ...pathway, resourceType: 'Pathway' })),
        ...inProgressCollections.map(collection => ({
          ...collection,
          resourceType: 'CourseCollection'
        }))
      ] as ResourceList
    ).sort((a, b) => {
      // Most recent startedAt to oldest
      const aLastProgressAt =
        'lastProgressAt' in a
          ? a.lastProgressAt
          : 'completion' in a && isNonEmptyString(a.completion?.lastProgressAt ?? '')
            ? (a.completion?.lastProgressAt ?? '')
            : '';
      const bLastProgressAt =
        'lastProgressAt' in b
          ? b.lastProgressAt
          : 'completion' in b && isNonEmptyString(b.completion?.lastProgressAt ?? '')
            ? (b.completion?.lastProgressAt ?? '')
            : '';

      const dateA = isNonEmptyString(aLastProgressAt)
        ? new Date(aLastProgressAt ?? '')
        : new Date();
      const dateB = isNonEmptyString(bLastProgressAt)
        ? new Date(bLastProgressAt ?? '')
        : new Date();

      return dateB.getTime() - dateA.getTime(); // Most recent first
    });
    setInProgressResources(combinedInProgressResources);
  }, [isFetchingCollections, isFetchingPathways, inProgressPathways, inProgressCollections]);

  useEffect(() => {
    if (inProgressResources === null) return;
    setIsLoading(false);
  }, [inProgressResources]);

  return (
    <S.FullWidthMain>
      <PageTitle
        title="In-progress"
        titleVariant="h1"
      />
      {isLoading || inProgressResources === null ? (
        <Spinner withWrapper />
      ) : inProgressResources.length > 0 ? (
        <S.InProgressResources data-cy="in-progress-resources">
          {inProgressResources.map(resource => {
            const { resourceType, ...resourceData } = resource;
            if (resourceType === 'Pathway') {
              return (
                <PathwayCard
                  key={resource.id}
                  pathway={resourceData as Pathway}
                />
              );
            } else if (resourceType === 'CourseCollection') {
              return (
                <CollectionCard
                  key={resource.id}
                  collection={resourceData as Collection}
                />
              );
            } else {
              return null;
              /*
                TODO: Include CourseCard when we have a filter to pull in-progress courses
                  Will also need to support viewing a course on this page
              */
              // return (<CourseCard key={resource.id} course={resourceData as EducationalResource} />)
            }
          })}
        </S.InProgressResources>
      ) : (
        <S.NoInProgressResources
          isBlock
          data-cy="no-in-progress-resources-alert"
          header="No education in-progress."
          id="no-in-progress-resources"
          isDismissable={false}
          type="info"
        >
          It seems you don't have any courses in progress. Explore our learning pathways or browse
          individual collections to find topics that interest you. Use filters like accrediting
          organization, community, and specialty to help guide your search.
        </S.NoInProgressResources>
      )}
    </S.FullWidthMain>
  );
};

export default InProgressEducation;
