import Button from '@/components/Buttons/Button';
import SearchField from '@/components/FormFields/SearchField';
import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

const popoverArrowLength = 14;
const searchFieldSidePadding = {
  desktop: {
    left: 17,
    right: 14
  },
  mobile: {
    left: 17,
    right: 21
  }
};

export const Filters = {
  CloseButton: styled(Button)`
    margin-top: 2.125rem;
  `,
  Container: styled.div`
    padding: 1.625rem 1.25rem 1.9375rem;
    overflow-y: auto;
    overscroll-behavior: contain;
  `,
  Popover: styled.div`
    right: 2.5rem;
    left: 2.5rem !important;
    background-color: #f9f9f9;
    border: 1px solid #edecec;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    border-radius: 0.625rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: ${searchFieldSidePadding.mobile.right + popoverArrowLength / 2}px;
      width: ${popoverArrowLength}px;
      height: ${popoverArrowLength}px;
      background-image: linear-gradient(to bottom right, #f9f9f9 50%, transparent 50%);
      transform: translate(${popoverArrowLength / 2}px, -${popoverArrowLength / 2}px) rotate(45deg);
      border-top: 1px solid #edecec;
      border-left: 1px solid #edecec;
      border-top-left-radius: 3px;
      z-index: 1;
    }
  `,
  Root: styled.div`
    color: var(--gray-800);

    ${DESKTOP_BREAKPOINT_RANGE} {
      padding: 1.5rem;
      border-radius: 1rem;
      background-color: #fff;
      border: 1px solid var(--gray-200);
    }
  `,
  SearchField: styled(SearchField)`
    margin-bottom: 1.5rem;
  `,
  Title: styled.div`
    ${TEXT_STYLES.headlineSectionLevel2Large}
    color: var(--gray-800);
    margin-bottom: 1.5rem;
  `,
  TriggerButton: styled(Button)`
    margin-bottom: 1.3125rem;
  `
};
