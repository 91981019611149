import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const RequirementsTitle = styled.div`
  color: var(--gray-800);
  ${TEXT_STYLES.bodyCopyRegular}
  font-weight: 500;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 140%; /* 1.225rem */
  letter-spacing: 0.0437rem;
  text-transform: uppercase;
`;

export const VioRequirementsCard = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  gap: 0.5rem;
`;
