import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import type { AriaDialogProps } from 'react-aria';
import { useDialog } from 'react-aria';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaDialogProps {
  className?: string;
  theme: 'dark' | 'light';
  title?: string;
}

const Dialog = ({
  children,
  className,
  theme,
  title,
  ...ariaDialogProps
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);

  const { dialogProps, titleProps } = useDialog(ariaDialogProps, ref);

  return (
    <S.Dialog
      {...dialogProps}
      ref={ref}
      $theme={theme}
      className={className}
    >
      {isNonEmptyString(title) && <h3 {...titleProps}>{title}</h3>}
      {children}
    </S.Dialog>
  );
};

export default Dialog;
