import { useState } from 'react';
import type { AriaListBoxSectionProps } from 'react-aria';
import { useListBoxSection } from 'react-aria';
import type { ListState, Node } from 'react-stately';

import Option from '../Option';

import * as S from './styles';

interface Props extends AriaListBoxSectionProps {
  section: Node<object>;
  state: ListState<object>;
}

const ListBoxSection = ({ section, state }: Props) => {
  const allSectionKeys = Array.from(section.childNodes).map(node => node.key);
  const [allChecked, setAllChecked] = useState(
    Array.from(state.selectionManager.selectedKeys).every(key => allSectionKeys.includes(key))
  );
  const { groupProps, headingProps, itemProps } = useListBoxSection({
    'aria-label': section['aria-label'],
    heading: section.rendered
  });

  const handleSectionPress = () => {
    const { selectionManager } = state;
    if (allChecked) {
      const filteredKeys = Array.from(selectionManager.selectedKeys).filter(
        key => !allSectionKeys.includes(key)
      );
      selectionManager.setSelectedKeys(filteredKeys);
      setAllChecked(false);
    } else {
      selectionManager.setSelectedKeys(allSectionKeys);
      setAllChecked(true);
    }
  };

  // If the section is not the first, add a separator element to provide visual separation.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <S.SectionListItem
          role="presentation"
          style={{
            borderTop: '1px solid gray',
            margin: '2px 5px'
          }}
        />
      )}
      <S.SectionListItem {...itemProps}>
        {section.rendered !== undefined && (
          <S.SectionHeading {...headingProps}>
            <S.ToggleAllInSectionButton onPress={handleSectionPress}>
              {section.rendered}
              {allChecked && <S.CheckedIcon />}
            </S.ToggleAllInSectionButton>
          </S.SectionHeading>
        )}
        <S.List
          {...groupProps}
          style={{
            listStyle: 'none',
            padding: 0
          }}
        >
          {Array.from(section.childNodes).map(node => (
            <Option
              key={node.key}
              item={node}
              state={state}
            />
          ))}
        </S.List>
      </S.SectionListItem>
    </>
  );
};

export default ListBoxSection;
