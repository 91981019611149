import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

interface BadgeProps {
  $color: 'gray' | 'green' | 'red' | 'yellow' | 'blue';
  $size: 'large' | 'small';
}

export const Badge = styled.div<BadgeProps>`
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  padding: 0.125rem 0.5rem;
  border-radius: 999rem;

  ${({ $color }) => {
    if ($color === 'gray') {
      return `
        color: var(--gray-800);
        background-color: var(--gray-100);
      `;
    } else if ($color === 'green') {
      return `
        color: var(--success-900);
        background-color: var(--success-100);
      `;
    } else if ($color === 'red') {
      return `
        color: var(--error-800);
        background-color: var(--error-100);
      `;
    } else if ($color === 'blue') {
      return `
        color: var(--blue-600);
        background-color: var(--blue-50);
      `;
    } else {
      return `
        color: var(--warning-800);
        background-color: var(--warning-100);
      `;
    }
  }}

  ${({ $size }) => {
    if ($size === 'large') {
      return `
        ${TEXT_STYLES.tagLarge}
      `;
    } else {
      return `
        ${TEXT_STYLES.tagSmall}
      `;
    }
  }}
`;

export const LeadingIcon = styled.div`
  width: 1rem;
  height: 1rem;
  fill: currentcolor;
`;
