import ScheduleIcon from '@material-design-icons/svg/round/schedule.svg?react';
import VerifiedIcon from '@material-design-icons/svg/round/verified.svg?react';
import type { Variants } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import BeginEvaluationModalDialog from '@/components/Education/BeginEvaluationModalDialog';
import { getHoursAndMinutes } from '@/utils/getHoursAndMinutes';
import isNonEmptyString from '@/utils/isNonEmptyString';

import useUser from '../../../hooks/useUser';
import type { EducationalResource as EducationalResourceInterface } from '../../../pages/Dashboard/utils';
import CourseDetailsModalDialog from '../CourseDetailsModalDialog';
import EducationCompletedModalDialog from '../EducationCompletedModalDialog';
import ResourceCardHeader from '../ResourceCardHeader';

import * as S from './styles';

interface Props {
  course: EducationalResourceInterface;
  refetchEducationalResources: (skipSettingIsFetching?: boolean) => void;
  variants?: Variants;
}

const CourseCard = ({ course, refetchEducationalResources, variants }: Props) => {
  const location = useLocation();
  const { user } = useUser();
  const [searchParams] = useSearchParams();

  const {
    close: closeCourseDetailsModalDialog,
    isOpen: courseDetailsModalDialogIsOpen,
    open: openCourseDetailsModalDialog
  } = useOverlayTriggerState({
    defaultOpen: window.location.pathname === `/dashboard/education/all-education/${course.id}`
  });

  const {
    close: closeEducationCompletedModalDialog,
    isOpen: educationCompletedModalDialogIsOpen,
    open: openEducationCompletedModalDialog
  } = useOverlayTriggerState({});

  const {
    close: closeBeginEvaluationModalDialog,
    isOpen: beginEvaluationModalDialogIsOpen,
    open: openBeginEvaluationModalDialog
  } = useOverlayTriggerState({});

  /*
    Non-scormcloud courses get this attribute from the response when they manually mark a course complete.
    Scormcloud courses we get this from a redirect titled isFirstCompletion that will set as true in the URL
  */
  const [isFirstCompletedEducation, setIsFirstCompletedEducation] = useState(
    searchParams.get('isFirstCompletion') !== null
      ? searchParams.get('isFirstCompletion') === 'true'
      : false
  );

  const { courseCollections } = course;
  const [accreditedCollectionId, setAccreditedCollectionId] = useState<string>();
  const [showEvaluationPrompt, setShowEvaluationPrompt] = useState<boolean>();
  const [previousUrl, setPreviousUrl] = useState<string>();

  const { hours, minutes } = getHoursAndMinutes(course.hours);

  useEffect(() => {
    setIsFirstCompletedEducation(searchParams.get('isFirstCompletion') === 'true');
  }, [searchParams]);

  useEffect(() => {
    const collectionNeedingEval = courseCollections.find(
      collection => collection.is_accredited && !collection.is_evaluated && collection.is_completed
    );
    setAccreditedCollectionId(collectionNeedingEval?.id);
  }, [accreditedCollectionId, courseCollections, user]);

  useEffect(() => {
    if (courseDetailsModalDialogIsOpen && isNonEmptyString(accreditedCollectionId)) {
      setShowEvaluationPrompt(true);
    } else {
      setShowEvaluationPrompt(false);
    }
  }, [accreditedCollectionId, courseDetailsModalDialogIsOpen, openBeginEvaluationModalDialog]);

  const handleCloseCourseDetailsModalDialog = () => {
    closeCourseDetailsModalDialog();

    if (window.location.pathname === `/dashboard/education/all-education/${course.id}`) {
      if (previousUrl === undefined) {
        // default = no previous history
        window.history.pushState(null, document.title, '/dashboard/education/all-education');
      } else {
        window.history.pushState(null, document.title, previousUrl);
      }
    }

    setPreviousUrl(undefined);
  };

  const handleViewMoreButtonPress = () => {
    openCourseDetailsModalDialog();

    if (window.location.pathname.includes('/dashboard/education/all-education')) {
      setPreviousUrl(`${location.pathname}${location.search}${location.hash}`);
      window.history.pushState(
        null,
        document.title,
        `/dashboard/education/all-education/${course.id}`
      );
    }
  };

  const handleOpenEvaluationModalDialog = (collectionId: string) => {
    setAccreditedCollectionId(collectionId);
    openBeginEvaluationModalDialog();
  };

  return (
    <>
      <S.CourseCard
        key={course.id}
        data-cy="course-card"
        variants={variants}
      >
        <S.CardLabel>Course</S.CardLabel>
        <S.CardContent>
          <ResourceCardHeader
            bookmarks={course.bookmarks}
            completedAt={course.completedAt}
            id={course.id}
            isNew={course.isNew}
            isPopular={course.isPopular}
            resourceType="Course"
          />
          <S.Title onPress={handleViewMoreButtonPress}>{course.title}</S.Title>
          <S.Byline>Published by {course.organization}</S.Byline>
          <S.Description>{course.description}</S.Description>
          <S.Tags data-cy="course-tags">
            {course.credits && (
              <S.TagItem>
                <VerifiedIcon
                  aria-hidden="true"
                  role="img"
                />
                {course.credits}
              </S.TagItem>
            )}
            {(hours > 0 || minutes > 0) && (
              <S.TagItem>
                <ScheduleIcon
                  aria-hidden="true"
                  role="img"
                />
                {hours > 0 && `${hours} hour${hours === 1 ? '' : 's'}`}
                {hours > 0 && minutes > 0 && ' '}
                {minutes > 0 && `${minutes} minute${minutes === 1 ? '' : 's'}`}
              </S.TagItem>
            )}
          </S.Tags>
          <S.ButtonWrapper>
            {course.completedAt !== null && course.completionStatus === 'complete' && (
              <S.Button
                data-cy="card-rate-course-button"
                size="regular"
                variant="primary"
                onPress={openEducationCompletedModalDialog}
              >
                Rate
              </S.Button>
            )}
            <S.Button
              data-cy="view-button"
              data-educational-resource-title={course.title}
              size="regular"
              variant={course.completedAt !== null ? 'outline' : 'primary'}
              onPress={handleViewMoreButtonPress}
            >
              View
            </S.Button>
          </S.ButtonWrapper>
        </S.CardContent>
      </S.CourseCard>
      {courseDetailsModalDialogIsOpen && (
        <CourseDetailsModalDialog
          close={handleCloseCourseDetailsModalDialog}
          educationalResource={course}
          openBeginEvaluationModalDialog={
            showEvaluationPrompt === true ? openBeginEvaluationModalDialog : undefined
          }
          openEducationCompletedModalDialog={(isFirstCompletedEducation = false) => {
            setIsFirstCompletedEducation(isFirstCompletedEducation);
            openEducationCompletedModalDialog();
          }}
          refetchEducationalResources={refetchEducationalResources}
        />
      )}
      {educationCompletedModalDialogIsOpen && (
        <EducationCompletedModalDialog
          close={closeEducationCompletedModalDialog}
          educationalResource={course}
          isFirstCompletedEducation={isFirstCompletedEducation}
          openBeginEvaluationModalDialog={handleOpenEvaluationModalDialog}
          refetchEducationalResources={refetchEducationalResources}
        />
      )}
      {isNonEmptyString(accreditedCollectionId) && beginEvaluationModalDialogIsOpen && (
        <BeginEvaluationModalDialog
          close={closeBeginEvaluationModalDialog}
          collectionId={accreditedCollectionId!}
        />
      )}
    </>
  );
};

export default CourseCard;
