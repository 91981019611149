import checkedIcon from '@material-design-icons/svg/round/check.svg?url';
// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import type { AriaCheckboxProps } from 'react-aria';
import { mergeProps, useCheckbox, useFocusRing, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';

import * as S from './styles';

import { generateStyledBgIcon } from '@/utils';
import isNonEmptyString from '@/utils/isNonEmptyString';

interface Props extends AriaCheckboxProps {
  className?: string;
  hasError?: boolean;
  helpText?: string;
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ className, hasError = false, helpText, ...ariaCheckboxProps }, forwardedRef) => {
    const { children, isRequired = false } = ariaCheckboxProps;
    const [checkedIconPath, setCheckedIconPath] = useState<string>('');

    const ref = useObjectRef(forwardedRef);

    const { focusProps, isFocusVisible } = useFocusRing();
    const state = useToggleState(ariaCheckboxProps);
    const { inputProps, isDisabled, isInvalid, isReadOnly, isSelected, validationErrors } =
      useCheckbox(ariaCheckboxProps, state, ref);

    useEffect(() => {
      if (checkedIconPath !== '') return;

      const setIcons = async () => {
        try {
          const path = await generateStyledBgIcon(
            checkedIcon,
            '#FFFFFF',
            'transform: scale(1) translate(0px, 0px); stroke-width: 0'
          );
          if (path) {
            setCheckedIconPath(path);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error setting checkbox icon:', error);
        }
      };
      setIcons();
    }, [checkedIconPath]);

    return (
      <>
        <S.Checkbox
          $checkedIcon={checkedIconPath}
          className={clsx(
            className,
            { 'is-invalid': hasError },
            { 'is-disabled': isDisabled },
            { 'is-focused': isFocusVisible },
            { 'is-selected': isSelected },
            { 'is-read-only': isReadOnly },
            { 'has-helper-text': isNonEmptyString(helpText) }
          )}
        >
          <VisuallyHidden>
            <input
              {...mergeProps(inputProps, focusProps)}
              ref={ref}
              required={isRequired}
            />
          </VisuallyHidden>
          <S.Input />
          {children !== undefined && (
            <S.Label>
              {children}
              {isRequired && '*'}
              {isNonEmptyString(helpText) && <S.Helper>{helpText}</S.Helper>}
            </S.Label>
          )}
        </S.Checkbox>
        {isInvalid && (
          <S.ErrorMessage data-cy="error-message">
            {validationErrors.length > 0
              ? validationErrors.join(' ')
              : 'Please check this box if you want to proceed.'}
          </S.ErrorMessage>
        )}
      </>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
