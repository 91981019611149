import Button from '@/components/Buttons/Button';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import styled from 'styled-components';

export const AddExperienceButton = styled(Button)`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin: var(--space-7) 1rem var(--space-7) 0;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin: var(--space-7) var(--space-6);
  }
`;
