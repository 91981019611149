import styled from 'styled-components';

import PlainLogotypeImage from './logotype-plain.svg?react';
import BaseLogotypeImage from './logotype.svg?react';

interface LogotypeProps {
  $color: string;
  $size: string;
}

export const Logotype = styled(BaseLogotypeImage)<LogotypeProps>`
  display: block;
  flex-shrink: 0;
  width: auto !important;
  ${({ $size }) =>
    $size === 'regular'
      ? 'height: var(--space-7) !important;'
      : 'height: var(--space-6) !important;'};

  path {
    ${({ $color }) => {
      if ($color === 'dark') {
        return `
          fill: var(--purple-400);
        `;
      } else if ($color === 'green') {
        return `
          fill: var(--green);
        `;
      } else {
        return `
          fill: var(--brand-tints-light);
        `;
      }
    }}
  }
`;

export const LogotypePlain = styled(PlainLogotypeImage)<LogotypeProps>`
  display: block;
  flex-shrink: 0;
  width: auto !important;
  ${({ $size }) =>
    $size === 'regular'
      ? 'height: var(--space-7) !important;'
      : 'height: var(--space-6) !important;'}

  path {
    ${({ $color }) => {
      if ($color === 'dark') {
        return `
          fill: var(--purple-400);
        `;
      } else if ($color === 'green') {
        return `
          fill: var(--green);
        `;
      } else {
        return `
          fill: var(--brand-tints-light);
        `;
      }
    }}
  }
`;
