import LeftArrow from '@material-design-icons/svg/round/chevron_left.svg?react';
import RightArrow from '@material-design-icons/svg/round/chevron_right.svg?react';
import styled from 'styled-components';

import {
  DESKTOP_BREAKPOINT_RANGE,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../../constants';
import UnstyledButton from '../../../UnstyledButton';

export const ArrowCircleLeftIcon = styled(LeftArrow)`
  width: 2rem;
  height: auto;
  fill: var(--purple-400);
`;

export const ArrowCircleRightIcon = styled(RightArrow)`
  width: 2rem;
  height: auto;
  fill: var(--purple-400);
`;

export const Button = styled(UnstyledButton)`
  &[disabled] {
    visibility: hidden;
  }

  & > svg {
    display: block;
    width: 1rem;
    height: 1rem;
  }

  &.is-pressed > svg {
    & > :nth-child(1) {
      fill: var(--purple-400);
    }

    & > :nth-child(2) {
      fill: var(--brand-tints-light);
    }
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--app-status-error);
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  width: 8.75rem;
  margin-right: auto;
  margin-left: auto;
`;

interface LabelProps {
  $type: 'copy-header' | 'form-header';
}

interface InputProps {
  $isNull: boolean;
}

export const Input = styled.input<InputProps>`
  all: unset;
  width: 100%;
  ${TEXT_STYLES.headlineStandardLevel1Regular}
  text-align: center;
  color: var(--purple-400);
  font-weight: 500;
  letter-spacing: 0.03rem;

  ${({ $isNull }) => $isNull && `color: var(--gray-200);`}
`;

export const Label = styled.label<LabelProps>`
  display: inline-block;
  color: var(--gray-500);
  margin: 0 0 1.25rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopyRegular}
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    ${TEXT_STYLES.bodyCopyMedium}
  }
`;

export const NumberField = styled.div``;
