import EndDateIcon from '@material-symbols/svg-400/rounded/event_available-fill.svg?react';
import TimeIcon from '@material-symbols/svg-400/rounded/schedule-fill.svg?react';
import ResourceTypeIcon from '@material-symbols/svg-400/rounded/school-fill.svg?react';
import StartDateIcon from '@material-symbols/svg-400/rounded/today-fill.svg?react';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

const ASSIGNMENT_ICON_STYLES = `
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  fill: currentcolor;
`;

export const AssignmentTimeIcon = styled(TimeIcon)`
  ${ASSIGNMENT_ICON_STYLES}
`;

export const AssignmentTypeIcon = styled(ResourceTypeIcon)`
  ${ASSIGNMENT_ICON_STYLES}
`;

export const EndIcon = styled(EndDateIcon)`
  ${ASSIGNMENT_ICON_STYLES}
`;

export const MetaData = styled.div`
  display: inline-flex;
  height: 3.5rem;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid var(--gray-200);
  background: var(--gray-25);
  margin-bottom: 1.5rem;
`;

export const MetaDataItem = styled.div`
  color: var(--gray-600);
  ${TEXT_STYLES.bodyCopyMedium}
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: 0.0088rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  &.start {
    padding-right: 1rem;
    border-right: 1px solid var(--gray-200);
  }

  &.end {
    padding-left: 1rem;
  }

  &.middle {
    padding-left: 1rem;
    padding-right: 1rem;
    border-right: 1px solid var(--gray-200);
  }
`;

export const StartIcon = styled(StartDateIcon)`
  ${ASSIGNMENT_ICON_STYLES}
`;
