import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';
import Spinner from '@/components/Spinner';

const EducationalResource = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const hasCalledApi = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams<{ educationalResourceId: string }>();
  const { educationalResourceId } = params;
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirect_uri');

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken } = useUser();

  useEffect(() => {
    // Check if we've already made the call
    if (hasCalledApi.current) return;

    // Check if all dependencies are ready
    const depsReady = Boolean(educationalResourceId && bearerToken && redirectUri);

    if (!depsReady) {
      setIsLoading(true);
      return;
    }

    // All dependencies are ready, proceed with API call
    hasCalledApi.current = true;
    setIsLoading(false);

    (async () => {
      const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard/courses/${educationalResourceId}`;

      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const {
          data: { link }
        } = (await response.json()) as APIUsersDashboardCoursesCourseId;

        const prepend = link.includes('?') ? '&' : '?';
        const fullLink = `${link}${prepend}redirect_uri=${redirectUri}`;
        window.location.replace(fullLink);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    })();
  }, [
    bearerToken,
    educationalResourceId,
    openErrorModalDialog,
    openSignedOutModalDialog,
    redirectUri
  ]);

  if (isLoading) {
    return <Spinner withWrapper />;
  }

  return null;
};

export default EducationalResource;
