import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  ${MOBILE_BREAKPOINT_RANGE} {
    display: grid;
    row-gap: 1.125rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    display: grid;
    justify-content: end;
    margin-top: 3rem;
  }
`;
