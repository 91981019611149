import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  className?: string;
  copy?: string;
  title: string;
}

const SectionHero = ({ className, copy, title }: Props) => (
  <S.SectionHero className={className}>
    {title && <S.Title>{title}</S.Title>}
    {isNonEmptyString(copy) && <S.Copy>{copy}</S.Copy>}
  </S.SectionHero>
);
export default SectionHero;
