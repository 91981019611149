import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import styled from 'styled-components';

import { SECONDARY_NAVIGATION_HEIGHT } from '../../../constants';
import { EDUCATION_MAIN_TOP_PADDING } from '../../constants';

const sidebarComputedHeight = 932;

export const LoadingState = styled.div`
  display: grid;
  place-content: center;

  ${MOBILE_BREAKPOINT_RANGE} {
    height: 200px;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    height: calc(100vh - ${SECONDARY_NAVIGATION_HEIGHT.desktop}px);
    max-height: calc(${sidebarComputedHeight}px + ${EDUCATION_MAIN_TOP_PADDING.desktop}rem);
    margin-top: -${EDUCATION_MAIN_TOP_PADDING.desktop}rem;
    margin-bottom: calc(-1 * var(--space-16));
  }
`;
