import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useNumberFormatter } from 'react-aria';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';
import ViewDetailsButton from '@/components/Buttons/ViewDetailsButton';
import DataCardHeader from '@/components/Reporting/DataCardHeader';
import Spinner from '@/components/Spinner';
import TableComparisonCell from '@/components/Table/TableComparisonCell';
import TableLinkedCell from '@/components/Table/TableLinkedCell';
import TableNumberCell from '@/components/Table/TableNumberCell';
import type { ServiceAreaComparisonResponse } from '@/pages/Dashboard/hooks/useGetNetworkServiceAreaComparisons';
import type { NetworkProvidersLocation } from '@/pages/Dashboard/utils';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  isFetchingLocation: boolean;
  isFetchingServiceAreaComparisons: boolean;
  locationComparisons: Record<string, ServiceAreaComparisonResponse>;
  networkLocations?: NetworkProvidersLocation[];
}

const MiniServiceAreasTable = ({
  isFetchingLocation,
  isFetchingServiceAreaComparisons,
  locationComparisons,
  networkLocations
}: Props) => {
  const isComparativeDataEnabled = useFeatureFlagEnabled('comparative_network_data');
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  return (
    <S.SingleTable>
      <DataCardHeader
        description={
          isComparativeDataEnabled === true
            ? 'View the percentage of inclusive providers in specific service areas. The evaluation is based on a comparison of in-network providers to all providers in that service area. Hover over a cell for more details on this comparison.'
            : 'This table displays the density of inclusive providers by geographic service area. Click on a service area to view more details.'
        }
        title="Service areas"
      />
      {isFetchingLocation ? (
        <Spinner withWrapper />
      ) : networkLocations && networkLocations.length > 0 ? (
        <>
          <S.OverviewLocationTable
            hasLinkedRows
            aria-label="Table of service area locations"
            data-cy="network-service-area-table"
          >
            <TableHeader>
              <Column key="state">County</Column>
              <Column key="provider_count">
                <TableNumberCell>Provider count</TableNumberCell>
              </Column>
              <Column key="bipoc_inclusivity">
                {isComparativeDataEnabled === true ? (
                  <>BIPOC inclusivity</>
                ) : (
                  <TableNumberCell alignRight={false}>BIPOC inclusivity</TableNumberCell>
                )}
              </Column>
              <Column key="lgbq_inclusivity">
                {isComparativeDataEnabled === true ? (
                  <>LGBQ inclusivity</>
                ) : (
                  <TableNumberCell alignRight={false}>LGBQ inclusivity</TableNumberCell>
                )}
              </Column>
              <Column key="tgnc_inclusivity">
                {isComparativeDataEnabled === true ? (
                  <>TGNC inclusivity</>
                ) : (
                  <TableNumberCell alignRight={false}>TGNC inclusivity</TableNumberCell>
                )}
              </Column>
            </TableHeader>
            <TableBody>
              {networkLocations.map(location => {
                const comparison = locationComparisons[`${location.state}-${location.name}`] as
                  | ServiceAreaComparisonResponse
                  | undefined;
                const rowServiceAreaComparisons = comparison === undefined ? [] : comparison.data;
                const rowServiceAreaComparisonsMeta = comparison?.meta;

                return (
                  <Row
                    key={`${location.state}-${location.name}`}
                    data-cy="network-service-area-row"
                  >
                    <Cell>
                      <TableLinkedCell
                        data-cy="service-area-state-filter-link"
                        to={`/dashboard/my-network/inclusivity/service-areas?locations%5B%5D=County%7C${location.name}%7C${location.state}&order_by%5Bcolumn%5D=provider_count&order_by%5Bdir%5D=desc&page=1&view=county`}
                      >
                        {isNonEmptyString(location.name) ? location.name : 'Unknown'}
                        {` (${location.state})`}
                      </TableLinkedCell>
                    </Cell>
                    <Cell>
                      <TableNumberCell data-cy="service-area-total-providers">
                        {commaNumFormatter.format(location.totalProviders)}
                      </TableNumberCell>
                    </Cell>
                    <Cell>
                      {isComparativeDataEnabled === true ? (
                        <TableComparisonCell
                          community="bipoc"
                          data-cy="bipoc-inclusivity-comparison"
                          isFetching={
                            isFetchingServiceAreaComparisons ||
                            rowServiceAreaComparisonsMeta === undefined
                          }
                          serviceAreaComparisons={rowServiceAreaComparisons}
                          serviceAreaComparisonsMeta={rowServiceAreaComparisonsMeta}
                          totalNetworkInclusivePercentage={location.inclusivity.bipoc.percentage}
                          totalNetworkInclusiveProviders={location.inclusivity.bipoc.total}
                        />
                      ) : (
                        <TableNumberCell
                          alignRight={false}
                          data-cy="bipoc-inclusivity-percentage"
                        >
                          {`${location.inclusivity.bipoc.percentage}%`}
                        </TableNumberCell>
                      )}
                    </Cell>
                    <Cell>
                      {isComparativeDataEnabled === true ? (
                        <TableComparisonCell
                          community="lgbq"
                          data-cy="lgbq-inclusivity-comparison"
                          isFetching={
                            isFetchingServiceAreaComparisons ||
                            rowServiceAreaComparisonsMeta === undefined
                          }
                          serviceAreaComparisons={rowServiceAreaComparisons}
                          serviceAreaComparisonsMeta={rowServiceAreaComparisonsMeta}
                          totalNetworkInclusivePercentage={location.inclusivity.lgbq.percentage}
                          totalNetworkInclusiveProviders={location.inclusivity.lgbq.total}
                        />
                      ) : (
                        <TableNumberCell
                          alignRight={false}
                          data-cy="lgbq-inclusivity-percentage"
                        >
                          {`${location.inclusivity.lgbq.percentage}%`}
                        </TableNumberCell>
                      )}
                    </Cell>
                    <Cell>
                      {isComparativeDataEnabled === true ? (
                        <TableComparisonCell
                          community="tgnc"
                          data-cy="tgnc-inclusivity-comparison"
                          isFetching={
                            isFetchingServiceAreaComparisons ||
                            rowServiceAreaComparisonsMeta === undefined
                          }
                          serviceAreaComparisons={rowServiceAreaComparisons}
                          serviceAreaComparisonsMeta={rowServiceAreaComparisonsMeta}
                          totalNetworkInclusivePercentage={location.inclusivity.tgnc.percentage}
                          totalNetworkInclusiveProviders={location.inclusivity.tgnc.total}
                        />
                      ) : (
                        <TableNumberCell
                          alignRight={false}
                          data-cy="tgnc-inclusivity-percentage"
                        >
                          {`${location.inclusivity.tgnc.percentage}%`}
                        </TableNumberCell>
                      )}
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </S.OverviewLocationTable>
          <ViewDetailsButton
            data-cy="service-area-view-details-button"
            linkToPage={`/dashboard/my-network/inclusivity/service-areas?order_by%5Bcolumn%5D=provider_count&order_by%5Bdir%5D=desc&page=1&view=county`}
            overrideText="View all service areas"
          />
        </>
      ) : (
        <S.EmptyState>No service areas found.</S.EmptyState>
      )}
    </S.SingleTable>
  );
};

export default MiniServiceAreasTable;
