import CheckIcon from '@material-design-icons/svg/round/check.svg?react';
import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: inline-flex;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
`;

export const CheckedIcon = styled(CheckIcon)`
  fill: var(--white-light);
  position: absolute;
  left: 10%;
  top: 10%;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
`;

export const Input = styled.input`
  appearance: none;
  flex-shrink: 0;
  border: 2px solid var(--gray-300);
  border-radius: 0.25rem;
  background-color: var(--white-light);
  width: 1.25rem;
  height: 1.25rem;

  &.is-checked {
    border: 2px solid var(--purple-400);
    background-color: var(--purple-400);
  }

  &.is-focused,
  &:focus-visible {
    outline-color: var(--bright-purple-400);
    outline-offset: 0.25rem;
    outline-width: 0.125rem;
  }
`;
