import type { SeparatorProps } from 'react-aria';
import { useSeparator } from 'react-aria';

interface Props extends SeparatorProps {
  className?: string;
}

const Separator = ({ className, ...separatorProps }: Props) => {
  const { separatorProps: htmlSeparatorProps } = useSeparator(separatorProps);

  return (
    <div
      className={className}
      {...htmlSeparatorProps}
    />
  );
};

export default Separator;
