import { forwardRef } from 'react';
import type { AriaNumberFieldProps } from 'react-aria';

import * as S from './styles';

interface Props extends AriaNumberFieldProps {
  className?: string;
  hideRequiredIndicator?: boolean;
}

const TableNumberField = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <S.TableNumberField
    {...props}
    ref={ref}
  />
));

TableNumberField.displayName = 'TableNumberField';

export default TableNumberField;
