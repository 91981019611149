import type { PlacementAxis } from 'react-aria';
import styled from 'styled-components';

const arrowLength = '0.625rem';

interface ArrowProps {
  $placement?: PlacementAxis;
}

export const Arrow = styled.span<ArrowProps>`
  position: absolute;
  width: ${arrowLength};
  height: ${arrowLength};
  background-color: inherit;
  border-radius: 0.0625rem;
  rotate: 45deg;

  ${({ $placement = 'top' }) => {
    if ($placement === 'bottom') {
      return `
        top: -0.25rem;
        translate: calc(${arrowLength} / 2 * -1);
      `;
    } else if ($placement === 'left') {
      return `
        right: -0.25rem;
        translate: 0% calc(${arrowLength} / 2 * -1);
      `;
    } else if ($placement === 'right') {
      return `
        left: -0.25rem;
        translate: 0% calc(${arrowLength} / 2 * -1);
      `;
    } else if ($placement === 'top') {
      return `
        bottom: -0.25rem;
        translate: calc(${arrowLength} / 2 * -1);
      `;
    }
  }}
`;

interface TooltipProps {
  $placement: PlacementAxis;
  $theme: 'dark' | 'light';
}

export const Tooltip = styled.span<TooltipProps>`
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.5),
    0 4px 6px -2px rgba(16, 24, 40, 0.2);

  ${({ $theme }) => {
    if ($theme === 'dark') {
      return `
        background-color: var(--gray-900);
      `;
    } else {
      return `
        background-color: white;
      `;
    }
  }}

  ${({ $placement }) => {
    let arrowStyles = ``;
    if ($placement === 'top') {
      arrowStyles = `
          border-bottom: 1px solid var(--gray-200);
          border-right: 1px solid var(--gray-200);
        `;
    } else if ($placement === 'right') {
      arrowStyles = `
          border-bottom: 1px solid var(--gray-200);
          border-left: 1px solid var(--gray-200);
        `;
    } else if ($placement === 'bottom') {
      arrowStyles = `
          border-top: 1px solid var(--gray-200);
          border-left: 1px solid var(--gray-200);
        `;
    } else if ($placement === 'left') {
      arrowStyles = `
          border-top: 1px solid var(--gray-200);
          border-right: 1px solid var(--gray-200);
        `;
    }
    return `
        border: 1px solid var(--gray-200);
        border-radius: 1rem;

        .arrow {
          ${arrowStyles}
        }
    `;
  }}
`;

interface TooltipContentProps {
  $maxHeight: number;
}

export const TooltipContent = styled.div<TooltipContentProps>`
  overflow-y: auto;
  ${({ $maxHeight = 100 }) => `max-height: ${$maxHeight - 20}px`};
`;
