// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import clsx from 'clsx';
import type { MutableRefObject } from 'react';
import { forwardRef } from 'react';
import type { AriaButtonProps, TooltipTriggerAria } from 'react-aria';
import { mergeProps, useButton, useFocusRing, useHover } from 'react-aria';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import isNonEmptyString from '@/utils/isNonEmptyString';
import type { CSSProperties } from 'styled-components';

import * as S from './styles';

interface Props extends AriaButtonProps {
  className?: string;
  style?: CSSProperties;
  to?: LinkProps['to'];
  tooltipTriggerProps?: TooltipTriggerAria['triggerProps'];
}

const UnstyledButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
  ({ className, style, to, tooltipTriggerProps, ...ariaButtonProps }, forwardedRef) => {
    const { children, href, isDisabled = false, target } = ariaButtonProps;

    const ref = useObjectRef(forwardedRef);

    const { buttonProps, isPressed } = useButton(ariaButtonProps, ref);

    const { hoverProps, isHovered } = useHover({ isDisabled });
    const { focusProps, isFocusVisible } = useFocusRing();

    const sharedProps = {
      ...mergeProps(buttonProps, focusProps, hoverProps, tooltipTriggerProps ?? {}),
      children,
      className: clsx(
        className,
        { 'is-focused': isFocusVisible },
        { 'is-hovered': isHovered },
        { 'is-pressed': isPressed },
        { 'is-disabled': isDisabled }
      ),
      style
    };

    if (isNonEmptyString(href)) {
      return (
        <S.UnstyledButton
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          as="a"
          href={href}
          target={target}
        />
      );
    } else if (to !== undefined) {
      return (
        <S.UnstyledButton
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          as={Link}
          to={to}
        />
      );
    } else {
      return (
        <S.UnstyledButton
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLButtonElement>}
        />
      );
    }
  }
);

UnstyledButton.displayName = 'UnstyledButton';

export default UnstyledButton;
