import Button from '@/components/Buttons/Button';
import ExpandableEditableTable from '@/components/ExpandableEditableTable';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import styled from 'styled-components';

export const AddEducationButton = styled(Button)`
  margin-right: 1rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-top: 1rem;
    margin-bottom: var(--space-7);
    margin-left: var(--space-2);
  }
`;

export const CenterText = styled.div`
  text-align: center;
`;

export const ClinicalActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;

  ${MOBILE_BREAKPOINT_RANGE} {
    align-items: center;
  }
`;

export const EditableNonClinicalExperienceTable = styled(ExpandableEditableTable)`
  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: 1rem;

    div[role='row'] {
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        'expand-collapse expand-collapse delete delete'
        'type type type type'
        'role role role role'
        'organization organization organization organization'
        'current-role current-role dates dates'
        'nested-table nested-table nested-table nested-table';
    }
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    tr:not(.empty-row) {
      grid-template-columns:
        1.75rem minmax(10rem, 0.75fr) minmax(0, 1fr) minmax(0, 1fr) 7.5rem 11rem
        5rem 0fr;
    }
  }

  tr.empty-row {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

export const Spacer = styled.div`
  height: var(--space-8);
`;
