import type { Key } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { AriaCheckboxGroupProps, AriaCheckboxProps, AriaSearchFieldProps } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import type { Selection } from 'react-stately';
import { Item } from 'react-stately';
import CollectionCard from '@/components/Education/CollectionCard';
import EducationFilters from '@/components/Education/EducationFilters';
import GroupCheckbox from '@/components/FormFields/CheckboxGroup/GroupCheckbox';
import MultiSelect from '@/components/FormFields/MultiSelect';
import PageTitle from '@/components/PageTitle';
import PopoverTrigger from '@/components/PopoverTrigger';
import Spinner from '@/components/Spinner';
import ObservedTarget from '@/pages/components/ObservedTarget';
import { COMMUNITIES, PROFESSIONAL_ORGS } from '@/pages/constants';
import { capitalize } from '@/utils/stringTransformations';

import CallToActionLink from '../../../../components/CallToActionLink';
import useBreakpointRange from '../../../../hooks/useBreakpointRange';
import useFilterAnalytics from '../../hooks/useFilterAnalytics';
import useGetCollections, {
  INITIAL_NUMBER_OF_LISTED_COLLECTIONS,
  MAX_NUMBER_OF_COLLECTIONS_TO_FETCH_NEXT
} from '../../hooks/useGetCollections';
import type { Collection } from '../../utils';
import EmptyState from '../components/EmptyState';
import LoadingState from '../components/LoadingState';
import Main from '../components/Main';

import * as S from './styles';
import useGetFaqLinks from '@/utils/getFaqLinks';

const LEVELS = ['Foundation', 'Beginner', 'Intermediate', 'Advanced'];
const SPECIALTIES = ['mental_health', 'physical_health'];
const PROGRESS_STATUSES = ['not_started', 'in_progress', 'completed'];

const Collections = () => {
  const { handleFilterAddedAnalytics } = useFilterAnalytics();
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();
  const [searchParams, setSearchParams] = useSearchParams();
  const FaqLinks = useGetFaqLinks();

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  /*
    searchText  manages a controlled state for the search field
                this allows us to set the value of the search field
                on back/forward navigation
    searchValue clones the value of searchText, but debounced
                to prevent duplicate requests and URL filter updates
  */
  const [searchText, setSearchText] = useState(
    searchParams.get('search') !== null ? searchParams.get('search') : ''
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') !== null ? searchParams.get('search') : ''
  );
  const [selectedCommunities, setSelectedCommunities] = useState<string[]>(
    searchParams.get('community') !== null ? searchParams.get('community')!.split(',') : []
  );
  const [selectedLevels, setSelectedLevels] = useState<string[]>(
    searchParams.get('level') !== null ? searchParams.get('level')!.split(',') : []
  );
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    searchParams.get('status') !== null ? searchParams.get('status')!.split(',') : []
  );
  const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>(
    searchParams.get('specialties') !== null ? searchParams.get('specialties')!.split(',') : []
  );

  const [selectedAccreditingOrgs, setSelectedAccreditingOrgs] = useState<Set<Key>>(
    new Set(
      searchParams.get('accrediting_org') !== null
        ? searchParams.get('accrediting_org')!.split(',')
        : []
    )
  );

  const [viewBookmarksIsSelected, setViewBookmarksIsSelected] = useState(
    searchParams.get('bookmarks') !== null ? Boolean(searchParams.get('bookmarks')) : false
  );
  const [viewAssignedCollectionsIsSelected, setViewAssignedCollectionsIsSelected] = useState(
    searchParams.get('assigned') !== null ? Boolean(searchParams.get('assigned')) : false
  );
  const [viewNewCollectionsIsSelected, setViewNewCollectionsIsSelected] = useState(
    searchParams.get('is_new') !== null ? Boolean(searchParams.get('is_new')) : false
  );
  const [viewPopularCollectionsIsSelected, setViewPopularCollectionsIsSelected] = useState(
    searchParams.get('is_trending') !== null ? Boolean(searchParams.get('is_trending')) : false
  );

  const isFirstRender = useRef(true);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [collectionsList, setCollectionsList] = useState<Collection[]>([]);
  const numberOfListedCollectionsRef = useRef(INITIAL_NUMBER_OF_LISTED_COLLECTIONS);

  const {
    collections,
    hasFetchedAll,
    isFetching,
    isFetchingMore,
    refetchCollections,
    updateCollectionsFilters
  } = useGetCollections({
    accrediting_organizations:
      Array.from(selectedAccreditingOrgs).length > 0
        ? (Array.from(selectedAccreditingOrgs) as string[])
        : undefined,
    assigned: viewAssignedCollectionsIsSelected ? viewAssignedCollectionsIsSelected : undefined,
    bookmarked: viewBookmarksIsSelected ? viewBookmarksIsSelected : undefined,
    communities: selectedCommunities.length > 0 ? selectedCommunities : undefined,
    is_new: viewNewCollectionsIsSelected,
    is_popular: viewPopularCollectionsIsSelected,
    level: selectedLevels.length > 0 ? selectedLevels : undefined,
    searchValue: searchValue === '' || searchValue === null ? undefined : searchValue,
    specialties: selectedSpecialties.length > 0 ? selectedSpecialties : undefined,
    status: selectedStatuses.length > 0 ? selectedStatuses : undefined
  });

  const refetch = useCallback(
    (skipSettingIsFetching: boolean) => {
      refetchCollections(
        {
          accrediting_organizations:
            Array.from(selectedAccreditingOrgs).length > 0
              ? (Array.from(selectedAccreditingOrgs) as string[])
              : undefined,
          assigned: viewAssignedCollectionsIsSelected
            ? viewAssignedCollectionsIsSelected
            : undefined,
          bookmarked: viewBookmarksIsSelected ? viewBookmarksIsSelected : undefined,
          communities: selectedCommunities.length > 0 ? selectedCommunities : undefined,
          is_new: viewNewCollectionsIsSelected,
          is_popular: viewPopularCollectionsIsSelected,
          level: selectedLevels.length > 0 ? selectedLevels : undefined,
          searchValue: searchValue === '' || searchValue === null ? undefined : searchValue,
          specialties: selectedSpecialties.length > 0 ? selectedSpecialties : undefined,
          status: selectedStatuses.length > 0 ? selectedStatuses : undefined
        },
        skipSettingIsFetching,
        numberOfListedCollectionsRef.current
      );
    },
    [
      refetchCollections,
      searchValue,
      selectedAccreditingOrgs,
      selectedCommunities,
      selectedLevels,
      selectedSpecialties,
      selectedStatuses,
      viewAssignedCollectionsIsSelected,
      viewBookmarksIsSelected,
      viewNewCollectionsIsSelected,
      viewPopularCollectionsIsSelected
    ]
  );

  const handleAssignedCollectionsChange: AriaCheckboxProps['onChange'] = isSelected => {
    if (isSelected) {
      handleFilterAddedAnalytics('Collections', 'Saved education', 'Assigned collections');
    }
    setViewAssignedCollectionsIsSelected(isSelected);
    setSearchParams(searchParams => {
      if (!isSelected) {
        searchParams.delete('assigned');
      } else {
        searchParams.set('assigned', isSelected.toString());
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleBookmarksChange: AriaCheckboxProps['onChange'] = isSelected => {
    if (isSelected) {
      handleFilterAddedAnalytics('Collections', 'Saved education', 'Bookmarks');
    }
    setViewBookmarksIsSelected(isSelected);
    setSearchParams(searchParams => {
      if (!isSelected) {
        searchParams.delete('bookmarks');
      } else {
        searchParams.set('bookmarks', isSelected.toString());
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleNewCollectionsChange: AriaCheckboxProps['onChange'] = isSelected => {
    if (isSelected) {
      handleFilterAddedAnalytics('Collections', 'Recommendations', 'New');
    }
    setViewNewCollectionsIsSelected(isSelected);
    setSearchParams(searchParams => {
      if (!isSelected) {
        searchParams.delete('is_new');
      } else {
        searchParams.set('is_new', isSelected.toString());
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handlePopularCollectionsChange: AriaCheckboxProps['onChange'] = isSelected => {
    if (isSelected) {
      handleFilterAddedAnalytics('Collections', 'Recommendations', 'Trending');
    }
    setViewPopularCollectionsIsSelected(isSelected);
    setSearchParams(searchParams => {
      if (!isSelected) {
        searchParams.delete('is_trending');
      } else {
        searchParams.set('is_trending', isSelected.toString());
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleAccreditingOrganizationsChange = (keys: Selection) => {
    const previousSelectedAccreditingOrgs = Array.from(selectedAccreditingOrgs);
    const newSelectedAccreditingOrgs = Array.from(keys);
    const isAdded = newSelectedAccreditingOrgs.length > previousSelectedAccreditingOrgs.length;
    if (isAdded) {
      handleFilterAddedAnalytics(
        'Collections',
        'Accrediting organizations',
        newSelectedAccreditingOrgs
          .filter(org => !previousSelectedAccreditingOrgs.includes(org))
          .map(key => key.toString())
      );
    }

    setSelectedAccreditingOrgs(new Set(keys));
    setSearchParams(searchParams => {
      if (newSelectedAccreditingOrgs.length === 0) {
        searchParams.delete('accrediting_org');
      } else {
        searchParams.set('accrediting_org', newSelectedAccreditingOrgs.join(','));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleSelectedCommunitiesChange: AriaCheckboxGroupProps['onChange'] = value => {
    const isAdded = value.length > selectedCommunities.length;
    if (isAdded) {
      handleFilterAddedAnalytics(
        'Collections',
        'Community',
        value.filter(community => !selectedCommunities.includes(community))
      );
    }

    setSelectedCommunities([
      ...(value.includes(COMMUNITIES[0]) ? [COMMUNITIES[0]] : []),
      ...(value.includes(COMMUNITIES[1]) ? [COMMUNITIES[1]] : []),
      ...(value.includes(COMMUNITIES[2]) ? [COMMUNITIES[2]] : [])
    ]);
    setSearchParams(searchParams => {
      if (value.length === 0) {
        searchParams.delete('community');
      } else {
        searchParams.set('community', value.join(','));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleSelectedLevelsChange: AriaCheckboxGroupProps['onChange'] = value => {
    const isAdded = value.length > selectedLevels.length;
    if (isAdded) {
      handleFilterAddedAnalytics(
        'Collections',
        'Level',
        value.filter(level => !selectedLevels.includes(level)).map(level => capitalize(level))
      );
    }
    setSelectedLevels(value);
    setSearchParams(searchParams => {
      if (value.length === 0) {
        searchParams.delete('level');
      } else {
        searchParams.set('level', value.join(','));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleSelectedStatusesChange: AriaCheckboxGroupProps['onChange'] = value => {
    const isAdded = value.length > selectedStatuses.length;
    if (isAdded) {
      handleFilterAddedAnalytics(
        'Collections',
        'Status',
        value.filter(status => !selectedStatuses.includes(status)).map(status => capitalize(status))
      );
    }
    setSelectedStatuses(value);
    setSearchParams(searchParams => {
      if (value.length === 0) {
        searchParams.delete('status');
      } else {
        searchParams.set('status', value.join(','));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleSelectedSpecialtiesChange: AriaCheckboxGroupProps['onChange'] = value => {
    const isAdded = value.length > selectedSpecialties.length;
    if (isAdded) {
      handleFilterAddedAnalytics(
        'Collections',
        'Specialty',
        value
          .filter(specialty => !selectedSpecialties.includes(specialty))
          .map(specialty => specialty.replace('_', ' '))
      );
    }
    setSelectedSpecialties(value);
    setSearchParams(searchParams => {
      if (value.length === 0) {
        searchParams.delete('specialties');
      } else {
        searchParams.set('specialties', value.join(','));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleSearchValueChange: AriaSearchFieldProps['onChange'] = value => {
    setSearchText(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchValue(value);
      setSearchParams(searchParams => {
        if (value === '') {
          searchParams.delete('search');
        } else {
          searchParams.set('search', value);
        }
        searchParams.sort();
        return searchParams;
      });
    }, 500);
  };

  useEffect(() => {
    setSearchText(searchParams.get('search') ?? '');
    setSearchValue(searchParams.get('search') ?? '');
    setSelectedCommunities(
      searchParams.get('community') !== null ? searchParams.get('community')!.split(',') : []
    );
    setSelectedLevels(
      searchParams.get('level') !== null ? searchParams.get('level')!.split(',') : []
    );
    setSelectedStatuses(
      searchParams.get('status') !== null ? searchParams.get('status')!.split(',') : []
    );
    setSelectedSpecialties(
      searchParams.get('specialties') !== null ? searchParams.get('specialties')!.split(',') : []
    );
    setSelectedAccreditingOrgs(
      new Set(
        searchParams.get('accrediting_org') !== null
          ? searchParams.get('accrediting_org')!.split(',')
          : []
      )
    );
    setViewBookmarksIsSelected(
      searchParams.get('bookmarks') !== null ? Boolean(searchParams.get('bookmarks')) : false
    );
    setViewAssignedCollectionsIsSelected(
      searchParams.get('assigned') !== null ? Boolean(searchParams.get('assigned')) : false
    );
    setViewNewCollectionsIsSelected(
      searchParams.get('is_new') !== null ? Boolean(searchParams.get('is_new')) : false
    );
    setViewPopularCollectionsIsSelected(
      searchParams.get('is_trending') !== null ? Boolean(searchParams.get('is_trending')) : false
    );
  }, [searchParams]);

  useEffect(() => {
    updateCollectionsFilters({
      accrediting_organizations: Array.from(selectedAccreditingOrgs) as string[],
      assigned: viewAssignedCollectionsIsSelected ? viewAssignedCollectionsIsSelected : undefined,
      bookmarked: viewBookmarksIsSelected ? viewBookmarksIsSelected : undefined,
      communities: selectedCommunities,
      is_new: viewNewCollectionsIsSelected,
      is_popular: viewPopularCollectionsIsSelected,
      level: selectedLevels.length > 0 ? selectedLevels : undefined,
      searchValue: searchValue === '' || searchValue === null ? undefined : searchValue,
      specialties: selectedSpecialties.length > 0 ? selectedSpecialties : undefined,
      status: selectedStatuses.length > 0 ? selectedStatuses : undefined
    });
  }, [
    viewAssignedCollectionsIsSelected,
    viewBookmarksIsSelected,
    selectedAccreditingOrgs,
    selectedCommunities,
    selectedLevels,
    selectedStatuses,
    selectedSpecialties,
    searchValue,
    viewNewCollectionsIsSelected,
    viewPopularCollectionsIsSelected,
    updateCollectionsFilters
  ]);

  useEffect(() => {
    if (isFirstRender.current) return;

    (() => {
      setIsRefetching(true);

      numberOfListedCollectionsRef.current = INITIAL_NUMBER_OF_LISTED_COLLECTIONS;

      refetch(false);
    })();
  }, [refetch]);

  useEffect(() => {
    numberOfListedCollectionsRef.current = INITIAL_NUMBER_OF_LISTED_COLLECTIONS;
  }, []);

  useEffect(() => {
    if (isRefetching) {
      setCollectionsList(prev => [...prev, ...collections]);
      setIsRefetching(false);
    } else {
      setCollectionsList(collections);
    }
  }, [collections, isRefetching]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const fetchMoreCollections = !hasFetchedAll && !isFetchingMore && isIntersecting;

  useEffect(() => {
    (() => {
      if (fetchMoreCollections) {
        numberOfListedCollectionsRef.current += MAX_NUMBER_OF_COLLECTIONS_TO_FETCH_NEXT;

        refetch(true);
      }
    })();
  }, [fetchMoreCollections, refetch]);

  useEffect(() => {
    if (!isRefetching) return;

    window.scrollTo({ top: 0 });
  }, [isRefetching]);

  const accreditingOrganizations = PROFESSIONAL_ORGS;

  const filters = (
    <>
      <S.Filters.SectionLabel>For you</S.Filters.SectionLabel>
      <S.Filters.SingleCheckbox
        data-cy="assigned-education-checkbox"
        isSelected={viewAssignedCollectionsIsSelected}
        onChange={handleAssignedCollectionsChange}
      >
        Assigned
      </S.Filters.SingleCheckbox>
      <S.Filters.SingleCheckbox
        data-cy="popular-education-checkbox"
        isSelected={viewPopularCollectionsIsSelected}
        onChange={handlePopularCollectionsChange}
      >
        Trending
      </S.Filters.SingleCheckbox>
      <S.Filters.SingleCheckbox
        data-cy="new-education-checkbox"
        isSelected={viewNewCollectionsIsSelected}
        onChange={handleNewCollectionsChange}
      >
        New
      </S.Filters.SingleCheckbox>
      <S.Filters.SingleCheckbox
        data-cy="bookmarks-checkbox"
        isSelected={viewBookmarksIsSelected}
        onChange={handleBookmarksChange}
      >
        Bookmarks
      </S.Filters.SingleCheckbox>
      <S.Filters.Separator />

      <S.Filters.CheckboxGroup
        direction="vertical"
        label="Community"
        value={selectedCommunities}
        onChange={handleSelectedCommunitiesChange}
      >
        {COMMUNITIES.map(community => (
          <GroupCheckbox
            key={community}
            data-cy={`community-${community}`}
            value={community}
          >
            {community}
          </GroupCheckbox>
        ))}
      </S.Filters.CheckboxGroup>

      <S.Filters.Separator />

      <S.Filters.CheckboxGroup
        direction="vertical"
        label="Specialty"
        value={selectedSpecialties}
        onChange={handleSelectedSpecialtiesChange}
      >
        {SPECIALTIES.map(specialty => (
          <GroupCheckbox
            key={specialty}
            data-cy={`specialties-${specialty}`}
            value={specialty}
          >
            {capitalize(specialty.replace('_', ' '))}
          </GroupCheckbox>
        ))}
      </S.Filters.CheckboxGroup>

      <S.Filters.Separator />

      <MultiSelect
        expandBeyondInput
        allText="All organizations"
        aria-label="Accrediting organizations"
        customLabel={
          <S.AccreditationLabel>
            Accrediting organizations
            <PopoverTrigger
              content={
                <S.PopoverContent>
                  To learn more about earning CE/CME credits through Violet, please{' '}
                  <S.FaqLink
                    href={FaqLinks.ceCredit}
                    target="_blank"
                  >
                    visit our FAQ
                  </S.FaqLink>
                  .
                </S.PopoverContent>
              }
            >
              <S.FaqButton aria-label="Question Mark">
                <S.QuestionMark
                  aria-hidden
                  role="img"
                />
              </S.FaqButton>
            </PopoverTrigger>
          </S.AccreditationLabel>
        }
        data-cy="accrediting-organizations"
        maxWidth="350px"
        placeholder="Choose organization(s)"
        // Remove when this is resolved: https://github.com/adobe/react-spectrum/issues/5492
        //@ts-expect-error - This is a valid prop
        selectedKeys={selectedAccreditingOrgs}
        selectionMode="multiple"
        onSelectionChange={handleAccreditingOrganizationsChange}
      >
        {accreditingOrganizations.map(org => (
          <Item
            key={org.acronym}
            textValue={`${org.name} ${org.acronym}`}
          >
            <S.OrganizationItem>
              <S.OrgAcronym>{org.acronym}</S.OrgAcronym>
              <S.OrgName>{org.name}</S.OrgName>
            </S.OrganizationItem>
          </Item>
        ))}
      </MultiSelect>

      <S.Filters.Separator />

      <S.Filters.CheckboxGroup
        direction="vertical"
        label="Level"
        value={selectedLevels}
        onChange={handleSelectedLevelsChange}
      >
        {LEVELS.map(level => (
          <GroupCheckbox
            key={level.toLowerCase()}
            data-cy={`level-${level.toLowerCase()}`}
            value={level.toLowerCase()}
          >
            {level}
          </GroupCheckbox>
        ))}
      </S.Filters.CheckboxGroup>

      <S.Filters.Separator />

      <S.Filters.CheckboxGroup
        direction="vertical"
        label="Status"
        value={selectedStatuses}
        onChange={handleSelectedStatusesChange}
      >
        {PROGRESS_STATUSES.map(status => (
          <GroupCheckbox
            key={status.toLowerCase()}
            data-cy={`status-${status.toLowerCase()}`}
            value={status.toLowerCase()}
          >
            {capitalize(status.replaceAll('_', ' '))}
          </GroupCheckbox>
        ))}
      </S.Filters.CheckboxGroup>
    </>
  );

  const educationTitle = (
    <PageTitle
      description="A collection is a series of e-learning modules designed to grow your cultural competence in a specific focus area.
            Each course within a collection builds on the last as you advance through facts and figures, case vignettes, video, quizzes, and more."
      title="Collections"
      titleVariant="h1"
    />
  );

  return (
    <Main>
      {isInMobileBreakpointRange && educationTitle}
      <div>
        <EducationFilters
          filters={filters}
          handleSearchValueChange={handleSearchValueChange}
          searchTextValue={searchText ?? ''}
        />
        <CallToActionLink
          caption="Explore our full catalog of education here."
          title="Looking for specific education?"
          to="/dashboard/education/all-education"
        />
      </div>
      {isFetching ? (
        <LoadingState />
      ) : collections.length === 0 && collectionsList.length === 0 ? (
        <EmptyState />
      ) : (
        <div>
          {isInDesktopBreakpointRange && educationTitle}
          <S.Collections>
            {collectionsList.map(collection => (
              <CollectionCard
                key={collection.id}
                collection={collection}
              />
            ))}
            {!hasFetchedAll && (
              <S.LoadMoreWrapper>{isFetchingMore && <Spinner withWrapper />}</S.LoadMoreWrapper>
            )}
            <ObservedTarget setIsIntersecting={setIsIntersecting} />
          </S.Collections>
        </div>
      )}
    </Main>
  );
};

export default Collections;
