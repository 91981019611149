import { Temporal } from '@js-temporal/polyfill';
// TODO: import `clamp` from `'react-aria'` once it stops throwing a TS error.
import { clamp } from '@react-aria/utils';
import * as Sentry from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { useLocation } from 'react-router-dom';
import type { BenchmarkObject } from '@/components/BenchmarkSection';
import BenchmarkSection from '@/components/BenchmarkSection';
import EducationCarousel from '@/components/Education/EducationCarousel';

import Spinner from '../../components/Spinner';
import useOpenErrorModalDialog from '../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../hooks/useUser';

import * as S from './styles';

const Dashboard = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const { hash } = useLocation();

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken, user } = useUser();

  const [benchmarks, setBenchmarks] = useState<BenchmarkObject[] | null>(null);

  useEffect(() => {
    (async () => {
      try {
        let url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard/badge_criteria`;

        let options: RequestInit = {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        };

        let response = await fetch(url, options);

        const { data: data1 } = (await response.json()) as APIUsersDashboardBadgeCriteria;

        url = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard`;

        options = {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        };

        response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data: data2 } = (await response.json()) as APIUsersDashboard;

        setBenchmarks(
          cloneDeep(data2)
            .sort((benchmark1, benchmark2) =>
              benchmark1.score_type === 'bipoc' ||
              (benchmark1.score_type === 'lgbq' && benchmark2.score_type === 'tgnc')
                ? -1
                : 1
            )
            .filter(benchmark => benchmark.is_active)
            .map(benchmark => {
              const communityAcronym = benchmark.score_type.toUpperCase() as
                | 'BIPOC'
                | 'LGBQ'
                | 'TGNC';
              const level = `${benchmark.score_level[0].toUpperCase()}${benchmark.score_level.slice(
                1
              )}`;
              const earnedAtDate = Temporal.PlainDate.from(
                benchmark.earned_at[benchmark.earned_at.length - 1] === 'Z'
                  ? benchmark.earned_at.slice(0, -1)
                  : benchmark.earned_at
              ).toLocaleString('en-US', { dateStyle: 'medium' });

              const awarenessBadgeIsNotYetAwarded =
                level !== 'Excellence' && level !== 'Proficiency' && level !== 'Awareness';
              const proficiencyBadgeIsNotYetAwarded =
                level !== 'Excellence' && level !== 'Proficiency';
              const excellenceBadgeIsNotYetAwarded = level !== 'Excellence';

              const criteria = data1
                .find(({ score_type }) => score_type === benchmark.score_type)!
                .criteria.sort((a, b) => a.low_score - b.low_score);

              return {
                badges: [
                  {
                    imageSrc: `${import.meta.env.VITE_BASE_PATH}${
                      criteria[awarenessBadgeIsNotYetAwarded ? 0 : 1].image.url
                    }`,
                    latestEarned: level.toUpperCase() === 'AWARENESS',
                    level: 'Awareness',
                    notYetAwarded: awarenessBadgeIsNotYetAwarded
                  },
                  {
                    imageSrc: `${import.meta.env.VITE_BASE_PATH}${
                      criteria[proficiencyBadgeIsNotYetAwarded ? 0 : 2].image.url
                    }`,
                    latestEarned: level.toUpperCase() === 'PROFICIENCY',
                    level: 'Proficiency',
                    notYetAwarded: proficiencyBadgeIsNotYetAwarded
                  },
                  {
                    imageSrc: `${import.meta.env.VITE_BASE_PATH}${
                      criteria[excellenceBadgeIsNotYetAwarded ? 0 : 3].image.url
                    }`,
                    latestEarned: level.toUpperCase() === 'EXCELLENCE',
                    level: 'Excellence',
                    notYetAwarded: excellenceBadgeIsNotYetAwarded
                  }
                ],
                communityAcronym,
                earnedAtDate,
                score: Math.round(
                  ((clamp(
                    (benchmark.total_score - criteria[0].low_score) /
                      (criteria[0].high_score - criteria[0].low_score),
                    0,
                    1
                  ) +
                    clamp(
                      (benchmark.total_score - criteria[1].low_score) /
                        (criteria[1].high_score - criteria[1].low_score),
                      0,
                      1
                    ) +
                    clamp(
                      (benchmark.total_score - criteria[2].low_score) /
                        (criteria[2].high_score - criteria[2].low_score),
                      0,
                      1
                    )) /
                    3) *
                    100
                )
              };
            })
        );
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    })();
  }, [bearerToken, openErrorModalDialog, openSignedOutModalDialog]);

  const isLoading = benchmarks === null;

  useEffect(() => {
    if (hash === '' || isLoading) return;

    const element = document.querySelector(hash);

    if (element) {
      element.scrollIntoView();
    }
  }, [hash, isLoading]);

  if (isLoading) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.Main data-cy="main">
      <BenchmarkSection
        benchmarks={benchmarks}
        isClinical={user.is_clinical}
      />
      <EducationCarousel />
      <S.ExploreMoreCollectionsLink.Root to="/dashboard/education/collections">
        <S.ExploreMoreCollectionsLink.CtaText>
          <S.ExploreMoreCollectionsLink.Title>
            Explore all collections
          </S.ExploreMoreCollectionsLink.Title>
          <S.ExploreMoreCollectionsLink.Subtitle>
            See all available Violet education collections.
          </S.ExploreMoreCollectionsLink.Subtitle>
        </S.ExploreMoreCollectionsLink.CtaText>
        <S.ExploreMoreCollectionsLink.ViewAllIconWrapper>
          <VisuallyHidden>View all</VisuallyHidden>
          <S.ExploreMoreCollectionsLink.ViewAllIcon />
        </S.ExploreMoreCollectionsLink.ViewAllIconWrapper>
      </S.ExploreMoreCollectionsLink.Root>
    </S.Main>
  );
};

export default Dashboard;
