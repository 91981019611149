import DownIcon from '@material-symbols/svg-400/rounded/keyboard_arrow_down.svg?react';
import UpIcon from '@material-symbols/svg-400/rounded/keyboard_arrow_up.svg?react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import * as S from './styles';

interface Props {
  children: ReactNode | string;
  defaultExpanded?: boolean;
  sectionId: string;
  title: string;
}

const ExpandableText = ({ children, defaultExpanded = false, sectionId, title }: Props) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const onExpandPress = () => {
    const isOpening = !isExpanded;
    setIsExpanded(isOpening);
  };

  useEffect(() => {
    if (defaultExpanded) return;
    const thisBlockId = document.getElementById(sectionId);
    if (thisBlockId && isExpanded) {
      /* slight delay to allow for the content to be rendered and animated */
      setTimeout(() => {
        thisBlockId.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 150);
    }
  }, [defaultExpanded, isExpanded, sectionId]);

  return (
    <S.FaqBlock>
      <S.FaqButton
        aria-controls={sectionId}
        aria-expanded={isExpanded}
        data-action="disclosure"
        onPress={onExpandPress}
      >
        {isExpanded ? (
          <UpIcon
            aria-hidden="true"
            role="img"
          />
        ) : (
          <DownIcon
            aria-hidden="true"
            role="img"
          />
        )}
        {title}
      </S.FaqButton>
      <S.FaqContent
        aria-hidden={!isExpanded}
        className={isExpanded ? 'expanded' : 'hidden'}
        id={sectionId}
      >
        {children}
      </S.FaqContent>
    </S.FaqBlock>
  );
};

export default ExpandableText;
