import type { PropsWithChildren } from 'react';

interface Props {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
}

const ConditionalWrap = ({
  children,
  condition,
  wrapper
}: PropsWithChildren<Props>): JSX.Element => {
  if (condition) {
    return wrapper(<div>{children}</div>);
  }

  return <>{children}</>;
};

export default ConditionalWrap;
