import type { GridNode } from '@react-types/grid';
import clsx from 'clsx';
import { useRef } from 'react';
import { mergeProps, useFocusRing, useTableCell } from 'react-aria';
import type { TableState } from 'react-stately';

import * as S from './styles';

interface Props {
  cell: GridNode<object>;
  'data-cy'?: string;
  isCentered?: boolean;
  state: TableState<object>;
}

const BodyCell = ({ cell, 'data-cy': dataCy, isCentered = false, state }: Props) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <S.BodyCell
      $isEmpty={cell.rendered === false}
      {...mergeProps(gridCellProps, focusProps)}
      ref={ref}
      className={clsx({ 'is-focused': isFocusVisible })}
      data-cy={dataCy}
    >
      {isCentered ? <S.CenterCellWrapper>{cell.rendered}</S.CenterCellWrapper> : cell.rendered}
    </S.BodyCell>
  );
};

export default BodyCell;
