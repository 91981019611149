import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import Spinner from '@/components/Spinner';
import VioletConfetti from '@/components/VioletConfetti';
import useOpenErrorModalDialog from '@/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import useUser from '@/hooks/useUser';
import useGetCollection from '@/pages/Dashboard/hooks/useGetCollection';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

import ExitConfirmationModalDialog from '../AccreditationSurvey/ExitConfirmationModalDialog';

import CertificateDownload from './CertificateDownload';
import CertificateSelection from './CertificateSelection';
import * as S from './styles';

const AccreditationCertificate = () => {
  const navigate = useNavigate();
  const { collectionId } = useParams<{ collectionId: string }>();
  const { collection } = useGetCollection(collectionId!);
  const { bearerToken } = useUser();

  const {
    close: closeExitConfirmationModalDialog,
    isOpen: exitConfirmationModalDialogIsOpen,
    open: openExitConfirmationModalDialog
  } = useOverlayTriggerState({});

  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const [certificateUrl, setCertificateUrl] = useState<string | undefined>();
  const [isGettingCertificate, setIsGettingCertificate] = useState<boolean>();
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const mainRef = useRef<HTMLElement>(null);

  const [size, setSize] = useState<Size>({
    height: undefined,
    width: undefined
  });

  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    box: 'border-box',
    onResize,
    ref: mainRef
  });

  useEffect(() => {
    if (collection) {
      setIsFetching(false);
      if (collection.progress < 100 || !collection.evaluation) {
        navigate(`/dashboard/education/collections/${collection.id}`, { replace: true });
      }
    }
    if (collection?.certificates && collection.certificates.length > 0) {
      setCertificateUrl(collection.certificates[0].file.url);
    }
  }, [collection, navigate]);

  const handleCloseButtonPress = () => {
    openExitConfirmationModalDialog();
  };

  const downloadCertificate = async (
    certificateType: string,
    dob?: string,
    licenseType?: string,
    licenseNumber?: string
  ) => {
    setIsGettingCertificate(true);

    try {
      const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/course_resource_certificates`;

      const options: RequestInit = {
        body: JSON.stringify({
          course_resource_certificate: {
            birth_date: dob,
            course_resource_id: collectionId,
            credit_type: certificateType,
            license_number: licenseNumber,
            license_type: licenseType
          }
        }),
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        method: 'POST'
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APICourseResourceCertificates;

      setCertificateUrl(data.file.url);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setIsGettingCertificate(false);
  };

  if (isFetching) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  } else {
    return (
      <>
        <S.Main ref={mainRef}>
          {certificateUrl !== undefined ? (
            <>
              <CertificateDownload
                collectionName={collection?.title ?? ''}
                fileUrl={certificateUrl}
              />
              <VioletConfetti
                height={size.height}
                width={size.width}
              />
            </>
          ) : (
            <>
              <S.CloseButton.Root onPress={handleCloseButtonPress}>
                <S.CloseButton.Icon />
              </S.CloseButton.Root>
              <S.Content>
                <S.ProgressBar
                  aria-label="Evaluation progress"
                  maxValue={4}
                  minValue={0}
                  value={4}
                />
                <CertificateSelection
                  collection={collection}
                  downloadCertificate={downloadCertificate}
                  isFetching={isGettingCertificate}
                />
              </S.Content>
            </>
          )}
        </S.Main>
        {exitConfirmationModalDialogIsOpen && (
          <ExitConfirmationModalDialog close={closeExitConfirmationModalDialog} />
        )}
      </>
    );
  }
};

export default AccreditationCertificate;
