import styled from 'styled-components';

import { TEXT_STYLES } from '../../../../../constants';

export const Option = styled.li`
  padding: 0.625rem 0.5rem;
  ${TEXT_STYLES.formsLabelRegular}
  color: #262626;
  cursor: default;
  border-radius: 0.5rem;

  &.is-focused,
  &.is-hovered {
    background-color: var(--gray-200);
    outline: 0;
  }

  &.is-selected,
  &.is-pressed {
    background-color: var(--purple-400);
    color: white;
  }

  svg {
    width: 1rem;
    height: 1rem;
    fill: currentcolor;
  }
`;
