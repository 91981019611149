import AddCircleIcon from '@material-design-icons/svg/round/add_circle.svg?react';
import EditPencilIcon from '@material-design-icons/svg/round/edit.svg?react';
import QuestionMarkIcon from '@material-design-icons/svg/round/help.svg?react';
import Button from '@/components/Buttons/Button';
import BaseCheckboxGroup from '@/components/FormFields/CheckboxGroup';
import {
  DESKTOP_BREAKPOINT_RANGE,
  FOCUS_STYLES,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '@/constants';
import styled from 'styled-components';

import UnstyledButton from '../../../../components/UnstyledButton';

export const AddAttachmentButton = styled(UnstyledButton)`
  display: grid;
  place-content: center;
  margin-right: auto;
  margin-left: auto;
  padding: 0 var(--space-4);

  &:focus-visible {
    ${FOCUS_STYLES}
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    height: 100%;
  }
`;

export const AddIcon = styled(AddCircleIcon)`
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--purple-400);
`;

interface BodyCellProps {
  $area?: string;
  $center?: boolean;
  $mobileAlignment?: 'left' | 'right';
}

export const BodyCell = styled.td<BodyCellProps>`
  all: unset;
  box-sizing: border-box;

  &:first-child {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
  }

  &:last-child {
    grid-column: 1 / -1;
  }

  &:not(:first-child, :last-child) {
    padding: 0.75rem;
  }

  ${({ $center = false }) =>
    $center &&
    `
    display: grid;
    place-content: center;
    height: 2.25rem;
    box-sizing: content-box;
  `}
`;

export const BodyFieldElement = styled.div<BodyCellProps>`
  all: unset;
  box-sizing: border-box;

  &.header-cell {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    min-height: unset;
  }

  &:last-child {
    grid-column: 1 / -1;
    min-height: 0;
  }

  &:not(:first-child, :last-child) {
    padding: 0.75rem;
  }

  ${({ $center = false }) =>
    $center &&
    `
    display: grid;
    place-content: center;
    height: 2.25rem;
    box-sizing: content-box;
  `}

  ${({ $area }) =>
    $area !== undefined &&
    `
    grid-area: ${$area};
  `}

  ${({ $mobileAlignment }) => {
    if ($mobileAlignment === 'left') {
      return `
        justify-content: start;
      `;
    } else if ($mobileAlignment === 'right') {
      return `
        justify-content: end;
      `;
    }
  }}
`;

export const CheckboxGroup = styled(BaseCheckboxGroup)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    display: flex;
    height: 100%;
    align-items: center;
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    display: flex;
  }

  .checkbox-container {
    column-gap: 0.75rem !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const CheckboxGroupMobileLabel = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 0.13rem;
  ${TEXT_STYLES.formsLabelMediumSmallCaps}
  color: var(--purple-600);
`;

export const CommunityMobileLabel = styled.div`
  margin-bottom: 0.5rem;
`;

export const DeleteButton = styled(UnstyledButton)`
  display: grid;
  margin-right: auto;
  margin-left: auto;
  height: 100%;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:focus-visible {
    ${FOCUS_STYLES}
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    place-content: center;
    color: var(--gray-dark-dark);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    width: 100%;
    place-content: center end;
    color: var(--purple-600);
  }
`;

export const EditAttachmentButton = styled(UnstyledButton)`
  display: grid;
  place-content: center;
  margin-right: auto;
  margin-left: auto;
  padding: 0 var(--space-4);

  ${DESKTOP_BREAKPOINT_RANGE} {
    height: 100%;
  }

  &:focus-visible {
    ${FOCUS_STYLES}
  }
`;

export const EditIcon = styled(EditPencilIcon)`
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--gray-dark-dark);
`;

export const ExpandButton = {
  Icon: styled.svg`
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  `,
  Root: styled(UnstyledButton)`
    display: flex;
    ${TEXT_STYLES.buttonTextRegular}
    color: var(--purple-600);
    align-items: center;

    &.is-focused {
      ${FOCUS_STYLES}
    }

    &.is-disabled {
      fill: var(--error-700);
      color: var(--error-700);
    }
  `
};

export const HelpIcon = styled(QuestionMarkIcon)`
  margin-left: 0.25rem;
  fill: var(--purple-600);
  height: 1rem;
  width: 1rem;
`;

export const MobileAttachmentButton = styled(Button)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const MobileExpandedContainer = styled.div`
  padding: 0 0.9rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MobileFieldRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const PopoverButton = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.is-focused {
    outline-color: var(--brand-bright);
    outline-offset: 0.25rem;
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);
  max-width: 18.75rem;
`;
