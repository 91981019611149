import { TEXT_STYLES } from '@/constants';
import { css } from 'styled-components';

export interface ButtonGroupProps {
  $align?: 'right' | 'left';
}

export const sharedModalStyles = {
  buttonGroup: css<ButtonGroupProps>`
    display: flex;
    align-items: center;
    margin-top: 3rem;
    gap: 1rem;

    ${({ $align }: ButtonGroupProps) => {
      if ($align === 'right') {
        return `
          justify-content: flex-end;
        `;
      } else if ($align === 'left') {
        return `
          justify-content: flex-start;
        `;
      } else {
        return `
          justify-content: space-between;
        `;
      }
    }};
  `,
  previewBlock: css`
    display: flex;
    padding: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 1.5rem;
    background: var(--gray-100);
  `,
  image: css`
    max-width: 100%;
    height: auto;
    margin: auto;
  `,
  text: css`
    ${TEXT_STYLES.bodyCopyRegular}
    color: var(--gray-800);
  `
};
