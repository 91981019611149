import DataReportRow from '@/components/Reporting/DataReportRow';
import Spinner from '@/components/Spinner';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '../../../constants';
import DataCard from '@/components/Reporting/DataCard';
import DataCardHeader from '@/components/Reporting/DataCardHeader';

export const CommunityCard = styled(DataCard)`
  display: flex;
  flex-direction: column;
`;

interface CustomComparitiveDataCardHeaderProps {
  $serviceAreaComparisonsEnabled?: boolean;
}

export const CustomComparitiveDataCardHeader = styled(
  DataCardHeader
)<CustomComparitiveDataCardHeaderProps>`
  ${({ $serviceAreaComparisonsEnabled }) => {
    if ($serviceAreaComparisonsEnabled) {
      return `
        margin-bottom: 0.8rem;
      `;
    }
  }}
`;

export const CustomDemographicsCardHeader = styled(DataCardHeader)`
  > div {
    margin-bottom: 1.45rem;
  }
`;

export const DataWrapper = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const NetworkMetricRow = styled(DataReportRow)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  > div {
    flex-grow: 1;
    min-width: 12.5rem;
  }
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }
`;

export const ToastSpinner = styled(Spinner)`
  height: 1.5rem;
  width: 1.5rem;
`;
