import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

import UnstyledButton from '../UnstyledButton';

export const FaqBlock = styled.div`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.75rem;
  border: 1px solid var(--gray-200);
`;

export const FaqButton = styled(UnstyledButton)`
  width: 100%;
  ${TEXT_STYLES.bodyCopyMedium}
  font-weight: 500;
  color: var(--grey-600);
  display: grid;
  grid-template-columns: 1.75rem 1fr;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--gray-800);
  }
`;

export const FaqContent = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--grey-600);
  margin-left: 1.75rem;
  transition: all 0.5s ease-in-out;

  &.expanded {
    max-height: 900px;
    opacity: 1;
    margin-top: 0.25rem;
    overflow: visible;
  }

  &.hidden {
    max-height: 0;
    opacity: 0;
    margin-top: 0;
    overflow: hidden;
  }
`;
