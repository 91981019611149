import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE } from '@/constants';
import styled from 'styled-components';

export const List = styled.ul`
  z-index: 998;
  margin-top: 0.5rem;
  width: 100%;
  display: grid;
  row-gap: 0;
  margin-bottom: 0;
  padding: 0.5rem;
  background-color: white;
  list-style-type: none;
  border: 1px solid var(--gray-200);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
  overflow: auto;

  ${MOBILE_BREAKPOINT_RANGE} {
    max-height: 11rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-height: 20rem;
  }
`;
