import { createContext, useContext } from 'react';

import type { ServiceAreaLocationFilter } from '../../hooks/useGetNetworkServiceAreaComparisons';
import type { Network } from '../../utils';

interface ContextValue {
  defaultComparisonStates: ServiceAreaLocationFilter[];
  networks: Network[];
}

export const NetworksContext = createContext<ContextValue>({
  defaultComparisonStates: [],
  networks: []
});

const useNetworks = () => useContext(NetworksContext);

export default useNetworks;
