import AddCircleIcon from '@material-design-icons/svg/filled/add_circle.svg?react';
import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import type { FormEventHandler, MutableRefObject } from 'react';
import { createRef, useEffect, useRef, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { useNavigate } from 'react-router-dom';
import BackNext from '@/components/Buttons/BackNext';
import ExpandableEditableTable from '@/components/ExpandableEditableTable';
import OnboardingHero from '@/components/heroes/OnboardingHero';
import useOpenErrorModalDialog from '@/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import useUser from '@/hooks/useUser';
import WhatWorkExperienceToAddPopover from '@/pages/components/WhatWorkExperienceToAddPopover';
import isNonEmptyString from '@/utils/isNonEmptyString';

import OnboardingLayout from '../components/OnboardingLayout';
import WorkExperienceRow from '../components/WorkExperienceRow';

import * as S from './styles';

/***** Custom types */
export interface WorkExperienceFormType {
  averagePanelSize: MutableRefObject<HTMLInputElement | null>;
  bipocPercentage: MutableRefObject<HTMLInputElement | null>;
  department: MutableRefObject<HTMLInputElement | null>;
  endDate: MutableRefObject<string | null>;
  id: string | undefined;
  index: number;
  isCurrentRole: MutableRefObject<HTMLInputElement | null>;
  jobTitle: MutableRefObject<HTMLInputElement | null>;
  lgbqPercentage: MutableRefObject<HTMLInputElement | null>;
  organization: MutableRefObject<HTMLInputElement | null>;
  startDate: MutableRefObject<string | null>;
  tgncPercentage: MutableRefObject<HTMLInputElement | null>;
}

/***** Begin component */
const WorkExperience = () => {
  const checkValidityOfWorkExperienceRowsRef = useRef<(() => boolean)[]>([]);
  const functionToCallAfterUserUpdateRef = useRef<() => void>();
  const { bearerToken, setUser, user } = useUser();
  const navigate = useNavigate();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const formRef = useRef<HTMLFormElement>(null);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(0);
  const [invalidRowIndices, setInvalidRowIndices] = useState<number[]>([]);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const [workExperiences, setWorkExperiences] = useState<WorkExperienceFormType[]>([]);

  const inLineAlertId = '519cU3ZPD7'; // Generated using `pwgen -s 10 1`.

  /*
   * Automated work experience from existing org data
   * for all users EXCEPT those who join via self sign-up
   * for self sign-up users, we'll create an empty row
   */
  const currentOrgs =
    user.sign_up_source === 'self_service'
      ? [
          {
            average_panel_size: undefined,
            bipoc_percentage: undefined,
            department: undefined,
            end_date: undefined,
            id: undefined,
            job_title: undefined,
            lgbq_percentage: undefined,
            organization: undefined,
            start_date: undefined,
            tgnc_percentage: undefined
          }
        ]
      : user.organization_memberships
          .filter(org => org.status === 'activated')
          .map(({ organization }) => {
            const currentOrgWorkExperience: WorkExperienceType = {
              average_panel_size: undefined,
              bipoc_percentage: undefined,
              department: undefined,
              end_date: undefined,
              id: undefined,
              job_title: undefined,
              lgbq_percentage: undefined,
              organization: organization.name,
              start_date: undefined,
              tgnc_percentage: undefined
            };
            return currentOrgWorkExperience;
          });

  /***** User effect */
  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }

    if (hasRendered) return;
    /*
     * if user does not have saved experiences,
     * we'll create them based on current organizations
     * so create empty objects for each active org to generate form refs
     */
    const workExperiences =
      user.user_info.experiences.length > 0
        ? [...user.user_info.experiences]
        : Array(currentOrgs.length).fill({});

    /***** Create form field refs from existing experiences */
    const experiencesToFormRefs = workExperiences.map((experience: WorkExperienceType, index) => {
      const remapExperience = {
        averagePanelSize: createRef<HTMLInputElement | null>(),
        bipocPercentage: createRef<HTMLInputElement | null>(),
        department: createRef<HTMLInputElement | null>(),
        endDate: createRef<string | null>(),
        id: experience.id,
        index,
        isCurrentRole: createRef<HTMLInputElement | null>(),
        jobTitle: createRef<HTMLInputElement | null>(),
        lgbqPercentage: createRef<HTMLInputElement | null>(),
        organization: createRef<HTMLInputElement | null>(),
        startDate: createRef<string | null>(),
        tgncPercentage: createRef<HTMLInputElement | null>()
      };
      return remapExperience;
    });
    setWorkExperiences(experiencesToFormRefs);
    setHasRendered(true);
  }, [currentOrgs.length, hasRendered, user]);

  /***** Action handlers */
  const removeWorkExperience = async (index: number) => {
    const item = workExperiences.find(experience => experience.index === index);

    if (item && isNonEmptyString(item.id)) {
      const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard/experiences/${item.id}`;
      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        method: 'DELETE'
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    }

    const newWorkExperiences = workExperiences.filter(experience => experience.index !== index);
    setWorkExperiences(newWorkExperiences);
  };

  const addWorkExperience = () => {
    const lastIndex = workExperiences.length - 1;
    const lastSavedWorkExp = lastIndex >= 0 ? workExperiences[lastIndex] : null;
    const newIndex = lastSavedWorkExp ? lastSavedWorkExp.index + 1 : 0;
    const newWorkExperience: WorkExperienceFormType = {
      averagePanelSize: createRef<HTMLInputElement | null>(),
      bipocPercentage: createRef<HTMLInputElement | null>(),
      department: createRef<HTMLInputElement | null>(),
      endDate: createRef<string | null>(),
      id: undefined,
      index: newIndex,
      isCurrentRole: createRef<HTMLInputElement | null>(),
      jobTitle: createRef<HTMLInputElement | null>(),
      lgbqPercentage: createRef<HTMLInputElement | null>(),
      organization: createRef<HTMLInputElement | null>(),
      startDate: createRef<string | null>(),
      tgncPercentage: createRef<HTMLInputElement | null>()
    };
    setWorkExperiences([...workExperiences, newWorkExperience]);
    setExpandedRowIndex(newIndex);
  };

  const saveWorkExperiences: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    setHasAttemptedSubmit(true);
    refreshInvalidRowIndices();

    if (
      checkValidityOfWorkExperienceRowsRef.current.some(
        checkValidityOfWorkExperienceRow => !checkValidityOfWorkExperienceRow()
      )
    ) {
      formRef.current!.reportValidity();
      return;
    }

    setFormIsSubmitting(true);

    const newExperienceValues = workExperiences.map(workExperience => ({
      average_panel_size: workExperience.averagePanelSize.current!.value,
      bipoc_percentage: workExperience.bipocPercentage.current!.value,
      department: workExperience.department.current!.value,
      end_date: workExperience.isCurrentRole.current!.checked
        ? null
        : workExperience.endDate.current!,
      id: workExperience.id,
      job_title: workExperience.jobTitle.current!.value,
      lgbq_percentage: workExperience.lgbqPercentage.current!.value,
      organization: workExperience.organization.current!.value,
      start_date: workExperience.startDate.current!,
      tgnc_percentage: workExperience.tgncPercentage.current!.value
    }));

    const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/onboard`;

    const options: RequestInit = {
      body: JSON.stringify({
        user: {
          id: user.id,
          user_info: {
            experiences: newExperienceValues
          }
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APIUsersOnboard;
      setUser(data);

      functionToCallAfterUserUpdateRef.current = () => {
        navigate('/onboarding/further-experience');
      };
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setFormIsSubmitting(false);
  };

  const refreshInvalidRowIndices = () => {
    const invalidRows = checkValidityOfWorkExperienceRowsRef.current
      .map((checkValidityOfWorkExperienceRow, index) =>
        checkValidityOfWorkExperienceRow() ? null : index
      )
      .filter((index): index is number => index !== null);
    setInvalidRowIndices(invalidRows);
  };

  useEffect(() => {
    if (workExperiences.length === 0) return;
    refreshInvalidRowIndices();
  }, [workExperiences]);

  const autoPopulatedRowIndices = user.user_info.experiences
    .filter(exp => exp.source === 'claims_data')
    .map((_, index) => index);
  const centeredColumnIndices = [0, 4, 6];

  /***** Render */
  return (
    <OnboardingLayout progressBarValue={4}>
      <form
        ref={formRef}
        noValidate
        onSubmit={saveWorkExperiences}
      >
        <OnboardingHero
          copy="Add any clinical work experience in which you provided care to BIPOC, LGBQ, and TGNC communities, including your current position."
          graphicType="work experience"
          header="Work experience."
        />
        <AnimatePresence>
          {user.user_info.experiences.some(({ source }) => source === 'claims_data') && (
            <S.InLineAlert id={inLineAlertId}>
              We've pre-filled some work experience details based on your NPI. Please review and add
              any additional information.
            </S.InLineAlert>
          )}
        </AnimatePresence>
        <ExpandableEditableTable
          aria-label="Work experience table"
          autoPopulatedRowIndices={autoPopulatedRowIndices}
          centeredColumnIndices={centeredColumnIndices}
          emptyMessage="You have not added any work experience."
          expandedRowIndex={expandedRowIndex}
          hasAttemptedSubmit={hasAttemptedSubmit}
          invalidRowIndices={invalidRowIndices}
        >
          <thead>
            <tr>
              <th>
                <VisuallyHidden>EXPAND / COLLAPSE</VisuallyHidden>
              </th>
              <th>JOB TITLE</th>
              <th>ORGANIZATION</th>
              <th>DEPARTMENT</th>
              <th>CURRENT ROLE</th>
              <th>START / END DATE</th>
              <th>ACTIONS</th>
              <th>
                <VisuallyHidden>EXPANDED CONTENT</VisuallyHidden>
              </th>
            </tr>
          </thead>
          <tbody>
            {workExperiences.map((experience, index) => {
              /*
               * If no existing work experience
               * set initial/default state to that autogenerated from org data
               */
              const savedInitialState = user.user_info.experiences.find(
                ex => ex.id === experience.id
              );
              const initialState =
                user.user_info.experiences.length === 0 && !savedInitialState
                  ? currentOrgs[experience.index]
                  : savedInitialState;
              return (
                <WorkExperienceRow
                  key={experience.id ?? experience.index}
                  checkValidityRef={checkValidityOfWorkExperienceRowsRef}
                  data-cy={`work-experience-row-${experience.index ? experience.index : index}`}
                  expandedRowIndex={expandedRowIndex}
                  handleExpandCollapseClick={index =>
                    setExpandedRowIndex(expandedRowIndex === index ? -1 : Number(index))
                  }
                  handleValidityChange={refreshInvalidRowIndices}
                  hasAttemptedSubmit={hasAttemptedSubmit}
                  index={index}
                  initialState={initialState}
                  isInvalid={hasAttemptedSubmit && invalidRowIndices.includes(index)}
                  workExperience={experience}
                  onRemove={removeWorkExperience}
                />
              );
            })}
          </tbody>
        </ExpandableEditableTable>
        <S.AddExperienceButton
          data-cy="add-experience-button"
          trailingIcon={AddCircleIcon}
          variant="plain"
          onPress={addWorkExperience}
        >
          Add experience
        </S.AddExperienceButton>
        <WhatWorkExperienceToAddPopover />
        <BackNext
          backTo="/onboarding/education-experience"
          nextIsLoading={formIsSubmitting}
          nextLabel="Next: Self assessment"
        />
      </form>
    </OnboardingLayout>
  );
};

export default WorkExperience;
