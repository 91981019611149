import HealthIcon from '@material-symbols/svg-400/rounded/cognition-fill.svg?react';
import TimeIcon from '@material-symbols/svg-400/rounded/schedule-fill.svg?react';
import type { Variants } from 'framer-motion';
import capitalize from 'lodash/capitalize';
import MiniProgressBar from '@/components/MiniProgressBar';
import type { Pathway } from '@/pages/Dashboard/utils';
import { getHoursAndMinutes } from '@/utils/getHoursAndMinutes';
import getLevelIcon from '@/utils/getLevelIcon';
import getPathwayProgress from '@/utils/getPathwayProgress';
import isNonEmptyString from '@/utils/isNonEmptyString';

import ResourceCardHeader from '../ResourceCardHeader';

import * as S from './styles';

interface Props {
  pathway: Pathway;
  variants?: Variants;
}

const PathwayCard = ({ pathway, variants }: Props) => {
  const { hours, minutes } = getHoursAndMinutes(pathway.hours);
  const Icon = getLevelIcon(pathway.level);

  const { completedStepsCount, inProgressStep } = getPathwayProgress(pathway);

  return (
    <S.CardContainer variants={variants}>
      <S.OtherCard />
      <S.PathwayCard data-cy="pathway-card">
        <S.CardLabel>Pathway</S.CardLabel>
        <S.CardContent>
          <ResourceCardHeader
            bookmarks={pathway.bookmarks}
            completedAt={pathway.completion?.completedAt}
            id={pathway.id}
            isNew={pathway.isNew}
            isPopular={pathway.isTrending}
            resourceType="Pathway"
          />
          <S.Title to={`/dashboard/education/pathways/${pathway.id}`}>{pathway.name}</S.Title>
          <S.Description>{pathway.shortDescription}</S.Description>
          <S.Tags data-cy="pathway-tags">
            <S.Tag
              aria-label="Level"
              data-cy="level-tag"
            >
              <Icon
                aria-hidden="true"
                role="img"
              />
              <div>{capitalize(pathway.level)}</div>
            </S.Tag>
            <S.Tag
              aria-label="Specialty area(s)"
              data-cy="specialty-area-tag"
            >
              <HealthIcon
                aria-hidden="true"
                role="img"
              />
              <div>
                {pathway.specialtyArea.length < 3
                  ? pathway.specialtyArea
                      .map(specialty => capitalize(specialty))
                      .join(' • ')
                      .replaceAll('_', ' ')
                  : 'All'}
              </div>
            </S.Tag>
            <S.Tag
              aria-label="Time to complete"
              data-cy="time-tag"
            >
              <TimeIcon
                aria-hidden="true"
                role="img"
              />
              <div>
                {hours > 0 && `${hours} hour${hours === 1 ? '' : 's'}`}
                {hours > 0 && minutes > 0 && ' '}
                {minutes > 0 && `${minutes} min${minutes === 1 ? '' : 's'}`}
              </div>
            </S.Tag>
            <S.Tag
              aria-label="Progress"
              className="progress-tag"
              data-cy="progress-tag"
            >
              <S.ProgressLabel>Progress </S.ProgressLabel>
              <S.ProgressBarWrapper>
                <MiniProgressBar
                  completedSteps={completedStepsCount}
                  inProgressStep={inProgressStep}
                  label="Pathway progress"
                  totalSteps={pathway.resources.length}
                />
              </S.ProgressBarWrapper>
            </S.Tag>
          </S.Tags>
          <S.ButtonWrapper>
            <S.Button
              fullWidth
              data-cy="view-button"
              size="regular"
              to={`/dashboard/education/pathways/${pathway.id}`}
              variant="primary"
            >
              {isNonEmptyString(pathway.completion?.completedAt)
                ? 'View'
                : isNonEmptyString(pathway.completion?.startedAt)
                  ? 'Resume'
                  : 'Start'}
            </S.Button>
          </S.ButtonWrapper>
        </S.CardContent>
      </S.PathwayCard>
    </S.CardContainer>
  );
};

export default PathwayCard;
