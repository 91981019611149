import ArrowForwardIcon from '@material-design-icons/svg/round/arrow_forward.svg?react';
import styled from 'styled-components';

import UnstyledButton from '../../components/UnstyledButton';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../constants';

export const ExploreMoreCollectionsLink = {
  CtaText: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Root: styled(UnstyledButton)`
    margin-top: 0.75rem;
    display: grid;
    border-radius: 1.5rem;
    background: var(--light-purple-300);
    transition:
      color 0.3s,
      background-color 0.3s;

    ${MOBILE_BREAKPOINT_RANGE} {
      align-content: space-between;
      justify-items: start;
      row-gap: 1.75rem;
      min-height: 229px;
      padding: 1.875rem 1.875rem 1.5625rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      grid-template-columns: 1fr auto;
      align-items: center;
      column-gap: 2.125rem;
      min-height: 136px;
      padding-right: 1.75rem;
      padding-left: 1.25rem;
    }

    &.is-hovered {
      color: #fff;
      background-color: var(--purple-200);

      ${() => {
        const { styledComponentId: viewAllIconWrapperClassName } =
          ExploreMoreCollectionsLink.ViewAllIconWrapper as { styledComponentId: string };

        return `
          .${viewAllIconWrapperClassName} {
            background-color: white;

            & > svg > path {
              fill: #e03fff;
              stroke: #e03fff;
            }
          }
        `;
      }}
    }

    &.is-pressed {
      color: #fff;
      background-color: var(--purple-400);
    }
  `,
  Subtitle: styled.p`
    ${TEXT_STYLES.bodyCopyRegular}
    margin: 0;
  `,
  Text: styled.p`
    ${TEXT_STYLES.buttonTextLarge}
    margin-bottom: 0;

    ${MOBILE_BREAKPOINT_RANGE} {
      margin-top: 0.875rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      margin-top: 0.6875rem;
    }
  `,
  Title: styled.h3`
    ${TEXT_STYLES.headlineSectionLevel1Regular}
    margin-bottom: 1.5rem;
  `,
  ViewAllIcon: styled(ArrowForwardIcon)`
    height: auto;

    & > path {
      transition:
        fill 0.3s,
        stroke 0.3s;
      stroke-width: 0;
      fill: var(--white-light);
    }

    ${MOBILE_BREAKPOINT_RANGE} {
      width: 1.5rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      width: 3.25rem;
    }
  `,
  ViewAllIconWrapper: styled.div`
    display: grid;
    place-content: center;
    background-color: var(--purple-400);
    border-radius: 100%;
    transition: background-color 0.3s;

    ${MOBILE_BREAKPOINT_RANGE} {
      width: 33px;
      height: 33px;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      width: 80px;
      height: 80px;
    }
  `
};

export const Main = styled.main`
  max-width: 1420px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  ${MOBILE_BREAKPOINT_RANGE} {
    padding: var(--space-7) var(--space-5) 120px;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    padding: var(--space-8) var(--space-9) var(--space-16);
  }
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100dvh;
`;
