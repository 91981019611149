import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import type { Placement } from 'react-aria';

import * as S from './styles';

import PopoverTrigger from '@/components/PopoverTrigger';

interface Props extends PropsWithChildren {
  className?: string;
  inline?: boolean;
  label?: string;
  placement?: Placement;
  size?: 'large' | 'small';
  type?: 'help' | 'info';
}

const HelpPopoverTrigger = ({
  children,
  className,
  inline = false,
  label,
  placement,
  size = 'large',
  type = 'help'
}: Props) => (
  <PopoverTrigger
    content={<S.PopoverContent>{children}</S.PopoverContent>}
    placement={placement}
  >
    <S.PopoverButton className={clsx({ inline }, size, className)}>
      <span>{label}</span>
      {type === 'help' && <S.HelpIcon />}
      {type === 'info' && <S.InfoIcon />}
    </S.PopoverButton>
  </PopoverTrigger>
);

export default HelpPopoverTrigger;
