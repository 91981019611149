import BaseXCircleIcon from '@material-design-icons/svg/round/cancel.svg?react';
import BaseCheckCircleIcon from '@material-design-icons/svg/round/check_circle.svg?react';
import styled from 'styled-components';

import { TEXT_STYLES } from '@/constants';

export const CheckCircleIcon = styled(BaseCheckCircleIcon)`
  fill: var(--success-400);
  width: 1.25rem;
  height: 1.25rem;
`;

export const IconItemList = styled.ul`
  padding: 0 !important;
  color: var(--gray-700);
`;

export const IconListItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.25rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const ListItem = styled.li`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-700);
`;

export const ListLabel = styled.div`
  ${TEXT_STYLES.tooltipTitle}
  margin: 0.75rem 0;
  color: var(--gray-700);
`;

export const XCircleIcon = styled(BaseXCircleIcon)`
  fill: var(--error-500);
  width: 1.25rem;
  height: 1.25rem;
`;
