/*
  This function is used to set the page title
  and returns a category based on the pathname
  for analytics purposes.
*/

interface RouteConfig {
  category: string;
  title: string;
}

const ROUTE_MAPPINGS: Record<string, RouteConfig> = {
  // Dashboard default
  '/dashboard': { category: 'dashboard', title: 'Dashboard' },

  // Education routes
  '/dashboard/education': { category: 'education', title: 'Education' },
  '/dashboard/education/collections': { category: 'education', title: 'Education - Collections' },
  '/dashboard/education/pathways': { category: 'education', title: 'Education - Pathways' },

  // My Education routes
  '/dashboard/my-education': { category: 'education', title: 'My Education - In-progress' },
  '/dashboard/my-education/completions-and-certificates': {
    category: 'education',
    title: 'My Education - Completions and Certificates'
  },

  // My Network routes
  '/dashboard/my-network': { category: 'network', title: 'My Network - Overview' },
  '/dashboard/my-network/demographics': { category: 'network', title: 'My Network - Diversity' },
  '/dashboard/my-network/education-engagement': {
    category: 'network',
    title: 'My Network - Education Engagement'
  },
  '/dashboard/my-network/inclusivity': { category: 'network', title: 'My Network - Inclusivity' },

  // My Organization routes
  '/dashboard/my-organization': { category: 'my_org', title: 'My Organization - Overview' },
  '/dashboard/my-organization/cultural-competencies': {
    category: 'my_org',
    title: 'My Organization - Cultural Competencies'
  },
  '/dashboard/my-organization/inclusivity/benchmarks': {
    category: 'my_org',
    title: 'My Organization - Inclusivity'
  },
  '/dashboard/my-organization/demographics': {
    category: 'my_org',
    title: 'My Organization - Demographics'
  },
  '/dashboard/my-organization/inclusivity/demographics': {
    category: 'my_org',
    title: 'My Organization - Demographics'
  },
  '/dashboard/my-organization/education': {
    category: 'my_org',
    title: 'My Organization - Education Engagement'
  },
  '/dashboard/my-organization/education/engagement': {
    category: 'my_org',
    title: 'My Organization - Education Engagement'
  },
  '/dashboard/my-organization/assignments': {
    category: 'my_org',
    title: 'My Organization - Assignments'
  },
  '/dashboard/my-organization/education/assignments': {
    category: 'my_org',
    title: 'My Organization - Assignments'
  },
  '/dashboard/my-organization/team': {
    category: 'my_org',
    title: 'My Organization - Team'
  },

  // Profile routes
  '/dashboard/profile': { category: 'profile', title: 'Profile' },
  '/dashboard/profile/account-information': {
    category: 'profile',
    title: 'Profile - Account Information'
  },
  '/dashboard/profile/cultural-competencies': {
    category: 'profile',
    title: 'Profile - Cultural Competencies'
  },
  '/dashboard/profile/education': { category: 'profile', title: 'Profile - Education History' },
  '/dashboard/profile/identity-and-languages': {
    category: 'profile',
    title: 'Profile - Identities & Languages'
  },
  '/dashboard/profile/work-experience': { category: 'profile', title: 'Profile - Work Experience' },

  // Onboarding routes
  '/onboarding': { category: 'onboarding', title: 'Onboarding' },
  '/onboarding/cultural-competencies': {
    category: 'onboarding',
    title: 'Onboarding - Communities'
  },
  '/onboarding/education-experience': {
    category: 'onboarding',
    title: 'Onboarding - Education History'
  },
  '/onboarding/further-experience': {
    category: 'onboarding',
    title: 'Onboarding - Self Assessment'
  },
  '/onboarding/identity': { category: 'onboarding', title: 'Onboarding - Identities' },
  '/onboarding/information': { category: 'onboarding', title: 'Onboarding - Personal Information' },
  '/onboarding/submitted': { category: 'onboarding', title: 'Onboarding - Submitted' },
  '/onboarding/work-experience': { category: 'onboarding', title: 'Onboarding - Work Experience' }
};

const getPageTitleAndCategory = (pathname: string): string => {
  // Find the most specific matching route by sorting routes by length
  const routes = Object.keys(ROUTE_MAPPINGS).sort((a, b) => b.length - a.length);
  const matchingRoute = routes.find(route => pathname.startsWith(route));

  if (matchingRoute !== undefined) {
    document.title = ROUTE_MAPPINGS[matchingRoute].title;
    return ROUTE_MAPPINGS[matchingRoute].category;
  }

  // Default fallback
  document.title = 'Dashboard';
  return '';
};

export default getPageTitleAndCategory;
