import { Temporal } from '@js-temporal/polyfill';
import { TableHeader, TableTitle } from '@/components/Table/styles';
import type { CourseCollectionCompletion } from '@/pages/Dashboard/utils';

import BooleanCheck from '../BooleanCheck';
import CollectionActionButton from '../CollectionActionButton';

import * as S from './styles';

interface Props {
  collectionCompletions: CourseCollectionCompletion[];
}

const MobileCards = ({ collectionCompletions }: Props) => (
  <S.MobileCards>
    <TableHeader>
      <TableTitle>Collection completions</TableTitle>
    </TableHeader>
    {collectionCompletions.length > 0 ? (
      collectionCompletions.map(completion => {
        const { collection } = completion;
        return (
          <S.Card
            key={completion.id}
            data-cy="completed-collection"
          >
            <S.CardRow>
              <S.Label>Collection name:</S.Label>
              <S.ValueLink to={`/dashboard/education/collections/${collection.id}`}>
                {collection.name}
              </S.ValueLink>
            </S.CardRow>
            <S.CardRow>
              <S.Label>Date completed:</S.Label>
              <S.Value>
                {completion.completedAt !== null
                  ? Temporal.PlainDate.from(completion.completedAt).toLocaleString('en-US', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                    })
                  : ''}
              </S.Value>
            </S.CardRow>
            <S.CardRow>
              <S.Label>Credits/Hours:</S.Label>
              <S.Value>{collection.credits ?? 0}</S.Value>
            </S.CardRow>

            <S.CardRow>
              <S.Label>Claimed Certificate?</S.Label>
              <S.Value>
                <BooleanCheck
                  dataCy="collection-claimed-certificate"
                  value={collection.evaluation !== null}
                />
              </S.Value>
            </S.CardRow>

            <CollectionActionButton collection={collection} />
          </S.Card>
        );
      })
    ) : (
      <S.Card>
        <S.BlankText>No collections have been completed.</S.BlankText>
      </S.Card>
    )}
  </S.MobileCards>
);

export default MobileCards;
