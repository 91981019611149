import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const AboveAvg = styled.span`
  color: var(--success-700);
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 0.25rem;

  svg {
    fill: var(--success-700);
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const Avg = styled.span`
  color: var(--blue-700);
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 0.25rem;

  svg {
    fill: var(--blue-700);
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const BelowAvg = styled.span`
  color: var(--warning-700);
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 0.25rem;

  svg {
    fill: var(--warning-700);
    width: 1.25rem;
    height: 1.25rem;
  }
`;

interface ComparisonLabelProps {
  $bolded?: boolean;
}

export const ComparisonLabel = styled.div<ComparisonLabelProps>`
  color: var(--gray-800);

  ${({ $bolded = false }) =>
    $bolded &&
    `
    font-weight: 500;
  `}
`;

interface ComparisonTableRowProps {
  $indent?: boolean;
  $last?: boolean;
}

export const ComparisonTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ComparisonTableRow = styled.div<ComparisonTableRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  border-top: 1px solid var(--gray-200);
  padding: 0.5rem 0;

  ${({ $indent = false }) =>
    $indent &&
    `
    padding-left: 1rem;
  `}

  ${({ $last = false }) =>
    $last &&
    `
    padding-bottom: 0;
  `}
`;

interface ComparisonValueProps {
  $bolded?: boolean;
}

export const ComparisonValue = styled.div<ComparisonValueProps>`
  font-weight: 400;
  text-align: right;

  ${({ $bolded = false }) =>
    $bolded &&
    `
  font-weight: 500;
  color: var(--gray-800);
  `}
`;

export const TooltipContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);
  max-width: 18.75rem;
  min-width: 18.75rem;
`;

export const TooltipTitle = styled.div`
  ${TEXT_STYLES.tooltipTitle}
  color: var(--gray-800);
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
`;
