import DownloadIcon from '@material-symbols/svg-400/rounded/download.svg?react';
import Button from '@/components/Buttons/Button';
import useBreakpointRange from '@/hooks/useBreakpointRange';
import type { CourseCollectionCompletion } from '@/pages/Dashboard/utils';

import * as S from './styles';

interface Props {
  collection: CourseCollectionCompletion['collection'];
}

const CollectionActionButton = ({ collection }: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const hasCertificate = collection.certificates.length > 0;
  const hasNotBeenEvaluated = collection.isAccredited && collection.canBeEvaluated;
  const hasBeenEvaluated = collection.isAccredited && collection.evaluation !== null;

  if (hasNotBeenEvaluated) {
    return (
      <Button
        fullWidth
        to={`/dashboard/education/collections/${collection.id}/accreditation-survey`}
      >
        Claim CE/CME credit
      </Button>
    );
  }

  if (hasBeenEvaluated && !hasCertificate) {
    return (
      <Button
        fullWidth
        to={`/dashboard/education/collections/${collection.id}/accreditation-certificate`}
        trailingIcon={DownloadIcon}
      >
        Download
      </Button>
    );
  }

  if (hasBeenEvaluated && hasCertificate) {
    return (
      <Button
        fullWidth
        href={`${import.meta.env.VITE_BASE_PATH}${collection.certificates[0]?.file.url}`}
        target="_blank"
        trailingIcon={DownloadIcon}
      >
        Download
      </Button>
    );
  }

  return (
    <S.NotAvailableText>
      {isInMobileBreakpointRange ? 'CE/CME certificate not available.' : 'Not available'}
    </S.NotAvailableText>
  );
};

export default CollectionActionButton;
