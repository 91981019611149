import CheckIcon from '@material-symbols/svg-400/rounded/check_circle-fill.svg?react';
import UnCheckIcon from '@material-symbols/svg-400/rounded/circle.svg?react';
import styled from 'styled-components';

export const CheckedIcon = styled(CheckIcon)`
  width: 1rem;
  height: 1rem;
  fill: var(--success-500);
`;

export const UnCheckedIcon = styled(UnCheckIcon)`
  width: 1rem;
  height: 1rem;
  fill: var(--gray-500);
`;
