import { useCallback, useEffect, useRef, useState } from 'react';
import type { AriaCheckboxGroupProps, AriaCheckboxProps, AriaSearchFieldProps } from 'react-aria';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CourseCard from '@/components/Education/CourseCard';
import EducationFilters from '@/components/Education/EducationFilters';
import GroupCheckbox from '@/components/FormFields/CheckboxGroup/GroupCheckbox';
import PageTitle from '@/components/PageTitle';
import ObservedTarget from '@/pages/components/ObservedTarget';
import { COMMUNITIES } from '@/pages/constants';

import CallToActionLink from '../../../../components/CallToActionLink';
import useBreakpointRange from '../../../../hooks/useBreakpointRange';
import useFilterAnalytics from '../../hooks/useFilterAnalytics';
import useGetCourse from '../../hooks/useGetCourse';
import useGetEducationalResources, {
  INITIAL_NUMBER_OF_LISTED_EDUCATIONAL_RESOURCES,
  MAX_NUMBER_OF_EDUCATIONAL_RESOURCES_TO_FETCH_NEXT
} from '../../hooks/useGetEducationalResources';
import type { EducationalResource } from '../../utils';
import EmptyState from '../components/EmptyState';
import LoadingState from '../components/LoadingState';
import Main from '../components/Main';

import * as S from './styles';

const educationTypes = ['Courses', 'Guides', 'Case vignettes', 'Other'];

const AllEducation = () => {
  const { handleFilterAddedAnalytics } = useFilterAnalytics();
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();
  const navigate = useNavigate();

  const { courseId } = useParams<{ courseId: string }>();
  const { course } = useGetCourse(courseId);
  const [courseList, setCourseList] = useState<EducationalResource[]>([]);

  const isFirstRender = useRef(true);
  const numberOfListedEducationalResourcesRef = useRef(
    INITIAL_NUMBER_OF_LISTED_EDUCATIONAL_RESOURCES
  );
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  /*
    TODO: Refactor to use useSearchParams hook
    to match our other filtering pages
  */
  const [searchParams] = useSearchParams();

  const [skipNavigateParams, setSkipNavigateParams] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [searchText, setSearchText] = useState(
    searchParams.get('search') !== null ? searchParams.get('search')! : ''
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') !== null ? searchParams.get('search')! : ''
  );
  const [selectedCommunities, setSelectedCommunities] = useState<string[]>(
    searchParams.get('community') !== null ? searchParams.get('community')!.split(',') : []
  );
  const [selectedEducationTypes, setSelectedEducationTypes] = useState<string[]>(
    searchParams.get('education_type') !== null
      ? searchParams.get('education_type')!.split(',')
      : []
  );
  const [viewBookmarksIsSelected, setViewBookmarksIsSelected] = useState(
    searchParams.get('bookmarks') !== null ? Boolean(searchParams.get('bookmarks')) : false
  );
  const [viewAssignedEducationIsSelected, setViewAssignedEducationIsSelected] = useState(
    searchParams.get('assigned') !== null ? Boolean(searchParams.get('assigned')) : false
  );
  const [viewNewEducationIsSelected, setViewNewEducationIsSelected] = useState(
    searchParams.get('is_new') !== null ? Boolean(searchParams.get('is_new')) : false
  );
  const [viewPopularEducationIsSelected, setViewPopularEducationIsSelected] = useState(
    searchParams.get('is_trending') !== null ? Boolean(searchParams.get('is_trending')) : false
  );

  const {
    educationalResources,
    hasFetchedAll,
    isFetching,
    isFetchingMore,
    refetchEducationalResources
  } = useGetEducationalResources({
    isNew: viewNewEducationIsSelected,
    isPopular: viewPopularEducationIsSelected,
    searchValue: searchValue === '' ? undefined : searchValue,
    selectedCommunities: selectedCommunities.length === 0 ? undefined : selectedCommunities,
    selectedEducationTypes:
      selectedEducationTypes.length === 0 ? undefined : selectedEducationTypes,
    viewAssignedEducationIsSelected,
    viewBookmarksIsSelected
  });

  const handleSearchValueChange: AriaSearchFieldProps['onChange'] = value => {
    setSearchText(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchValue(value);
    }, 500);
  };

  const handleSelectedCommunitiesChange: AriaCheckboxGroupProps['onChange'] = value => {
    const isAdded = value.length > selectedCommunities.length;
    if (isAdded) {
      handleFilterAddedAnalytics(
        'All education',
        'Community',
        value.filter(community => !selectedCommunities.includes(community))
      );
    }

    setSelectedCommunities([
      ...(value.includes(COMMUNITIES[0]) ? [COMMUNITIES[0]] : []),
      ...(value.includes(COMMUNITIES[1]) ? [COMMUNITIES[1]] : []),
      ...(value.includes(COMMUNITIES[2]) ? [COMMUNITIES[2]] : [])
    ]);
  };

  const handleSelectedEducationTypesChange: AriaCheckboxGroupProps['onChange'] = value => {
    const isAdded = value.length > selectedEducationTypes.length;
    if (isAdded) {
      handleFilterAddedAnalytics(
        'All education',
        'Education type',
        value.filter(educationType => !selectedEducationTypes.includes(educationType))
      );
    }

    setSelectedEducationTypes([
      ...(value.includes(educationTypes[0]) ? [educationTypes[0]] : []),
      ...(value.includes(educationTypes[1]) ? [educationTypes[1]] : []),
      ...(value.includes(educationTypes[2]) ? [educationTypes[2]] : []),
      ...(value.includes(educationTypes[3]) ? [educationTypes[3]] : []),
      ...(value.includes(educationTypes[4]) ? [educationTypes[4]] : [])
    ]);
  };

  useEffect(() => {
    /*
      skip updating URL if values are being set from back button
      or if the user is viewing a course (/all-education/:courseId)
    */
    if (
      skipNavigateParams ||
      window.location.pathname.match(new RegExp('/all-education/([^/]+)'))
    ) {
      setSkipNavigateParams(false);
      return;
    }

    let params = '';
    if (searchValue !== '') {
      params += `?search=${searchValue}`;
    }
    if (selectedCommunities.length > 0) {
      params += `${params.length > 0 ? '&' : '?'}community=${selectedCommunities.join(',')}`;
    }
    if (selectedEducationTypes.length > 0) {
      params += `${params.length > 0 ? '&' : '?'}education_type=${selectedEducationTypes.join(
        ','
      )}`;
    }
    if (viewBookmarksIsSelected) {
      params += `${params.length > 0 ? '&' : '?'}bookmarks=true`;
    }
    if (viewAssignedEducationIsSelected) {
      params += `${params.length > 0 ? '&' : '?'}assigned=true`;
    }
    if (viewNewEducationIsSelected) {
      params += `${params.length > 0 ? '&' : '?'}is_new=true`;
    }
    if (viewPopularEducationIsSelected) {
      params += `${params.length > 0 ? '&' : '?'}is_trending=true`;
    }
    navigate(`/dashboard/education/all-education${params}`);
    // don't want skipNavigateParams change to retrigger
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    selectedCommunities,
    selectedEducationTypes,
    searchValue,
    viewBookmarksIsSelected,
    viewAssignedEducationIsSelected,
    viewNewEducationIsSelected,
    viewPopularEducationIsSelected
  ]);

  useEffect(() => {
    setSearchText(searchParams.get('search') ?? '');
    setSearchValue(searchParams.get('search') ?? '');
    setSelectedCommunities(searchParams.get('community')?.split(',') ?? []);
    setSelectedEducationTypes(searchParams.get('education_type')?.split(',') ?? []);
    setViewBookmarksIsSelected(Boolean(searchParams.get('bookmarks')));
    setViewAssignedEducationIsSelected(Boolean(searchParams.get('assigned')));
    setViewNewEducationIsSelected(Boolean(searchParams.get('is_new')));
    setViewPopularEducationIsSelected(Boolean(searchParams.get('is_trending')));
    setSkipNavigateParams(true);
  }, [searchParams]);

  useEffect(() => {
    if (!isRefetching) return;

    window.scrollTo({ top: 0 });
  }, [isRefetching]);

  const refetch = useCallback(
    (skipSettingIsFetching: boolean) => {
      refetchEducationalResources(
        {
          isNew: viewNewEducationIsSelected,
          isPopular: viewPopularEducationIsSelected,
          searchValue: searchValue === '' ? undefined : searchValue,
          selectedCommunities: selectedCommunities.length === 0 ? undefined : selectedCommunities,
          selectedEducationTypes:
            selectedEducationTypes.length === 0 ? undefined : selectedEducationTypes,
          viewAssignedEducationIsSelected,
          viewBookmarksIsSelected
        },
        skipSettingIsFetching,
        numberOfListedEducationalResourcesRef.current
      );
    },
    [
      refetchEducationalResources,
      searchValue,
      selectedCommunities,
      selectedEducationTypes,
      viewBookmarksIsSelected,
      viewAssignedEducationIsSelected,
      viewNewEducationIsSelected,
      viewPopularEducationIsSelected
    ]
  );

  useEffect(() => {
    if (isFirstRender.current) return;

    (() => {
      setIsRefetching(true);

      numberOfListedEducationalResourcesRef.current =
        INITIAL_NUMBER_OF_LISTED_EDUCATIONAL_RESOURCES;

      refetch(false);

      setIsRefetching(false);
    })();
  }, [refetch]);

  useEffect(() => {
    const currentLoadedCourses = educationalResources.map(course => course.id);
    if (course && !currentLoadedCourses.includes(course.id)) {
      setCourseList([...educationalResources, course]);
    } else {
      setCourseList(educationalResources);
    }
  }, [educationalResources, course]);

  const fetchMoreEducationalResources = !hasFetchedAll && !isFetchingMore && isIntersecting;

  useEffect(() => {
    (() => {
      if (fetchMoreEducationalResources) {
        numberOfListedEducationalResourcesRef.current +=
          MAX_NUMBER_OF_EDUCATIONAL_RESOURCES_TO_FETCH_NEXT;

        refetch(true);
      }
    })();
  }, [fetchMoreEducationalResources, refetch]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const handleViewBookmarksChange: AriaCheckboxProps['onChange'] = isSelected => {
    if (isSelected) {
      handleFilterAddedAnalytics('All education', 'Saved education', 'Bookmarks');
    }
    setViewBookmarksIsSelected(isSelected);
  };

  const handleViewAssignedEducationChange: AriaCheckboxProps['onChange'] = isSelected => {
    if (isSelected) {
      handleFilterAddedAnalytics('All education', 'Saved education', 'Assigned courses');
    }
    setViewAssignedEducationIsSelected(isSelected);
  };

  const handleNewEducationChange: AriaCheckboxProps['onChange'] = isSelected => {
    if (isSelected) {
      handleFilterAddedAnalytics('All education', 'Recommendations', 'New');
    }
    setViewNewEducationIsSelected(isSelected);
  };

  const handlePopularEducationChange: AriaCheckboxProps['onChange'] = isSelected => {
    if (isSelected) {
      handleFilterAddedAnalytics('All education', 'Recommendations', 'Trending');
    }
    setViewPopularEducationIsSelected(isSelected);
  };

  const filters = (
    <>
      <S.Filters.SectionLabel>For you</S.Filters.SectionLabel>
      <S.Filters.SingleCheckbox
        data-cy="assigned-education-checkbox"
        isSelected={viewAssignedEducationIsSelected}
        onChange={handleViewAssignedEducationChange}
      >
        Assigned
      </S.Filters.SingleCheckbox>
      <S.Filters.SingleCheckbox
        data-cy="popular-education-checkbox"
        isSelected={viewPopularEducationIsSelected}
        onChange={handlePopularEducationChange}
      >
        Trending
      </S.Filters.SingleCheckbox>
      <S.Filters.SingleCheckbox
        data-cy="new-education-checkbox"
        isSelected={viewNewEducationIsSelected}
        onChange={handleNewEducationChange}
      >
        New
      </S.Filters.SingleCheckbox>
      <S.Filters.SingleCheckbox
        data-cy="bookmarks-checkbox"
        isSelected={viewBookmarksIsSelected}
        onChange={handleViewBookmarksChange}
      >
        Bookmarks
      </S.Filters.SingleCheckbox>
      <S.Filters.Separator />
      <S.Filters.CheckboxGroup
        direction="vertical"
        label="Community"
        size="small"
        value={selectedCommunities}
        onChange={handleSelectedCommunitiesChange}
      >
        {COMMUNITIES.map(community => (
          <GroupCheckbox
            key={community}
            data-cy={`community-${community}`}
            value={community}
          >
            {community}
          </GroupCheckbox>
        ))}
      </S.Filters.CheckboxGroup>
      <S.Filters.Separator />
      <S.Filters.CheckboxGroup
        label="Education type"
        size="small"
        value={selectedEducationTypes}
        onChange={handleSelectedEducationTypesChange}
      >
        {educationTypes.map(educationType => (
          <GroupCheckbox
            key={educationType}
            data-cy={`education-type-${educationType.replace(' ', '-')}`}
            value={educationType}
          >
            {educationType}
          </GroupCheckbox>
        ))}
      </S.Filters.CheckboxGroup>
    </>
  );

  const educationTitle = (
    <PageTitle
      description="This section includes Violet collections as well as curated 3rd-party education.
      Use the search and filtering options on the left to find courses on specific topics."
      title="All education"
      titleVariant="h1"
    />
  );

  return (
    <Main>
      {isInMobileBreakpointRange && educationTitle}
      <div>
        <EducationFilters
          filters={filters}
          handleSearchValueChange={handleSearchValueChange}
          searchTextValue={searchText}
        />
        <CallToActionLink
          caption="Explore our curated curriculum here."
          title="Don't know where to start?"
          to="/dashboard/education/collections"
        />
      </div>
      {isFetching ? (
        <LoadingState />
      ) : educationalResources.length === 0 && courseList.length === 0 ? (
        <EmptyState />
      ) : (
        <div>
          {isInDesktopBreakpointRange && educationTitle}
          <S.EducationalResources>
            {courseList.map(educationalResource => (
              <CourseCard
                key={educationalResource.id}
                course={educationalResource}
                refetchEducationalResources={(skipSettingIsFetching = false) => {
                  refetchEducationalResources(
                    {
                      searchValue: searchValue === '' ? undefined : searchValue,
                      selectedCommunities:
                        selectedCommunities.length === 0 ? undefined : selectedCommunities,
                      selectedEducationTypes:
                        selectedEducationTypes.length === 0 ? undefined : selectedEducationTypes,
                      viewAssignedEducationIsSelected,
                      viewBookmarksIsSelected
                    },
                    skipSettingIsFetching,
                    numberOfListedEducationalResourcesRef.current
                  );
                }}
              />
            ))}
          </S.EducationalResources>
          {!hasFetchedAll && (
            <S.EducationalResourcesLoadingMoreState.Root>
              {isFetchingMore && <S.EducationalResourcesLoadingMoreState.Spinner />}
            </S.EducationalResourcesLoadingMoreState.Root>
          )}
          <ObservedTarget setIsIntersecting={setIsIntersecting} />
        </div>
      )}
    </Main>
  );
};

export default AllEducation;
