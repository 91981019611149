import type { ReactElement, ReactNode } from 'react';
import { Children } from 'react';
import { BREAKPOINT } from '@/constants';

import BodyRow from './BodyRow';
import * as S from './styles';

interface Props {
  'aria-label'?: string;
  autoPopulatedRowIndices?: number[];
  centeredColumnIndices?: number[];
  children: ReactElement[];
  className?: string;
  emptyMessage?: string;
  expandedRowIndex: number | null;
  hasAttemptedSubmit?: boolean;
  invalidRowIndices: number[];
}

const ExpandableEditableTable = ({
  'aria-label': ariaLabel,
  autoPopulatedRowIndices = [],
  centeredColumnIndices = [],
  children,
  className,
  emptyMessage = 'No data available.',
  expandedRowIndex,
  hasAttemptedSubmit = false,
  invalidRowIndices
}: Props) => {
  const headerRows = children.find(child => child.type === 'thead');
  const bodyRows = children.find(child => child.type === 'tbody');
  const columnCount =
    headerRows !== undefined ? (headerRows.props.children.props.children.length as number) : 1;

  return window.innerWidth <= BREAKPOINT ? (
    <S.ExpandableEditableCard className={className}>
      <S.BodyMobile role="rowgroup">
        {bodyRows === undefined ||
        bodyRows.props.children === null ||
        bodyRows.props.children.length === 0 ? (
          <S.EmptyCard>{emptyMessage}</S.EmptyCard>
        ) : (
          Children.map(bodyRows.props.children, (row: ReactNode, index) => (
            <S.CardRow
              key={(row as ReactElement).key}
              $isAutoPopulated={autoPopulatedRowIndices.includes(index)}
              $isExpanded={expandedRowIndex === index}
              $isInvalid={hasAttemptedSubmit && invalidRowIndices.includes(index)}
              data-cy={(row as JSX.Element).props['data-cy'] as string}
              role="row"
            >
              {row as ReactElement}
            </S.CardRow>
          ))
        )}
      </S.BodyMobile>
    </S.ExpandableEditableCard>
  ) : (
    <S.ExpandableEditableTable
      aria-label={ariaLabel}
      className={className}
    >
      <S.Header role="rowgroup">
        {headerRows !== undefined &&
          Children.map(headerRows.props.children, (headerRow: ReactNode) => (
            <S.HeaderRow
              key={(headerRow as ReactElement).key}
              role="row"
            >
              {Children.map(
                (headerRow as ReactElement).props.children,
                (column: ReactNode, index) => (
                  <S.HeaderCell
                    key={(column as ReactElement).key}
                    $center={centeredColumnIndices.includes(index)}
                    role="columnheader"
                  >
                    {(column as ReactElement).props.children}
                  </S.HeaderCell>
                )
              )}
            </S.HeaderRow>
          ))}
      </S.Header>
      <S.Body role="rowgroup">
        {bodyRows === undefined ||
        bodyRows.props.children === null ||
        Children.count(bodyRows.props.children) === 0 ? (
          <S.EmptyState.BodyRow
            className="empty-row"
            data-cy="empty-row"
          >
            <S.EmptyState.BodyCell colSpan={columnCount}>{emptyMessage}</S.EmptyState.BodyCell>
          </S.EmptyState.BodyRow>
        ) : (
          Children.map(bodyRows.props.children, (row: ReactNode, index) => (
            <BodyRow
              key={(row as ReactElement).key}
              dataCy={(row as JSX.Element).props['data-cy'] as string}
              isAutoPopulated={autoPopulatedRowIndices.includes(index)}
              isExpanded={expandedRowIndex === index}
              isInvalid={hasAttemptedSubmit && invalidRowIndices.includes(index)}
              row={row}
            />
          ))
        )}
      </S.Body>
    </S.ExpandableEditableTable>
  );
};

export default ExpandableEditableTable;
