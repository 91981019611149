import BaseCheckCircleIcon from '@material-design-icons/svg/round/check_circle.svg?react';
import styled, { keyframes } from 'styled-components';

import UnstyledButton from '@/components/UnstyledButton';
import { Bar, ProgressBar as BaseProgressBar, Track } from '@/components/UnstyledProgressBar';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';

const benchmarkSize = '2.8125rem';

const shineEffect = keyframes`
    0% {
        top: -120%;
        left: -120%;
    }
    100% {
        left:100%;
        top:100%;
    }
`;

export const Benchmark = styled.img`
  width: calc(${benchmarkSize} - 0.25rem);
  height: calc(${benchmarkSize} - 0.25rem);
`;

export const BenchmarkBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 0.75rem;
  align-items: center;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }
`;

export const BenchmarkImageWrapper = styled.div`
  position: relative;
  width: calc(${benchmarkSize} - 0.25rem);
  height: calc(${benchmarkSize} - 0.25rem);
`;

export const BenchmarkLabel = styled.div`
  position: absolute;
  bottom: 0;
  ${TEXT_STYLES.bodyCopySmall}
  text-align: center;
  color: var(--gray-600);
  translate: calc(-50% + ${benchmarkSize} / 2) calc(100% + 0.3125rem);
`;

export const BenchmarkShineEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 2;

  &::before {
    content: '';
    z-index: 2;
    position: absolute;
    height: 200%;
    width: 200%;
    top: -120%;
    left: -120%;
    background: linear-gradient(
      transparent 0%,
      rgba(255, 255, 255, 0.1) 45%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.1) 55%,
      transparent 100%
    );
    transition: all 2s;
    transform: rotate(-45deg);
    animation: ${shineEffect} 2s infinite forwards;
  }
`;

export const BenchmarkWrapper = styled(UnstyledButton)`
  position: relative;
  right: calc(-1 * ${benchmarkSize} / 1.9);
  display: grid;
  place-content: center;
  width: ${benchmarkSize};
  height: ${benchmarkSize};
  background-color: white;
  border-radius: 100%;
  filter: drop-shadow(0 6.667px 10 rgba(16, 24, 40, 0.03)) drop-shadow(0 0 10 rgba(16, 24, 40, 0.1));

  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
  }
`;

export const CheckCircleIcon = styled(BaseCheckCircleIcon)`
  fill: var(--success-600);
  width: 1rem;
  height: 1rem;
`;

export const CommunityAcronym = styled.div`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--gray-800);
`;

export const CommunityDescription = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-600);
`;

export const CommunityTextWrapper = styled.div`
  padding: 0 0 0 0.75rem;
`;

export const EarnedAtDate = styled.div`
  display: flex;
  align-items: center;

  ${MOBILE_BREAKPOINT_RANGE} {
    column-gap: var(--space-2);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    column-gap: var(--space-1);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Popover = styled.div`
  max-width: 21rem;
  font-size: 0.8125rem;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: var(--gray-700);
`;

export const PopoverText = styled.p`
  margin-bottom: 0;
`;

export const PopoverTitle = styled.div`
  font-weight: 700;
`;

export const ProgressBar = styled(BaseProgressBar)`
  position: absolute;
  width: calc(100% - ${benchmarkSize});
  padding-right: 0.0625rem;
  border-radius: 999px;
  overflow: hidden;

  ${Bar} {
    height: 1rem;
    background: linear-gradient(90deg, #e03fff 34.73%, #f6b60d 94.56%);
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
  }

  ${Track} {
    background-color: var(--gray-200);
    height: 1rem;
  }
`;

export const ProgressBarContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid var(--gray-200);
  background: var(--gray-50);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem ${benchmarkSize} 2rem 0.75rem;
`;
