import { Temporal } from '@js-temporal/polyfill';
import { TableHeader, TableTitle } from '@/components/Table/styles';
import type { Education } from '@/pages/Dashboard/utils';

import CommunityCheck from '../CommunityCheck';

import * as S from './styles';

interface Props {
  educations: Education[];
}

const MobileCards = ({ educations }: Props) => (
  <S.MobileCards>
    <TableHeader>
      <TableTitle>Course completions</TableTitle>
    </TableHeader>
    {educations.length > 0 ? (
      educations.map(education => (
        <S.Card
          key={education.id}
          data-cy="completed-course"
        >
          <S.CardRow>
            <S.Label>Course Name</S.Label>
            <S.ValueLink to={`/dashboard/education/all-education/${education.courseId}`}>
              {education.courseName}
            </S.ValueLink>
          </S.CardRow>
          <S.CardRow>
            <S.Label>Completed at</S.Label>
            <S.Value>
              {education.completedAt !== null
                ? Temporal.PlainDate.from(education.completedAt).toLocaleString('en-US', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                  })
                : education.year}
            </S.Value>
          </S.CardRow>
          <S.CardRow>
            <S.Label>Credits</S.Label>
            <S.Value>{education.credits}</S.Value>
          </S.CardRow>
          <S.Label className="section-label">Communities of Focus</S.Label>
          <S.CardRowTable>
            <S.CardRowTableColumn>
              <S.Label className="label">BIPOC</S.Label>
              <S.Value>
                <CommunityCheck
                  communities={education.communities}
                  community="bipoc"
                />
              </S.Value>
            </S.CardRowTableColumn>
            <S.CardRowTableColumn>
              <S.Label className="label">LGBQ</S.Label>
              <S.Value>
                <CommunityCheck
                  communities={education.communities}
                  community="lgbq"
                />
              </S.Value>
            </S.CardRowTableColumn>
            <S.CardRowTableColumn>
              <S.Label className="label">TGNC</S.Label>
              <S.Value>
                <CommunityCheck
                  communities={education.communities}
                  community="tgnc"
                />
              </S.Value>
            </S.CardRowTableColumn>
          </S.CardRowTable>
        </S.Card>
      ))
    ) : (
      <S.Card>
        <S.BlankText>No courses have been completed.</S.BlankText>
      </S.Card>
    )}
  </S.MobileCards>
);

export default MobileCards;
