import TagGroup from '@/components/TagGroup';
import styled from 'styled-components';

import { default as BaseAssignedEducation } from '../AssignedEducation';
import { default as BaseBookmark } from '../Bookmark';

export const Assigned = styled(BaseAssignedEducation)`
  margin-right: 0.645rem;
`;

export const Bookmark = styled(BaseBookmark)``;

export const EducationTagGroup = styled(TagGroup)`
  margin-right: auto;
  align-self: start;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
`;
