import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';
import type { OverlayTriggerState } from 'react-stately';

interface ContextValue {
  setSource: Dispatch<SetStateAction<'announcement' | 'dashboard'>>;
  shareBenchmarksModalDialog: OverlayTriggerState;
  source: 'announcement' | 'dashboard';
}

export const ShareBenchmarksContext = createContext<ContextValue>({
  shareBenchmarksModalDialog: {
    close: () => {},
    isOpen: false,
    open: () => {},
    setOpen: () => {},
    toggle: () => {}
  },
  source: 'dashboard',
  setSource: () => {}
});

const useShareBenchmarks = () => useContext(ShareBenchmarksContext);

export default useShareBenchmarks;
