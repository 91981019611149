import type { ToastState } from '@react-stately/toast';
import type { FormEventHandler } from 'react';
import { useMemo, useRef, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import Button from '@/components/Buttons/Button';
import PageTitle from '@/components/PageTitle';
import type { VioletToast } from '@/components/ToastProvider';
import UserEducationForm from '@/pages/components/UserEducationForm';
import {
  EducationsContext,
  revalidateEducationRows
} from '@/pages/components/UserEducationForm/useEducationsContext';

import useGetEducations from '../../hooks/useGetEducations';
import type { Education } from '../../utils';

import * as S from './styles';

const EducationPage = () => {
  const { createEducation, deleteEducation, patchEducation } = useGetEducations({}, true);

  const toastState = useOutletContext();

  const formRef = useRef<HTMLFormElement>(null);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [invalidRowIndices, setInvalidRowIndices] = useState<number[]>([]);
  const [originalEducations, setOriginalEducations] = useState<Education[]>([]);
  const [draftEducations, setDraftEducations] = useState<Education[]>([]);

  const handleFormSubmit: FormEventHandler = event => {
    event.preventDefault();
    const invalidRowIndices = revalidateEducationRows(draftEducations);
    setInvalidRowIndices(invalidRowIndices);

    setHasAttemptedSubmit(true);

    if (invalidRowIndices.length > 0) {
      /* Don't attempt submission if any rows are invalid */
      if (!formRef.current) {
        window.scrollTo(0, 0);
      } else {
        formRef.current.reportValidity();
      }
      return;
    }

    if (formRef.current?.checkValidity() === false) {
      formRef.current.reportValidity();
      return;
    }

    /* Thou may proceed to handle submission */
    setFormIsSubmitting(true);

    let encounteredError = 0;

    /* Save all education entries */
    draftEducations.forEach(async (educationDraft, index) => {
      if (educationDraft.createdAt === '' || educationDraft.createdAt === null) {
        /* No createdAt === create */
        try {
          const newEducation = await createEducation(educationDraft);
          if (newEducation) {
            setDraftEducations(prevState => {
              const newState = [...prevState];
              newState[index] = newEducation;
              return newState;
            });
            setOriginalEducations(prevState => [...prevState, newEducation]);
          }
        } catch (_error) {
          encounteredError++;
        }
      } else if (originalEducations.find(e => e.id === educationDraft.id)) {
        /* Id exists in educations === patch */
        try {
          const changedEducation = await patchEducation(educationDraft);
          if (changedEducation) {
            setDraftEducations(prevState => {
              const newState = [...prevState];
              newState[index] = changedEducation;
              return newState;
            });
            setOriginalEducations(prevState => {
              const newState = [...prevState];
              const originalIndex = prevState.findIndex(e => e.id === changedEducation.id);
              newState[originalIndex] = changedEducation;
              return newState;
            });
          }
        } catch (_error) {
          encounteredError++;
        }
      }
    });

    originalEducations.forEach(async educationDraft => {
      if (!draftEducations.find(e => e.id === educationDraft.id)) {
        /* Id no longer in draftEducations === delete */
        try {
          await deleteEducation(educationDraft.id);
          setOriginalEducations(prevState => prevState.filter(e => e.id !== educationDraft.id));
        } catch (_error) {
          encounteredError++;
        }
      }
    });

    if (encounteredError > 0) {
      setFormIsSubmitting(false);
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );
      return;
    }

    window.scrollTo(0, 0);
    (toastState as ToastState<VioletToast>).add(
      {
        description: 'Your data has been saved.',
        type: 'success'
      },
      { timeout: 8000 }
    );

    setFormIsSubmitting(false);
    setHasAttemptedSubmit(false);
  };

  const memoizedEducationContextValue = useMemo(
    () => ({
      draftEducations,
      invalidRowIndices,
      originalEducations,
      setDraftEducations,
      setInvalidRowIndices,
      setOriginalEducations
    }),
    [originalEducations, draftEducations, invalidRowIndices]
  );

  return (
    <EducationsContext.Provider value={memoizedEducationContextValue}>
      <form
        ref={formRef}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <PageTitle
          description={
            <>
              To provide you with the most accurate Benchmarks we'll need to learn more about any
              non-Violet educational training completed specifically related to BIPOC, LGBQ, and
              TGNC communities. To see your completed Violet education and access CE/CME
              certificates, visit{' '}
              <Link to="/dashboard/my-education/completions-and-certificates">My education</Link>.
            </>
          }
          title="Education history"
          titleVariant="h1"
        />
        <UserEducationForm hasAttemptedSubmit={hasAttemptedSubmit} />
        <S.ButtonWrapper
          align="right"
          size="large"
        >
          <Button
            data-cy="save-button"
            isLoading={formIsSubmitting}
            size="large"
            type="submit"
          >
            Save
          </Button>
        </S.ButtonWrapper>
      </form>
    </EducationsContext.Provider>
  );
};

export default EducationPage;
