import { Outlet, useLocation } from 'react-router-dom';
import SecondaryNavigation from '@/components/SecondaryNavigation';

interface Props {
  isHidden?: boolean;
}

const Wrapper = ({ isHidden = false }: Props) => {
  const location = useLocation();

  if (isHidden) return <Outlet />;

  const educationLinks = [
    {
      children: [
        { link: '/collections', title: 'Collections' },
        { link: '/pathways', title: 'Pathways' },
        { link: '/all-education', title: 'All education' }
      ],
      link: '/dashboard/education/collections',
      parentLink: '/dashboard/education',
      title: 'Catalog'
    },
    {
      children: [
        { link: '/in-progress', title: 'In-progress' },
        { link: '/completions-and-certificates', title: 'Completions & certificates' }
      ],
      link: '/dashboard/my-education/in-progress',
      parentLink: '/dashboard/my-education',
      title: 'My education'
    }
  ];

  return (
    <>
      <SecondaryNavigation
        linkList={educationLinks}
        navTitle="Education"
      />
      <div key={location.pathname}>
        <Outlet />
      </div>
    </>
  );
};

export default Wrapper;
