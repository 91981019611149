import TextField from '@/components/FormFields/TextField';
import { sharedInputTextAreaStyles } from '@/components/FormFields/TextField/styles';
import * as S from '@/components/FormFields/TextField/styles';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const TableTextField = styled(TextField)`
  & ${S.Input} {
    all: unset;
    box-sizing: border-box;
    text-overflow: ellipsis;
    border-radius: 0.375rem;
    color: var(--gray-700);
    width: 100%;

    &:read-only {
      width: auto;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }

    ${MOBILE_BREAKPOINT_RANGE} {
      font-weight: 100;
      height: 3.25rem;

      &:not(:read-only) {
        padding: calc(0.75rem - 1px) calc(1rem - 1px);
        background-color: var(--white-light);
        border: 1px solid var(--gray-5);
      }

      &:not(:read-only):focus {
        padding: calc(0.75rem - 2px) calc(1rem - 2px);
        border-width: 2px;
        border-color: var(--brand-bright);
      }
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      height: 2.25rem;
      padding-right: calc(0.75rem - 1px);
      padding-left: calc(0.75rem - 1px);

      &:not(:read-only) {
        border: 1px solid var(--gray-5);
        background-color: var(--white-light);
      }
    }
  }

  &.is-invalid,
  &.is-invalid:not(:read-only) {
    border-color: var(--error-500);

    &:not(:read-only):focus {
      padding-right: calc(0.75rem - 2px);
      padding-left: calc(0.75rem - 2px);
      border-width: 2px;
    }

    &::placeholder {
      color: var(--error-500);
    }

    & ${S.Label} {
      display: inline-flex;
      align-items: center;
      column-gap: 0.13rem;
      ${TEXT_STYLES.formsLabelMediumSmallCaps}
      color: var(--purple-600);
      padding-bottom: var(--space-2);
    }

    & ${S.TextArea} {
      ${sharedInputTextAreaStyles}
      min-height: 7rem;
      height: 100%;
      padding: calc(0.75rem - 1px) calc(1rem - 1px);
      max-width: 100%;
      resize: none;

      &:focus {
        padding: calc(0.75rem - 2px) calc(1rem - 2px);
      }
    }
  }
`;
