import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Link, useOutletContext } from 'react-router-dom';
import type { VioletToast } from '@/components/ToastProvider';
import type { ToastState } from '@react-stately/toast';
import ClipboardIcon from '@material-symbols/svg-400/rounded/content_copy.svg?react';
import DOMPurify from 'dompurify';
import * as S from './styles';
import Button from '@/components/Buttons/Button';
import type { Benchmark } from '../..';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import useUser from '@/hooks/useUser';
import Spinner from '@/components/Spinner';
import useGetFaqLinks from '@/utils/getFaqLinks';

interface Props {
  handleDoneSharing: () => void;
  selectedBenchmarks: Benchmark[];
  setHasShared: Dispatch<SetStateAction<boolean>>;
  toastState?: ToastState<VioletToast>;
}

const CopyBenchmarkCode = ({
  handleDoneSharing,
  selectedBenchmarks,
  setHasShared,
  toastState
}: Props) => {
  const contextToastState = useOutletContext<ToastState<VioletToast>>();
  const [codeBlock, setCodeBlock] = useState('');
  const { bearerToken, user } = useUser();
  const FaqLinks = useGetFaqLinks();

  const activeToastState = toastState || contextToastState;

  useEffect(() => {
    if (!user.id || selectedBenchmarks.length === 0) return;

    const fetchHtmlAsText = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_BASE_PATH}/users/${user.id}/benchmark_html?code=true&communities=${selectedBenchmarks.map(b => b.score_type).join(',')}`,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`
            }
          }
        );
        const html = await response.text();
        setCodeBlock(DOMPurify.sanitize(html));
      } catch (_error) {
        setCodeBlock('');
        activeToastState.add(
          {
            description: 'Failed to load benchmark code. Please try again.',
            type: 'error'
          },
          { timeout: 3000 }
        );
      }
    };
    fetchHtmlAsText();
  }, [user.id, selectedBenchmarks, activeToastState, bearerToken]);

  const copyToClipboard = (showToast = true) => {
    navigator.clipboard.writeText(codeBlock);
    if (showToast) {
      activeToastState.add(
        {
          description: 'Copied to clipboard.',
          type: 'success'
        },
        { timeout: 1000 }
      );
    }
  };

  const handleCopyAndGoToSuccess = () => {
    copyToClipboard(false);
    handleDoneSharing();
  };

  return (
    <>
      <S.PreviewBlock>
        <S.Text>
          Copy the HTML code below and paste it into your website builder to display your Verified
          Inclusive Provider badge{selectedBenchmarks.length > 1 ? 's' : ''}. Need help? Check out{' '}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to={FaqLinks.sharingBenchmarks}
          >
            this guide
          </Link>{' '}
          for step-by-step instructions.
        </S.Text>
        {codeBlock ? (
          <>
            <S.CodeBlockContainer>
              <S.CodeBlock
                data-cy="benchmark-code-block"
                language="javascript"
                style={vs}
                wrapLines={true}
                wrapLongLines={true}
              >
                {codeBlock}
              </S.CodeBlock>
              <S.ClipboardButton
                data-cy="copy-code-block-button"
                onPress={() => copyToClipboard(true)}
              >
                <ClipboardIcon />
              </S.ClipboardButton>
            </S.CodeBlockContainer>
            <S.PreviewText>Here’s a preview:</S.PreviewText>
            <S.PreviewContainer
              dangerouslySetInnerHTML={{ __html: codeBlock }}
              data-cy="benchmark-code-block-preview"
            />
          </>
        ) : (
          <Spinner withWrapper />
        )}
      </S.PreviewBlock>
      <S.ButtonGroup>
        <Button
          data-cy="back-button"
          size="large"
          variant="outline"
          onPress={() => setHasShared(false)}
        >
          Back
        </Button>
        <Button
          data-cy="copy-embed-code-button"
          leadingIcon={ClipboardIcon}
          size="large"
          onPress={handleCopyAndGoToSuccess}
        >
          Copy embed code
        </Button>
      </S.ButtonGroup>
    </>
  );
};

export default CopyBenchmarkCode;
