import { Temporal } from '@js-temporal/polyfill';
import OrganizationIcon from '@material-design-icons/svg/round/apartment.svg?react';
import type { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { useOutletContext } from 'react-router-dom';
import Button from '@/components/Buttons/Button';
import Label from '@/components/Label';
import PageTitle from '@/components/PageTitle';
import CommunityGraphs from '@/components/Reporting/CommunityGraphs';
import DataCard from '@/components/Reporting/DataCard';
import DataCardHeader from '@/components/Reporting/DataCardHeader';
import DataHighlight from '@/components/Reporting/DataHighlight';
import DataReportRow from '@/components/Reporting/DataReportRow';
import Spinner from '@/components/Spinner';
import type { VioletToast } from '@/components/ToastProvider';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import useUser from '@/hooks/useUser';
import { NETWORK_INCLUSIVITY_URL_W_PARAMS } from '@/pages/Dashboard/MyNetwork/Wrapper';
import { downloadCsv } from '@/utils/downloadCsv';

import useGetNetworkLocations from '../hooks/useGetNetworkLocations';
import useGetNetworkOrgMembers from '../hooks/useGetNetworkOrgMembers';
import useGetNetworkOverview from '../hooks/useGetNetworkOverview';
import type { ServiceAreaComparisonResponse } from '../hooks/useGetNetworkServiceAreaComparisons';
import useGetNetworkServiceAreaComparisons from '../hooks/useGetNetworkServiceAreaComparisons';

import CommunityInclusivityComparisons from './Components/CommunityInclusivityComparisons';
import MiniProviderOrganizationsTable from './Inclusivity/Tables/MiniProviderOrganizationsTable';
import MiniServiceAreasTable from './Inclusivity/Tables/MiniServiceAreasTable';
import * as S from './styles';
import useNetworks from './Wrapper/useNetworks';

const MyNetwork = () => {
  // SEARCH PARAMS: If you need to implement search params,
  // please use "useSearchParams"
  const { defaultComparisonStates } = useNetworks();
  const isComparativeDataEnabled = useFeatureFlagEnabled('comparative_network_data');

  const { bearerToken, user } = useUser();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const organizationName = user.organization_memberships.find(
    membership => membership.member_role === 'network_manager'
  )?.organization.name;
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };
  const toastState = useOutletContext();
  const [isExporting, setIsExporting] = useState(false);

  const { isFetching: isFetchingOverview, networkOverview } = useGetNetworkOverview();
  const {
    isFetching: isFetchingLocation,
    networkLocations,
    updateNetworkLocationFilters
  } = useGetNetworkLocations({
    order_by: {
      column: 'provider_count',
      dir: 'desc'
    },
    page: 1,
    per_page: 5,
    view: 'county'
  });
  const { isFetching: isFetchingNetworkOrgMembers, networkOrgMembers } = useGetNetworkOrgMembers({
    order_by: {
      column: 'total_providers',
      dir: 'desc'
    },
    page: 1,
    per_page: 5
  });
  const {
    isFetching: isFetchingServiceAreaComparisons,
    serviceAreaComparisons,
    serviceAreaComparisonsMeta,
    singleFetchServiceAreaComparisons,
    updateServiceAreaComparisonsFilters
  } = useGetNetworkServiceAreaComparisons({
    locations: defaultComparisonStates
  });

  const compactNumFormatter = useNumberFormatter({ notation: 'compact' });

  const handleExportVIOButtonClick = async () => {
    setIsExporting(true);

    const downloadingToastKey = (toastState as ToastState<VioletToast>).add({
      cancelable: false,
      customIcon: (
        <S.ToastSpinner
          backgroundColor="#d1e9ff"
          color="#1570ef"
        />
      ),
      description: 'Your report is downloading.',
      type: 'info'
    });

    const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/networks/organization_members/export`;

    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { organization } = user.organization_memberships.find(
        membership => membership.member_role === 'network_manager'
      )!;

      const formattedDate = Temporal.Now.plainDateISO()
        .toLocaleString('en-US')
        .replace(/[/]/g, '-');
      const organizationName = organization.name.replace(/\s/g, '');

      await downloadCsv(
        response,
        `Violet_${organizationName}_Verified-Inclusive-Organization-Report_${formattedDate}.csv`
      );

      (toastState as ToastState<VioletToast>).close(downloadingToastKey);
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Download complete.',
          type: 'success'
        },
        { timeout: 8000 }
      );
    } catch (error) {
      Sentry.captureException(error);
      (toastState as ToastState<VioletToast>).close(downloadingToastKey);
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Download failed.',
          type: 'error'
        },
        { timeout: 8000 }
      );
    }
    setIsExporting(false);
  };

  const [locationComparisons, setLocationComparisons] = useState<
    Record<string, ServiceAreaComparisonResponse>
  >({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchLocationComparisons = async () => {
      if (!networkLocations || isFetchingLocation) return;

      const comparisons: Record<string, ServiceAreaComparisonResponse> = {};
      for (const location of networkLocations) {
        try {
          const result = await singleFetchServiceAreaComparisons(
            {
              locations: [
                {
                  location_name: location.name ?? '',
                  location_type: 'county' as const,
                  state_code: location.state
                }
              ],
              scope: ['county']
            },
            signal
          );
          comparisons[`${location.state}-${location.name}`] = {
            data: result.data,
            meta: result.meta
          };
        } catch (error) {
          if (error instanceof Error && error.name === 'AbortError') {
            return;
          }
          comparisons[`${location.state}-${location.name}`] = {
            data: [],
            meta: undefined
          };
        }
      }
      setLocationComparisons(comparisons);
    };

    fetchLocationComparisons();

    return () => {
      controller.abort();
    };
  }, [networkLocations, isFetchingLocation, singleFetchServiceAreaComparisons]);

  useEffect(() => {
    updateNetworkLocationFilters({
      order_by: {
        column: 'provider_count',
        dir: 'desc'
      },
      page: 1,
      per_page: 5,
      view: 'county'
    });
  }, [updateNetworkLocationFilters]);

  useEffect(() => {
    updateServiceAreaComparisonsFilters({
      locations: defaultComparisonStates
    });
  }, [defaultComparisonStates, updateServiceAreaComparisonsFilters]);

  return (
    <>
      <PageTitle
        actionButton={
          <Button
            data-cy="export-vio-button"
            isDisabled={isExporting}
            isLoading={isExporting}
            onPress={handleExportVIOButtonClick}
          >
            Export
          </Button>
        }
        data-cy="network-overview-title-container"
        description={
          isComparativeDataEnabled === true
            ? 'Get a comprehensive view of inclusivity and diversity within your network. These insights reflect the cultural competencies and demographics of providers, powered by Violet’s proprietary benchmarking framework and self-reported provider data. Hover over specific elements for definitions and additional context.'
            : "Inclusivity and diversity insights are based on the overall cultural competencies and demographics of providers within the network. Violet generates these insights using a proprietary benchmarking framework, in conjunction with providers' self-reported identity disclosures."
        }
        labelComponent={
          <Label
            data-cy="network-label"
            icon={OrganizationIcon}
          >
            {organizationName ?? ''}
          </Label>
        }
        title="Overview"
        titleVariant="h1"
      />
      {isFetchingOverview ? (
        <Spinner withWrapper />
      ) : (
        <S.DataWrapper data-cy="network-overview">
          <S.NetworkMetricRow layout="fifths">
            <DataHighlight
              data-cy="total-network-organizations"
              detailText="Provider organizations"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalNetworkOrganizations ?? 0
              )}
              popoverContent="Total number of provider organizations linked to your network, based on the roster provided."
              variant="small"
            />
            <DataHighlight
              data-cy="total-network-verified-organizations"
              detailText="Verified Inclusive Organizations"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalVioNetworkOrganizations ?? 0
              )}
              popoverContent={
                <>
                  <p>
                    Total number of organizations within your network that have an active partership
                    with Violet and meet the following criteria for verification:
                  </p>
                  <ul>
                    <li>
                      At least 30% of a provider's clinical staff (including licensed professionals,
                      pre-licensed individuals, and interns) must onboard with Violet, submitting
                      their profiles to receive Benchmarks.
                    </li>
                    <li>
                      Of those, 40% must achieve an Awareness Benchmark in at least one of the three
                      communities—BIPOC, LGBQ, or TGNC.
                    </li>
                  </ul>
                </>
              }
              variant="small"
            />
            <DataHighlight
              data-cy="total-network-providers"
              detailText="Total providers"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalNetworkProviders ?? 0
              )}
              popoverContent="Total number of clinical providers linked to your network, based on your provided roster or inferred from third-party data. Clinical users include all licensed and pre-licensed providers, as well as interns actively delivering care."
              variant="small"
            />
            <DataHighlight
              data-cy="total-network-providers-benchmarks"
              detailText="Providers with a Benchmark"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
              )}
              popoverContent="Includes all providers who have earned a Violet Benchmark, either through claims data analysis or by completing their profile on Violet to achieve Verified Inclusive Provider status."
              variant="small"
            />
            <DataHighlight
              data-cy="total-active-network-providers"
              detailText="Providers on Violet"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalActiveNetworkProviders ?? 0
              )}
              popoverContent="Total number of providers who have completed their Violet profiles in order to be benchmarked."
              variant="small"
            />
          </S.NetworkMetricRow>
          <DataReportRow layout="fifty-fifty">
            <S.CommunityCard data-cy="benchmarks-community-graphs">
              <S.CustomComparitiveDataCardHeader
                $serviceAreaComparisonsEnabled={isComparativeDataEnabled === true}
                badgeColor="gray"
                badgeText="All providers"
                badgeTooltip="All providers includes both those actively using Violet’s platform and those with Benchmarks inferred through analysis of external data."
                description={
                  isComparativeDataEnabled === true
                    ? 'This chart shows the community competencies of all in-network providers benchmarked by Violet. The comparative bar below highlights how your network’s providers compare to those in the same service areas.'
                    : 'This chart represents network providers who have had their cultural competence assessed and benchmarked by Violet. It includes both providers that are active on the platform and providers whose Benchmarks are inferred through data analytics.'
                }
                title="Cultural competencies"
                titleVariant="h3"
              />
              {isComparativeDataEnabled === true ? (
                isFetchingServiceAreaComparisons ||
                networkOverview === undefined ||
                serviceAreaComparisonsMeta === undefined ? (
                  <Spinner withWrapper />
                ) : (
                  <CommunityInclusivityComparisons
                    networkOverview={networkOverview}
                    serviceAreaComparisons={serviceAreaComparisons}
                    serviceAreaComparisonsMeta={serviceAreaComparisonsMeta}
                    size="large"
                  />
                )
              ) : (
                <CommunityGraphs
                  data={networkOverview?.networkInclusivityPercentages}
                  dataType="benchmarks"
                  linkToPage={NETWORK_INCLUSIVITY_URL_W_PARAMS}
                />
              )}
            </S.CommunityCard>
            {isComparativeDataEnabled === true ? (
              <MiniServiceAreasTable
                isFetchingLocation={isFetchingLocation}
                isFetchingServiceAreaComparisons={isFetchingServiceAreaComparisons}
                locationComparisons={locationComparisons}
                networkLocations={networkLocations}
              />
            ) : (
              <DataCard data-cy="demographics-community-graphs">
                <DataCardHeader
                  badgeColor="green"
                  badgeText="On Violet"
                  description="This chart represents active providers who have voluntarily submitted profile information onto Violet's platform, enabling more accurate REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics."
                  title="Diversity"
                  titleVariant="h3"
                />
                <CommunityGraphs
                  data={networkOverview?.networkDiversityPercentages}
                  dataType="demographics"
                  linkToPage="/dashboard/my-network/inclusivity/diversity"
                />
              </DataCard>
            )}
          </DataReportRow>
        </S.DataWrapper>
      )}

      <S.TableWrapper>
        {isComparativeDataEnabled === true ? (
          <DataCard
            data-cy="demographics-community-graphs"
            isVertical={isComparativeDataEnabled}
          >
            <S.CustomDemographicsCardHeader
              badgeColor="green"
              badgeText="On Violet"
              description="This chart represents active providers who have voluntarily submitted profile information onto Violet's platform, enabling more accurate REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics."
              title="Diversity"
              titleVariant="h3"
            />
            <CommunityGraphs
              data={networkOverview?.networkDiversityPercentages}
              dataType="demographics"
              isVertical={isComparativeDataEnabled}
              linkToPage="/dashboard/my-network/inclusivity/diversity"
              size="large"
            />
          </DataCard>
        ) : (
          <MiniServiceAreasTable
            isFetchingLocation={isFetchingLocation}
            isFetchingServiceAreaComparisons={isFetchingServiceAreaComparisons}
            locationComparisons={locationComparisons}
            networkLocations={networkLocations}
          />
        )}
        <MiniProviderOrganizationsTable
          isFetchingNetworkOrgMembers={isFetchingNetworkOrgMembers}
          networkOrgMembers={networkOrgMembers}
        />
      </S.TableWrapper>
    </>
  );
};

export default MyNetwork;
