import QuestionMarkIcon from '@material-design-icons/svg/round/help_outline.svg?react';
import UnstyledButton from '@/components/UnstyledButton';
import { FOCUS_STYLES, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const Bar = styled.div`
  width: 100%;
  background: var(--gray-100);
  height: 1.25rem;
  border-radius: 5.4375rem;
  margin-left: 0.25rem;
`;

interface LabelProps {
  $isReport?: boolean;
}

export const HelpIcon = styled(QuestionMarkIcon)`
  display: block;
  fill: var(--gray-400);
  width: 1rem;
  height: 1rem;
`;

export const Label = styled.div<LabelProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.375rem;
  color: var(--gray-800);

  ${({ $isReport }) => {
    if ($isReport === true) {
      return `
        ${TEXT_STYLES.buttonTextSmallScreenLarge}
        width: 3.125rem;
        margin-right: 0.75rem;
      `;
    } else {
      return `
        ${TEXT_STYLES.bodyCopyMedium}
        width: 3.9rem;
      `;
    }
  }}
`;

export const LabelWrapper = styled.div``;

interface PercentValueProps {
  $isReport?: boolean;
}

interface MeterProps {
  $isVertical?: boolean;
  $size?: 'small' | 'large';
}

export const Meter = styled.div<MeterProps>`
  display: flex;
  width: 100%;

  ${({ $isVertical = false }) => {
    if ($isVertical) {
      return `
        flex-direction: column;
        gap: 0.25rem;

        ${Bar} {
          margin-left: 0;
        }

        ${Label} {
          ${TEXT_STYLES.buttonTextLargeScreen}
        }
      `;
    }
  }}

  ${({ $size = 'small' }) => {
    if ($size === 'large') {
      return `
        ${Bar} {
          height: 1.5rem;
        }

        ${ValueBar} {
          height: 1.5rem;
        }
      `;
    }
  }}

  ${({ $isVertical = false, $size = 'small' }) => {
    if ($isVertical && $size === 'large') {
      return `
        border-bottom: 2px solid var(--gray-200);
        padding-bottom: 2.5rem;
      `;
    } else if ($isVertical && $size === 'small') {
      return `
        border-bottom: 1px solid var(--gray-200);
        padding-bottom: 1rem;
      `;
    }
  }}
`;

export const MeterContent = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const PercentValue = styled.div<PercentValueProps>`
  align-items: flex-start;
  color: var(--purple-400);
  margin-left: 0.875rem;
  display: flex;
  justify-content: end;

  ${({ $isReport }) => {
    if ($isReport === true) {
      return `
        ${TEXT_STYLES.ctaBaseRegular}
        min-width: 3.1rem;
      `;
    } else {
      return `
        ${TEXT_STYLES.ctaBaseSmall}
        min-width: 2.55rem;
      `;
    }
  }}
`;

export const PopoverButton = styled(UnstyledButton)`
  &.is-focused {
    ${FOCUS_STYLES}
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-700);
  white-space: nowrap;
`;

/* There is no standard text style for this combination */
export const Subtitle = styled.div`
  color: var(--gray-700);
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.4;
`;

export const ValueBar = styled.div`
  background: var(--purple-400);
  height: 1.25rem;
  border-radius: 3.1875rem;
`;
