import { Temporal } from '@js-temporal/polyfill';
import { useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import BeginEvaluationModalDialog from '@/components/Education/BeginEvaluationModalDialog';
import CourseDetailsModalDialog from '@/components/Education/CourseDetailsModalDialog';
import EducationCompletedModalDialog from '@/components/Education/EducationCompletedModalDialog';
import ResourceCard from '@/components/Education/ResourceCard';
import MiniProgressBar from '@/components/MiniProgressBar';
import PageTitle from '@/components/PageTitle';
import Spinner from '@/components/Spinner';
import useGetCourse from '@/pages/Dashboard/hooks/useGetCourse';
import useGetPathway from '@/pages/Dashboard/hooks/useGetPathway';
import getPathwayProgress from '@/utils/getPathwayProgress';
import isNonEmptyString from '@/utils/isNonEmptyString';
import { useUpdateEffect } from 'usehooks-ts';

import PathwayMetaData from './PathwayMetaData';
import PathwaySidebar from './PathwaySidebar';
import * as S from './styles';

const Pathway = () => {
  const { courseId, pathwayId } = useParams<{ courseId?: string; pathwayId: string }>();
  const { isFetching, pathway, refetchPathway, startPathway } = useGetPathway(pathwayId ?? '');
  const { changeCourseId, course, refetchCourse } = useGetCourse(courseId);
  const pathwayUrl = `/dashboard/education/pathways/${pathwayId}`;
  const completedAt = pathway?.completion === null ? null : pathway?.completion?.completedAt;
  const isIncomplete = completedAt === null || completedAt === undefined;
  const startedAt = pathway?.completion === null ? null : pathway?.completion?.startedAt;

  const [accreditedCollectionId, setAccreditedCollectionId] = useState<string>();
  const [currentProgressStep, setCurrentProgressStep] = useState<number | undefined>(undefined);
  const [currentCompletedSteps, setCurrentCompletedSteps] = useState<number>(0);

  const {
    close: closeCourseDetailsModalDialog,
    isOpen: courseDetailsModalDialogIsOpen,
    open: openCourseDetailsModalDialog
  } = useOverlayTriggerState({
    defaultOpen: useMatch(`${pathwayUrl}/${courseId}`) !== null
  });

  const {
    close: closeEducationCompletedModalDialog,
    isOpen: educationCompletedModalDialogIsOpen,
    open: openEducationCompletedModalDialog
  } = useOverlayTriggerState({});

  const {
    close: closeBeginEvaluationModalDialog,
    isOpen: beginEvaluationModalDialogIsOpen,
    open: openBeginEvaluationModalDialog
  } = useOverlayTriggerState({});

  const [isFirstCompletedEducation, setIsFirstCompletedEducation] = useState(false);

  const handleCloseCourseDetailsModalDialog = () => {
    closeCourseDetailsModalDialog();
    refetchPathway(true);
    if (window.location.pathname === `${pathwayUrl}/${courseId}`) {
      window.history.pushState(null, document.title, pathwayUrl);
    }
  };

  const handleNextCoursePress = () => {
    if (startedAt === null) {
      startPathway();
    }

    openCourseDetailsModalDialog();

    if (window.location.pathname.includes(pathwayUrl)) {
      window.history.pushState(null, document.title, `${pathwayUrl}/${pathway?.nextCourseId}`);
      changeCourseId(
        pathway?.nextCourseId !== null && pathway?.nextCourseId !== undefined
          ? pathway.nextCourseId
          : ''
      );
    }
  };

  const handleOpenEvaluationModalDialog = (collectionId: string) => {
    setAccreditedCollectionId(collectionId);
    openBeginEvaluationModalDialog();
  };

  useUpdateEffect(() => {
    if (course !== undefined) {
      const accreditedCollection = course.courseCollections.find(
        collection =>
          collection.is_accredited && !collection.is_evaluated && collection.is_completed
      );
      setAccreditedCollectionId(accreditedCollection?.id ?? '');
    }
  }, [course]);

  useUpdateEffect(() => {
    const { completedStepsCount, inProgressStep } = getPathwayProgress(pathway);
    setCurrentProgressStep(inProgressStep);
    setCurrentCompletedSteps(completedStepsCount);
  }, [pathway]);

  return (
    <>
      <S.MainGrid>
        {pathway && (
          <PageTitle
            data-cy="pathway-page-title"
            title={pathway.name}
            titleVariant="h1"
          />
        )}
        <S.ContentGrid>
          {isFetching || pathway === undefined ? (
            <>
              <div />
              <Spinner withWrapper />
            </>
          ) : (
            <>
              <PathwaySidebar pathway={pathway} />
              <div>
                <S.PathwayMetaContainer>
                  <PathwayMetaData pathway={pathway} />
                  <S.PathwayProgressBar className={!isIncomplete ? 'completed' : ''}>
                    <S.PathwayProgressLabel>
                      {isIncomplete ? 'Progress' : 'Completed'}
                      {completedAt !== null && completedAt !== undefined ? (
                        <S.PathwayCompletedDate>
                          {Temporal.PlainDate.from(
                            completedAt[completedAt.length - 1] === 'Z'
                              ? completedAt.slice(0, -1)
                              : completedAt
                          ).toLocaleString('en-US', { dateStyle: 'long' })}
                        </S.PathwayCompletedDate>
                      ) : null}
                    </S.PathwayProgressLabel>
                    <MiniProgressBar
                      completedSteps={currentCompletedSteps}
                      inProgressStep={currentProgressStep}
                      label="Pathway progress"
                      totalSteps={pathway.resources.length}
                    />
                  </S.PathwayProgressBar>
                  {!isIncomplete ? null : (
                    <S.NextActionButton
                      data-cy="pathway-page-cta"
                      size="large"
                      onPress={handleNextCoursePress}
                    >
                      {startedAt !== null ? 'Resume' : 'Start pathway'}
                    </S.NextActionButton>
                  )}
                </S.PathwayMetaContainer>
                <S.ResourceList>
                  {pathway.resources.map((resource, index) => (
                    <ResourceCard
                      key={resource.id}
                      index={index}
                      maxResources={pathway.resources.length}
                      nextResourceId={pathway.nextResourceId ?? null}
                      resource={resource}
                    />
                  ))}
                </S.ResourceList>
              </div>
            </>
          )}
        </S.ContentGrid>
      </S.MainGrid>
      {courseDetailsModalDialogIsOpen && course && (
        <CourseDetailsModalDialog
          close={handleCloseCourseDetailsModalDialog}
          educationalResource={course}
          openEducationCompletedModalDialog={(isFirstCompletedEducation = false) => {
            setIsFirstCompletedEducation(isFirstCompletedEducation);
            openEducationCompletedModalDialog();
          }}
          refetchEducationalResources={refetchCourse}
        />
      )}
      {educationCompletedModalDialogIsOpen && course && (
        <EducationCompletedModalDialog
          close={closeEducationCompletedModalDialog}
          educationalResource={course}
          isFirstCompletedEducation={isFirstCompletedEducation}
          openBeginEvaluationModalDialog={handleOpenEvaluationModalDialog}
          refetchEducationalResources={refetchCourse}
        />
      )}
      {accreditedCollectionId !== undefined &&
        isNonEmptyString(accreditedCollectionId) &&
        beginEvaluationModalDialogIsOpen && (
          <BeginEvaluationModalDialog
            close={closeBeginEvaluationModalDialog}
            collectionId={accreditedCollectionId}
          />
        )}
    </>
  );
};

export default Pathway;
