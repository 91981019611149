import { MOBILE_HEADER_HEIGHT } from '@/pages/Dashboard/constants';
import styled from 'styled-components';

export const ScreenSizeWarning = {
  Container: styled.div`
    margin-top: -${MOBILE_HEADER_HEIGHT}px;
  `,
  Image: styled.img`
    display: block;
    margin-right: auto;
    margin-bottom: 3.125rem;
    margin-left: auto;
  `,
  Root: styled.div`
    position: fixed;
    top: ${MOBILE_HEADER_HEIGHT}px;
    display: grid;
    place-content: center;
    height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
    padding-right: 1.875rem;
    padding-left: 1.875rem;
    background-color: var(--purple-600);
  `,
  Text: styled.p`
    margin-bottom: 0;
    font-size: 2.375rem;
    font-weight: 500;
    line-height: 1.11;
    text-align: center;
    color: white;
  `
};
