import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

import Checkbox from '../../Checkbox';

export const CheckboxLabel = styled(Checkbox)`
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: start;
`;

export const Option = styled.li`
  ${TEXT_STYLES.formsLabelRegular}
  display: flex;
  justify-content: space-between;
  color: var(--gray-800);
  padding: 0.31rem 0.5rem;
  border-radius: 0.375rem;
  outline: 0;
  cursor: pointer;

  &.is-hovered,
  &:hover {
    background-color: var(--gray-200);
  }

  &.hidden {
    display: none;
  }
`;
