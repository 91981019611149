import { transparentize } from 'polished';
import { GRAY_400_COLOR } from '@/components/GlobalStyle';
import { TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const NotAvailableText = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2.25rem;
  ${TEXT_STYLES.tooltipTitle}
  color: ${transparentize(0.2, GRAY_400_COLOR)};
`;
