import ArrowForwardIcon from '@material-design-icons/svg/round/arrow_forward.svg?react';
import { FOCUS_STYLES } from '@/constants';
import type { IStyledComponent } from 'styled-components';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../constants';

export const CallToActionLink: {
  Header: IStyledComponent<'web', object>;
  Icon: IStyledComponent<'web', object>;
  IconWrapper: IStyledComponent<'web', object>;
  Root: IStyledComponent<'web', object>;
  Text: IStyledComponent<'web', object>;
} = {
  Header: styled.p`
    ${TEXT_STYLES.headlineSectionLevel2Regular}
    grid-area: 1 / 1 / 2 / 6;
  `,
  Icon: styled(ArrowForwardIcon)`
    width: 1.5rem;
    height: auto;
    fill: var(--brand-tints-light);
    transition: fill 0.3s;
  `,
  IconWrapper: styled.div`
    display: grid;
    grid-area: 2 / 5 / 3 / 6;
    justify-self: right;
    place-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--purple-400);
    border-radius: 100%;
    transition: background-color 0.3s;
  `,
  Root: styled.button`
    all: unset;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #f8f0f9;
    border-radius: 0.438rem;
    transition:
      color 0.3s,
      background-color 0.3s;
    cursor: pointer;

    ${MOBILE_BREAKPOINT_RANGE} {
      column-gap: 1.75rem;
      margin-bottom: 2.1875rem;
      padding-right: 1.8125rem;
      padding-left: 1.5625rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      column-gap: 1.875rem;
      margin-top: 1.5rem;
      padding-right: 1.375rem;
      padding-left: 1.375rem;
    }

    &.is-focused {
      ${FOCUS_STYLES}
    }

    &.is-hovered {
      color: white;
      background-color: #b266c0;

      ${() => {
        const { styledComponentId: iconWrapperClassName } = CallToActionLink.IconWrapper;

        return `
          .${iconWrapperClassName} {
            background-color: white;

            & > svg {
              fill: #e03fff;
            }
          }
        `;
      }}
    }

    &.is-pressed {
      background-color: #83039c;
    }
  `,
  Text: styled.p`
    ${TEXT_STYLES.bodyCopyMedium}
    margin-bottom: 0;
    grid-area: 2 / 1 / 3 / 5;
  `
};
