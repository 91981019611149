import { Temporal } from '@js-temporal/polyfill';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { Item } from 'react-stately';
import useApiRequest from '@/pages/Dashboard/hooks/useApiRequest';
import { useUpdateEffect } from 'usehooks-ts';

import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';

import * as S from './styles';

interface Props {
  bookmarks: Bookmark[];
  completedAt?: string | null;
  id: string;
  isNew: boolean;
  isPopular: boolean;
  resourceType: 'Course' | 'CourseCollection' | 'Pathway';
}

const ResourceCardHeader = ({
  bookmarks,
  completedAt,
  id,
  isNew,
  isPopular,
  resourceType
}: Props) => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const { deleteRequest, postRequest } = useApiRequest();

  const assignments = bookmarks.filter(bookmark => bookmark.assigned_by_name !== null);
  const isAssigned = assignments.length > 0;
  const bookmark = bookmarks.find(
    bookmark => bookmark.user_id !== null && bookmark.assigned_by_name === null
  );

  const [bookmarkId, setBookmarkId] = useState(bookmark ? bookmark.id : undefined);
  const [bookmarkIsDisabled, setBookmarkIsDisabled] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(bookmark !== undefined);

  const completedAtDate =
    completedAt !== null && completedAt !== undefined
      ? Temporal.PlainDate.from(
          completedAt[completedAt.length - 1] === 'Z' ? completedAt.slice(0, -1) : completedAt
        ).toLocaleString('en-US', { dateStyle: 'long' })
      : '';

  useUpdateEffect(() => {
    setIsBookmarked(bookmark !== undefined);
  }, [bookmark]);

  const handleBookmarkPress = async () => {
    setBookmarkIsDisabled(true);

    if (isBookmarked) {
      setIsBookmarked(false);

      const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/bookmarks/${bookmarkId}`;
      try {
        await deleteRequest(url);
        setBookmarkId(undefined);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
        setIsBookmarked(true);
      }
    } else {
      setIsBookmarked(true);

      const url: RequestInfo = `${import.meta.env.VITE_API_V2_BASE_PATH}/bookmarks`;
      try {
        const { data } = (await postRequest(url, {
          bookmark: {
            resource_id: id,
            resource_type: resourceType
          }
        })) as APIBookmarks;
        setBookmarkId(data.id);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
        setIsBookmarked(false);
      }
    }

    setBookmarkIsDisabled(false);
  };

  return (
    <S.Header>
      {completedAt !== undefined && completedAt !== null ? (
        <S.EducationTagGroup
          aria-label="Completion tag"
          data-cy="education-completed-tag"
          variant="green"
        >
          <Item textValue={`Completed ${completedAtDate}`}>Completed {completedAtDate}</Item>
        </S.EducationTagGroup>
      ) : (
        (isNew || isPopular) && (
          <S.EducationTagGroup
            aria-label="Education tags"
            data-cy={`education-${isNew ? 'new' : 'popular'}-tag`}
            variant={isNew ? 'green' : 'blue'}
          >
            {isNew ? <Item textValue="New">New</Item> : <Item textValue="Trending">Trending</Item>}
          </S.EducationTagGroup>
        )
      )}
      {isAssigned && <S.Assigned assignments={assignments} />}
      {resourceType !== 'Pathway' && (
        <S.Bookmark
          isDisabled={bookmarkIsDisabled}
          isSelected={isBookmarked}
          onPress={handleBookmarkPress}
        />
      )}
    </S.Header>
  );
};

export default ResourceCardHeader;
