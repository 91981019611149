import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';
import ModalDialog from '../ModalDialog';
import type { ButtonGroupProps } from './sharedStyles';
import { sharedModalStyles } from './sharedStyles';

export const BenchmarkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ButtonGroup = styled.div<ButtonGroupProps>`
  ${sharedModalStyles.buttonGroup}
`;

export const NoBenchmarksText = styled.p`
  ${sharedModalStyles.text}
  ${sharedModalStyles.previewBlock}
  margin-bottom: 0;
  background-color: var(--warning-100);
  color: var(--warning-700);
  flex-direction: row;
  align-items: start;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--warning-700);
  }
`;

export const SelectionSection = styled.div`
  margin-bottom: 0.75rem;
`;
export const ShareBenchmarksModal = styled(ModalDialog)`
  background: var(--gray-25);

  > button {
    margin-bottom: 0;
  }

  h3 {
    ${TEXT_STYLES.headlineStandardLevel2Regular}
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: var(--purple-400);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 30rem;
  }
`;

export const Text = styled.p`
  ${sharedModalStyles.text}
`;
