import type { ReactNode } from 'react';
import { useRef } from 'react';

import * as S from './styles';

interface Props {
  children: ReactNode;
}

const NoResultsOption = ({ children }: Props) => {
  const ref = useRef<HTMLLIElement>(null);

  return <S.Option ref={ref}>{children}</S.Option>;
};

export default NoResultsOption;
