import type { ReactNode } from 'react';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props {
  children: ReactNode;
  className?: string;
  copy?: string;
  header?: string;
}

const InputGroup = ({ children, className, copy, header }: Props) => (
  <S.InputGroup className={className}>
    <S.TextContainer>
      {isNonEmptyString(header) && <S.Header>{header}</S.Header>}
      {isNonEmptyString(copy) && <S.Description>{copy}</S.Description>}
    </S.TextContainer>
    <S.InputsContainer>{children}</S.InputsContainer>
  </S.InputGroup>
);

export default InputGroup;
