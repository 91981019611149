import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';

export const RESOURCE_CARD_MAX_WIDTH = 400;
export const RESOURCE_CARD_MIN_WIDTH = 300;

export const RESOURCE_CARD_STYLES = {
  card: `
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: ${RESOURCE_CARD_MIN_WIDTH}px;
    max-width: ${RESOURCE_CARD_MAX_WIDTH}px;

    ${MOBILE_BREAKPOINT_RANGE} {
      justify-self: center;
    }
  `,
  cardContent: `
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid var(--gray-200);
    border-top-width: 0;
    background: #fff;
    flex-grow: 1;
  `,
  cardDescription: `
    ${TEXT_STYLES.bodyCopyMedium}
    color: var(--gray-500);
    margin-bottom: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `,
  cardLabel: `
    ${TEXT_STYLES.bodyCopyMedium}
    font-weight: 500;
    line-height: 1;
    display: flex;
    padding: 0.75rem 1.5rem;
    gap: 0.625rem;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid var(--gray-200);
  `,
  cardTagItem: `
    color: var(--gray-500);
    width: 100%;
    ${TEXT_STYLES.bodyCopyMedium}
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.0088rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray-200);
    padding: 0.25rem 0.5rem;
    gap: 0.25rem;

    &:first-child {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 0.5rem 0.5rem;
    }

    &.progress-tag {
      height: 2.25rem;
      padding: 0.5rem;
      gap: 0.5rem;
      background-color: var(--gray-25);
    }

    > svg:first-child {
      fill: var(--purple-400);
      min-width: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
    }

    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  cardTags: `
    list-style: none;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-200);
    padding: 0;
    margin-top: auto;
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;

    ${MOBILE_BREAKPOINT_RANGE} {
      width: 100%;
    }
  `,
  cardTitle: `
    margin-bottom: 0.5rem;
    ${TEXT_STYLES.headlineSectionLevel2Regular}
    color: var(--gray-800);
    line-height: 140%; /* 1.75rem */
    letter-spacing: 0.0125rem;
    text-decoration: none;
  `
};
