import { type AriaToastProps, useToast } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import isNonEmptyString from '@/utils/isNonEmptyString';

import type { VioletToast } from '..';

import * as S from './styles';

interface ToastProps<T extends VioletToast> extends AriaToastProps<T> {
  state: ToastState<T>;
}

const Toast = <T extends VioletToast>({ state, ...props }: ToastProps<T>) => {
  const ref = useRef(null);
  const { closeButtonProps, descriptionProps, titleProps, toastProps } = useToast(
    props,
    state,
    ref
  );
  /* TODO: reenable when animation does not break closing of toast */
  // const { animation, key } = props.toast;
  const {
    cancelable = true,
    customIcon,
    description,
    title,
    type
  } = props.toast.content as VioletToast;

  return (
    <motion.div
      key={`toast-animation-${props.toast.key}`}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      initial={{ opacity: 0, y: 10 }}
    >
      {/* TODO: motion.div not needed once aria close works with animations */}
      <S.Toast
        className={`${toastProps.className} ${type}`}
        data-cy="toast"
        {...toastProps}
        ref={ref}
        $type={type}
        /* TODO: reenable when animation does not break closing of toast */
        // data-animation={animation}
        // onAnimationEnd={() => {
        //   if (animation === 'exiting') {
        //     state.remove(key);
        //   }
        // }}
      >
        {customIcon !== undefined ? (
          customIcon
        ) : type === 'error' ? (
          <S.ErrorIcon aria-hidden="true" />
        ) : type === 'warning' ? (
          <S.WarningIcon aria-hidden="true" />
        ) : type === 'success' ? (
          <S.SuccessIcon aria-hidden="true" />
        ) : (
          <S.InfoIcon aria-hidden="true" />
        )}
        <S.TextBlock>
          {isNonEmptyString(title) && <S.Header {...titleProps}>{title}</S.Header>}
          {isNonEmptyString(description) && <S.Text {...descriptionProps}>{description}</S.Text>}
        </S.TextBlock>
        {cancelable && (
          <S.CloseButton
            aria-label="Dismiss"
            {...closeButtonProps}
          >
            <S.Close aria-hidden="true" />
          </S.CloseButton>
        )}
      </S.Toast>
    </motion.div>
  );
};

export default Toast;
