import UnstyledButton from '@/components/UnstyledButton';
import styled from 'styled-components';

import {
  DESKTOP_BREAKPOINT_RANGE,
  FOCUS_STYLES,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../../constants';

export const Definition = styled.td`
  color: var(--gray-600);
  letter-spacing: 0.006rem;
  line-height: 1.4;
  font-size: 0.625rem;
  font-weight: 400;

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: 0.75rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: 0.25rem;
  }
`;

export const DefinitionRow = styled.tr`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: minmax(100px, 155px) minmax(100px, 318px);

  ${MOBILE_BREAKPOINT_RANGE} {
    display: flex;
    flex-direction: column;
    grid-gap: unset;
  }
`;

export const DefinitionTable = styled.table`
  display: grid;
  grid-gap: 0.75rem;
  padding: var(--space-3) var(--space-5) var(--space-5);
`;

export const DefinitionTitle = styled.td`
  ${TEXT_STYLES.headlineSectionLevel2Bold}
  color: var(--gray-600);
  font-size: 1rem;
  font-weight: 700;

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: 0.5rem;
  }
`;

export const TriggerButton = styled(UnstyledButton)`
  display: block;
  margin-top: calc(0.5rem - 1px);
  padding: 0.0625rem 0.375rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-bottom: calc(1rem - 1px);
  }

  &.is-focused {
    ${FOCUS_STYLES}
  }
`;
