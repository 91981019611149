const canvas = document.createElement('canvas');
const context = canvas.getContext('2d')!;
context.font = 'normal 18px niveau-grotesk, sans-serif';

export const VIOLET_COMMUNITIES = ['bipoc', 'lgbq', 'tgnc'] as VioletCommunity[];

export const VIOLET_COMMUNITY_DEFINITIONS: Record<VioletCommunity, string> = {
  bipoc: 'Black, Indigenous, and People of Color',
  lgbq: 'Lesbian, Gay, Bisexual, and Queer',
  tgnc: 'Transgender and Gender Non-Conforming'
} as const;

export type VioletCommunity = 'bipoc' | 'lgbq' | 'tgnc';

export const generateStyledBgIcon = async (icon: string, color: string, css: string) => {
  try {
    const response = await fetch(icon);
    const text = await response.text();
    const encodedCSS = `${css}; fill: ${color}; stroke: ${color}`.replace(/"/g, "'");

    const processedSvg = text
      .replace('<svg', `<svg style="${encodedCSS}"`)
      .replace(/fill="[^"]*"/g, `fill="${color}"`)
      .replace(/stroke="[^"]*"/g, `stroke="${color}"`)
      .replace(/"/g, "'")
      .replace(/\s+/g, ' ');

    const encoded = processedSvg
      .replace(/%/g, '%25')
      .replace(/#/g, '%23')
      .replace(/{/g, '%7B')
      .replace(/}/g, '%7D')
      .replace(/</g, '%3C')
      .replace(/>/g, '%3E')
      .replace(/\s+/g, ' ');

    return `url("data:image/svg+xml;charset=UTF-8,${encoded}")`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error generating styled background icon:', error);
    return '';
  }
};
