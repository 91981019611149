import PageTitle from '@/components/PageTitle';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '@/constants';
import styled from 'styled-components';

export const CommunityDescription = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-500);
`;

export const CommunityRow = styled.div`
  display: grid;
  grid-gap: 2.25rem;
  margin-bottom: 3rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr 2fr;
  }
`;

export const CommunityTitle = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Med}
  color: var(--purple-400);
  margin: 0;
`;

export const Form = styled.form``;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 3rem;
  grid-gap: 0;
`;

export const TextBlock = styled.div``;
